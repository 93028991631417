import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

export function DecisionPanel({ children }: Props) {
  return (
    <div
      className="border-l border-solid border-gray-200 p-4 sticky top-0"
      style={{ gridColumn: 2 }}
    >
      {children}
    </div>
  );
}
