export function BookIcon() {
  return (
    <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#a)">
        <g
          clipPath="url(#b)"
          stroke="#AD362B"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="m10 6.7 3-1.2M3 5.5l3 1.2M10 10.7l3-1.2M3 9.5l3 1.2M.5.5l7.5 3 7.5-3v12l-7.5 3-7.5-3V.5Z" />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
        <clipPath id="b">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
