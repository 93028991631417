import { useFlowContext } from '../../../hooks/useFlowContext';
import type { Stakeholder } from '../../../types/Stakeholder';
import SBLCard from './SBLCard';
import SBLHeader from './SBLHeader';

export default function SBL() {
  const { flow } = useFlowContext();
  const stakeholders: Stakeholder[] = flow?.data?.stakeholders;
  return (
    <div className={'grid gap-y-10'}>
      {stakeholders?.map((stakeholder, index) => {
        const employers = stakeholder?.sbl?.mapped?.incomeData?.employerIncome;
        return (
          <div
            key={stakeholder?.firstName + index}
            className="flex flex-col gap-5"
          >
            <SBLHeader stakeholder={stakeholder} />
            {employers?.map((employer, index) => (
              <SBLCard
                key={employer?.virksomhetId + index}
                employer={employer}
              />
            ))}
          </div>
        );
      })}
    </div>
  );
}
