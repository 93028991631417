export function ClipboardIcon({
  width = 24,
  height = 24,
}: {
  width?: number;
  height?: number;
}) {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
    >
      <g clipPath="url(#a)">
        <path
          d="M14.4 19.2c-2.4 2.4-6.15 2.4-8.55 0-2.4-2.4-2.55-6-.15-8.25l6.75-6.75c1.5-1.5 3.9-1.5 5.25 0 1.5 1.5 1.5 3.9 0 5.25l-5.25 5.4c-.6.6-1.5.6-2.1 0-.6-.6-.6-1.5 0-2.1l4.2-4.2-2.25-2.1-4.05 4.05c-1.8 1.8-1.8 4.65 0 6.3 1.8 1.65 4.65 1.8 6.3 0l5.4-5.4c2.7-2.7 2.7-6.9 0-9.6-2.7-2.7-6.9-2.7-9.6 0L3.6 8.85c-3.45 3.45-3.3 9 .15 12.45C5.55 23.1 7.8 24 10.2 24c2.4 0 4.65-.9 6.3-2.7l6.45-6.45-2.1-2.1-6.45 6.45Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
