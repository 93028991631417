import { useState } from 'react';
import { Person } from './FamilyCard.types';

export function useFamilyState(data: any, onUpdateFn?: (data: any) => void) {
  const [persons, setPersons] = useState<Person[]>(
    data.households[0]?.situations?.current?.situation?.persons,
  );

  const onAdd = () => {
    const newPerson = {
      id: `child${persons.length}`,
      name: 'Barn',
      firstName: resolveFirstNameForChild(persons),
      status: 'full',
      age: 0,
      role: 'child',
      editable: true,
      parents: persons[0].id,
    };
    setPersons([...persons, newPerson]);
    onUpdateFn && onUpdateFn(newPerson);
  };

  const onUpdate = (personData: any) => {
    const changedField = personData.columnId;
    const updatedPersons = persons.map((person: any) => {
      if (person.id === personData.rowId) {
        return { ...person, [changedField]: personData.value };
      }
      return person;
    });
    setPersons(updatedPersons);
    onUpdateFn && onUpdateFn(updatedPersons);
  };

  const onDelete = (personData: any) => {
    setPersons(persons.filter((person) => person.id !== personData.id));
    onUpdateFn && onUpdateFn(persons);
  };

  return { persons, onAdd, onUpdate, onDelete };
}

function resolveFirstNameForChild(persons: Person[]) {
  const numberOfChildren = persons.filter((person) => person.role === 'child');
  return `Barn ${numberOfChildren.length + 1}`;
}
