import { useFlowContext } from '../../hooks/useFlowContext';
import { GreenIcon } from '../icons/GreenDecisionIcon';
import { LoadingSpinner } from '../icons/LoadingSpinner';
import { RedIcon } from '../icons/RedDecisionIcon';
import { YellowIcon } from '../icons/YellowDecisionIcon';
import { DecisionCode } from './ScoringCard/DecisionsHelper';

type DecisionIconProps = {
  decisionCode?: string;
};

export function ConditionalDecisionIcon({ decisionCode }: DecisionIconProps) {
  const { isReevaluating } = useFlowContext();
  switch (decisionCode) {
    case DecisionCode.RED:
      return isReevaluating ? <LoadingSpinner /> : <RedIcon size={16} />;
    case DecisionCode.YELLOW:
      return isReevaluating ? <LoadingSpinner /> : <YellowIcon />;
    case DecisionCode.GREEN:
      return isReevaluating ? <LoadingSpinner /> : <GreenIcon />;
    default:
      return null;
  }
}
