import { formatMoney } from '../../../utils/formatMoney';
import { HandMoneyIcon } from '../../icons/HandMoneyIcon';
import { Card } from '../Card';
import { useFlowContext } from '../../../hooks/useFlowContext';
import { IncomeTable } from './IncomeTable';
import { IncomeCardProps, Income } from './IncomeCard.types';

export function IncomeCard({ incomes }: IncomeCardProps) {
  const {
    t,
    flow: { data },
  } = useFlowContext();

  return (
    <Card>
      <Card.Header className="justify-between bg-grass-90 text-grass-20 ">
        <div className="flex gap-3 ">
          <HandMoneyIcon size={24} />
          <Card.HeaderTitle>{t('incomes')}</Card.HeaderTitle>
        </div>
        <div className="flex gap-1">
          <span>{formatMoney(getTotalIncome(incomes))}</span>
          <span className="text-secondary-200">NOK</span>
        </div>
      </Card.Header>
      <Card.Content>
        {data.stakeholders?.length > 0 && (
          <IncomeTable incomes={incomes} stakeholders={data.stakeholders} />
        )}
      </Card.Content>
    </Card>
  );
}

function getTotalIncome(incomes: Income[]): number {
  return incomes.reduce((a: number, b: Income) => a + b.value, 0);
}
