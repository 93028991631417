import { Loan } from '../../../../types/DisbursementTypes';

export function fetchTotalFeeAmount(loan: Loan): number {
  return (
    (loan.fees ?? []).reduce(
      (
        total: number,
        fee: {
          feeCode: string;
          amount: number;
        },
      ) => total + fee.amount,
      0,
    ) ?? 0
  );
}
