import { formatMoney } from '../../../utils/formatMoney';
import { DownArrowIcon } from '../../icons/DownArrowIcon';
import { Card } from '../Card';
import { List } from '../List';
import { DecisionCode, DecisionId, findDecisionCode } from './DecisionsHelper';
import { useFlowContext } from '../../../hooks/useFlowContext';
import { ListItemWithDecisionIconType } from '../ListItemWithDecisionIcon';
import { isSelectedLoan } from '../../../utils/isSelectedLoan';
import { Debt } from '../DebtCard/DebtCard.types';
import { Asset } from '../AssetsCard/AssetsCard.types';
import { useProductStore } from '../../../customer/stores/useProductStore';
import { findCollateralObject } from '../../../customer/tasks/ManuellBehandling/utils/findCollateralObject';
import { useSituationStore } from '../../../customer/stores/useSituationStore';
import { TaskProps } from '../../../types/TaskProps';

export function ScoringCardLoan({
  selectedLoan,
  selectedProperty,
  situation,
}: {
  selectedLoan?: Debt;
  selectedProperty?: Asset;
  situation?: any;
}) {
  const {
    t,
    flow: { data },
  } = useFlowContext();

  const usedSituation =
    situation?.situation ?? data.households?.[0].situations?.current.situation;

  const storeAssets = useSituationStore((s) => s.assets);

  const assets = situation ? situation.situation.assets : storeAssets;

  const loan = selectedLoan ?? usedSituation.debts.find(isSelectedLoan);
  const loanAmount = loan.value;
  const propertyValue = findPropertyValue(assets, data);
  const decisions = data.decisions;

  const storeCoreProduct = useProductStore((s) => s?.ltvProduct?.product);
  const coreProduct = situation
    ? situation?.ltvProduct?.product
    : storeCoreProduct;

  const productText = coreProduct
    ? `${t(coreProduct?.rateCode)} - ${(coreProduct?.rateValue * 100).toFixed(
        2,
      )}%`
    : t('noProduct');

  const items: ListItemWithDecisionIconType[] = [
    {
      label: t('balance'),
      value: formatMoney(loanAmount),
      decisionCode: findDecisionCode(
        decisions,
        DecisionId.LOAN_AMOUNT_ABOVE_MAX,
      ),
    },
    {
      label: t('propertyValue'),
      value: formatMoney(propertyValue),
      decisionCode: findDecisionCode(decisions, DecisionId.HIGH_PROPERTY_VALUE),
    },
    {
      label: t('product'),
      value: productText,
      decisionCode: DecisionCode.GREEN,
    },
    {
      label: t('loanType'),
      value: loan.loanType,
      decisionCode: DecisionCode.GREEN,
    },
    {
      label: t('gracePeriod'),
      value: loan.gracePeriod ?? 0,
      decisionCode: DecisionCode.GREEN,
    },
    {
      label: t('isSysconLoan'),
      value: loan.sourceSystemCode === 'SYSCON' ?? false,
      decisionCode: findDecisionCode(decisions, DecisionId.IS_SYSCON_LOAN),
    },
  ];

  return (
    <Card>
      <Card.Header className=" bg-negative-50 font-bold text-negative-800">
        <DownArrowIcon />
        <Card.HeaderTitle>{t('theLoan')}</Card.HeaderTitle>
      </Card.Header>
      <Card.Content>
        <List items={items} />
      </Card.Content>
    </Card>
  );
}

export function findPropertyValue(
  assets: Asset[],
  state: TaskProps['flow']['data'],
) {
  const property = findCollateralObject(assets, state?.loans?.[0].securities);
  if (property) {
    return property.marketValue ?? 0;
  }
  return (assets || [])
    .filter((asset) => asset.isSecurity)
    .reduce((acc, asset) => acc + (asset.marketValue ?? 0), 0);
}
