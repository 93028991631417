import WarningIcon from '../../../../components/icons/WarningIcon';
import { Card } from '../../../../components/_shared/Card';
import { List } from '../../../../components/_shared/List';
import { ListItemType } from '../../../../components/_shared/ListItem';

export default function WarningCard({
  title,
  items,
}: {
  title: string;
  items: ListItemType[];
}) {
  return (
    <Card>
      <Card.Header className="justify-between bg-negative-50 text-negative-600">
        <div className="flex gap-3">
          <WarningIcon size={24} />
          <Card.HeaderTitle>{title}</Card.HeaderTitle>
        </div>
      </Card.Header>
      <Card.Content>
        <List items={items} />
      </Card.Content>
    </Card>
  );
}
