export function PdfIcon() {
  return (
    <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#a)" fill="#003CC7">
        <path d="M7.668 10.333v2a1.001 1.001 0 0 0 0-2ZM4.5 10.333H4v1h.5a.5.5 0 0 0 0-1Z" />
        <path d="M10.943.667h-7.61C2.598.667 2 1.265 2 2v12c0 .735.598 1.333 1.333 1.333h9.334c.735 0 1.333-.598 1.333-1.333V3.724L10.943.667ZM4.5 12.333H4v1H3v-4h1.5c.827 0 1.5.673 1.5 1.5 0 .828-.673 1.5-1.5 1.5Zm3.167 1h-1v-4h1c1.103 0 2 .897 2 2 0 1.104-.897 2-2 2Zm5.533-3H11V11h1.667v1H11v1.333h-1v-3c0-.551.449-1 1-1h2.2v1ZM10 4.666V1.333l3.333 3.333H10Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
