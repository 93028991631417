import { LoanType } from '../../../../components/_shared/DebtCard/DebtCard.types';

export function isFinancingApplication(state: any): boolean {
  return !!state?.proofOfFinance;
}

export function isProofOfFinanceProduct(loanProduct: string): boolean {
  return loanProduct === 'applyFinance' || loanProduct === 'buyProperty';
}

export function isBridgingLoanProduct(loanProduct: string): boolean {
  return loanProduct === LoanType.BRIDGING_LOAN;
}
