import { PersonIcon } from '../../../components/icons/PersonIcon';
import { Card } from '../../../components/_shared/Card';
import type { Stakeholder } from '../../../types/Stakeholder';

export function MessageCard({
  stakeholder,
  message,
}: {
  stakeholder: Stakeholder;
  message: {
    text: string;
    date: string;
  };
}) {
  const time = new Date(message?.date ?? new Date()).toLocaleTimeString();
  const date = new Date(message?.date ?? new Date()).toLocaleDateString();
  return (
    <Card>
      <Card.Header className="justify-between bg-grass-90 text-grass-20 ">
        <div className="flex gap-3">
          <PersonIcon height={24} width={24} />
          <Card.HeaderTitle>
            {stakeholder?.fullName ?? stakeholder?.nationalId}
          </Card.HeaderTitle>
        </div>
        <span>{date + ' ' + time}</span>
      </Card.Header>
      <Card.Content>
        <span>
          <p>{message?.text}</p>
        </span>
      </Card.Content>
    </Card>
  );
}
