import { WarningTooltip } from '../../../customer/tasks/GjennomforUtbetalingsKontroll/DisbursementControlCard/Components/WarningTooltip';
import { useFlowContext } from '../../../hooks/useFlowContext';

type CloudmersiveScanResult = {
  message: string;
  result: VirusScanResult;
  documentType?: string;
  uploadedDate?: Date;
};

export type FoundVirus = {
  FileName: string;
  VirusName: string;
};
export interface VirusScanResult {
  CleanResult: boolean;
  FoundViruses: FoundVirus[];
}

export function VirusFileRow({ data }: { data: CloudmersiveScanResult }) {
  const { t } = useFlowContext();
  return (
    <>
      <tr className=" border-misc-ui-separator border-t">
        <td className="grid py-3 place-items-center">
          <WarningTooltip text={t('scanResultMaliciousContentFound')} />
        </td>
        <td>{}</td>
        {t(data.documentType ?? '')}
        <td>{}</td>
        <td className="text-right pr-2">{}</td>
        <td className="w-3 text-sea-30 pr-5"></td>
      </tr>
    </>
  );
}
