import { TaskProps } from '../../../types/TaskProps';
import { FormProvider, useForm } from 'react-hook-form';
import { TextArea } from '../../../components/_shared/TextArea';
import { useFlowContext } from '../../../hooks/useFlowContext';
import { DecisionButtonGroup } from '../../components/DecisionButtonGroup';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Stakeholder } from '../../../types/Stakeholder';
import { Checkbox } from '../../../components/_shared/Checkbox';
import { Select } from '../../../components/_shared/Select';

export function Decision({ save, complete }: TaskProps) {
  const { t, task, flow } = useFlowContext();
  const stakeholders: Stakeholder[] = flow?.data?.stakeholders || [];
  const hasCoApplicant = stakeholders?.length > 1;
  const hlt = stakeholders?.filter(
    (holder: Stakeholder) => holder.type === 'mainApplicant',
  )[0];

  const mlt = stakeholders?.filter(
    (holder: Stakeholder) => holder.type === 'coApplicant',
  )[0];

  const isPEPCaseForMainApplicant = fetchIsPEPCaseValue(hlt) ?? false;
  const isPEPCaseForCoApplicant = hasCoApplicant
    ? fetchIsPEPCaseValue(mlt)
    : false;

  const method = useForm({
    defaultValues: getDefaultValues(task, flow),
    resolver: zodResolver(Decision.validationSchema),
  });
  const { handleSubmit, watch } = method;
  const returnToCaseWorkerValue = watch('returnToCaseWorker');

  const decisionPEPOptions = [
    { value: '', label: '' },
    { value: 'approve-pep', label: t('approve-pep', 'Godkjenn PEP') },
    { value: 'false-pep', label: t('false-pep', 'Falsk PEP') },
    { value: 'reject-pep', label: t('reject-pep', 'Avslå PEP') },
  ];

  const onSubmit = (data: any) => {
    const structuredData = {
      returnToCaseWorker: data.returnToCaseWorker,
      assessment: data.assessment,
      ...(!data.returnToCaseWorker && {
        decisionsPEP: [
          data.isPEPCaseForMainApplicant && {
            nationalId: hlt?.nationalId,
            value: data?.decisionPEPMainApplicant,
          },
          data.isPEPCaseForCoApplicant && {
            nationalId: mlt?.nationalId,
            value: data?.decisionPEPCoApplicant,
          },
        ].filter(Boolean),
      }),
    };
    complete(structuredData);
  };

  return (
    <FormProvider {...method}>
      <form className="grid gap-4 py-8" onSubmit={handleSubmit(onSubmit)}>
        <h2 className="text-sm font-semibold text-black">{t('decision')}</h2>
        <div className="grid gap-6 pb-2">
          <Checkbox
            label={t('return-to-caseworker', 'Returner til Saksbehandler')}
            id="returnToCaseWorker"
          />
          {!returnToCaseWorkerValue && (
            <>
              {isPEPCaseForMainApplicant && (
                <Select
                  id="decisionPEPMainApplicant"
                  label="PEP Hovedlåntaker"
                  options={decisionPEPOptions}
                />
              )}
              {hasCoApplicant && isPEPCaseForCoApplicant && (
                <Select
                  id="decisionPEPCoApplicant"
                  label="PEP Medlåntaker"
                  options={decisionPEPOptions}
                />
              )}
            </>
          )}
        </div>
        <TextArea id="assessment" label={t('internal-note', 'Notat')} />
        <DecisionButtonGroup save={save} />
      </form>
    </FormProvider>
  );
}

Decision.validationSchema = z
  .object({
    returnToCaseWorker: z.boolean(),
    assessment: z.string({
      required_error: 'assessmentRequired',
      invalid_type_error: 'assessmentRequired',
    }),
    decisionPEPMainApplicant: z.string(),
    decisionPEPCoApplicant: z.string(),
    isPEPCaseForMainApplicant: z.boolean().optional(),
    isPEPCaseForCoApplicant: z.boolean().optional(),
  })
  .superRefine(
    (
      {
        returnToCaseWorker,
        assessment,
        isPEPCaseForMainApplicant,
        isPEPCaseForCoApplicant,
        decisionPEPMainApplicant,
        decisionPEPCoApplicant,
      },
      context,
    ) => {
      if (
        isPEPCaseForMainApplicant &&
        (!decisionPEPMainApplicant || decisionPEPMainApplicant === '') &&
        !returnToCaseWorker
      ) {
        context.addIssue({
          code: 'custom',
          message: 'pep-hlt-is-required',
          path: ['decisionPEPMainApplicant'],
        });
      }
      if (
        isPEPCaseForCoApplicant &&
        (!decisionPEPCoApplicant || decisionPEPCoApplicant === '') &&
        !returnToCaseWorker
      ) {
        context.addIssue({
          code: 'custom',
          message: 'pep-mlt-is-required',
          path: ['decisionPEPCoApplicant'],
        });
      }
      if (
        (isPEPCaseForMainApplicant ||
          isPEPCaseForCoApplicant ||
          returnToCaseWorker) &&
        (!assessment || assessment === '')
      ) {
        context.addIssue({
          code: 'custom',
          message: 'assessment-is-required',
          path: ['assessment'],
        });
      }
    },
  );

function fetchIsPEPCaseValue(stakeholder: Stakeholder) {
  return (
    stakeholder?.declaration?.pepDeclaration?.isPep ||
    stakeholder?.trapets?.hasHitInList
  );
}

function getDefaultValues(
  task: TaskProps['task'],
  flow: TaskProps['flow'],
): any {
  const stakeholders: Stakeholder[] = flow?.data?.stakeholders || [];

  const hasCoApplicant = stakeholders?.length > 1;
  const hlt = stakeholders?.filter(
    (holder: Stakeholder) => holder.type === 'mainApplicant',
  )[0];

  const mlt = stakeholders?.filter(
    (holder: Stakeholder) => holder.type === 'coApplicant',
  )[0];

  const isPEPCaseForMainApplicant = fetchIsPEPCaseValue(hlt) ?? false;
  const isPEPCaseForCoApplicant = hasCoApplicant
    ? fetchIsPEPCaseValue(mlt)
    : false;

  return {
    returnToCaseWorker: task.data?.returnToCaseWorker ?? false,
    decisionPEPMainApplicant: task.data?.decisionPEPMainApplicant ?? '',
    decisionPEPCoApplicant: task.data?.decisionPEPCoApplicant ?? '',
    assessment: task?.data?.assessment ?? '',
    isPEPCaseForMainApplicant,
    isPEPCaseForCoApplicant,
  };
}
