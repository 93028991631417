import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { TextArea } from '../../../components/_shared/TextArea';
import { Checkbox } from '../../../components/_shared/Checkbox';
import { DateInput } from '../../../components/_shared/DateInput';
import { useFlowContext } from '../../../hooks/useFlowContext';
import { Loan } from '../../../types/DisbursementTypes';
import { PrimaryProgressButton, SecondaryProgressButton } from '@flow/buttons';
import { TaskProps } from '../../../types/TaskProps';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { DecisionError } from '../../components/DecisionError';
import { Select } from '../../../components/_shared/Select';
import { useDisbursementControlStore } from '../../stores/useDisbursementStore';
import { fetchAccountNumberValidationError } from './utils/fetchAccountNumberValidationError';
import { fetchErrorMessage } from './utils/fetchErrorMessage';
import { calculateTotalCorrectedAmount } from './utils/calculateTotalCorrectedAmount';
import { calculateTotalFeesAmount } from './utils/calculateTotalFeesAmount';
import { fetchDefaultValues } from './utils/fetchDefaultValues';

type DecisionDefaultProps = {
  save: TaskProps['save'];
  complete: TaskProps['complete'];
  t: any;
};

export function DecisionDefault({ save, complete, t }: DecisionDefaultProps) {
  const { task } = useFlowContext();
  const { loans, updateRedemptionDate } = useDisbursementControlStore();

  const method = useForm({
    defaultValues: mapDefaultValues(task),
    resolver: zodResolver(DecisionDefault.validationSchema),
  });

  const { handleSubmit, getValues, watch } = method;

  const totalAmount =
    (loans ?? []).reduce(
      (total: number, loan: Loan) => total + loan.amount,
      0,
    ) ?? 0;

  const feeAmount = calculateTotalFeesAmount(loans);
  const [errorState, setErrorState] = useState({
    hasError: false,
    errorText: '',
  });
  const { hasError, errorText } = errorState;

  function handleSave(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    const data = getValues();

    save({
      ...data,
      loans,
    });
  }

  const onSubmit = (data: any) => {
    const { disbursementAccountNoError, payoutAccountNoError } =
      fetchAccountNumberValidationError(loans);
    const hasAccountError = disbursementAccountNoError || payoutAccountNoError;

    const totalCustomerPayout =
      totalAmount - calculateTotalCorrectedAmount(loans) - feeAmount;
    const hasTotalAmountError = totalCustomerPayout < 0;

    if (hasAccountError || hasTotalAmountError) {
      setErrorState({
        hasError: true,
        errorText: fetchErrorMessage(hasAccountError, hasTotalAmountError, t),
      });
      return;
    }

    setErrorState({
      hasError: false,
      errorText: '',
    });

    complete({
      ...data,
      loans,
    });
  };

  const redemptionDateWatch = watch('redemptionDate');

  useEffect(() => {
    updateRedemptionDate(redemptionDateWatch);
  }, [redemptionDateWatch, updateRedemptionDate]);

  return (
    <FormProvider {...method}>
      <form className="grid gap-2 py-8" onSubmit={handleSubmit(onSubmit)}>
        <h2 className="text-sm font-semibold text-black">
          {t('control-routine')}
        </h2>
        <div className="grid gap-6 ">
          <Checkbox
            label={t('mortgage-registered')}
            id="checklistRefinancing.isMortgageRegistered"
          />
          <Select
            id="checklistRefinancing.priorityStatus"
            label={t('priority-status')}
            options={[
              { value: '', label: '' },
              {
                value: 'OTHER-BANK-PANT',
                label: t('other-bank-pant-option'),
              },
              {
                value: 'CORRECT-PRIORITY',
                label: t('correct-priority-option'),
              },
            ]}
          />
          <DateInput label={t('redemption-date')} id={'redemptionDate'} />
          <TextArea id="internalComment" label={t('internal-comment')} />
        </div>
        {hasError && <DecisionError text={errorText} />}
        <div className="grid grid-cols-2 gap-4 pt-2">
          <PrimaryProgressButton type="submit">
            {t('complete')}
          </PrimaryProgressButton>
          <SecondaryProgressButton onClick={handleSave} type={'button'}>
            {t('save')}
          </SecondaryProgressButton>
        </div>
      </form>
    </FormProvider>
  );
}

DecisionDefault.validationSchema = z.object({
  redemptionDate: z
    .string({
      required_error: 'Innfrielsesdato er påkrevd',
      invalid_type_error: 'Innfrielsesdato er påkrevd',
    })
    .min(1, 'redemption-date-required')
    .refine((date) => {
      const day = new Date(Date.parse(date.toString())).getDay();
      return day !== 0 && day !== 6;
    }, 'redemption-date-invalid'),
  checklistRefinancing: z.object({
    priorityStatus: z.string().min(1, 'priority-status-required'),
    isMortgageRegistered: z
      .boolean()
      .or(z.string())
      .refine(
        (bool) => bool === 'true' || bool === true,
        'mortgage-registered-is-required',
      ),
  }),
  internalComment: z.string().optional(),
});

function mapDefaultValues(task: TaskProps['task']): any {
  const { internalComment, redemptionDate, checklistRefinancing } =
    fetchDefaultValues(task);

  return {
    internalComment,
    redemptionDate,
    checklistRefinancing,
  };
}
