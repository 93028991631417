import { ListItem, ListItemType } from '../../../components/_shared/ListItem';
import { useFlowContext } from '../../../hooks/useFlowContext';
import { RadioSelect } from '../../components/common/RadioSelect';
import { ConditionalDecisionIcon } from '../../../components/_shared/ConditionalDecisionIcon';
import { DecisionCode } from '../../../components/_shared/ScoringCard/DecisionsHelper';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { AdditionalValuesView } from '../../components/common/AdditionalValuesView';

const flowOptions = ['DIGITAL', 'PAPER'];

export function SettlementSummary() {
  const { t, task } = useFlowContext();
  const settlement = task?.context?.settlement;

  const [intentionStatus, setIntentionStatus] = useState<string>(
    settlement?.settlementIntent?.intentionStatus,
  );
  const [manualGetIntentionAvailable, setManualGetIntentionAvailable] =
    useState<boolean>(task.context.autoGetIntentionDone);

  const showGetIntentionButton =
    manualGetIntentionAvailable && intentionStatus !== 'ACCEPTED';

  useEffect(() => {
    const interval = setInterval(() => {
      getUpdatedStatus().then((manualGetIntentionAvailable: boolean) => {
        if (manualGetIntentionAvailable) {
          clearInterval(interval);
        }
      });
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <ul className="mt-4 grid rounded-md bg-white text-xs grid-cols-1">
        <AdditionalValuesView />
        {mapSettlementSummary(settlement)?.map(
          (item: ListItemType, index: number) => {
            if (item.label === 'intentionStatus') {
              return (
                <li
                  className="flex flex-row justify-between items-center px-4 divide-gray-200 border-t h-11"
                  key={`${item.label}-${index}`}
                >
                  <label>{t(item.label)}</label>
                  <div className="flex">
                    <ConditionalDecisionIcon
                      decisionCode={responseToDecisionCodeMapper(
                        intentionStatus,
                      )}
                    />
                    {showGetIntentionButton ? (
                      <a
                        href="#"
                        className="text-blue-400 underline pl-2"
                        onClick={() => onGetIntentionClick()}
                      >
                        {t('getIntention')}
                      </a>
                    ) : (
                      <span className="pl-2">{t(intentionStatus)}</span>
                    )}
                  </div>
                </li>
              );
            } else {
              return (
                <ListItem
                  key={`${item.label}-${index}`}
                  item={item}
                  styling="px-4 divide-gray-200 border-t"
                />
              );
            }
          },
        )}
        <li className="pl-4 pr-2 divide-gray-200 border-t">
          <RadioSelect id="signingFlow" options={flowOptions} />
        </li>
      </ul>
    </div>
  );

  function getUpdatedStatus() {
    return fetchTask(task.flowId, 'klargjor-oppgjor').then((response) => {
      const status =
        response.data[0]?.context?.settlement?.settlementIntent
          ?.intentionStatus || 'PENDING';
      const manualGetIntentionAvailable =
        response.data[0]?.context?.autoGetIntentionDone || false;

      setIntentionStatus(status);
      setManualGetIntentionAvailable(manualGetIntentionAvailable);
      return manualGetIntentionAvailable;
    });
  }

  function onGetIntentionClick() {
    setIntentionStatus('PENDING');
    setManualGetIntentionAvailable(false);

    triggerGetIntention(task.flowId).then(() => {
      return setTimeout(() => {
        getUpdatedStatus();
      }, 3000);
    });
  }
}

function mapSettlementSummary(settlement: any): ListItemType[] {
  return [
    {
      label: 'settlementInstance',
      value: settlement.settlementInstance?.name || '',
    },
    {
      label: 'intentionStatus',
      value: settlement.settlementIntent?.intentionStatus || '',
    },
    {
      label: 'orgNumber',
      value: settlement.settlementInstance?.orgNumber || '',
    },
    {
      label: 'accountNumber',
      value: settlement.settlementDetails?.accountNumber || '',
    },
    {
      label: 'customerIdOrMessage',
      value: settlement.settlementDetails?.customerIdOrMessage || '',
    },
  ];
}

async function fetchTask(flowId: string, taskType: string) {
  return await axios.get(
    `/api/flow/tasks?flowId=${flowId}&taskType=${taskType}&status=pending`,
  );
}

async function triggerGetIntention(flowId: string) {
  const { data: tasks } = await fetchTask(
    flowId,
    'manually-get-intention-status',
  );
  const { taskId } = tasks[0];

  await axios.post(`/api/flow/tasks/${taskId}/trigger`, {});
}

const responseToDecisionCodeMapper = (status: string) => {
  switch (status) {
    case 'ACCEPTED':
      return DecisionCode.GREEN;
    case 'REJECTED':
      return DecisionCode.RED;
    case 'PENDING':
    case 'SENT':
      return DecisionCode.YELLOW;
    default:
      return DecisionCode.RED;
  }
};
