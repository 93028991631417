import { SvgIconProps } from './SvgIcon.types';

export function CheckIcon({ color = '#2A77BF' }: SvgIconProps) {
  return (
    <svg
      width="14"
      height="10"
      viewBox="0 0 14 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.24 0.695683L4.81552 7.12011L2.00568 4.31027C1.54685 3.85144 0.802947 3.85144 0.34412 4.31027C-0.114707 4.76909 -0.114707 5.513 0.34412 5.97183L3.98475 9.61245L4.45615 9.14104L3.99533 9.62281C4.45409 10.0616 5.17696 10.0616 5.63571 9.62281L5.1749 9.14104L5.6463 9.61245L12.9015 2.35724C13.3603 1.89842 13.3603 1.15451 12.9015 0.695683C12.4427 0.236856 11.6988 0.236856 11.24 0.695683Z"
        fill={color}
      />
    </svg>
  );
}
