import { Layout } from '../../../components/Layout/Layout';
import { DecisionPanel } from '../../../components/Layout/DecisionPanel';
import { TaskProps } from '../../../types/TaskProps';
import { Stakeholders } from '../../../components/Stakeholders';
import { Tab } from '@headlessui/react';
import { ScoringCardOther } from '../../../components/_shared/ScoringCard/ScoringCardOther';
import { FamilyCard } from './FamilyCard/FamilyCard';
import { IncomeCard } from './IncomesCard/IncomeCard';
import { DebtCard } from './DebtsCard/DebtCard';
import { AssetsCard } from './AssetsCard/AssetsCard';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { EconomyCard } from './EconomyCard/EconomyCard';
import { mapDecisionsData } from './EconomyCard/useEconomyState';
import { FlowContext } from '../../../hooks/useFlowContext';
import { ManualDecisionAnalysis } from './ManualDecisionAnalysis';
import { useSituationState } from '../../../utils/useSituationState';
import { getBorrowers } from './utils/borrowersHelper';
import { useQuery } from '@tanstack/react-query';
import { Files } from '../../../components/_shared/Files';
import { fetchAttachmentsByFlowId } from '../../../utils/fetchAttachments';
import SBL from '../../../components/_shared/SBL';
import CreditCheck from '../../../components/_shared/CreditCheck';
import { PaymentPlanWrapper } from '../../../components/_shared/Paymentplan';
import { ScoringCardApplicant } from '../../../components/_shared/ScoringCard/ScoringCardApplicant';
import { ScoringCardLoan } from '../../../components/_shared/ScoringCard/ScoringCardLoan';
import { isSelectedLoan } from '../../../utils/isSelectedLoan';
import { decisionFilter, mapDecision } from './DecisionsCard/useDecisionsState';
import {
  fetchUpdatedData,
  triggerMessageTask,
} from '../../../utils/reEvaluationHelper';
import { DecisionsCard } from '../../../components/_shared/DecisionCard/DecisionCard';
import { ScoringCardPurpose } from '../../../components/_shared/ScoringCard/ScoringCard.Purpose';
import {
  initSituationStore,
  useSituationStore,
} from '../../stores/useSituationStore';

import { useProductStore } from '../../stores/useProductStore';
import { SuspenseWrapper } from '../../components/SuspenseWrapper';
import { LazyAbilityToPay } from '../../../components/Insight/AbilityToPay/LazyAbilityToPay';
import { isFinancingApplication } from './utils/loanPurposeContextHelper';
import {
  initProofOfFinancingStore,
  useProofOfFinancingStore,
} from '../../stores/useProofOfFinancingStore';
import { ProofOfFinance } from '../../../components/Insight/ProofOfFinance/LazyProofOfFinance';
import { useMutationQueryFetcher } from '../../../hooks/useMutationQueryFetcher';
import { LazyAgreementRegister } from '../../../components/Insight/AgreementRegister/LazyAgreementRegister';
import { TaskSummary } from '../../components/Summary/TaskSummary/TaskSummary';

export function ScoringOverviewEditable(props: TaskProps) {
  const [isInitializedStores, setIsInitializedStores] = useState(false);
  const { flow, t } = props;
  const data = flow.data;

  const updateFn = useCallback(() => {
    setTimeout(() => mutateAsync('mutate'), 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isInitializedStores) {
      initSituationStore(data, updateFn);
      if (isFinancingApplication(data)) {
        initProofOfFinancingStore(data.proofOfFinance, updateFn);
      }

      setIsInitializedStores(true);
    }
  }, [data, updateFn, isInitializedStores]);

  const {
    collaterals,
    collateralCoverage,
    assets,
    debts,
    setDebts,
    updateDebt,
    addDebt,
    deleteDebt,
    setBetjeningsevne,
    setBetjeningsevneShortTerm,
    setAssets,
  } = useSituationStore();

  // TODO: This will be removed when all state handling is migrated to zustand
  const { decisions, economyItems, persons, incomes } = useSituationState(
    data,
    updateFn,
  );

  const selectedLoan = debts.find(isSelectedLoan);
  const editable = true;
  const borrowers = getBorrowers(data.stakeholders);

  const { flowId } = flow;
  const { data: files } = useQuery({
    queryKey: ['files', flowId],
    queryFn: () => fetchAttachmentsByFlowId(flowId),
  });

  // TODO: Use store state for this after merging with main
  const [abilityToPaySituation, setAbilityToPaySituation] = useState(
    data.households?.[0]?.situations?.current,
  );

  const attachmentLength = files?.attachments.length ?? 0;

  const tabs = [
    t('overview', 'Oversikt'),
    t('scoring', 'Scoring'),
    t('sbl', 'SBL'),
    t('abilityToPay', 'Betjeningsevne'),
    t('sikkerheter', 'Sikkerheter'),
    ...(isFinancingApplication(data)
      ? [t('proofOfFinance', 'Finansieringsbevis')]
      : []),
    t('creditCheck', 'Kredittsjekk'),
    t('filesWithCount', { count: attachmentLength }),
    t('paymentPlan', 'Nedbetalingsplan'),
  ];

  const situation = {
    persons: persons.persons,
    incomes: incomes.incomes,
    debts: debts,
    assets: assets,
    collaterals,
  };

  const productStore = useProductStore();
  const ltv = productStore.ltvProduct?.calculatedLtv ?? 0;

  const [evaluationError, setEvaluationError] = useState(
    data.households[0].situations.current.situationError || null,
  );

  const {
    equityUtilized,
    setMaxBid,
    setCalculatedCostsOwnedHousing,
    setEquityUtilized,
  } = useProofOfFinancingStore((s) => ({
    equityUtilized: s.equityUtilized,
    setMaxBid: s.setMaxBid,
    setCalculatedCostsOwnedHousing: s.setCalculatedCostsOwnedHousing,
    setEquityUtilized: s.setEquityUtilized,
  }));

  function handleQuerySuccess(data: any) {
    const currentSituation = data.households[0].situations.current;
    economyItems.setEconomyItems(
      mapDecisionsData(currentSituation.betjeningsevne, data.decisions),
    );
    const mappedDecisions = data.decisions
      .filter(decisionFilter)
      .map((decision: any) =>
        mapDecision(decision, currentSituation, flow.data.loans),
      );
    decisions.setDecisions(mappedDecisions);
    setDebts(currentSituation.situation.debts);
    setAssets(currentSituation.situation.assets);
    setAbilityToPaySituation(currentSituation);
    setBetjeningsevne(currentSituation.betjeningsevne);
    if (currentSituation.betjeningsevneShortTerm) {
      setBetjeningsevneShortTerm(currentSituation.betjeningsevneShortTerm);
    }

    productStore.setLtvProduct(
      data.households[0].situations.current.ltvProduct,
    );
    productStore.setLtvProductShortTerm(
      data.households[0].situations.current.ltvProductShortTerm,
    );
    setEvaluationError(currentSituation.situationError || null);
    if (data.proofOfFinance) {
      setMaxBid(data.proofOfFinance.maxBid);
      setCalculatedCostsOwnedHousing(
        data.proofOfFinance.calculatedCostsOwnedHousing,
      );
      setEquityUtilized(data.proofOfFinance.equity.equityUtilized);
    }
  }

  const queryProps = {
    queryFn: () => fetchUpdatedData(flowId),
    queryKey: ['refetch', flowId],
    onSuccessFn: handleQuerySuccess,
    refetchInterval: 750,
  };

  const proofOfFinancingData = isFinancingApplication(data)
    ? {
        equityUtilized,
        isApproved: data.proofOfFinance?.isApproved,
      }
    : undefined;

  const mutationProps = {
    mutationFn: () =>
      triggerMessageTask(flowId, situation, proofOfFinancingData),
    mutationKey: ['evaluate', flowId],
  };

  const {
    data: analysisData,
    mutateAsync,
    isReevaluating,
  } = useMutationQueryFetcher({
    queryProps,
    mutationProps,
  });

  const collateralObjects = assets.filter(
    (asset) => collaterals?.[0]?.propertyIds?.includes(asset.id),
  );

  const errors = useMemo(() => {
    return validate({
      loanToValue: ltv,
      collateralCoverage,
      priority: Math.max(
        ...collateralObjects.map((collateral) => collateral.priority!),
        1,
      ),
    });
  }, [ltv, collateralCoverage, collateralObjects]);

  return (
    <FlowContext.Provider
      value={{
        ...props,
        isReevaluating,
        errors,
      }}
    >
      {!isInitializedStores ? (
        <Initializing />
      ) : (
        <Layout>
          <div>
            <Tab.Group>
              <Tab.List className="sticky top-0 z-50 bg-white">
                {tabs.map((tab, index) => (
                  <Tab as={Fragment} key={index}>
                    {({ selected }) => (
                      <button
                        className={`whitespace-nowrap border-b-2 border-transparent py-4 px-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700 ${
                          selected ? 'border-b-2 border-basic-ink' : ''
                        }`}
                      >
                        {tab}
                      </button>
                    )}
                  </Tab>
                ))}
              </Tab.List>
              <Tab.Panels className="h-full overflow-auto bg-gray-100 p-4">
                <Tab.Panel className=" space-y-12">
                  <div className="grid grid-cols-2 gap-6">
                    <DecisionsCard
                      {...decisions}
                      evaluationError={evaluationError}
                    />
                    <EconomyCard {...economyItems} />
                  </div>
                  <FamilyCard {...persons} editable={editable} />
                  <IncomeCard {...incomes} editable={editable} />
                  <DebtCard
                    debts={debts}
                    onUpdate={updateDebt}
                    onAdd={addDebt}
                    onDelete={deleteDebt}
                    loanOwnerOptions={borrowers}
                    editable={editable}
                  />
                  <AssetsCard editable={editable} />
                </Tab.Panel>
                <Tab.Panel className="grid grid-cols-2 gap-7">
                  {data.stakeholders.map((stakeholder: any, index: number) => (
                    <ScoringCardApplicant key={index} applicant={stakeholder} />
                  ))}
                  <ScoringCardLoan
                    selectedLoan={selectedLoan}
                    selectedProperty={collateralObjects[0]}
                  />
                  <ScoringCardPurpose />
                  <ScoringCardOther />
                </Tab.Panel>
                <Tab.Panel>
                  <SBL />
                </Tab.Panel>
                <Tab.Panel className="flex justify-center">
                  <SuspenseWrapper>
                    <LazyAbilityToPay
                      {...props}
                      householdSituation={abilityToPaySituation}
                      className="px-12"
                    />
                  </SuspenseWrapper>
                </Tab.Panel>
                <Tab.Panel className="h-full">
                  <SuspenseWrapper>
                    <LazyAgreementRegister {...props} />
                  </SuspenseWrapper>
                </Tab.Panel>
                {isFinancingApplication(data) && isInitializedStores && (
                  <Tab.Panel>
                    <SuspenseWrapper>
                      <ProofOfFinance isEditable={true} />
                    </SuspenseWrapper>
                  </Tab.Panel>
                )}
                <Tab.Panel>
                  <CreditCheck />
                </Tab.Panel>
                <Tab.Panel>
                  <Files {...props} />
                </Tab.Panel>
                <Tab.Panel>
                  <PaymentPlanWrapper
                    caseData={flow}
                    data={data}
                    loan={selectedLoan}
                  />
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </div>
          <div>
            <DecisionPanel>
              <Stakeholders stakeholders={data.stakeholders} />
              <TaskSummary onUpdate={updateDebt} editable={true} />
              <ManualDecisionAnalysis
                {...props}
                analysisDataErrors={analysisData?.errors}
                proofOfFinancingData={proofOfFinancingData}
              />
            </DecisionPanel>
          </div>
        </Layout>
      )}
    </FlowContext.Provider>
  );
}

function validate({
  loanToValue,
  collateralCoverage,
  priority,
}: {
  loanToValue: number;
  collateralCoverage: number;
  priority: number;
}) {
  const errors = [];

  if (loanToValue > 0.85) {
    errors.push('ltv-error-message');
  }

  if (collateralCoverage < 100) {
    errors.push('collateral-coverage-error-message');
  }

  if (priority > 1) {
    errors.push('priority-warning-message');
  }

  return errors;
}

function Initializing() {
  return (
    <div className="flex items-center justify-center h-full">
      <div className="flex flex-col items-center justify-center space-y-4">
        <div className="animate-spin rounded-full h-16 w-16 border-b-2 border-gray-900"></div>
        <div className="text-xl font-bold">Laster...</div>
      </div>
    </div>
  );
}
