import { useState } from 'react';
import { CopyIcon } from '../../icons/CopyIcon';
import { GreenIcon } from '../../icons/GreenDecisionIcon';

type EmailCardProps = {
  data: { title: string; email: string };
};

export function EmailCard({ data }: EmailCardProps) {
  const [hasCopied, hasCopiedSet] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(data?.email).then((r) => {
      hasCopiedSet(true);
      const timer = setTimeout(() => {
        hasCopiedSet(false);
      }, 2000);
      return () => clearTimeout(timer);
    });
  };

  return (
    <div
      className={
        'flex flex-col gap-y-4 rounded border border-gray-200 bg-misc-seaLightPastel py-8 px-10 '
      }
    >
      <div className={'flex w-full flex-row justify-between'}>
        <span className={'text-base font-semibold text-blue-600'}>
          {data?.title}
        </span>
        <button
          type={'button'}
          className={`focus:outline-none flex h-7 w-7 flex-col items-center justify-center rounded border border-misc-seaLightPastel bg-transparent hover:border-primary-800 focus:ring-1 focus:ring-gray-300 `}
          onClick={handleCopy}
        >
          {!hasCopied && <CopyIcon />}
          {hasCopied && <GreenIcon />}
        </button>
      </div>

      <span className={'whitespace-pre-line'}>{data?.email}</span>
    </div>
  );
}
