import type { ListItemType } from '../../../../components/_shared/ListItem';
import { useFlowContext } from '../../../../hooks/useFlowContext';
import type { Stakeholder } from '../../../../types/Stakeholder';
import WarningCard from './WarningCard';

export default function OtherMainIncome({
  stakeholder,
}: {
  stakeholder: Stakeholder;
}) {
  const { t } = useFlowContext();
  const title = t('decision-kyc-other-main-income');
  const declaration = stakeholder?.declaration?.incomeDeclaration;
  const shouldRender = declaration?.hasOtherMainIncome;

  if (!shouldRender) return null;

  const keys = Object.keys(declaration)
    .filter((key) => key !== 'hasOtherMainIncome' && key !== 'otherDescription')
    .filter((key) => declaration[key as keyof typeof declaration] === true);

  const items: ListItemType[] = keys.map((key) => {
    return {
      label: t('type'),
      value: t(key),
    };
  });

  if (declaration?.otherDescription !== null)
    items.push({
      label: t('description-of-other', 'Beskrivelse av Annet'),
      value: declaration?.otherDescription,
    });

  return <WarningCard title={title} items={items} />;
}
