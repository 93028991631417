import { Income } from '../../../../components/_shared/IncomeCard/IncomeCard.types';
import { Stakeholder } from '../../../../types/Stakeholder';

export function getTotalIncomeByType(
  incomes: Income[],
  incomeType: IncomeColumn,
): number {
  return incomes?.reduce((a: number, b: Income) => a + (b[incomeType] || 0), 0);
}

export enum IncomeColumn {
  INCOME_SBL = 'incomeSbl',
  INCOME_STATED = 'incomeStated',
  INCOME_ANALYSIS = 'value',
}
export function calculateTotalIncomeRental(incomes: Income[]): number {
  return incomes
    .filter(
      (income) =>
        income.workPlace?.name === 'rentalIncomeTaxable' ||
        income.workPlace?.name === 'rentalIncomeTaxFree',
    )
    .reduce((acc, income) => acc + income.value, 0);
}

export function getTotalIncome(incomes: Income[]): number {
  return incomes.reduce((acc, income) => acc + income.value, 0);
}

export function getTruncatedAverageSblIncome(stakeholder: Stakeholder): number {
  return Math.round(
    (stakeholder.sbl?.mapped?.incomeData?.incomeAveragesAccepted
      ?.truncatedAverage || 0) * 12,
  );
}
