import { TaskProps } from '../../../types/TaskProps';
import { TextArea } from '../../../components/_shared/TextArea';
import { DecisionButtonGroup } from '../../components/DecisionButtonGroup';
import { useFlowContext } from '../../../hooks/useFlowContext';
import {} from './SettlementInput';

interface Props {
  save: TaskProps['save'];
  complete: TaskProps['complete'];
  task: TaskProps['task'];
}
export function Decision({ save, complete }: Props) {
  const { t } = useFlowContext();
  return (
    <div className="grid gap-8 py-8">
      <TextArea id="internalComment" label={t('internalComment')} />
      <DecisionButtonGroup save={save} />
    </div>
  );
}
