import { useState } from 'react';
import { Chevron } from '../../../components/icons/Chevron';
import { FilePreview } from '../../../components/_shared/PreviewFile/PreviewFile';
import { useFlowContext } from '../../../hooks/useFlowContext';
import { classNames } from '../../../utils/classNames';
import { downloadAttachment } from '../../../utils/attachments';

type FileRowProps = {
  data: {
    documentType: string;
    owner: string;
    filename: string;
    updatedAt: Date;
    createdAt: Date;
    attachmentKey: string;
    mimetype: string;
    version: number;
    hasFailed?: boolean;
  };
};

export function FileRow({ data }: FileRowProps) {
  const [open, setOpen] = useState(false);
  const { t, flow } = useFlowContext();
  const { flowId } = flow;

  async function handleClick() {
    const isImage = data.mimetype.includes('image');
    const isPdf = data.mimetype.includes('pdf');
    if (!isImage && !isPdf) {
      const file = await downloadAttachment(
        flowId,
        data.attachmentKey,
        data.mimetype,
        data.version,
      );
      const a = document.createElement('a');
      a.href = file!;
      a.download = t(data.filename);
      a.click();
    } else {
      setOpen(!open);
    }
  }

  return (
    <>
      <tr
        onClick={() => handleClick()}
        className="cursor-pointer border-misc-ui-separator border-t"
      >
        <td className="pl-5 py-3">{t(data.documentType)}</td>
        <td>{t(data.owner)}</td>
        <td>
          {t(data.filename)}{' '}
          {data.hasFailed && (
            <span className=" text-negative-600">{`(${t(
              'failedToFetch',
            )})`}</span>
          )}
        </td>
        <td className="text-right pr-2">
          {new Date(data.updatedAt ?? data.createdAt).toLocaleDateString(
            'no-NO',
            {
              hour: 'numeric',
              minute: 'numeric',
            },
          )}
        </td>
        <td className="w-3 text-sea-30 pr-5">
          <Chevron
            className={classNames(
              open ? 'rotate-180' : 'rotate-0',
              'text-blue-500 transform duration-150 ease-in-out',
            )}
          />
        </td>
      </tr>
      {open && (
        <tr>
          <FilePreview
            attachmentKey={data.attachmentKey}
            mimeType={data.mimetype}
            flowId={flowId}
            version={data.version}
          />
        </tr>
      )}
    </>
  );
}
