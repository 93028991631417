import { MenuItem } from '../../../../../components/_shared/DropdownMenu';
import { useFlowContext } from '../../../../../hooks/useFlowContext';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import { ThreeDotIcon } from '../../../../../components/icons/ThreeDotIcon';
import { Fragment } from 'react';
import { HandMoneyIcon } from '../../../../../components/icons/HandMoneyIcon';
import { ChevronRight } from '../../../../../components/icons/ChevronRight';
import { IncomeType } from '../../../../../components/_shared/IncomeCard/IncomeCard.types';
import { CarIcon } from '../../../../../components/icons/CarIcon';

type IncomeAddFunction = (
  applicant: string,
  incomeType: IncomeType,
  incomeLabel?: string,
) => void;
export interface SubcontentMenuItem {
  label: string;
  handleClick?: React.MouseEventHandler<HTMLDivElement>;
  icon?: JSX.Element;
}

export type MenuProps = {
  items: MenuItem[];
};

export function IncomeDropdownMenu({
  stakeholders,
  onAdd,
}: {
  stakeholders: any;
  onAdd: any;
}) {
  const { t } = useFlowContext();
  return (
    <div className="relative inline-block text-left">
      <DropdownMenuPrimitive.Root>
        <DropdownMenuPrimitive.Trigger asChild>
          <button className="inline-flex w-full justify-center rounded-md px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-200">
            <ThreeDotIcon />
          </button>
        </DropdownMenuPrimitive.Trigger>
        <DropdownMenuPrimitive.Content
          align="end"
          sideOffset={5}
          className="focus:outline-none absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 "
        >
          {stakeholders.map((stakeholder: any) => {
            const subMenuItems = createSubMenuItems(
              stakeholder.nationalId,
              onAdd,
            );
            return (
              <Fragment key={stakeholder.nationalId}>
                <DropdownMenuPrimitive.Sub>
                  <DropdownMenuPrimitive.SubTrigger className="flex cursor-pointer items-center justify-between gap-2.5 px-4 py-2 text-sm font-medium focus:bg-gray-100 focus:text-gray-900">
                    <div className="flex items-center gap-2 text-black">
                      <div className="text-grass-20">
                        <HandMoneyIcon />
                      </div>
                      {t(stakeholder.type)}
                    </div>
                    <ChevronRight color="#25562b" />
                  </DropdownMenuPrimitive.SubTrigger>
                  <IncomeDropdownSubContent items={subMenuItems} />
                </DropdownMenuPrimitive.Sub>
              </Fragment>
            );
          })}
          <DropdownMenuPrimitive.Separator className="h-px bg-gray-200 " />
        </DropdownMenuPrimitive.Content>
      </DropdownMenuPrimitive.Root>
    </div>
  );
}

function IncomeDropdownSubContent({ items }: { items: SubcontentMenuItem[] }) {
  const { t } = useFlowContext();

  return (
    <DropdownMenuPrimitive.SubContent
      className="focus:outline-none  w-auto origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5"
      sideOffset={0}
    >
      {items.map((item, index) => (
        <Fragment key={index}>
          <DropdownMenuPrimitive.Item
            onClick={item.handleClick}
            className="flex cursor-pointer items-center gap-2.5 px-4 py-2 text-sm font-medium focus:bg-gray-100 focus:text-gray-900"
          >
            {t(item.label)}
          </DropdownMenuPrimitive.Item>
          <DropdownMenuPrimitive.Separator className="h-px bg-gray-200 " />
        </Fragment>
      ))}
    </DropdownMenuPrimitive.SubContent>
  );
}

function createSubMenuItems(
  stakeholderId: string,
  onAdd: IncomeAddFunction,
): SubcontentMenuItem[] {
  return [
    {
      label: IncomeType.SALARY,
      handleClick: () => onAdd(stakeholderId, IncomeType.SALARY),
      icon: <HandMoneyIcon />,
    },
    {
      label: IncomeType.BUSINESS,
      handleClick: () => onAdd(stakeholderId, IncomeType.BUSINESS),
      icon: <HandMoneyIcon />,
    },
    {
      label: IncomeType.CHILD_SUPPORT,
      handleClick: () => onAdd(stakeholderId, IncomeType.CHILD_SUPPORT),
      icon: <HandMoneyIcon />,
    },
    {
      label: 'carAllowance',
      handleClick: () =>
        onAdd(stakeholderId, IncomeType.SALARY, 'carAllowance'),
      icon: <CarIcon />,
    },
    {
      label: 'companyCar',
      handleClick: () => onAdd(stakeholderId, IncomeType.SALARY, 'companyCar'),
      icon: <CarIcon />,
    },
    {
      label: IncomeType.PENSION_TYPE_OLD_AGE,
      handleClick: () => onAdd(stakeholderId, IncomeType.PENSION_TYPE_OLD_AGE),
      icon: <HandMoneyIcon />,
    },
    {
      label: IncomeType.PENSION_TYPE_RETIREMENT_OCCUPATION,
      handleClick: () =>
        onAdd(stakeholderId, IncomeType.PENSION_TYPE_RETIREMENT_OCCUPATION),
      icon: <HandMoneyIcon />,
    },
    {
      label: IncomeType.PENSION_TYPE_DISABILITY_NATIONAL,
      handleClick: () =>
        onAdd(stakeholderId, IncomeType.PENSION_TYPE_DISABILITY_NATIONAL),
      icon: <HandMoneyIcon />,
    },
    {
      label: IncomeType.PENSION_TYPE_DISABILITY_OCCUPATION,
      handleClick: () =>
        onAdd(stakeholderId, IncomeType.PENSION_TYPE_DISABILITY_OCCUPATION),
      icon: <HandMoneyIcon />,
    },
    {
      label: IncomeType.OTHER,
      handleClick: () => onAdd(stakeholderId, IncomeType.OTHER),
      icon: <HandMoneyIcon />,
    },
    {
      label: IncomeType.OTHER_TAXABLE,
      handleClick: () => onAdd(stakeholderId, IncomeType.OTHER_TAXABLE),
      icon: <HandMoneyIcon />,
    },
  ];
}
