//NB! This must correspond to the one that exists in the flow app -> queueHelper.ts
export const queueNames = {
  MANUAL_CASE_MANAGEMENT: 'manual-case-management-queue',
  DISBURSEMENT: 'disbursement-queue',
  MY_CASES: 'my-cases-queue',
  YELLOW: 'yellow-queue',
  GREEN: 'green-queue',
  CREDIT_APPROVAL: 'credit-approval-queue',
  DISBURSEMENT_CONTROL: 'disbursement-control-queue',
  REJECTION_APPEAL: 'rejection-appeal-queue',
  IN_PROGRESS_CASES: 'in-progress-cases-queue',
  PEP_KYC: 'pep-kyc-queue',
  EMPLOYEE_LOAN: 'employee-loan-queue',
  FAILED_CASES: 'failed-cases-queue',
  CHANGE_LOAN: 'change-loan-queue',
  MANUAL_CASE_MANAGEMENT_ALL_CASEWORKERS:
    'manual-case-management-all-caseworkers-queue',
  DISBURSEMENT_ALL_CASEWORKERS: 'disbursement-all-caseworkers-queue',
  OTHER_INQUIRIES_ALL_CASEWORKERS: 'other-inquiries-all-caseworkers-queue',
  CONTACT_US: 'contact-us-queue',
  PANTEDOKUMENT: 'pantedokument-queue',
  OTHER_INQUIRIES: 'other-inquiries-queue',
  PROPERTY_PURCHASED: 'property-purchased-queue',
  PROPERTY_PURCHASED_IN_PROGRESS: 'property-purchased-queue-in-progress',
  PROPERTY_PURCHASED_TAKEOVER: 'property-purchased-takeover-queue',
  FOLLOW_UP: 'follow-up-queue',
};
