import type { ListItemType } from '../../../../components/_shared/ListItem';
import { useFlowContext } from '../../../../hooks/useFlowContext';
import type { Stakeholder } from '../../../../types/Stakeholder';
import { Translation } from '../../../../types/TaskProps';
import WarningCard from './WarningCard';

export default function Residency({
  stakeholder,
}: {
  stakeholder: Stakeholder;
}) {
  const { t } = useFlowContext();
  const title = t('decision-kyc-foreign-citizenship');
  const declaration = stakeholder?.declaration?.citizenshipDeclaration ?? {};

  //Should be removed when the new declaration is in use
  if ('foreignTax' in declaration) {
    return oldDeclaration(declaration, t);
  }

  const shouldRender = declaration?.isForeignCitizen;

  if (!shouldRender) return null;

  const countries =
    declaration?.countries?.map((country: any) => {
      return {
        label: t('country'),
        value: country?.name,
      };
    }) ?? [];

  const items: ListItemType[] = [
    {
      label: t('citizenshipOutsideOfNorway'),
      value: declaration?.isForeignCitizen ? t('yes') : t('no'),
    },
    ...(declaration?.isForeignCitizen ? countries! : []),
  ];

  return <WarningCard title={title} items={items} />;
}

function oldDeclaration(
  declaration: Stakeholder['declaration']['citizenshipDeclaration'],
  t: Translation,
) {
  const title = t('decision-kyc-multiple-citizenship');
  const shouldRender =
    declaration?.isForeignCitizen ||
    declaration?.foreignTax ||
    declaration?.foreignCitizenship;

  if (!shouldRender) return null;

  const countries = declaration?.countries?.map((country) => {
    return {
      label: t('country'),
      value: country?.name,
    };
  });

  const countriesAndTin = declaration?.countriesAndTinNumber?.map((value) => {
    return [
      {
        label: t('taxLiabilityTo'),
        value: value?.country?.name,
      },
      {
        label: t('tinNumber'),
        value: value?.tinNumber,
      },
    ];
  });

  const items: ListItemType[] = [
    {
      label: t('citizenshipOutsideOfNorway'),
      value: declaration?.isForeignCitizen ? t('yes') : t('no'),
    },
    ...(declaration?.isForeignCitizen ? countries! : []),
    {
      label: t('foreignTaxResidency'),
      value: declaration?.foreignTax ? t('yes') : t('no'),
    },
  ];

  if (declaration?.foreignTax) {
    countriesAndTin?.forEach((country) => {
      items.push(...country);
    });
  }

  return <WarningCard title={title} items={items} />;
}
