export function BetjeningsevneIcon() {
  return (
    <svg width="22" height="23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.692 6.546A11.957 11.957 0 0 0 11 0C6.47 0 2.373 2.508.309 6.545a1 1 0 0 0 1.78.91A9.967 9.967 0 0 1 11 2c3.777 0 7.19 2.09 8.91 5.457a1 1 0 0 0 1.782-.91Z"
        fill="currentColor"
      />
      <path
        d="m14.038 7.635-.34-2.934-1.513 2.537c-.822 1.38-4.94 8.35-5.258 10.067a3.972 3.972 0 0 0 .637 2.995 3.981 3.981 0 0 0 2.567 1.667 4.004 4.004 0 0 0 4.662-3.204c.313-1.691-.574-9.567-.755-11.128Z"
        fill="currentColor"
      />
    </svg>
  );
}
