import { TaskProps } from '../../../types/TaskProps';
import { FormProvider, useForm } from 'react-hook-form';
import { TextArea } from '../../../components/_shared/TextArea';
import { DecisionButtonGroup } from '../../components/DecisionButtonGroup';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useFlowContext } from '../../../hooks/useFlowContext';
import { RadioButtonList } from '../../../components/_shared/RadioButtonList';
import { Checkbox } from '../../../components/_shared/Checkbox';
import { useState } from 'react';
import { Egenkapital } from '../../../types/ProofOfFinance';

interface Props {
  save: TaskProps['save'];
  complete: TaskProps['complete'];
  task: TaskProps['task'];
  analysisDataErrors?: string[];
  proofOfFinancingData?: {
    equityUtilized: Egenkapital;
  };
}
export function ManualDecisionAnalysis({
  save,
  complete,
  analysisDataErrors = [],
  proofOfFinancingData,
}: Props) {
  const { task, t } = useFlowContext();
  const [errors, setErrors] = useState<string[]>([]);
  const allErrors = [...errors, ...analysisDataErrors];
  const method = useForm({
    resolver: zodResolver(ManualDecisionAnalysis.validationSchema),
    defaultValues: {
      isSignoffRequired: task?.data?.isSignoffRequired ?? true,
      overrideSikkerhetsdekning: task?.data?.overrideSikkerhetsdekning ?? false,
      internalComment: task?.data?.internalComment ?? '',
      decision: task?.data?.decision ?? undefined,
      taskType: task?.taskType,
    },
  });
  const { handleSubmit, watch } = method;
  const decisionWatch = watch('decision');

  const onSubmit = (data: any) => {
    const submitData = {
      ...data,
      proofOfFinancingData,
    };

    setErrors([]);
    complete(submitData, undefined, (error) => setErrors(error.errors));
  };

  const taskType = task?.taskType;

  return (
    <FormProvider {...method}>
      <form className="grid gap-4 py-8" onSubmit={handleSubmit(onSubmit)}>
        <h2 className="text-sm font-semibold text-black">{t('decision')}</h2>
        {taskType === 'vurder-kjop' && <RadioSelectVurderKjop id="decision" />}
        <div className="p-2">
          <Checkbox
            label="overrideSikkerhetsdekning"
            id="overrideSikkerhetsdekning"
          />
        </div>
        {taskType === 'vurder-kjop' && decisionWatch === 'approve' && (
          <div className="flex flex-col">
            <Checkbox label="sendToSignoff" id="isSignoffRequired" />
          </div>
        )}
        <TextArea id="internalComment" label="Intern kommentar" />
        <DecisionButtonGroup save={save} />

        <div className="flex flex-col gap-2">
          {allErrors.map((error, index) => (
            <div key={index} className="flex flex-col text-red-500">
              {error}
            </div>
          ))}
        </div>
      </form>
    </FormProvider>
  );
}

ManualDecisionAnalysis.validationSchema = z
  .object({
    internalComment: z.string().optional(),
    taskType: z.string().optional(),
    decision: z.string().optional(),
    isSignoffRequired: z.boolean().optional(),
    overrideSikkerhetsdekning: z.boolean().optional(),
  })
  .superRefine((val, context) => {
    if (
      val.taskType === 'vurder-kjop' &&
      (!val?.decision || val?.decision === '')
    ) {
      context.addIssue({
        code: 'custom',
        message: 'decisionRequired',
        path: ['decision'],
      });
    }
  });

function RadioSelectVurderKjop({ id }: { id: string }) {
  const options = [
    { value: 'approve', label: 'Godkjenn' },
    { value: 'reject', label: 'Avslå' },
  ];
  return <RadioButtonList id={id} options={options} />;
}
