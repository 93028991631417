import { formatMoney } from '../../utils/formatMoney';
import { NumericInput } from './NumericInput';
import { RightCell } from './RightCell';

interface ConditionalInputCellProps {
  editable: boolean;
  initialValue: any;
  className?: string;
  labelPlaceholder?: string;
  rowId: string | number;
  columnId: string | number;
  onUpdate: any;
  context?: string;
}

export function ConditionalInputCell({
  editable,
  className = '',
  labelPlaceholder = '',
  initialValue,
  rowId,
  columnId,
  onUpdate,
  context = '',
}: ConditionalInputCellProps) {
  return editable ? (
    <NumericInput
      className={className}
      initialValue={initialValue}
      rowId={rowId}
      columnId={columnId}
      context={context}
      onUpdate={onUpdate}
    />
  ) : (
    <RightCell
      className={`flex h-11 items-center justify-end justify-self-end ${className}`}
    >
      {formatMoney(Number(initialValue))}
      <span className="text-misc-placeholder"> {labelPlaceholder}</span>
    </RightCell>
  );
}
