import { useFlowContext } from '../../hooks/useFlowContext';
import { useFormConnect } from '../../hooks/useFormConnect';

export function Checkbox({ label, id }: { label: string; id: string }) {
  const { name, value, onChange, error } = useFormConnect(id);
  const { t } = useFlowContext();
  return (
    <div>
      <div className="flex items-center gap-2">
        <input
          type="checkbox"
          id={name}
          name={name}
          checked={value || false}
          value={value}
          onChange={onChange}
          className="min-w-1rem h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
        />
        <label htmlFor={name}>{t(label)}</label>
      </div>
      {error && <span className="block text-red-500">{t(error)}</span>}
    </div>
  );
}
