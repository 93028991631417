import { create } from 'zustand';
import { Egenkapital, ProofOfFinance } from '../../types/ProofOfFinance';
import Decimal from 'decimal.js';

interface ProofOfFinancingProps {
  equityStated: Egenkapital;
  equityUtilized: Egenkapital;
  isApproved: boolean;
  maxBid: number;
  calculatedCostsOwnedHousing: number;
}
interface ProofOfFinancingState extends ProofOfFinancingProps {
  getTotalEquityUtilized: () => number;
  setEquityUtilized: (equityUtilized: Egenkapital) => void;
  setEquityStated: (equityStated: Egenkapital) => void;
  setIsApproved: (isApproved: boolean) => void;
  setMaxBid: (maxBid: number) => void;
  setCalculatedCostsOwnedHousing: (costs: number) => void;
  updateFn?: (data?: any) => void;
  setUpdateFn: (fn: () => void) => void;
  updateEquityUtilized: (
    equityUtilized: Pick<
      Egenkapital,
      | 'inheritAndGiftAmount'
      | 'otherAmount'
      | 'saleOfHousingAmount'
      | 'savedFundsAmount'
      | 'soldPropertyAmount'
    >,
    shouldUpdate?: boolean,
  ) => void;
}

export const useProofOfFinancingStore = create<ProofOfFinancingState>(
  (set, get) => {
    return {
      equityStated: {
        savedFundsAmount: 0,
        saleOfHousingAmount: 0,
        inheritAndGiftAmount: 0,
        soldPropertyAmount: 0,
        otherAmount: 0,
        saleOfHousing: false,
      },
      equityUtilized: {
        savedFundsAmount: 0,
        saleOfHousingAmount: 0,
        inheritAndGiftAmount: 0,
        soldPropertyAmount: 0,
        otherAmount: 0,
        saleOfHousing: false,
      },
      isApproved: false,
      maxBid: 0,
      getTotalEquityUtilized: () => {
        const equityUtilized = get().equityUtilized!;
        return new Decimal(equityUtilized.inheritAndGiftAmount)
          .add(equityUtilized.otherAmount)
          .add(equityUtilized.saleOfHousingAmount ?? 0)
          .add(equityUtilized.savedFundsAmount)
          .add(equityUtilized.soldPropertyAmount)
          .toNumber();
      },
      setEquityUtilized: (equityUtilized) => {
        set({ equityUtilized });
      },
      setEquityStated: (equityStated) => set({ equityStated }),
      setIsApproved: (isApproved) => set({ isApproved }),
      setMaxBid: (maxBid) => set({ maxBid }),
      calculatedCostsOwnedHousing: 0,
      setCalculatedCostsOwnedHousing: (calculatedCostsOwnedHousing) =>
        set({ calculatedCostsOwnedHousing }),
      setUpdateFn: (fn: (data: any) => void) => set({ updateFn: fn }),
      updateEquityUtilized: (equityUtilized, shouldUpdate = true) => {
        set({ equityUtilized: { ...get().equityUtilized, ...equityUtilized } });
        shouldUpdate && get().updateFn && get().updateFn?.();
      },
    };
  },
);

export const initProofOfFinancingStore = (
  proofOfFinance: ProofOfFinance,
  updateFn?: any,
) => {
  const store = useProofOfFinancingStore.getState();
  const equityDeclaration = proofOfFinance?.equity?.equityDeclaration!;
  const equityUtilized = proofOfFinance?.equity?.equityUtilized!;

  store.setEquityStated({
    savedFundsAmount: equityDeclaration?.savedFundsAmount ?? 0,
    saleOfHousingAmount: equityDeclaration?.saleOfHousingAmount ?? 0,
    inheritAndGiftAmount: equityDeclaration?.inheritAndGiftAmount ?? 0,
    soldPropertyAmount: equityDeclaration?.soldPropertyAmount ?? 0,
    otherAmount: equityDeclaration?.otherAmount ?? 0,
    saleOfHousing: equityDeclaration?.saleOfHousing ?? false,
  });
  store.setEquityUtilized(equityUtilized);
  store.setIsApproved(proofOfFinance?.isAccepted ?? false);
  store.setMaxBid(proofOfFinance?.maxBid ?? 0);
  if (updateFn) {
    store.setUpdateFn(updateFn);
  }
};
