import { TaskProps, Translation } from '../../../../../types/TaskProps';
import { getLoanDurationStringFromTermsAndTermLengthInMonths } from '../../../../../utils/loanDetails';
import { formatMoneyValue } from '../../utils/formatMoneyValue';
import { ListItemType } from '../../../../../components/_shared/ListItem';

type Props = {
  data: TaskProps['flow']['data'];
  t: Translation;
};

export function formatRedemptionPeriodSummary({ data, t }: Props) {
  const loanDetails = data?.loanDetails;

  const summaryData = {
    loanType: loanDetails?.loanType ?? '-',
    initialLoanDuration: loanDetails?.currentPaymentPeriod
      ? getLoanDurationStringFromTermsAndTermLengthInMonths(
          loanDetails?.currentPaymentPeriod,
          loanDetails?.termsPerYear,
          t,
        )
      : '-',
    remainingLoanDuration: loanDetails?.termLength
      ? getLoanDurationStringFromTermsAndTermLengthInMonths(
          loanDetails?.termLength,
          loanDetails?.termsPerYear,
          t,
        )
      : '-',
    requestedNewRepaymentPeriod: `${data.requestedNewRepaymentPeriod} år`,
    advanceDeposit: formatMoneyValue(loanDetails?.advanceDeposit),
    coopDebt: formatMoneyValue(loanDetails?.coopDebt),
    loanAmount: formatMoneyValue(loanDetails?.remainingLoanAmount),
    propertyValue: formatMoneyValue(loanDetails?.housingValue),
    loanToValue: loanDetails?.ltv ? `${loanDetails?.ltv?.toFixed(2)}%` : '-',
    product: loanDetails?.product
      ? `${loanDetails?.product} - ${loanDetails?.nominalInterestRate}% (${loanDetails?.effectiveInterestRate}%)`
      : '-',
  };

  return Object.entries(summaryData).map(
    ([label, value]): ListItemType => ({
      label,
      value,
    }),
  );
}
