import { useMemo } from 'react';
import { getEffectiveInterest, getPaymentPlan } from 'payment-plan';
import { useFlowContext } from './useFlowContext';

function getDate(termNo: any): any {
  const startDate = new Date();
  return new Date(startDate.setMonth(startDate.getMonth() + termNo - 1));
}

export function useSummary({
  loanAmount,
  terms,
  gracePeriod,
  interestRate,
  loanType,
  startFee = 0,
  termFee = 0,
}: any) {
  const { t } = useFlowContext();
  const plan = useMemo(() => {
    function getMonthFromNumber(number: number) {
      const months = [
        t('january'),
        t('february'),
        t('march'),
        t('april'),
        t('may'),
        t('june'),
        t('july'),
        t('august'),
        t('september'),
        t('october'),
        t('november'),
        t('december'),
      ];

      return months[number];
    }

    const defaultValues = {
      yearPaymentPlan: [],
      paymentPlan: [],
      effectiveInterestRate: null,
      monthlyPayment: null,
      totalPayment: null,
    };

    if (loanAmount <= 0) return defaultValues;
    if (terms === 0) return defaultValues;
    if (terms <= gracePeriod) return defaultValues;

    const paymentPlan = getPaymentPlan({
      loanAmount,
      interestRate,
      terms: terms,
      gracePeriod,
      startFee,
      termFee,
      type: loanType,
    }).map((term: any): any => {
      const date = getDate(term.termNo);
      return {
        date,
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        monthName: getMonthFromNumber(date.getMonth()),
        ...term,
      };
    });

    const monthlyPayment = paymentPlan[0]?.monthlyPayment;

    const effectiveInterestRate = getEffectiveInterest({
      loanAmount,
      interestRate,
      terms: terms,
      startFee,
      termFee,
      type: loanType,
      plan: paymentPlan,
    });

    const yearPaymentPlan: any = Object.values(
      paymentPlan.reduce((acc: any, term: any) => {
        if (!acc[term.year]) {
          acc[term.year] = {
            year: term.year,
            yearlyPayment: 0,
            installment: 0,
            interestAmount: 0,
            restAmount: 0,
            interestRate: term.interestRate,
            terms: [],
          };
        }

        acc[term.year].yearlyPayment += term.monthlyPayment;
        acc[term.year].installment += term.installment;
        acc[term.year].interestAmount += term.interestAmount;
        acc[term.year].restAmount = term.restAmount;
        acc[term.year].terms.push(term);
        return acc;
      }, {}),
    );

    const totalPayment = paymentPlan.reduce(
      (totalAmount: any, term: any) => totalAmount + term.monthlyPayment,
      0,
    );

    return {
      yearPaymentPlan,
      paymentPlan,
      effectiveInterestRate,
      monthlyPayment,
      totalPayment,
    };
  }, [
    loanAmount,
    terms,
    gracePeriod,
    interestRate,
    startFee,
    termFee,
    loanType,
    t,
  ]);

  return plan;
}
