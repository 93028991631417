import { useEffect, useState } from 'react';
import { TaskProps } from '../../../types/TaskProps';
import { FormProvider, useForm } from 'react-hook-form';
import { Checkbox } from '../../../components/_shared/Checkbox';
import { TextArea } from '../../../components/_shared/TextArea';
import { DecisionButtonGroup } from '../../components/DecisionButtonGroup';
import { RadioButtonListWithCheckbox } from '../VurderBehandlingsbehov/ManualDecisionAssessment';
import { useFlowContext } from '../../../hooks/useFlowContext';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Select } from '../../../components/_shared/Select';

const options = [
  { value: 'manual', label: 'Manuell behandling' },
  { value: 'approve', label: 'Godkjenn' },
  { value: 'reject', label: 'Avslå' },
];

export function ManualDecisionControl({ save, complete }: TaskProps) {
  const { task } = useFlowContext();
  const method = useForm({
    defaultValues: getDefaultValues(task),
    resolver: zodResolver(ManualDecisionControl.validationSchema),
  });
  const { handleSubmit, watch, setValue } = method;
  const { t } = useFlowContext();
  const decisionWatch = watch('decision');
  const overrideSikkerhetsdekning = watch('overrideSikkerhetsdekning');
  const rejectionReasonChoice = watch('rejectionReasonChoice');
  const rejectionDrafts = task?.context?.rejectionDrafts;
  const [submitErrors, setSubmitErrors] = useState<string[]>([]);

  useEffect(() => {
    const rejectionDraft =
      rejectionDrafts?.filter(
        (elem: any) => elem?.slug === rejectionReasonChoice,
      ) ?? [];

    setValue(
      'rejectionReason',
      task?.data?.rejectionReason ?? rejectionDraft[0]?.content ?? '',
    );
  }, [
    rejectionDrafts,
    rejectionReasonChoice,
    setValue,
    task?.data?.rejectionReason,
  ]);

  const onSubmit = (data: any) => {
    complete(data, undefined, (error) => setSubmitErrors(error.errors));
  };

  const rejectionOptions = (rejectionDrafts ?? [])?.map((draft: any) => {
    return {
      value: draft?.slug,
      label: draft?.heading,
    };
  });

  return (
    <FormProvider {...method}>
      <form className="grid gap-2 py-8" onSubmit={handleSubmit(onSubmit)}>
        <h2 className="text-sm font-semibold text-black">{t('decision')}</h2>
        <div className="grid gap-6">
          <RadioButtonListWithCheckbox id="decision" options={options} />
          {!!overrideSikkerhetsdekning && (
            <span className="p-4 bg-red-200 rounded items-center flex gap-4 text-gray-700">
              <p>{t('override-sikkerhets-dekning-decision')}</p>
            </span>
          )}
          {(decisionWatch === 'approve' || decisionWatch === 'reject') && (
            <div className="flex flex-col">
              <Checkbox label="sendToSignoff" id="isSignoffRequired" />
            </div>
          )}
          <TextArea id="creditAssessment" label="creditAssessment" />
          {decisionWatch === 'reject' && (
            <>
              <Select
                id="rejectionReasonChoice"
                label={t('rejection-draft')}
                options={[
                  {
                    value: '',
                    label: '',
                  },
                  ...rejectionOptions,
                ]}
              />
              {rejectionReasonChoice !== '' && (
                <TextArea
                  id={'rejectionReason'}
                  label={'editable-paragraph-text'}
                />
              )}
            </>
          )}
          <DecisionButtonGroup save={save} />
          <div className="flex flex-col gap-2">
            {submitErrors.map((error, index) => (
              <div key={index} className="flex flex-col text-red-500">
                {error}
              </div>
            ))}
          </div>
        </div>
      </form>
    </FormProvider>
  );
}

ManualDecisionControl.validationSchema = z
  .object({
    decision: z.string({
      required_error: 'decisionRequired',
    }),
    overrideSikkerhetsdekning: z.boolean().optional(),
    isSignoffRequired: z.boolean().optional(),
    creditAssessment: z.string().optional(),
    analysis: z.boolean().optional().default(false),
    documentation: z.boolean().optional().default(false),
    rejectionReason: z.string().optional(),
    rejectionReasonChoice: z.string().optional(),
  })
  .superRefine((val, context) => {
    if (
      (val.decision === 'reject' ||
        (val.decision === 'approve' && val?.isSignoffRequired)) &&
      (!val?.creditAssessment || val?.creditAssessment === '')
    ) {
      context.addIssue({
        code: 'custom',
        message: 'messageRequired',
        path: ['creditAssessment'],
      });
    }

    if (
      val.decision === 'reject' &&
      (!val?.rejectionReasonChoice || val?.rejectionReasonChoice === '')
    ) {
      context.addIssue({
        code: 'custom',
        message: 'rejectionReasonIsRequired',
        path: ['rejectionReasonChoice'],
      });
    }
    if (val.decision === 'manual' && !val?.analysis && !val?.documentation) {
      context.addIssue({
        code: 'custom',
        message: 'analysisOrDocumentationRequired',
        path: ['manual'],
      });
    }
  });

function getDefaultValues(task: TaskProps['task']): any {
  return {
    decision: task.data?.decision,
    overrideSikkerhetsdekning: task?.context?.overrideSikkerhetsdekning,
    isSignoffRequired: task?.data?.isSignoffRequired ?? true,
    creditAssessment: task.data?.creditAssessment ?? '',
    analysis: task.data?.analysis ?? false,
    documentation: task.data?.documentation ?? false,
    rejectionReason: task?.data?.rejectionReason ?? '',
    rejectionReasonChoice: task?.data?.rejectionReasonChoice ?? '',
  };
}
