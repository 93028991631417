import React from 'react';
import { TaskProps } from '../../../../../types/TaskProps';
import { FlowContext } from '../../../../../hooks/useFlowContext';
import SBLHeader from '../../../../../components/_shared/SBL/SBLHeader';
import SBLCard from '../../../../../components/_shared/SBL/SBLCard';
import { EmptyState } from './EmptyState';
import { Stakeholder } from '../../../../../types/Stakeholder';
import { EmployerIncome } from '../../../../../types/SBL';

export default function SBLTab(props: TaskProps) {
  const { flow, t } = props;
  const { data } = flow;

  const stakeholders = data?.stakeholders;
  const hasSituation =
    !!data?.households?.[0]?.situations?.current?.betjeningsevne;

  if (!hasSituation) {
    return <EmptyState t={t} tab={t('sbl', 'SBL')} />;
  }

  return (
    <FlowContext.Provider value={props}>
      <div className="grid gap-y-10">
        {stakeholders?.map((stakeholder: Stakeholder, index: number) => {
          const employers =
            stakeholder?.sbl?.mapped?.incomeData?.employerIncome;
          return (
            <div
              key={stakeholder?.firstName + index}
              className="flex flex-col gap-5"
            >
              <SBLHeader stakeholder={stakeholder} />
              {employers?.map((employer: EmployerIncome, index: number) => (
                <SBLCard
                  key={employer?.virksomhetId + index}
                  employer={employer}
                />
              ))}
            </div>
          );
        })}
      </div>
    </FlowContext.Provider>
  );
}
