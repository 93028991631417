import { useFlowContext } from '../../../hooks/useFlowContext';
import type { Stakeholder } from '../../../types/Stakeholder';
import { formatMoney } from '../../../utils/formatMoney';
import { formatNationalId } from '../../../utils/formatNationalId';

export default function SBLHeader({
  stakeholder,
}: {
  stakeholder: Stakeholder;
}) {
  const { t } = useFlowContext();
  const income =
    stakeholder?.sbl?.mapped?.incomeData?.incomeAveragesAccepted?.total;
  return (
    <div className="flex justify-between text-black">
      <div className="flex flex-col">
        <span className="text-lg">{stakeholder?.fullName}</span>
        <span>{formatNationalId(stakeholder?.nationalId)}</span>
      </div>
      <div className="flex flex-col">
        <span className="text-lg">{formatMoney(income)}</span>
        <span>{t('sblIncome')}</span>
      </div>
    </div>
  );
}
