import { useFlowContext } from '../../hooks/useFlowContext';
import { useFormConnect } from '../../hooks/useFormConnect';

export function TextArea({
  label,
  id,
  placeholder = 'Skriv inn...',
}: {
  label: string;
  id: string;
  placeholder?: string;
}) {
  const { name, value, onChange, error } = useFormConnect(id);
  const { t } = useFlowContext();
  return (
    <div className="flex flex-col gap-2">
      <label className="text-sm font-semibold text-black" htmlFor={name}>
        {t(label)}
      </label>
      <textarea
        id={name}
        name={name}
        placeholder={placeholder}
        className="h-80 rounded border-2 border-solid border-gray-200 p-2"
        value={value}
        onChange={onChange}
      />
      {error && <span className="block text-red-500">{t(error)}</span>}
    </div>
  );
}
