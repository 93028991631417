import { nanoid } from 'nanoid';
import React, { useEffect, useMemo } from 'react';
import { Cell, Row } from 'react-table';
import { PadlockIcon } from '../../icons/PadlockIcon';
import { Card } from '../Card';
import { Mortgagors } from './Mortgagors';
import { ConditionalInputCell } from '../ConditionalInputCell';
import { DataTable } from '../DataTable';
import DropdownMenu from '../DropdownMenu';
import { CustomNumericInput } from '../Fields/CustomNumericInput';
import { LabelPill } from '../Fields/LabelPill';
import { ListboxCell } from '../ListboxCell';
import { useFlowContext } from '../../../hooks/useFlowContext';
import { formatMoney } from '../../../utils/formatMoney';
import { calculateCollateralCoveragePure } from '../../../customer/tasks/ManuellBehandling/utils/calculateCollateralCoverage';
import { CollateralCardProps } from './CollateralsCard.types';
import { useSituationStore } from '../../../customer/stores/useSituationStore';
import { HeartHouseIcon } from '../../icons/HeartHouseIcon';
import { PlusHeartIcon } from '../../icons/PlusHouseIcon';
import { GrunnbokUtskrift } from './GrunnbokUtskrift';
import { buildTinglysingsReference } from './buildTinglysingsReference';
import { CrossIcon } from '../../icons/CrossIcon';
import { classNames } from '../../../utils/classNames';

export function CollateralsCard({ editable = false }: CollateralCardProps) {
  const {
    t,
    flow: { flowId, data: flowData },
  } = useFlowContext();

  const {
    collaterals,
    setCollateralCoverage,
    collateralCoverage,
    updateCollateral,
    updateAsset,
    assets,
    debts,
  } = useSituationStore();

  const collateral = collaterals?.[0]; // We can only have one collateral agreement for now

  let loans = debts.filter((debt) => collateral?.accountIds.includes(debt.id));

  const properties = assets.filter((asset) =>
    collateral?.propertyIds.includes(asset.id),
  );

  const selectedLoans = debts.filter((debt) => debt.isSelected)!;

  const existingSecurities = flowData?.securities?.filter(
    (security: any) => security?.isBeroendePant,
  );

  const { collateralData } = useMemo(() => {
    const collateralRow = {
      ...collateral,
      type: 'collateralAgreement',
      details: buildTinglysingsReference(
        collateral?.securityId,
        existingSecurities,
      ),
    };
    const collateralData = [collateralRow, ...properties, ...loans];

    return { collateralData, existingSecurities };
  }, [collateral, existingSecurities, properties, loans]);

  const calculatedCollateralCoverage = useMemo(() => {
    const marketValueExemptPriority = properties.reduce(
      (acc, property) =>
        acc +
        ((property.marketValue ?? 0) - (property.priorityMortgageAmount ?? 0)),
      0,
    );
    const loanBalance = loans.reduce((acc, loan) => {
      return acc + (loan.balance ?? 0);
    }, 0);

    return calculateCollateralCoveragePure({
      loanBalance,
      marketValueExemptPriority,
      collateralValue: collateral?.amount,
    });
  }, [collateral, loans, properties]);

  useEffect(() => {
    setCollateralCoverage(calculatedCollateralCoverage);
  }, [calculatedCollateralCoverage, setCollateralCoverage]);

  const columns = React.useMemo(
    () => [
      {
        Header: t('type'),
        accessor: 'type',
        columnType: 'left',
        Cell: ({ value, row }: Cell<any>) => {
          const typeValue =
            value === 'INSTALLMENT'
              ? row.original.loanProduct
              : 'collateralObject';
          return (
            <div className="flex">
              {isCollateralAgreement(row) ? (
                <CollateralAgreementTypeCell
                  collateralType={properties?.[0]?.type}
                  isExistingCollateral={collateral?.isBeroendePant}
                />
              ) : (
                <TypeCell value={t(typeValue)} />
              )}
            </div>
          );
        },
      },
      {
        Header: t('details'),
        accessor: 'details',
        columnType: 'left',
        Cell: ({ row, value }: Cell<any>) => {
          if (isCollateralAgreement(row)) {
            return value;
          }
          return (
            <div className="flex gap-x-6 items-center">
              <span className="min-w-1/3 ">{t(value)}</span>
              {/* TODO: change condition */}
              {row.original.type !== 'INSTALLMENT' ? (
                <GrunnbokUtskrift
                  id={row.original.key ?? row.original.id}
                  flowId={flowId}
                />
              ) : null}
              <div></div>
            </div>
          );
        },
      },
      {
        Header: t('mortgagors'),
        accessor: 'mortgagors',
        columnType: 'left',
        Cell: (cell: Cell<any>) => {
          return cell.value ? <Mortgagors mortgagors={cell.value} /> : null;
        },
      },
      {
        Header: t('priority'),
        accessor: 'priority',
        columnType: 'right',
        Cell: (cell: Cell<any>) => {
          return cell.value ? (
            <div className="relative flex w-full justify-end">
              {editable ? (
                <ListboxCell
                  initialValue={cell.value}
                  options={[3, 2, 1]}
                  rowId={cell.row.original.id}
                  onUpdate={updateAsset}
                  columnId="priority"
                  styles="-top-1/2 -translate-y-full"
                />
              ) : (
                cell.value
              )}
            </div>
          ) : null;
        },
      },
      {
        Header: t('priorityMortgageAmount'),
        accessor: 'priorityMortgageAmount',
        columnType: 'right',
        Cell: ({ row, value }: Cell<any>) => {
          return row.original.priority && row.original.priority !== 1 ? (
            <ConditionalInputCell
              editable={editable}
              initialValue={value}
              rowId={row.original.id}
              columnId={'priorityMortgageAmount'}
              onUpdate={updateAsset}
            />
          ) : null;
        },
      },
      {
        Header: t('usedValue'),
        columnType: 'right',
        Cell: ({ row }: Cell<any>) => {
          const rowType = row.original.type;
          const isCollateralAgreement = rowType === 'collateralAgreement';
          const usedValue = isCollateralAgreement
            ? row.original.amount
            : rowType === 'INSTALLMENT'
            ? row.original.balance
            : row.original.marketValue;
          const handleUpdate = (val: number) => {
            const updatedCollateral = {
              ...collateral,
              amount: val,
            };
            updateCollateral(updatedCollateral);
          };
          const isEditable =
            isCollateralAgreement && editable && !row.original?.isBeroendePant;
          return (
            <CustomNumericInput
              initialValue={usedValue}
              handleUpdate={handleUpdate}
              allowNegative={false}
              editable={isEditable}
              styles={classNames(isEditable ? '' : 'pr-2', 'float-right')}
            />
          );
        },
      },
      {
        Header: '',
        accessor: 'action',
        columnType: 'action',
        cellStyles: editable ? 'w-12' : '',
        Cell: ({ row }: Cell<any>) => {
          const handleSelectBeroende = (security: any) => {
            const selectedLoanIds = selectedLoans?.map((loan) => loan.id);
            const loansToBeRedeemed = debts.filter((debt) => debt.toBeRedeemed);

            const accountIds = new Set([
              ...selectedLoanIds,
              ...security.accountIds.filter(
                (accountId: string) =>
                  !loansToBeRedeemed.some((debt) => debt.id === accountId),
              ),
            ]);

            const updatedCollateral = {
              ...row.original,
              isBeroendePant: true,
              amount: security.amount,
              securityId: security.id,
              accountIds: Array.from(accountIds),
            };
            updateCollateral(updatedCollateral);
          };
          const beroendePantItems = (existingSecurities || []).map(
            (security: any) => ({
              icon: (
                <div className="text-tertiary-800">
                  <HeartHouseIcon />
                </div>
              ),
              label: formatMoney(security.amount),
              handleClick: () => handleSelectBeroende(security),
              subValue: true,
            }),
          );
          const handleSelectNyPant = () => {
            const collateralAmount = selectedLoans?.reduce(
              (acc, loan) => acc + (loan.balance ?? 0),
              0,
            );
            const updatedCollateral = {
              ...row.original,
              isBeroendePant: false,
              amount: collateralAmount,
              accountIds: selectedLoans?.map((s) => s.id),
              securityId: nanoid(20),
            };
            updateCollateral(updatedCollateral);
          };

          if (
            isCollateralAgreement(row) &&
            editable &&
            existingSecurities?.length > 0
          ) {
            return (
              <DropdownMenu
                items={[
                  ...(row.original?.isBeroendePant
                    ? [
                        {
                          icon: (
                            <div className="text-tertiary-800">
                              <PlusHeartIcon />
                            </div>
                          ),
                          label: 'Nytt pant',
                          handleClick: handleSelectNyPant,
                        },
                      ]
                    : []),
                  {
                    icon: <div className="mr-0.5  w-6" />,
                    label: 'Beroende pant',
                    nonClick: true,
                  },
                  ...beroendePantItems,
                ]}
              />
            );
          } else if (isCollateralObjectRow(row) && editable) {
            const handleRemoveFromCollateralsList = () => {
              updateAsset({
                initialValue: true,
                value: false,
                rowId: row.original.id,
                columnId: 'isSecurity',
              });
              const updatedCollateral = {
                ...collateral,
                propertyIds: collateral.propertyIds.filter(
                  (id) => id !== row.original.id,
                ),
              };
              updateCollateral(updatedCollateral);
            };
            return (
              <DropdownMenu
                items={[
                  ...(row.original?.isSecurity
                    ? [
                        {
                          icon: (
                            <div className="text-tertiary-800">
                              <CrossIcon width={16} height={16} />
                            </div>
                          ),
                          label: 'Fjern sikkerhet',
                          handleClick: handleRemoveFromCollateralsList,
                        },
                      ]
                    : []),
                ]}
              />
            );
          }
          return null;
        },
      },
    ],
    [
      t,
      properties,
      collateral,
      flowId,
      editable,
      updateAsset,
      updateCollateral,
      existingSecurities,
      selectedLoans,
      debts,
    ],
  );

  return (
    <Card>
      <Card.Header className="justify-between bg-tertiary-50 text-tertiary-800 ">
        <div className="flex items-center gap-3">
          <PadlockIcon />
          <Card.HeaderTitle>{t('theCollateral')}</Card.HeaderTitle>
        </div>
        <div className="flex items-center gap-1">
          <span>
            Sikkerhetsdekning: {formatMoney(collateralCoverage ?? 0)}%
          </span>
        </div>
      </Card.Header>
      <Card.Content>
        {!collateral ? (
          <EmptyState />
        ) : (
          <DataTable
            columns={columns}
            data={collateralData}
            isEditable={editable}
          />
        )}
      </Card.Content>
    </Card>
  );
}

function CollateralAgreementTypeCell({
  collateralType,
  isExistingCollateral,
}: {
  collateralType: string;
  isExistingCollateral: boolean;
}) {
  const { t } = useFlowContext();
  return (
    <div className="flex w-full items-center justify-between gap-2 font-semibold">
      {t('collateralTypeName', {
        collateralType: t(collateralType).toLowerCase(),
      })}
      {isExistingCollateral ? (
        <LabelPill value={t('existingCollateral')} color="bg-tertiary-400" />
      ) : (
        <LabelPill value={t('newCollateral')} />
      )}
    </div>
  );
}

function TypeCell({ value }: { value: string }) {
  const { t } = useFlowContext();
  return (
    <div className="flex items-center gap-2">
      <HeartHouseIcon />
      {t(value)}
    </div>
  );
}

function isCollateralAgreement(row: Row<any>) {
  return row.original.type === 'collateralAgreement';
}

function EmptyState() {
  const { t } = useFlowContext();
  return (
    <div className="flex h-full flex-col items-center justify-center">
      <div className="flex h-24 w-24 items-center justify-center rounded-full bg-tertiary-50">
        <PadlockIcon width={24} height={24} />
      </div>
      <div className="mt-4 font-semibold text-tertiary-800">
        {t('no-collaterals-found')}
      </div>
    </div>
  );
}

function isCollateralObjectRow(row: Row<any>) {
  return (
    row.original.type === 'SHAREDHOUSE' || row.original.type === 'OWNEDHOUSE'
  );
}
