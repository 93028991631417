import { Listbox, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import { GreenIcon } from '../../../../components/icons/GreenDecisionIcon';
import { Caret } from '../../../../components/_shared/Caret';
import { classNames } from '../../../../utils/classNames';
import { Owner } from '../utils/borrowersHelper';

export function LoanOwnerDisplay({
  editable,
  loanOwners,
  options,
  rowId,
  columnId,
  onUpdate,
}: any) {
  return editable ? (
    <div className="relative w-auto">
      <LoanOwnerPicker
        initialValue={loanOwners}
        options={options}
        rowId={rowId}
        columnId={columnId}
        onUpdate={onUpdate}
      />
    </div>
  ) : (
    <div
      className={classNames(editable ? '' : 'ml-2', 'flex items-center h-11')}
    >
      {(loanOwners || []).map(
        (owner: Owner, index: number) =>
          `${owner.name}${loanOwners?.length - 1 !== index ? ',' : ''} `,
      )}
    </div>
  );
}

interface ListboxProps {
  initialValue: Owner[];
  options: Owner[];
  rowId?: number | string;
  onUpdate?: any;
  columnId?: number | string;
}

function LoanOwnerPicker({
  onUpdate,
  initialValue,
  options,
  rowId,
  columnId,
}: ListboxProps) {
  const [selectedOwners, setSelectedOwners] = useState(initialValue);

  const onChange = (value: any) => {
    setSelectedOwners(value);
    if (value !== initialValue) {
      onUpdate({ rowId, columnId, initialValue, value });
    }
  };

  return (
    <Listbox value={selectedOwners} by="id" onChange={onChange} multiple>
      <Listbox.Button className="flex min-w-1/2 justify-between whitespace-nowrap border border-misc-ui-separator bg-input p-2.5">
        {selectedOwners.map((owner) => owner.name).join(', ')}
        <Caret />
      </Listbox.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Listbox.Options className="focus:outline-none absolute z-10 mt-2 min-w-1/2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5">
          <div className="py-1">
            {(options || []).map((option) => (
              <Listbox.Option key={option.id} value={option} className="py-1">
                {({ active, selected }) => (
                  <div
                    className={classNames(
                      active
                        ? 'bg-gray-100 text-gray-900'
                        : 'bg-white text-gray-700',
                      'flex cursor-pointer items-center justify-between gap-2 px-4 py-2  text-sm',
                    )}
                  >
                    {option.name} {selected ? <GreenIcon /> : null}
                  </div>
                )}
              </Listbox.Option>
            ))}
          </div>
        </Listbox.Options>
      </Transition>
    </Listbox>
  );
}
