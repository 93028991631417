import { Debt } from '../components/_shared/DebtCard/DebtCard.types';
import { isProofOfFinanceProduct } from '../customer/tasks/ManuellBehandling/utils/loanPurposeContextHelper';

export function isSelectedLoan(debt: Debt) {
  return (
    debt?.isSelected ??
    (debt?.loanProduct === 'moveLoan' ||
      debt?.loanProduct === 'applyLoan' ||
      debt?.loanProduct === 'increaseLoan' ||
      isProofOfFinanceProduct(debt?.loanProduct))
  );
}
