import { useFlowContext } from '../../../hooks/useFlowContext';
import type { Stakeholder } from '../../../types/Stakeholder';
import CommercialInterests from './CommercialInterests';
import EquationCard from './EquationCard';
import PaymentNotes from './PaymentNotes';

export default function CreditCheck() {
  const { flow } = useFlowContext();
  const stakeholders: Stakeholder[] = flow?.data?.stakeholders;
  return (
    <div className="flex flex-col gap-10">
      {stakeholders?.map((stakeholder) => (
        <div key={stakeholder?.firstName} className="flex flex-col gap-5">
          <EquationCard stakeholder={stakeholder} />
          <CommercialInterests stakeholder={stakeholder} />
          <PaymentNotes stakeholder={stakeholder} />
        </div>
      ))}
    </div>
  );
}
