import { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useEditingStore } from '../../customer/stores/useEditingStore';

interface NumericInputProps {
  initialValue: any;
  rowId: number | string;
  columnId: number | string;
  onUpdate: any;
  suffix?: string;
  className?: string;
  context?: string;
  isAllowedFn?: (value: number) => boolean;
  thousandSeparator?: string;
  clearOnEmpty?: boolean;
}

export function NumericInput({
  initialValue,
  onUpdate,
  rowId,
  columnId,
  className,
  suffix = '',
  context = '',
  isAllowedFn = () => true,
  thousandSeparator = ' ',
  clearOnEmpty = false,
}: NumericInputProps) {
  const [value, setValue] = useState(initialValue);
  const setIsEditing = useEditingStore((state) => state.setIsEditing);

  const handleChange = (value?: number) => {
    if (clearOnEmpty) {
      setValue(value !== 0 ? value : '');
    } else {
      setValue(value);
    }

    if (value !== initialValue) {
      setIsEditing && setIsEditing(true);
    } else {
      setIsEditing && setIsEditing(false);
    }
  };

  const onBlur = () => {
    if (value !== initialValue) {
      onUpdate({
        rowId,
        columnId,
        value: Number(value),
        initialValue,
        context,
      });
    }
    setTimeout(() => setIsEditing && setIsEditing(false), 100);
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <NumericFormat
      className={`focus:outline-none float-right justify-self-end border border-misc-ui-separator bg-input p-2.5 text-right focus:border-transparent focus:ring-2  ${
        className ?? ''
      }`}
      value={value}
      onValueChange={(values) => handleChange(values.floatValue ?? 0)}
      onBlur={onBlur}
      thousandSeparator={thousandSeparator}
      suffix={suffix}
      valueIsNumericString={true}
      defaultValue={0}
      isAllowed={(values) => isAllowedFn(values.floatValue ?? 0)}
    />
  );
}
