import { ReactNode, Suspense } from 'react';
import { LoadingSpinner } from '../../components/icons/LoadingSpinner';

export function SuspenseWrapper({ children }: { children: ReactNode }) {
  return (
    <Suspense
      fallback={
        <div className="flex justify-center items-center h-full">
          <LoadingSpinner width={36} height={36} />
        </div>
      }
    >
      {children}
    </Suspense>
  );
}
