import { useSituationState } from '../../../../../utils/useSituationState';
import { FlowContext } from '../../../../../hooks/useFlowContext';
import { DecisionsCard } from '../../../../../components/_shared/DecisionCard/DecisionCard';
import { EconomyCard } from '../../../../tasks/ManuellBehandling/EconomyCard/EconomyCard';
import { FamilyCard } from '../../../../../components/_shared/FamilyCard/FamilyCard';
import { IncomeCard } from '../../../../../components/_shared/IncomeCard/IncomeCard';
import { DebtCard } from '../../../../../components/_shared/DebtCard/DebtCard';
import { AssetsCard } from '../../../../../components/_shared/AssetsCard/AssetsCard';
import React from 'react';
import { TaskProps } from '../../../../../types/TaskProps';
import { useSituationStore } from '../../../../stores/useSituationStore';

export function AnalyseTabContent(props: TaskProps) {
  const { flow } = props;
  const { data } = flow;

  const { decisions, economyItems, persons, incomes } = useSituationState(data);

  const { debts, assets } = useSituationStore();

  return (
    <FlowContext.Provider value={props}>
      <div className="min-h-40 space-y-12">
        <div className="grid  grid-cols-2 gap-6">
          {decisions && <DecisionsCard {...decisions} />}
          {<EconomyCard {...economyItems} />}
        </div>
        <FamilyCard {...persons} />
        <IncomeCard {...incomes} />
        <DebtCard debts={debts} />
        <AssetsCard assets={assets} />
      </div>
    </FlowContext.Provider>
  );
}
