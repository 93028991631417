export function HouseInvertedIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" fill="none">
      <g
        stroke="#7A7C7A"
        strokeLinecap="round"
        strokeLinejoin="round"
        clipPath="url(#a)"
      >
        <path d="M16 7.5 8.5.5 1 7.5M3 8.5v7h11v-7" />
        <path d="M5.56 12.472a1.95 1.95 0 0 0 2.94-.316 1.95 1.95 0 0 0 2.941.316 1.812 1.812 0 0 0 0-2.618l-2.94-2.852-2.942 2.852a1.813 1.813 0 0 0 0 2.618v0Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M.5 0h16v16H.5z" />
        </clipPath>
      </defs>
    </svg>
  );
}
