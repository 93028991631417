import React, { Fragment } from 'react';
import { Tab } from '@headlessui/react';
import { AdditionalFilePreview } from '../tasks/DocumentControl/AdditionalFilePreview';
import { TaskProps } from '../../types/TaskProps';

type FilePreviewTabsProps = {
  attachments: any[];
  props: TaskProps;
};

const FilePreviewTabs = ({ attachments, props }: FilePreviewTabsProps) => {
  return (
    <div className="h-full bg-gray-100">
      <Tab.Group>
        <Tab.List className="sticky top-0 z-50 bg-white">
          {attachments?.map((arr: any, index: number) => (
            <Tab as={Fragment} key={index}>
              {({ selected }) => (
                <button
                  className={`whitespace-nowrap border-b-2 border-transparent py-4 px-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700 ${
                    selected ? 'border-b-2 border-basic-ink' : ''
                  }`}
                >
                  Vedlegg {index + 1}
                </button>
              )}
            </Tab>
          ))}
        </Tab.List>

        {attachments?.length > 0 &&
          attachments?.map((attachment: any, index: number) => {
            const matchObjectStructure = {
              task: {
                variables: {
                  flowId: props.flow.flowId,
                },
                context: {
                  attachmentMeta: {
                    owner: attachment.owner,
                    documentType: attachment.documentType,
                    mimetype: attachment.mimetype,
                    filetype: attachment.filetype,
                    attachmentKey: `${attachment.owner}-${attachment.documentType}-${attachment.attachmentId}`,
                  },
                  cloudmersiveErrors: props.task.context.cloudmersiveErrors,
                },
              },
            };
            return (
              <Tab.Panel className="h-full">
                {/*@ts-ignore*/}
                <AdditionalFilePreview {...(matchObjectStructure as any)} />
              </Tab.Panel>
            );
          })}
      </Tab.Group>
    </div>
  );
};

export default FilePreviewTabs;
