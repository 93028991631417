export function PlusHeartIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_9484_245934)">
        <path
          d="M15.5 7.5L8 0.5L0.5 7.5"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.5 8.5V15.5H13.5V8.5"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M8 8L8 12" stroke="currentColor" strokeLinecap="round" />
        <path d="M6 10H10" stroke="currentColor" strokeLinecap="round" />
      </g>
      <defs>
        <clipPath id="clip0_9484_245934">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
