import { TaskProps } from '../../../types/TaskProps';
import { FormProvider, useForm } from 'react-hook-form';
import { TextArea } from '../../../components/_shared/TextArea';
import { RadioButtonList } from '../../../components/_shared/RadioButtonList';
import { DecisionButtonGroup } from '../../components/DecisionButtonGroup';
import { useFlowContext } from '../../../hooks/useFlowContext';
import { DecisionError } from '../../components/DecisionError';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { SameUserError } from '../../../components/_shared/SameUserError/SameUserError';

const decisionOptions = [
  { value: 'approve', label: 'Enig' },
  { value: 'reject', label: 'Send tilbake til saksbehandler' },
];

export function ManualDecisionSignoffKjop({ save, complete }: TaskProps) {
  const { task } = useFlowContext();
  const method = useForm({
    defaultValues: getDefaultValues(task),
    resolver: zodResolver(ManualDecisionSignoffKjop.validationSchema),
  });
  const { handleSubmit, formState } = method;
  const { errors } = formState;

  const onSubmit = (data: any) => {
    complete(data);
  };

  const {
    t,
    user,
    task: { context, permissions },
  } = useFlowContext();
  const isSameUser =
    user?.profile?.username === context?.sentToTaskBy?.username;

  if (isSameUser && !permissions.complete) {
    return <SameUserError />;
  }

  return (
    <FormProvider {...method}>
      <form className="grid gap-2 py-8" onSubmit={handleSubmit(onSubmit)}>
        <h2 className="text-sm font-semibold text-black">
          {t('considerDecision')}
        </h2>
        <div className="grid gap-6">
          <RadioButtonList id="decision" options={decisionOptions} />
          <TextArea id="comment" label="comment" />
          <DecisionButtonGroup save={save} />
        </div>
        {Object.keys(errors).length > 0 && (
          <DecisionError text={'Melding er påkrevd'} />
        )}
      </form>
    </FormProvider>
  );
}

ManualDecisionSignoffKjop.validationSchema = z
  .object({
    decision: z.string(),
    comment: z.string().optional(),
  })
  .superRefine((val, context) => {
    if (val.decision === 'reject' && (!val?.comment || val?.comment === '')) {
      context.addIssue({
        code: 'custom',
        message: 'Melding er påkrevd',
      });
    }
  });

function getDefaultValues(task: TaskProps['task']): any {
  return {
    decision: task.data?.decision,
    comment: task.data?.comment ?? '',
  };
}
