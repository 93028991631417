import React from 'react';
import { format, parseISO } from 'date-fns';
import { Link } from 'react-router-dom';
import type { Stakeholder } from '../../types/Stakeholder';

type QueueItemProps = {
  path: string;
  flow: any;
  t: (key: string) => string;
};
export function QueueItem({ path, flow, t }: QueueItemProps) {
  const { flowDefinitionId, flowId, data, createdAt, assignee, referenceId } =
    flow;
  const { proofOfFinance, loans } = data;
  const createdAtDate = format(parseISO(createdAt), 'dd.MM.yyyy');
  const takeOverDate = proofOfFinance?.purchase?.takeoverDate
    ? format(parseISO(proofOfFinance?.purchase?.takeoverDate), 'dd.MM.yy')
    : null;
  const loanPurpose = loans?.[0]?.product;

  const isActive =
    window?.location?.pathname ===
    `/inbox/${path}/${flowDefinitionId}/${flowId}`;

  return (
    <Link
      aria-current={'page'}
      to={`/inbox/${path}/${flowDefinitionId}/${flowId}`}
      className={` queue-link flex flex-col px-3 py-2  ${
        isActive
          ? 'border-l-4 border-primary-800 bg-primary-100'
          : 'border-l-4 border-opacity-0 bg-primary-50 shadow'
      }`}
    >
      <div>
        <div className={'grid grid-cols-queue-item gap-1'}>
          <p className={'text-lg text-gray-700'}>
            {!!data?.stakeholders
              ? data.stakeholders
                  ?.map(
                    (stakeholder: Stakeholder) =>
                      `${stakeholder.fullName ?? stakeholder?.email} `,
                  )
                  .join(' & ')
              : ''}
          </p>
        </div>
        <div className={'mt-1 grid grid-cols-2 text-xs text-gray-500'}>
          <p>{referenceId}</p>
          <p className={'text-right'}>
            {loanPurpose
              ? `${t(`${loanPurpose}-loan-purpose`)} ${
                  takeOverDate ? ` - Overtas ${takeOverDate}` : ''
                }`
              : t(flowDefinitionId)}
          </p>
          <p>{assignee?.name ? assignee?.name : t('no-case-worker')}</p>
          <p className={'text-right'}>{createdAtDate}</p>
        </div>
      </div>
    </Link>
  );
}
