import { formatMoney } from '../../../utils/formatMoney';
import { DownArrowIcon } from '../../icons/DownArrowIcon';
import { Card } from '../Card';
import { List } from '../List';
import { DecisionId, findDecisionCode } from './DecisionsHelper';
import { useFlowContext } from '../../../hooks/useFlowContext';
import { useMemo } from 'react';
import { ListItemWithDecisionIconType } from '../ListItemWithDecisionIcon';

export function ScoringCardPurpose({
  decisions,
}: {
  decisions?: ListItemWithDecisionIconType[];
}) {
  const {
    t,
    flow: { data },
  } = useFlowContext();
  const usedDecisions = decisions ?? data.decisions;
  const redemption = data.loans?.[0]?.redemption;

  const items = useMemo(() => {
    const payouts = redemption?.payout
      ? new Array(redemption.payout)
      : redemption?.payouts;

    return (payouts ?? []).map(
      (payout: {
        amount: string | number;
        loanFor: string | string[];
        payoutReason?: string;
        reason?: string;
      }) => {
        const givenReason = payout?.payoutReason || payout?.reason;
        const val = `${t(payout.loanFor.toString())} ${
          !!givenReason ? `- ${t(givenReason)}` : ''
        }`;
        return {
          label: formatMoney(payout?.amount),
          value: val,
          decisionCode: findDecisionCode(
            usedDecisions,
            payout?.loanFor?.includes('other')
              ? DecisionId.PAYOUT_REASON_OTHER
              : '',
          ),
        };
      },
    );
  }, [redemption.payout, redemption?.payouts, t, decisions]);

  if (!items?.length) {
    return null;
  }

  return (
    <Card>
      <Card.Header className=" bg-negative-50 font-bold text-negative-800">
        <DownArrowIcon />
        <Card.HeaderTitle>{t('purpose', 'Formål')}</Card.HeaderTitle>
      </Card.Header>
      <Card.Content>
        <List items={items} />
      </Card.Content>
    </Card>
  );
}
