import { useEffect } from 'react';
import { z } from 'zod';
import {
  OrganizationDetailsSchema,
  OrganizationLookup,
} from './OrganizationLookup';
import { RadioSelect } from '../../components/common/RadioSelect';
import { HeaderLabel } from '../../components/common/HeaderLabel';
import { Input } from '../../components/common/Input';
import { useFlowContext } from '../../../hooks/useFlowContext';
import { useFormContext } from 'react-hook-form';
import { validateAccountNumberMOD11 } from '../../../libs/accountNumber';

const SIGNING_OPTIONS = ['DIGITAL', 'PAPER'];
const KID_PATTERN = /^[0-9.-\s]+$/;

const SettlementDetailsSchema = z.object({
  accountNumber: z
    .string({ required_error: 'accountNumberMissing' })
    .refine((val) => validateAccountNumberMOD11(val), {
      message: 'accountNumberInvalid',
    }),
  customerIdOrMessage: z
    .string({ required_error: 'customerIdOrMessageMissing' })
    .min(1, 'customerIdOrMessageMissing'),
  isCustomerId: z.boolean(),
});

export const SettlementSchema = z.object({
  settlementFlow: z.enum(['DIGITAL', 'PAPER'], {
    required_error: 'signing-flow-required',
    invalid_type_error: 'signing-flow-required',
  }),
  settlementInstance: OrganizationDetailsSchema,
  settlementDetails: SettlementDetailsSchema,
});

export type Settlement = z.infer<typeof SettlementSchema>;

export function SettlementInput() {
  const { watch, setValue } = useFormContext<{
    settlement: Settlement;
    kidOrMessageSelect: string;
  }>();
  const { t } = useFlowContext();

  const customerIdOrMessage = watch(
    'settlement.settlementDetails.customerIdOrMessage',
  );

  useEffect(() => {
    if (customerIdOrMessage) {
      if (KID_PATTERN.test(customerIdOrMessage)) {
        setValue('kidOrMessageSelect', 'KID');
        setValue('settlement.settlementDetails.isCustomerId', true);
      } else {
        setValue('kidOrMessageSelect', 'message');
        setValue('settlement.settlementDetails.isCustomerId', false);
      }
    }
  }, [customerIdOrMessage, setValue]);

  return (
    <div className="w-full h-full bg-primary-50 rounded border">
      <div className="p-4 grid grid-cols-1 gap-4">
        <RadioSelect id="settlement.settlementFlow" options={SIGNING_OPTIONS} />
        <div>
          <OrganizationLookup id="settlement.settlementInstance" />
        </div>
        <div>
          <HeaderLabel labelKey="settlementDetails" />
          <Input
            id="settlement.settlementDetails.accountNumber"
            label={t('accountNumber')}
          />
          <Input
            id="settlement.settlementDetails.customerIdOrMessage"
            label={t('customerIdOrMessage')}
          />
          <RadioSelect
            id="kidOrMessageSelect"
            options={['KID', 'message']}
            className="p-2 pr-0"
          />
        </div>
      </div>
    </div>
  );
}
