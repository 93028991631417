import { Stakeholder } from '../../../../types/Stakeholder';

export interface Owner {
  id: string;
  name: string;
}
export function getBorrowers(stakeholders: Stakeholder[]): Owner[] {
  let borrowers = stakeholders.map((stakeholder: Stakeholder) => ({
    id: stakeholder.nationalId,
    name: stakeholder.firstName,
  }));
  return borrowers;
}
