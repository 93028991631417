import { Loan } from '../../../../types/DisbursementTypes';

export function calculateTotalCorrectedAmount(loans: Loan[]): number {
  let totalCorrectedAmount = 0;

  if (!loans) {
    return 0;
  }
  for (const loan of loans) {
    for (const disbursement of loan.disbursements) {
      totalCorrectedAmount += disbursement.correctedAmount;
    }
  }

  return totalCorrectedAmount;
}
