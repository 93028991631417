import { useFormContext } from 'react-hook-form';
import { PropertyInfo, SearchInputProperty } from './SearchInputProperty';
import { SwitchSelect } from '../../components/common/SwitchSelect';
import { PropertyInput } from './PropertyInput';

export function PropertyDetails() {
  const { watch } = useFormContext<{
    propertyInfo: PropertyInfo;
    hasCompletePropertyKey: boolean;
  }>();

  const hasCompletePropertyKey = watch('hasCompletePropertyKey');

  return (
    <div className="w-full h-full bg-primary-50 rounded border">
      <div className="p-4 flex-col">
        <SwitchSelect
          id="hasCompletePropertyKey"
          label="hasCompletePropertyKey"
        />
        {hasCompletePropertyKey ? (
          <SearchInputProperty id="propertyInfo" />
        ) : (
          <PropertyInput />
        )}
      </div>
    </div>
  );
}
