import { useFlowContext } from '../../../hooks/useFlowContext';
import { Card } from '../Card';
import { WalletIcon } from '../../icons/WalletIcon';
import { ConditionalDecisionIcon } from '../ConditionalDecisionIcon';
import React from 'react';
import { DataTable } from '../DataTable';
import { formatMoney } from '../../../utils/formatMoney';

interface ScoreItem {
  label: string;
  value: string | number;
  decisionCode?: string;
}

interface Props {
  economyItems: ScoreItem[];
}

export function EconomyCard({ economyItems }: Props) {
  const { t } = useFlowContext();

  const mappedItems = economyItems?.map((item) => ({
    label: item.label,
    value:
      item.label === 'disposableLiquidity'
        ? formatMoney(item.value)
        : Number(item.value ?? 0).toFixed(2),
    decisionCode: item.decisionCode,
  }));

  const columns = React.useMemo(
    () => [
      {
        accessor: 'label',
        columnType: 'left',
        Cell: ({ cell, row }: any) => (
          <div>{` ${t(cell.value, {
            context: t(row.original.context ?? ''),
          })}`}</div>
        ),
      },
      {
        accessor: 'value',
        columnType: 'right',
        Cell: ({ cell, row }: { cell: any; row: any }) => {
          return (
            <div className="float-right flex items-center gap-3">
              {t(cell.value)}
              <ConditionalDecisionIcon
                decisionCode={row.original.decisionCode}
              />
            </div>
          );
        },
      },
    ],
    [t],
  );
  return (
    <Card>
      <Card.Header className=" justify-between bg-note-100 text-note-900">
        <div className="flex gap-3">
          <WalletIcon />
          <Card.HeaderTitle>{t('economy')}</Card.HeaderTitle>
        </div>
      </Card.Header>
      <Card.Content>
        <DataTable data={mappedItems} columns={columns} />
      </Card.Content>
    </Card>
  );
}
