import { TaskProps } from '../../../../types/TaskProps';

export const hasPermissionSave = (task: TaskProps['task']) =>
  task.permissions?.save;

export const hasPermissionComplete = (task: TaskProps['task']) =>
  task.permissions?.complete;

export const hasPermissionsSaveOrComplete = (task: TaskProps['task']) =>
  hasPermissionSave(task) || hasPermissionComplete(task);
