export interface Asset {
  id: string;
  key?: string;
  type: AssetType;
  details: string;
  fuelType?: FuelType;
  annualDrivingDistance?: number;
  coopDebt?: number;
  /**
   * @deprecated
   */
  fetchedValue?: number;
  statedValue?: number;
  marketValue: number;
  mortgagors?: Mortgagor[];
  priority?: number;
  priorityMortgageAmount?: number;
  collateralValue?: number;
  isSecurity?: boolean;
  securityIds?: string[];
  isNew?: boolean;
  buildYear?: number;
  housingType?: HousingType;
  usageAcreage?: number;
  addressInformation?: {
    streetAddressName: string;
    streetAddressNumber: string;
    postalCode: string;
    postalPlace: string;
  };
  cadastre?: {
    cadastralUnitNumber: string;
    unitNumber: string;
    municipalityNumber: string;
    leaseholdUnitNumber: string;
    sectionNumber: string;
  };
  share?: {
    organizationNumber: string;
    shareNumber: string;
  };
  municipality?: {
    municipalityNumber: string;
    municipalityName: string;
  };
  assetStatus?: AssetStatus;
  salesObjectData?: SalesObjectData;
  /**
   * @deprecated
   */
  areaScore?: number;
  calculatedCosts?: number;
  calculatedCostsIsOverridden?: boolean;
  eiendomsverdi?: {
    fetchedValue: number;
    areaScore: number;
    municipalityScore: number;
    fetchedDate: string;
  };
  hasUnknownAddress?: boolean;
}

export interface SalesObjectData {
  availableEquityAmount: number;
  utilizedEquityAmount: number;
  currentLoanAmount: number;
  propertyValue: number;
  brokersFee: number;
  marginOfSafetyAmount: number;
  safetyMargin: number;
  isSold: boolean;
  jointDebt?: number;
  areaScore?: number;
  loanToValue?: number;
  usageAcreage: number;
  saleCosts?: number;
  eTariffCode?: string;
}

export enum HousingType {
  DETACHED = 'DETACHED',
  SEMI_DETACHED = 'SEMI.DETACHED',
  TOWNHOUSES = 'TOWNHOUSES',
  APARTMENT = 'APARTMENT',
  CABIN = 'CABIN',
}

export enum AssetType {
  VEHICLE = 'VEHICLE',
  OWNED_HOUSING = 'OWNEDHOUSE',
  SHARED_HOUSING = 'SHAREDHOUSE',
  BOAT = 'BOAT',
  OTHER = 'OTHER',
}

export enum FuelType {
  GAS = 'GASOLINE',
  DIESEL = 'DIESEL',
  ELECTRIC = 'ELECTRIC',
}

export type Mortgagor = {
  nationalId: string;
  name: string;
  firstName: string;
  lastName: string;
  fraction: { numerator: number; denominator: number };
};

export enum AssetStatus {
  TO_BE_SOLD = 'TO_BE_SOLD',
  TO_BE_PURCHASED = 'TO_BE_PURCHASED',
  SOLD = 'SOLD',
}
