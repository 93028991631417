import { useFlowContext } from '../../../hooks/useFlowContext';
import type { Stakeholder } from '../../../types/Stakeholder';
import BannerCard from './BannerCard';
import TrapetsCard from './TrapetsCard';

export default function Trapets() {
  const { flow } = useFlowContext();
  const stakeholders: Stakeholder[] = flow?.data?.stakeholders || [];
  const showBanner = stakeholders.some((s) => s?.trapets?.hasHitInList);

  return (
    <div className="grid grid-cols-2 gap-5">
      {showBanner && <BannerCard />}
      {stakeholders?.map((stakeholder) => (
        <TrapetsCard key={stakeholder.firstName} stakeholder={stakeholder} />
      ))}
    </div>
  );
}
