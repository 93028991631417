import { StateCreator } from 'zustand';

export interface Collateral {
  id: string;
  propertyIds: string[];
  accountIds: string[];
  amount: number;
  isBeroendePant: boolean;
  collateralCoverage: number;
  securityId: string;
}

export interface CollateralSlice {
  collaterals: Collateral[];
  setCollateralAgreements: (collateralAgreements: Collateral[]) => void;
  getCollateralAgreements: () => Collateral[];
  collateralCoverage: number;
  setCollateralCoverage: (ltv: number) => void;
  updateCollateral: (collateral: Collateral) => void;
}

export const createCollateralSlice: StateCreator<
  CollateralSlice,
  [],
  [],
  CollateralSlice
> = (set, get) => ({
  collaterals: [],
  collateralCoverage: 0,
  setCollateralAgreements: (collateralAgreements: Collateral[]) =>
    set({ collaterals: collateralAgreements }),
  getCollateralAgreements: () => get().collaterals,
  setCollateralCoverage: (ltv) => set({ collateralCoverage: ltv }),
  updateCollateral: (collateral) =>
    set((state) => {
      return {
        collaterals: (state.collaterals || []).map((agreement) =>
          agreement.id === collateral.id ? collateral : agreement,
        ),
      };
    }),
});
