import axios from 'axios';
import { TaskProps } from '../../../types/TaskProps';
import { MaliciousContentMessage } from '../../../components/_shared/MaliciousContentMessage';
import { useQuery } from '@tanstack/react-query';
import { Spinner } from '@flow/icons';
import { useState } from 'react';
import { useFlowContext } from '../../../hooks/useFlowContext';

async function getFile({ task }: TaskProps) {
  try {
    const { mimetype, attachmentKey } = task.context?.attachmentMeta || {};
    const { flowId } = task.variables;
    const { data } = await axios.get(
      `/api/flow/flows/${flowId}/attachments/${attachmentKey}/download`,
      {
        responseType: 'blob',
      },
    );
    const file = new Blob([data], { type: mimetype });

    return URL.createObjectURL(file);
  } catch (err: any) {
    console.error('error', err);
  }

  return null;
}

export function AdditionalFilePreview(props: TaskProps) {
  const { t } = useFlowContext();
  const [imgError, setImgError] = useState(false);
  const { data: src, isLoading } = useQuery({
    queryKey: ['file', props],
    queryFn: () => getFile(props),
  });

  const attachmentMeta = props.task.context?.attachmentMeta;

  const handleError = () => {
    setImgError(true);
  };

  if (!src) {
    if (props.task.context?.cloudmersiveErrors) {
      const { cloudmersiveErrors } = props.task.context;
      return (
        <div className="p-4">
          {cloudmersiveErrors &&
            cloudmersiveErrors.map((error: any) => {
              return <MaliciousContentMessage message={error.message} />;
            })}
        </div>
      );
    }
    return (
      <div className="grid place-items-center h-full">
        {isLoading && <Spinner size={50} />}
      </div>
    );
  }
  if (
    attachmentMeta?.filetype === 'pdf' ||
    attachmentMeta?.filetype === 'application/pdf'
  ) {
    return <iframe title="file" src={src} className="h-full w-full" />;
  }

  return (
    <div className="grid h-full w-full items-center justify-center object-fill">
      {!imgError ? (
        <img src={src} alt="previewed document" onError={handleError} />
      ) : (
        <div className="grid gap-6 bg-white p-6 rounded">
          <div className="text-red-500">
            {t('could-not-preview-file', 'Kunne ikke forhåndsvise fil')}:{' '}
            {attachmentMeta?.filename}.
          </div>
          <div className="flex justify-center">
            <a
              href={src}
              download={attachmentMeta?.filename}
              className="bg-blue-500 text-white px-4 py-2 rounded text-center"
            >
              {t('download', 'Last ned')}
            </a>
          </div>
        </div>
      )}
    </div>
  );
}
