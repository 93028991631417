import { useState } from 'react';
import { Person } from '../../components/Person';
import { FlowContext } from '../../hooks/useFlowContext';
import type { Stakeholder } from '../../types/Stakeholder';
import { Modal } from '../../components/_shared/Modal/Modal';
import { CaseDetails } from './Summary/CaseDetails/CaseDetails';
import { SummaryDetails } from './Summary/SummaryDetails/SummaryDetails';
import { PowerLevelIcon } from '../../components/icons/PowerLevel';
import { CaseDetailsOriginal } from './Summary/CaseDetails/CaseDetailsOriginal';
import { HistoryIcon } from '../../components/icons/HistoryIcon';
import FollowUpTask from './FollowUpTask';

export function CaseSummary(props: any) {
  const [openModalCurrent, openModalCurrentSet] = useState(false);
  const [openModalOriginal, openModalOriginalSet] = useState(false);
  const structuredProps = {
    ...props,
    flow: props.caseData,
  };

  const {
    t,
    flow: { flowId },
  } = structuredProps;

  const { data } = structuredProps?.caseData;

  const flowDefinitionId =
    props?.flowDefinitionId ?? props?.caseData?.flowDefinitionId;

  const isCompletedAndSentToCore =
    structuredProps?.flow.status === 'completed' &&
    structuredProps?.flow.flowDefinitionId === 'mortgage' &&
    !!structuredProps?.flow?.data?.createAccount;

  const isEligibleForFollowUp =
    structuredProps?.flow.status === 'completed' &&
    structuredProps?.flow.flowDefinitionId === 'mortgage';

  return (
    <FlowContext.Provider value={structuredProps}>
      <div className="grid gap-8">
        <div className="border-line grid gap-4 rounded-md border-solid border-primary-200 bg-primary-50 p-4 shadow">
          <div
            className={`grid gap-2.5 ${
              data?.stakeholders?.length > 1 ? `grid-cols-2` : `grid-cols-1`
            }`}
          >
            {data?.stakeholders?.map((stakeholder: Stakeholder) => (
              <Person
                showType={flowDefinitionId !== 'contact-us'}
                key={stakeholder?.nationalId}
                person={stakeholder}
                styling="bg-white px-2 py-1 rounded-br-md rounded-tr-md text-xs"
              />
            ))}
          </div>
          {flowDefinitionId !== 'contact-us' && (
            <SummaryDetails
              flowId={flowId}
              flowDefinitionId={flowDefinitionId}
              data={data}
              isCompletedAndSentToCore={isCompletedAndSentToCore}
              showDetails={
                <CaseDetailsButton
                  handleOnClickCurrent={() =>
                    openModalCurrentSet(!openModalCurrent)
                  }
                  handleOnClickOriginal={() =>
                    openModalOriginalSet(!openModalOriginal)
                  }
                  t={t}
                />
              }
            />
          )}
        </div>
      </div>
      <Modal
        heading={t('case-details', 'Saksdetaljer')}
        isOpen={openModalCurrent}
        onClose={() => openModalCurrentSet(false)}
        t={t}
      >
        <CaseDetails {...structuredProps} />
      </Modal>
      <Modal
        heading={t('case-details-original', 'Original sak')}
        isOpen={openModalOriginal}
        onClose={() => openModalOriginalSet(false)}
        t={t}
      >
        <CaseDetailsOriginal {...structuredProps} />
      </Modal>
      {isEligibleForFollowUp && (
        <FollowUpTask
          flowId={flowId}
          requiresFollowUp={data.requiresFollowUp ?? false}
          t={t}
        />
      )}
    </FlowContext.Provider>
  );
}

export function CaseDetailsButton({
  handleOnClickOriginal,
  handleOnClickCurrent,
  t,
}: any) {
  return (
    <div className="flex justify-end p-4 gap-10">
      <button
        aria-labelledby="Åpne saksdetaljer"
        type="button"
        className="flex min-w-28 justify-end gap-1 text-xs text-blue-400 focus:underline"
        onClick={handleOnClickOriginal}
      >
        <HistoryIcon />
        <p>{t('case-details-original', 'Original sak')}</p>
      </button>

      <button
        aria-labelledby="Åpne saksdetaljer"
        type="button"
        className="flex min-w-28 justify-end gap-1 text-xs text-blue-400 focus:underline"
        onClick={handleOnClickCurrent}
      >
        <PowerLevelIcon />
        <p>{t('case-details', 'Saksdetaljer')}</p>
      </button>
    </div>
  );
}
