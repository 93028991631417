// export function formatMoney(value: number) {
//   if (isNaN(value)) {
//     return "";
//   }

//   const options: Intl.NumberFormatOptions = {
//     style: "currency",
//     currencyDisplay: "symbol",
//     unitDisplay: "short",
//     currency: "NOK", // http://www.currency-iso.org/en/home/tables/table-a1.html,
//     useGrouping: true,
//     unit: undefined,
//     minimumFractionDigits: 0,
//   };

//   const formatter = new Intl.NumberFormat("no", options);
//   const formattedValue = formatter.format(value);

//   return formattedValue;
// }

export function formatMoney(
  value: number | string,
  prefix = false,
  decimals = 0,
) {
  const numberValue = Number(value);
  const formatedNumber = new Intl.NumberFormat('no-NB', {
    style: prefix ? 'currency' : 'decimal',
    currency: 'NOK',
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(isNaN(numberValue) ? 0 : numberValue);
  return formatedNumber;
}
