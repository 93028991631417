import { useFlowContext } from '../../hooks/useFlowContext';
import { useFormConnect } from '../../hooks/useFormConnect';
import { CalendarIcon } from '../icons/CalendarIcon';

export function DateInput({
  label,
  id,
  disabled = false,
}: {
  label: string;
  id: string;
  disabled?: boolean;
}) {
  const { name, value, onChange, error } = useFormConnect(id);
  const { t } = useFlowContext();
  const minDate = new Date().toISOString().split('T')[0];
  return (
    <div className="flex flex-col">
      <label htmlFor={id}>{label}</label>
      <div className="relative w-full">
        <input
          type="date"
          id={id}
          name={name}
          value={value}
          pattern="dd-MM-yyyy"
          onChange={onChange}
          className={
            'focus:outline-none z-0 float-right w-full cursor-pointer justify-self-end border border-misc-ui-separator bg-input p-2.5 focus:border-transparent focus:ring-2'
          }
          min={minDate}
          disabled={disabled}
        />
        <div className="dateIcon">
          <CalendarIcon color="#787573" />
        </div>
      </div>
      {error && <span className="block text-red-500">{t(error)}</span>}
    </div>
  );
}
