import { Combobox } from '@headlessui/react';
import { Fragment, useState } from 'react';
import { MagnifyingGlassIcon } from '../../../components/icons/MagnifyingGlassIcon';
import { useQuery } from '@tanstack/react-query';
import { Spinner } from '@flow/icons';
import { classNames } from '../../../utils/classNames';
import { searchPropertyAmbita } from '../ManuellBehandling/utils/getPropertyAmbita';
import { useDebounce } from 'usehooks-ts';
import { useFormConnect } from '../../../hooks/useFormConnect';
import { SelectedPropertyDetails } from './SelectedPropertyDetails';
import { z } from 'zod';

interface SearchInputValue {
  key: string;
  type: string;
}

export function SearchInputProperty({ id }: { id: string }) {
  const { value, onChange, name } = useFormConnect(id);

  const [searchString, setSearchString] = useState<string>(value || '');
  const debouncedValue = useDebounce(searchString, 500);

  const { refetch, isFetching, data } = useQuery({
    queryKey: ['propertySearch', debouncedValue],
    queryFn: () => searchPropertyAmbita(debouncedValue.replaceAll('/', '-')),
    enabled: debouncedValue.length >= 6,
  });

  const handleButtonClick = () => {
    if (searchString.length >= 6) {
      refetch();
    }
  };

  function handleRemove() {
    onChange('');
    setSearchString('');
  }

  return (
    <Fragment>
      <Combobox value={value} onChange={onChange} name={name}>
        <Combobox.Label htmlFor="search">Søk etter eiendom</Combobox.Label>
        <div className="flex">
          <Combobox.Input
            name="search"
            onChange={(e) => setSearchString(e.target.value)}
            className="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            placeholder="Matrikkel / org.nr (f.eks. 4601-11/1/0/0 eller 123456789-1)"
            displayValue={(value: SearchInputValue) => value.key}
          />
          <Combobox.Button
            className="relative -ml-px inline-flex  items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-primary-600 bg-primary-500 hover:bg-primary-600 "
            type="button"
            onClick={handleButtonClick}
          >
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3" />
            {isFetching ? <Spinner size={22} /> : <MagnifyingGlassIcon />}
          </Combobox.Button>
        </div>
        <Combobox.Options className="mt-1 max-h-56 overflow-y-scroll">
          {(data || []).map((property: any) => (
            <Combobox.Option key={property.key} value={property} as={Fragment}>
              {({ active }) => (
                <li
                  className={classNames(
                    active
                      ? ' bg-primary-200 text-white'
                      : 'bg-white text-black',
                    'text-gray-900 cursor-pointer relative py-2 pl-3 pr-9',
                  )}
                >
                  {property.key}
                </li>
              )}
            </Combobox.Option>
          ))}
        </Combobox.Options>
      </Combobox>

      <SelectedPropertyDetails
        selectedProperty={value}
        onRemove={handleRemove}
      />
    </Fragment>
  );
}

export const PropertyInfoSchema = z.object({
  key: z.string({ required_error: 'propertyKeyRequired' }).min(1, {
    message: 'propertyKeyLengthRequired',
  }),
  address: z
    .object({
      formatted: z.object({
        line1: z.string(),
        line2: z.string(),
      }),
      municipality: z.object({
        municipalityNumber: z.number(),
        municipalityName: z.string(),
        regionNumber: z.number(),
        regionName: z.string(),
      }),
    })
    .optional(),
  ident: z
    .object({
      organizationNumber: z.number().optional(),
      shareNumber: z.number().optional(),
    })
    .or(
      z.object({
        municipalityNumber: z.number().optional(),
        cadastralUnitNumber: z.number().optional(),
        unitNumber: z.number().optional(),
        leaseholdUnitNumber: z.number().optional(),
        sectionNumber: z.number().optional(),
        section: z.boolean().optional(),
        leasehold: z.boolean().optional(),
      }),
    )
    .optional(),
  type: z.string({ required_error: 'propertyTypeRequired' }),
  customText: z.string().optional(),
});

export type PropertyInfo = z.infer<typeof PropertyInfoSchema>;
