import { Fragment, useEffect, useState } from 'react';
import { Tab } from '@headlessui/react';
import { FlowContext } from '../../../hooks/useFlowContext';
import { TaskProps } from '../../../types/TaskProps';
import { Layout } from '../../../components/Layout/Layout';
import { EmailView } from '../../../components/_shared/EmailView/EmailView';
import { Stakeholders } from '../../../components/Stakeholders';
import { DecisionPanel } from '../../../components/Layout/DecisionPanel';
import { AssessmentView } from '../../../components/_shared/Assessment/AssessmentView';
import { DisbursementControlCard } from './DisbursementControlCard/DisbursementControlCard';
import {
  Attachment,
  Documentation,
  DocumentationElement,
} from '../../../components/_shared/Documentation/Documentation';
import { triggerPendingMessageTask } from '../../../utils/messageTasks';
import { useMutation, useQuery } from '@tanstack/react-query';
import { KARValidationView } from './DisbursementControlCard/Components/KARValidationView';
import { initSituationStore } from '../../stores/useSituationStore';
import WarningIcon from '../../../components/icons/WarningIcon';
import {
  initDisbursementStore,
  useDisbursementControlStore,
} from '../../stores/useDisbursementStore';
import { SuspenseWrapper } from '../../components/SuspenseWrapper';
import { LazyAgreementRegister } from '../../../components/Insight/AgreementRegister/LazyAgreementRegister';
import { DecisionProofOfFinance } from './Decision.ProofOfFinance';
import { DecisionDefault } from './Decision.Default';
import {
  isBridgingLoanProduct,
  isProofOfFinanceProduct,
} from '../ManuellBehandling/utils/loanPurposeContextHelper';
import { fetchRedemptionAccounts } from './utils/fetchRedemptionAccounts';
import { Disbursement, Loan } from '../../../types/DisbursementTypes';
import { TaskSummary } from '../../components/Summary/TaskSummary/TaskSummary';
import { CollateralsCard } from '../../../components/_shared/CollateralsCard/CollateralsCard';
import { TinglysingView } from '../../../components/_shared/TinglysingCard/TinglysingView';

export function DisbursementControl(props: TaskProps) {
  const { flow, t, task, user } = props;
  const { data, flowId } = flow;
  const loan = data.loanOffer.loans?.[0];
  const isOldState = !!loan?.securities;
  const karValidation =
    loan?.payout?.['accountNumber-validation-kar'] ??
    loan?.payout?.karValidationResponse;

  const mainApplicantNationalId = data.stakeholders[0].nationalId;
  const { loans, redemptionDate, updateDisbursement, storeId } =
    useDisbursementControlStore();

  const isProofOfFinance = loans.some(
    (loan) =>
      isProofOfFinanceProduct(loan.product) ||
      isBridgingLoanProduct(loan.product),
  );

  const sender = user?.profile?.name;
  const [note, setNote] = useState('');
  const [currentFile, setCurrentFile] = useState<Attachment>();
  const [documentationData, documentationDataSet] = useState<
    DocumentationElement[]
  >(flow.data.documentation?.disbursement ?? []);

  const internalLoans =
    task.taskId === storeId
      ? loans.filter((loan) =>
          loan.disbursements.some(
            (disbursement) => disbursement.isInternalLoan,
          ),
        )
      : [];

  const { mutate } = useMutation({
    mutationKey: ['add-document', currentFile, note],
    mutationFn: (document: DocumentationElement) =>
      triggerPendingMessageTask(flow.flowId, 'add-document', {
        documentationElement: document,
      }).then(() => {
        documentationDataSet([...documentationData, document]);
        setNote('');
        setCurrentFile(undefined);
      }),
    onError: (err: any, newData, context) => {
      if (err.response) {
        console.error(err.response?.data);
      }
    },
  });

  async function saveDocument(e: any) {
    if (!currentFile && note.length === 0) return;
    e.preventDefault();
    const newDocumentation: DocumentationElement = {
      id: currentFile?.id ?? `${documentationData.length + 1}`,
      attachment: currentFile,
      sender,
      note,
      timestamp: new Date().toISOString(),
      mimeType: currentFile?.file?.type ?? '',
      owner: 'caseWorker',
    };
    mutate(newDocumentation);
  }

  const {
    context: { assessments },
  } = task;

  const { tinglysing } = data;

  const hasTinglysing = !!tinglysing;

  const tabs = [
    t('overview'),
    t('documentation'),
    ...(hasTinglysing ? [t('tinglysing', 'Tinglysing')] : []),
    t('sikkerheter', 'Sikkerheter'),
    ...(assessments?.length > 0
      ? [`${t('assessments')} (${assessments?.length ?? 0})`]
      : []),
  ];

  async function deleteDocument(document: DocumentationElement) {
    triggerPendingMessageTask(flowId, 'delete-document', {
      documentId: document.id,
    }).then(() => {
      documentationDataSet(
        documentationData.filter((d) => d.id !== document.id),
      );
    });
  }

  useEffect(() => {
    initSituationStore(data);
  }, [data]);

  useEffect(() => {
    initDisbursementStore(task);
  }, [task]);

  const { failureReason } = useQuery(
    [redemptionDate, flowId],
    () =>
      fetchRedemptionAccounts(
        internalLoans,
        mainApplicantNationalId,
        redemptionDate!,
      ),
    {
      enabled: redemptionDate != null && internalLoans?.length > 0,
      retry: 1,
      onSuccess: (data) => {
        if (data) {
          loans.map((loan: Loan) => {
            if (loan.disbursements && loan.disbursements.length > 0) {
              const updatedDisbursements = loan.disbursements.map(
                (disbursement: Disbursement) => {
                  const matchingDisbursement = data.find(
                    (d) => d.accountNo === disbursement.loanNumber,
                  );
                  if (matchingDisbursement) {
                    updateDisbursement(loan.id, disbursement.id, {
                      ...disbursement,
                      correctedAmount:
                        matchingDisbursement.resultRedemptionAmount,
                      accountNumber: matchingDisbursement.resultBankAccountNo,
                    });
                  }
                  return disbursement;
                },
              );

              return { ...loan, disbursements: updatedDisbursements };
            }

            return loan;
          });
        }
      },
    },
  );

  return (
    <FlowContext.Provider value={props}>
      <Layout>
        <div>
          <Tab.Group>
            <Tab.List className="sticky top-0 z-50 bg-white">
              {tabs.map((tab, index) => (
                <Tab as={Fragment} key={index}>
                  {({ selected }) => (
                    <button
                      className={`whitespace-nowrap border-b-2 border-transparent py-4 px-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700 ${
                        selected ? 'border-b-2 border-basic-ink' : ''
                      }`}
                    >
                      {tab}
                    </button>
                  )}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels className="h-full overflow-auto bg-gray-100 p-4">
              <Tab.Panel className="space-y-10">
                <>
                  <KARValidationView data={karValidation} />
                  {failureReason && (
                    <div className="flex items-center gap-2 rounded-md bg-caution-100 p-4 align-middle font-bold shadow">
                      <WarningIcon color="#946300" />
                      <p className="text-caution-800">
                        Henting av innfrielsessum for valgt dato feilet.
                        <br />
                        {`${failureReason}`}
                      </p>
                    </div>
                  )}
                  {loans?.map((loan, index) => {
                    return (
                      <DisbursementControlCard id={loan.id} key={loan.id} />
                    );
                  })}
                  <EmailView />
                </>
              </Tab.Panel>
              <Tab.Panel>
                <Documentation
                  documentationElements={documentationData}
                  saveDocument={saveDocument}
                  deleteDocument={deleteDocument}
                  currentFile={currentFile}
                  setCurrentFile={setCurrentFile}
                  note={note}
                  setNote={setNote}
                />
              </Tab.Panel>
              {hasTinglysing && (
                <Tab.Panel>
                  <div className="flex flex-col gap-16">
                    <TinglysingView tinglysing={tinglysing} />
                  </div>
                </Tab.Panel>
              )}
              <Tab.Panel className="agreementregister-disabled h-full">
                {isOldState ? (
                  <CollateralsCard />
                ) : (
                  <SuspenseWrapper>
                    <LazyAgreementRegister {...props} />
                  </SuspenseWrapper>
                )}
              </Tab.Panel>
              <Tab.Panel>
                <AssessmentView assessments={assessments} />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
        <div>
          <DecisionPanel>
            <Stakeholders stakeholders={data?.stakeholders} />
            <TaskSummary />
            {isProofOfFinance ? (
              <DecisionProofOfFinance {...props} />
            ) : (
              <DecisionDefault {...props} />
            )}
          </DecisionPanel>
        </div>
      </Layout>
    </FlowContext.Provider>
  );
}
