import { useMemo } from 'react';
import { PersonIcon } from '../../icons/PersonIcon';
import { RedIcon } from '../../icons/RedDecisionIcon';
import { YellowIcon } from '../../icons/YellowDecisionIcon';
import { GreenIcon } from '../../icons/GreenDecisionIcon';
import { Card } from '../Card';
import { useFlowContext } from '../../../hooks/useFlowContext';
import type { Stakeholder } from '../../../types/Stakeholder';
import { formatMoney } from '../../../utils/formatMoney';

const usedKeys = [
  'SkatteAr',
  'BruttoInntekt',
  'Inntekt',
  'Skatt',
  'Formue',
  'SkatteKlasse',
  'KommuneNavn',
  'Kommunenr',
];

const moneyKeys = ['Inntekt', 'BruttoInntekt', 'Skatt', 'Formue'];

function reduceEquations(arr: { [key: string]: any }[]): {
  [key: string]: any[];
} {
  return arr?.reduce((obj, element) => {
    usedKeys?.forEach((key) => {
      if (obj[key]) {
        obj[key]?.push(element[key]);
      } else {
        obj[key] = [element[key]];
      }
      if (key === 'Kommunenr') {
        if (!obj?.Kommune) {
          obj.Kommune = [];
        }
        obj?.Kommune.push(`${element?.Kommunenr} - ${element?.KommuneNavn}`);
      }
    });
    delete obj?.Kommunenr;
    delete obj?.KommuneNavn;
    return obj;
  }, {});
}

function getCreditScoreDecision(decisions: any[]) {
  const creditDecisions = decisions.filter(
    (decision: any) =>
      decision.table === 'table-bisnode-credit' &&
      decision.i18n !== 'decision-gjeldsregisteret-high-debt-amount',
  );

  const hasRedDecisions = creditDecisions.some(
    (decision: any) => decision.code === 'red',
  );
  const hasYellowDecisions = creditDecisions.some(
    (decision: any) => decision.code === 'yellow',
  );

  if (hasRedDecisions) {
    return 'red';
  }
  if (hasYellowDecisions) {
    return 'yellow';
  }
  return 'green';
}

function getCreditDecisionBackgroundColor(
  decision: 'green' | 'yellow' | 'red',
) {
  switch (decision) {
    case 'red':
      return 'bg-negative-50';
    case 'green':
      return 'bg-grass-90';
    default:
      return 'bg-caution-100';
  }
}

function getCreditDecisionTextColor(decision: 'green' | 'yellow' | 'red') {
  switch (decision) {
    case 'red':
      return 'text-negative-800';
    case 'green':
      return 'text-grass-20';
    default:
      return 'text-note-900';
  }
}

function getCreditDecisionIcon(decision: 'green' | 'yellow' | 'red') {
  switch (decision) {
    case 'red':
      return <RedIcon />;
    case 'green':
      return <GreenIcon />;
    default:
      return <YellowIcon />;
  }
}

export default function EquationCard({
  stakeholder,
}: {
  stakeholder: Stakeholder;
}) {
  const { t, flow } = useFlowContext();

  const decisions = flow?.data.decisions || [];
  const creditScoreDecision = getCreditScoreDecision(decisions);

  const score = stakeholder?.creditData?.Scoring?.Score;

  const equations = useMemo(() => {
    return reduceEquations(stakeholder?.creditData?.Ligning) || {};
  }, [stakeholder?.creditData?.Ligning]);

  return (
    <Card>
      <Card.Header
        className={`flex justify-between ${getCreditDecisionBackgroundColor(
          creditScoreDecision,
        )}`}
      >
        <div
          className={`flex gap-3 ${getCreditDecisionTextColor(
            creditScoreDecision,
          )}`}
        >
          <PersonIcon height={24} width={24} />
          {stakeholder?.fullName}
        </div>
        <div
          className={`flex gap-3 ${getCreditDecisionTextColor(
            creditScoreDecision,
          )}`}
        >
          <span>{score}</span>
          {getCreditDecisionIcon(creditScoreDecision)}
        </div>
      </Card.Header>
      <Card.Content horizontalPadding={false}>
        <table className="w-full text-basic-ink">
          <thead>
            <tr>
              <td className=" pl-5 pb-5 pt-3 font-bold">
                {t('incomeAndWealth')}
              </td>
            </tr>
          </thead>
          <tbody>
            {Object?.keys(equations)?.map((key, index) => (
              <tr
                key={key + index}
                className="border-t border-misc-ui-separator"
              >
                <td className="py-3 pl-5 text-basic-ink">{t(key)}</td>
                {equations?.[key]?.map((value, index) => {
                  const isMoney = moneyKeys?.includes(key);
                  if (isMoney) {
                    return (
                      <td
                        key={key + index}
                        className="py-3 text-right text-basic-ink last:pr-5"
                      >
                        {formatMoney(value)}
                      </td>
                    );
                  }
                  return (
                    <td key={key + index} className="text-right last:pr-5">
                      {value}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </Card.Content>
    </Card>
  );
}
