import type { ListItemType } from '../../../../components/_shared/ListItem';
import { useFlowContext } from '../../../../hooks/useFlowContext';
import type { Stakeholder } from '../../../../types/Stakeholder';
import WarningCard from './WarningCard';

export default function TaxResidency({
  stakeholder,
}: {
  stakeholder: Stakeholder;
}) {
  const { t } = useFlowContext();
  const title = t('decision-kyc-tax-citizenship');
  const declaration = stakeholder?.declaration?.taxDeclaration;
  const shouldRender = declaration?.isForeignCitizenTax;

  if (!shouldRender) return null;

  const countriesAndTin = declaration?.countriesAndTinNumber?.map((value) => {
    return [
      {
        label: t('taxLiabilityTo'),
        value: value?.country?.name,
      },
      {
        label: t('tinNumber'),
        value: value?.tinNumber,
      },
    ];
  });

  const items: ListItemType[] = [
    {
      label: t('foreignTaxResidency'),
      value: declaration?.isForeignCitizenTax ? t('yes') : t('no'),
    },
  ];

  if (declaration?.isForeignCitizenTax) {
    countriesAndTin?.forEach((country) => {
      items.push(...country);
    });
  }

  return <WarningCard title={title} items={items} />;
}
