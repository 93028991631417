export function PowerLevelIcon() {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_14623_62536)">
        <path
          d="M13.2406 4.8822C12.0369 2.52665 9.6472 1.06348 7.00369 1.06348C4.36074 1.06348 1.97101 2.52637 0.767031 4.88135C0.620626 5.16817 0.734277 5.51965 1.0211 5.66634C1.30793 5.81274 1.65912 5.69909 1.80609 5.41227C2.80955 3.4495 4.80109 2.23014 7.00369 2.23014C9.20686 2.23014 11.1984 3.44979 12.2016 5.41312C12.3047 5.61507 12.5095 5.73099 12.7217 5.73099C12.8108 5.73099 12.9017 5.71049 12.9866 5.66719C13.2734 5.5205 13.387 5.16903 13.2406 4.8822Z"
          fill="currentColor"
        />
        <path
          d="M8.77439 5.51753L8.57597 3.80586L7.69378 5.28601C7.21428 6.09091 4.81212 10.1564 4.62642 11.1582C4.51269 11.7711 4.64452 12.3915 4.99779 12.9053C5.19752 13.1962 5.45497 13.4334 5.75171 13.6047C5.97915 13.736 6.22982 13.8288 6.49549 13.8779C7.76057 14.1123 8.9807 13.2739 9.21487 12.0087C9.39761 11.0222 8.88 6.42837 8.77439 5.51753Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_14623_62536">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(0 0.480469)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
