import { Layout } from '../../../../components/Layout/Layout';
import { DecisionPanel } from '../../../../components/Layout/DecisionPanel';
import { TaskProps } from '../../../../types/TaskProps';
import { Stakeholders } from '../../../../components/Stakeholders';
import { Tab } from '@headlessui/react';
import { Fragment, useEffect, useRef } from 'react';
import { FlowContext } from '../../../../hooks/useFlowContext';
import { initSituationStore } from '../../../stores/useSituationStore';
import AnalysisTab from './components/AnalysisTab';
import ScoringTab from './components/ScoringTab';
import SBLTab from './components/SBLTab';
import CreditCheckTab from './components/CreditCheckTab';
import PaymentPlanTab from './components/PaymentPlanTab';
import AbilityToPayTab from './components/AbilityToPayTab';
import AgreementRegisterTab from './components/AgreementRegisterTab';
import ProofOfFinanceTab from './components/ProofOfFinanceTab';
import { isFinancingApplication } from '../../../tasks/ManuellBehandling/utils/loanPurposeContextHelper';
import { initProofOfFinancingStore } from '../../../stores/useProofOfFinancingStore';
import { TaskSummary } from '../TaskSummary/TaskSummary';
import { CollateralsCard } from '../../../../components/_shared/CollateralsCard/CollateralsCard';
import { PaymentPictureTabWrapper } from './components/PaymentPictureTab';
import { ManualDecision } from '../../../../types/ManualDecision';
import { AssessmentView } from '../../../../components/_shared/Assessment/AssessmentView';
import { TinglysingView } from '../../../../components/_shared/TinglysingCard/TinglysingView';

export function CaseDetails(props: TaskProps) {
  const { flow, t } = props;
  const isInitializedStoresRef = useRef(false);
  const { data } = flow;
  const hasCurrentSituation =
    data?.households?.[0]?.situations?.current?.betjeningsevne;

  const { manualDecisions = [], tinglysing } = data;

  const isOldState = !!data?.loanOffer?.loans?.[0]?.securities;
  const loans = data?.loanOffer?.loans;

  const assessments = manualDecisions.filter(
    (elem: ManualDecision) => elem.decisionType === 'disbursement',
  );

  const isCompletedAndSentToCore =
    flow.status === 'completed' &&
    flow.flowDefinitionId === 'mortgage' &&
    !!flow?.data?.createAccount;

  const tabs = [
    t('overview', 'Oversikt'),
    t('scoring', 'Scoring'),
    t('sbl', 'SBL'),
    t('abilityToPay', 'Betjeningsevne'),
    t('sikkerheter', 'Sikkerheter'),
    t('proofOfFinance', 'Finansieringsbevis'),
    t('creditCheck', 'Kredittsjekk'),
    t('paymentPlan', 'Nedbetalingsplan'),
    ...(isCompletedAndSentToCore
      ? [t('paymentPicture', 'Utbetalingsbilde')]
      : []),
    ...(isCompletedAndSentToCore && !!tinglysing
      ? [t('tinglysing', 'Tinglysing')]
      : []),
    ...(isCompletedAndSentToCore && assessments?.length > 0
      ? [`${t('assessments')} (${assessments?.length ?? 0})`]
      : []),
  ];

  useEffect(() => {
    if (!isInitializedStoresRef.current) {
      initSituationStore(data);
      if (isFinancingApplication(data)) {
        initProofOfFinancingStore(data?.proofOfFinance);
      }

      isInitializedStoresRef.current = true;
    }
  }, [data]);

  const controlRoutineAssessment = assessments.filter(
    (assessment: ManualDecision) =>
      'checklistFinancingMain' in assessment ||
      'checklistFinancingBridging' in assessment,
  );

  const controlRoutine = {
    checklistFinancingMain:
      controlRoutineAssessment?.[0]?.checklistFinancingMain,
    checklistFinancingBridging:
      controlRoutineAssessment?.[0]?.checklistFinancingBridging,
  };

  const firstLoan = loans?.[0];

  const karValidation =
    firstLoan?.payout?.['accountNumber-validation-kar'] ??
    firstLoan?.payout?.karValidationResponse;

  return (
    <FlowContext.Provider value={props}>
      <Layout>
        <div>
          <Tab.Group>
            <Tab.List className="sticky top-0 z-50 bg-white">
              {tabs.map((tab, index) => (
                <Tab as={Fragment} key={index}>
                  {({ selected }) => (
                    <button
                      className={`whitespace-nowrap border-b-2 border-transparent py-4 px-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700 ${
                        selected ? 'border-b-2 border-basic-ink' : ''
                      }`}
                    >
                      {tab}
                    </button>
                  )}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels className="h-full overflow-auto bg-gray-100 p-4">
              <Tab.Panel>
                <AnalysisTab {...props} />
              </Tab.Panel>
              <Tab.Panel>
                <ScoringTab {...props} />
              </Tab.Panel>
              <Tab.Panel>
                <SBLTab {...props} />
              </Tab.Panel>
              <Tab.Panel>
                <AbilityToPayTab {...props} />
              </Tab.Panel>
              <Tab.Panel className="h-full agreementregister-disabled">
                {isOldState ? (
                  <CollateralsCard />
                ) : (
                  <AgreementRegisterTab {...props} />
                )}
              </Tab.Panel>
              <Tab.Panel>
                <ProofOfFinanceTab {...props} />
              </Tab.Panel>
              <Tab.Panel>
                <CreditCheckTab {...props} />
              </Tab.Panel>
              <Tab.Panel>
                <PaymentPlanTab {...props} />
              </Tab.Panel>
              <Tab.Panel>
                <PaymentPictureTabWrapper
                  loans={loans}
                  karValidation={karValidation}
                  redemptionDate={flow.data?.loanOffer?.redemptionDate}
                  priorityStatus={flow.data?.loanOffer?.priorityStatus}
                  controlRoutine={controlRoutine}
                />
              </Tab.Panel>
              {tinglysing && (
                <Tab.Panel>
                  <TinglysingView tinglysing={tinglysing} />
                </Tab.Panel>
              )}
              <Tab.Panel>
                <AssessmentView assessments={assessments} />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
        <div className="text-sm">
          <DecisionPanel>
            <Stakeholders stakeholders={data?.stakeholders} />
            {hasCurrentSituation && <TaskSummary />}
          </DecisionPanel>
        </div>
      </Layout>
    </FlowContext.Provider>
  );
}
