export function CopyIcon() {
  return (
    <svg
      width="24"
      height="32"
      viewBox="0 0 24 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 5V20H8.25V18.5H4.5V6.5H13.5V7.25H15V5H3ZM9 8V23H21V8H9ZM10.5 9.5H19.5V21.5H10.5V9.5Z"
        fill="#467EFF"
      />
    </svg>
  );
}
