import { create } from 'zustand';
import {
  Disbursement,
  DisbursementsState,
  Loan,
  LoanType,
  Payout,
} from '../../types/DisbursementTypes';
import { TaskProps } from '../../types/TaskProps';
import { fetchTotalFeeAmount } from '../tasks/GjennomforUtbetalingsKontroll/utils/fetchTotalFeeAmount';
import { fetchTotalCorrectedDisbursementAmount } from '../tasks/GjennomforUtbetalingsKontroll/utils/fetchTotalCorrectedDisbursementAmount';
import { nanoid } from 'nanoid';

interface DisbursementControlState extends DisbursementsState {
  addDisbursement: (type: LoanType, id: string) => void;
  updateDisbursement: (
    loanId: string,
    disbursementId: string,
    data: Disbursement,
  ) => void;
  deleteDisbursement: (loanId: string, disbursementId: string) => void;
  updatePayout: (loanId: string, data: Payout) => void;
  setLoans: (data: Loan[]) => void;
  setStoreId: (id: string) => void;
  updateLoans: (loanId: string, loan: Loan) => void;
  updateRedemptionDate: (data: string) => void;
}

export const useDisbursementControlStore = create<DisbursementControlState>(
  (set, get) => {
    return {
      storeId: '',
      redemptionDate: '',
      loans: [],
      addDisbursement: (type: LoanType, id: string) => {
        let newDisbursement: Disbursement = {
          id: `added-${type}-${nanoid(5)}`,
          nameOfBank: 'Tilført lån',
          loanNumber: '',
          loanAmount: 0,
          loanFor: type,
          ocr: '',
          message: '',
          correctedAmount: 0,
          accountNumber: '',
          isInternalLoan: false,
        };
        set((state) => ({
          loans: state.loans.map((loan) => {
            return loan.id === id
              ? {
                  ...loan,
                  disbursements: [...loan.disbursements, newDisbursement],
                }
              : loan;
          }),
        }));
      },
      updateDisbursement: (loanId, disbursementId, data) => {
        set((state) => ({
          loans: state.loans.map((loan) => {
            if (loan.id === loanId) {
              const updatedDisbursements = loan.disbursements.map(
                (disbursement) => {
                  if (disbursement.id === disbursementId) {
                    return { ...disbursement, ...data };
                  }
                  return disbursement;
                },
              );
              const totalLoanPayout =
                loan.amount -
                fetchTotalCorrectedDisbursementAmount(updatedDisbursements) -
                fetchTotalFeeAmount(loan);

              return {
                ...loan,
                disbursements: updatedDisbursements,
                payout: {
                  ...loan.payout,
                  correctedAmount: totalLoanPayout,
                },
              };
            }
            return loan;
          }),
        }));
      },
      deleteDisbursement: (loanId, disbursementId) => {
        set((state) => ({
          loans: state.loans.map((loan) => {
            const disbursementToBeRemoved = loan.disbursements.find(
              (d) => d.id === disbursementId,
            );
            return loan.id === loanId
              ? {
                  ...loan,
                  payout: {
                    ...loan.payout,
                    correctedAmount:
                      (loan.payout.correctedAmount ?? 0) +
                      (disbursementToBeRemoved?.correctedAmount ?? 0),
                  },
                  disbursements: loan.disbursements.filter(
                    (d) => d.id !== disbursementId,
                  ),
                }
              : loan;
          }),
        }));
      },
      updatePayout: (loanId, data) => {
        set((state) => ({
          loans: state.loans.map((loan) =>
            loan.id === loanId ? { ...loan, payout: data } : loan,
          ),
        }));
      },
      setLoans: (data) => {
        set({ loans: data });
      },
      setStoreId: (id) => {
        set({ storeId: id });
      },
      updateLoans: (loanId, data) => {
        set((state) => ({
          loans: state.loans.map((loan) =>
            loan.id === loanId ? { ...data } : loan,
          ),
        }));
      },
      updateRedemptionDate: (data: string) => {
        set({ redemptionDate: data });
      },
    };
  },
);

export const initDisbursementStore = (task: TaskProps['task']) => {
  const mappedLoans = mapDisbursementData(
    task?.data?.loans || task?.context?.loans,
  );

  const store = useDisbursementControlStore.getState();
  store.updateRedemptionDate(
    task.data.redemptionDate || task.context?.redemptionDate,
  );
  store.setStoreId(task.taskId);
  store.setLoans(mappedLoans.loans);
};

function mapDisbursementData(loans: []) {
  const formattedLoans = loans.map((elem: Loan, index: number) => ({
    id: elem.id ?? `disbursement-${elem?.product}-${index}`,
    product: elem?.product ?? '',
    amount: elem?.amount,
    disbursements:
      elem?.disbursements?.map((disbursement: IncomingDisbursementType) => {
        return {
          id: `disbursement-${disbursement?.loanFor}-${nanoid(5)}`,
          nameOfBank:
            disbursement.nameOfBank || `${disbursement?.loanFor}-loan`,
          loanNumber: disbursement?.loanNumber || '',
          loanAmount: disbursement?.loanAmount || disbursement?.amount || 0,
          loanFor: disbursement?.loanFor,
          ocr: disbursement?.ocr ?? '',
          message: disbursement?.message || disbursement?.ocrMessage,
          correctedAmount: disbursement?.correctedAmount ?? 0,
          accountNumber: disbursement?.accountNumber,
          isInternalLoan: disbursement?.isInternalLoan || false,
        };
      }) ?? [],
    payout:
      {
        correctedAccountNumber: '',
        correctedAmount: elem.amount,
        ocr: '',
        message: 'Utbetaling av lån',
        ...elem.payout,
      } ?? {},
    fees: elem?.fees ?? [],
  }));
  return { redemptionDate: '', loans: formattedLoans };
}

interface IncomingDisbursementType {
  nameOfBank: string;
  loanNumber: string;
  loanAmount?: number;
  amount?: number;
  accountNumber: string;
  correctedAmount: number;
  loanFor?: string;
  ocrMessage?: string;
  ocr?: string;
  message?: string;
  id: string;
  isInternalLoan?: boolean;
}
