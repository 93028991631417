import { DecisionPanel } from '../../../components/Layout/DecisionPanel';
import { Layout } from '../../../components/Layout/Layout';
import { Stakeholders } from '../../../components/Stakeholders';
import { FlowContext } from '../../../hooks/useFlowContext';
import { TaskProps } from '../../../types/TaskProps';
import { Decision } from './Decision';
import { IntentionError } from './IntentionError';
import FilePreviewTabs from '../../components/FilePreviewTabs';

export function ReadySettlement(props: TaskProps) {
  const { task, flow } = props;

  const intentionError =
    props.flow.data?.settlement?.settlementIntent?.intentionError;

  const attachments = task.context?.attachments ?? [];

  return (
    <FlowContext.Provider value={props}>
      <Layout>
        <FilePreviewTabs attachments={attachments} props={props} />
        <div>
          <DecisionPanel>
            <IntentionError intentError={intentionError} />
            <Stakeholders stakeholders={flow.data.stakeholders} />
            <div className="grid gridcols-1 gap-4">
              <Decision {...props} />
            </div>
          </DecisionPanel>
        </div>
      </Layout>
    </FlowContext.Provider>
  );
}
