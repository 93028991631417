import { Disbursement, Loan } from '../../../../types/DisbursementTypes';
import { validateAccountNumberMOD11 } from '../../../../libs/accountNumber';

export function fetchAccountNumberValidationError(loans: Loan[]) {
  const filteredDisbursements = loans?.flatMap((loan) =>
    loan.disbursements.filter(
      (disbursement: Disbursement) =>
        !validateAccountNumberMOD11(disbursement.accountNumber || null),
    ),
  );

  const payoutAccountNoError = loans?.some(
    (loan) =>
      !!loan.payout.correctedAmount &&
      !validateAccountNumberMOD11(loan.payout.correctedAccountNumber || null),
  );

  const disbursementAccountNoError = filteredDisbursements?.length > 0;

  return { disbursementAccountNoError, payoutAccountNoError };
}
