import { Layout } from '../../../components/Layout/Layout';
import { DecisionPanel } from '../../../components/Layout/DecisionPanel';
import { TaskProps } from '../../../types/TaskProps';
import { Stakeholders } from '../../../components/Stakeholders';
import { Decision } from './Decision';
import { FlowContext } from '../../../hooks/useFlowContext';
import { Tab } from '@headlessui/react';
import { Fragment } from 'react';
import { AdditionalFilePreview } from './AdditionalFilePreview';

export function DocumentControl(props: TaskProps) {
  const { flow, task } = props;

  const attachments = task?.context?.attachments;

  return (
    <FlowContext.Provider value={props}>
      <Layout>
        <div className="h-full bg-gray-100">
          <Tab.Group>
            <Tab.List className="sticky top-0 z-50 bg-white">
              {attachments.map((arr: any, index: number) => (
                <Tab as={Fragment} key={index}>
                  {({ selected }) => (
                    <button
                      className={`whitespace-nowrap border-b-2 border-transparent py-4 px-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700 ${
                        selected ? 'border-b-2 border-basic-ink' : ''
                      }`}
                    >
                      Vedlegg {index + 1}
                    </button>
                  )}
                </Tab>
              ))}
            </Tab.List>
            {attachments.map((arr: any, index: number) => {
              const object = {
                task: {
                  variables: {
                    flowId: flow?.flowId,
                  },
                  context: {
                    attachmentMeta: arr,
                  },
                },
              };
              return (
                <Tab.Panel className="h-full">
                  {/*@ts-ignore*/}
                  <AdditionalFilePreview {...object} />
                </Tab.Panel>
              );
            })}
          </Tab.Group>
        </div>
        <DecisionPanel>
          <Stakeholders stakeholders={flow.data.stakeholders} />
          <Decision {...props} />
        </DecisionPanel>
      </Layout>
    </FlowContext.Provider>
  );
}
