import React from 'react';
import { TaskProps } from '../../../../../types/TaskProps';
import { FlowContext } from '../../../../../hooks/useFlowContext';
import { PaymentPlanWrapper } from '../../../../../components/_shared/Paymentplan';
import { isSelectedLoan } from '../../../../../utils/isSelectedLoan';
import { EmptyState } from './EmptyState';
import { useSituationStore } from '../../../../stores/useSituationStore';

export default function PaymentPlanTab(props: TaskProps) {
  const { flow, t } = props;
  const { data } = flow;
  const hasSituation =
    !!data?.households?.[0]?.situations?.current?.betjeningsevne;
  const { debts } = useSituationStore();

  if (!hasSituation) {
    return <EmptyState t={t} tab={t('payment-plan', 'Nedbetalingsplan')} />;
  }

  return (
    <FlowContext.Provider value={props}>
      <PaymentPlanWrapper
        data={data}
        caseData={flow}
        loan={debts.find(isSelectedLoan)}
      />
    </FlowContext.Provider>
  );
}
