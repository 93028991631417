import { useState, useEffect } from 'react';
import { NumericFormat } from 'react-number-format';
import { useEditingStore } from '../../../customer/stores/useEditingStore';
import { classNames } from '../../../utils/classNames';

interface NumericInputProps {
  initialValue: any;
  handleUpdate: any;
  suffix?: string;
  editable?: boolean;
  allowNegative?: boolean;
  valueIsNumericString?: boolean;
  styles?: any;
  decimalScale?: number;
  fixedDecimalScale?: boolean;
}
export function CustomNumericInput({
  initialValue,
  handleUpdate,
  suffix = '',
  editable = true,
  allowNegative = true,
  valueIsNumericString = true,
  styles = '',
  decimalScale = undefined,
  fixedDecimalScale = false,
}: NumericInputProps) {
  const [value, setValue] = useState(initialValue);
  const setIsEditing = useEditingStore((state) => state.setIsEditing);

  const handleChange = (value?: number) => {
    setValue(value);
    if (value !== initialValue) {
      setIsEditing && setIsEditing(true);
    } else {
      setIsEditing && setIsEditing(false);
    }
  };

  const onBlur = () => {
    if (value !== initialValue) {
      handleUpdate(value);
    }
    setTimeout(() => setIsEditing && setIsEditing(false), 100);
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <NumericFormat
      className={classNames(
        editable
          ? 'focus:outline-none  border border-misc-ui-separator bg-input p-2.5 text-right focus:border-transparent focus:ring-2'
          : '',
        `flex items-center overflow-ellipsis whitespace-nowrap ${styles}`,
      )}
      value={value}
      onValueChange={(values) => handleChange(values.floatValue ?? 0)}
      onBlur={onBlur}
      thousandSeparator=" "
      suffix={suffix}
      valueIsNumericString={valueIsNumericString}
      defaultValue={0}
      allowNegative={allowNegative}
      decimalScale={decimalScale}
      fixedDecimalScale={fixedDecimalScale}
      displayType={editable ? 'input' : 'text'}
    />
  );
}
