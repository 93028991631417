import React from 'react';
import { Card } from '../Card';
import { DataTable } from '../DataTable';
import { formatMoney } from '../../../utils/formatMoney';
import { useFlowContext } from '../../../hooks/useFlowContext';
import { PalaceIcon } from '../../icons/PalaceIcon';
import { Asset, AssetType } from './AssetsCard.types';
import { AssetTypeCell } from './AssetType';
import { classNames } from '../../../utils/classNames';
import { isSalesObject } from '../../../utils/isSalesObject';

export function AssetsCard({ assets }: { assets: Asset[] }) {
  const { t } = useFlowContext();

  const columns = React.useMemo(
    () => [
      {
        Header: t('type'),
        accessor: 'assetType',
        columnType: 'left',
        Cell: ({ row }: any) => (
          <AssetTypeCell
            className={classNames(
              isSalesObject(row.original) ? 'text-neutral-500' : '',
              'w-44',
            )}
            asset={row.original}
          />
        ),
      },
      {
        Header: t('details'),
        accessor: 'details',
        columnType: 'left',
        Cell: ({ cell, row }: any) => {
          if (isVehicle(row?.original?.type)) {
            return (
              <div className="flex items-center gap-2">
                <span>{t(row.original.fuelType)}</span>
                {'-'}
                <span>
                  {formatMoney(row.original.annualDrivingDistance)} km
                </span>
              </div>
            );
          }
          return (
            <div
              className={classNames(
                isSalesObject(row.original) ? 'text-neutral-500' : '',
              )}
            >
              {t(cell.value)}
            </div>
          );
        },
      },
      {
        Header: t('usageAcreage'),
        accessor: 'usageAcreage',
        columnType: 'right',
        Cell: ({ row, cell }: any) =>
          isHousing(row.original.type) ? (
            <span>
              {cell.value ?? ''} m<sup>2</sup>
            </span>
          ) : (
            <>-</>
          ),
      },
      {
        Header: t('buildYear'),
        accessor: 'buildYear',
        columnType: 'right',
        Cell: ({ row, cell }: any) =>
          isHousing(row.original.type) ? (
            <span>{cell.value ?? '-'}</span>
          ) : (
            <>-</>
          ),
      },
      {
        Header: t('coop-debt'),
        accessor: 'coopDebt',
        columnType: 'right',
        Cell: ({ cell }: any) =>
          cell.value !== undefined ? formatMoney(cell.value) : '-',
      },
      {
        Header: t('fetched-value'),
        accessor: (data: Asset) => {
          return data.fetchedValue
            ? data.fetchedValue
            : data.eiendomsverdi?.fetchedValue;
        },
        columnType: 'right',
        Cell: ({ cell, row }: any) => (
          <span
            className={classNames(
              isSalesObject(row.original) ? 'text-neutral-500' : '',
            )}
          >
            {isHousing(row?.original?.type) && formatMoney(cell.value ?? 0)}
          </span>
        ),
      },
      {
        Header: t('stated-value'),
        accessor: 'statedValue',
        columnType: 'right',
        Cell: ({ cell, row }: any) => (
          <span
            className={classNames(
              isSalesObject(row.original) ? 'text-neutral-500' : '',
            )}
          >
            {formatMoney(cell.value ?? 0)}
          </span>
        ),
      },
      {
        Header: t('marked-value'),
        accessor: 'marketValue',
        columnType: 'right',
        Cell: ({ cell, row }: any) => (
          <span
            className={classNames(
              isSalesObject(row.original) ? 'text-neutral-500' : '',
            )}
          >
            {formatMoney(cell.value ?? 0)}
          </span>
        ),
      },
    ],
    [t],
  );

  function findTotalValue(assets: Asset[]): number {
    return assets?.reduce(
      (a: number, b: Asset) => a + Number(b?.marketValue ?? 0),
      0,
    );
  }

  return (
    <Card>
      <Card.Header className="justify-between bg-primary-50 text-primary-800 ">
        <div className="flex items-center gap-3">
          <PalaceIcon />
          <Card.HeaderTitle>{t('properties')}</Card.HeaderTitle>
        </div>
        <div className="flex items-center gap-1">
          <span>{formatMoney(findTotalValue(assets))}</span>
          <span className="text-primary-200">NOK</span>
        </div>
      </Card.Header>
      <Card.Content>
        <DataTable data={assets ?? []} columns={columns} />
      </Card.Content>
    </Card>
  );
}

const isHousing = (assetType: AssetType) =>
  [AssetType.OWNED_HOUSING, AssetType.SHARED_HOUSING].includes(assetType);

const isVehicle = (assetType: AssetType) => assetType === AssetType.VEHICLE;
