import { Loan } from '../../../../types/DisbursementTypes';

export function calculateTotalFeesAmount(loans: Loan[]): number {
  let totalFeeAmount = 0;

  for (const loan of loans) {
    for (const fee of loan.fees) {
      totalFeeAmount += fee.amount;
    }
  }

  return totalFeeAmount;
}
