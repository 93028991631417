import { SvgIconProps } from './SvgIcon.types';

export function PersonIcon({ height = 16, width = 16 }: SvgIconProps) {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={height}
      height={width}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 21a7 7 0 0 1 7-7h6a7 7 0 0 1 7 7v1.73l-.695.222L21 22l.305.952-.003.001-.004.001-.01.004-.036.01a13.59 13.59 0 0 1-.595.159c-.409.1-1.008.23-1.791.36-1.567.258-3.871.513-6.866.513-2.994 0-5.299-.255-6.866-.513-.783-.13-1.382-.26-1.79-.36a13.556 13.556 0 0 1-.596-.158l-.035-.011-.011-.004h-.004l-.002-.001L3 22l-.305.952L2 22.73V21Zm2 .228c.354.082.842.183 1.46.285C6.924 21.755 9.12 22 12 22s5.076-.245 6.54-.487a23.384 23.384 0 0 0 1.46-.285V21a5 5 0 0 0-5-5H9a5 5 0 0 0-5 5v.228ZM12 2a4 4 0 0 0-4 4c0 1.132.468 2.419 1.252 3.419C10.038 10.422 11.026 11 12 11c.974 0 1.962-.578 2.748-1.581C15.532 8.419 16 7.132 16 6a4 4 0 0 0-4-4ZM6 6a6 6 0 0 1 12 0c0 1.63-.651 3.342-1.677 4.652C15.299 11.958 13.787 13 12 13c-1.787 0-3.3-1.042-4.323-2.348C6.651 9.342 6 7.63 6 6Z"
        fill={'currentColor'}
      />
    </svg>
  );
}
