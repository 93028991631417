import { SvgIconProps } from './SvgIcon.types';

export function ApprovedCircleIcon({
  size = 18,
  color = '#145BFF',
}: SvgIconProps) {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
    >
      <path
        d="M12 1a11 11 0 1 1 0 22"
        stroke={color}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="m6 12 4 4 8-8M1.046 12.913A11.028 11.028 0 0 1 1 12c0-.308.021-.611.046-.913M6.242 2.64c.518-.321 1.062-.599 1.626-.83M2.188 7.044c.276-.543.596-1.063.958-1.554M3.126 18.483c-.36-.493-.68-1.015-.954-1.561M6.223 21.348c.519.324 1.064.604 1.63.837"
        stroke={color}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
}
