import { format, parseISO } from 'date-fns';
import { Card } from '../Card';
import { PersonIcon } from '../../icons/PersonIcon';
import { DocumentationElement } from '../Documentation/Documentation';
import DropdownMenu, {
  MenuItem,
} from '../../../components/_shared/DropdownMenu';
import { TrashIcon } from '../../icons/TrashIcon';
import { useFlowContext } from '../../../hooks/useFlowContext';
import { DocumentationFilePreview } from '../Documentation/DocumentationFilePreview';

export function DocumentationCard({
  document,
  handleDelete,
}: {
  document: DocumentationElement;
  handleDelete?: (document: DocumentationElement) => void;
}) {
  const hasDocument = !!document?.attachment?.file;

  const { task: flow } = useFlowContext();
  const editable = flow.taskType === 'gjennomfor-utbetalings-kontroll';
  const { flowId } = flow;

  const dropdownItems: MenuItem[] = [
    {
      label: 'delete',
      handleClick: () => handleDelete && handleDelete(document),
      icon: <TrashIcon />,
    },
  ];

  return (
    <Card>
      <Card.Header className="justify-between bg-tertiary-50 text-tertiary-800 ">
        <div className="flex items-center gap-3">
          <PersonIcon />
          <Card.HeaderTitle className={'text-base'}>
            {document?.sender}
          </Card.HeaderTitle>
        </div>
        <div className="flex items-center gap-1 text-sm">
          {format(parseISO(document.timestamp), 'dd.MM.yyyy HH:mm')}
          {editable && <DropdownMenu items={dropdownItems} />}
        </div>
      </Card.Header>
      <Card.Content horizontalPadding={false} verticalPadding={false}>
        <div className="flex flex-col gap-3">
          {hasDocument && (
            <DocumentationFilePreview
              flowId={flowId}
              attachmentKey={document?.attachment?.id ?? ''}
              mimeType={document?.mimeType ?? ''}
            />
          )}
          {document.note && (
            <div className="w-full text-basic-ink p-5">{document.note}</div>
          )}
        </div>
      </Card.Content>
    </Card>
  );
}
