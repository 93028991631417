import { Layout } from '../../../components/Layout/Layout';
import { DecisionPanel } from '../../../components/Layout/DecisionPanel';
import { TaskProps } from '../../../types/TaskProps';
import { Stakeholders } from '../../../components/Stakeholders';
import { BehandleForesporselDecision } from './BehandleForesporselDecision';
import { FlowContext } from '../../../hooks/useFlowContext';
import { Tab } from '@headlessui/react';
import { Fragment } from 'react';
import { Files } from '../../../components/_shared/Files';
import { MessageCard } from './MessageCard';
import { SecondaryButton } from '@flow/buttons';
import { openUserInCore } from '../../../utils/openInCore';
import { useQuery } from '@tanstack/react-query';
import { fetchAttachmentsByFlowId } from '../../../utils/fetchAttachments';

export function BehandleForesporsel(props: TaskProps) {
  const { flow, t } = props;
  const stakeholders = flow?.data?.stakeholders;
  const message = flow?.data?.message;
  const contactNumber = flow?.data?.contactNumber;

  const { flowId } = flow;
  const { data: files } = useQuery({
    queryKey: ['files', flowId],
    queryFn: () => fetchAttachmentsByFlowId(flowId),
  });
  const attachmentLength = files?.attachments.length ?? 0;
  const tabs = ['Kontakt', `Filer (${attachmentLength})`];
  function openInCore() {
    openUserInCore(contactNumber);
  }
  const hasContactNumber = !!contactNumber;
  return (
    <FlowContext.Provider value={props}>
      <Layout>
        <div>
          <Tab.Group>
            <Tab.List className="sticky top-0 z-50 bg-white">
              {tabs.map((tab, index) => (
                <Tab as={Fragment} key={index}>
                  {({ selected }) => (
                    <button
                      className={`whitespace-nowrap border-b-2 border-transparent py-4 px-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700 ${
                        selected ? 'border-b-2 border-basic-ink' : ''
                      }`}
                    >
                      {tab}
                    </button>
                  )}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels className="h-full overflow-auto bg-gray-100 p-4">
              <Tab.Panel className=" space-y-12">
                <div className="h-full bg-gray-100">
                  <MessageCard
                    stakeholder={stakeholders?.[0]}
                    message={message}
                  />
                </div>
              </Tab.Panel>
              <Tab.Panel>
                <Files {...props} />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
        <div>
          <DecisionPanel>
            <div className="flex flex-col gap-3">
              <Stakeholders stakeholders={stakeholders} />
              {hasContactNumber && (
                <div>
                  <SecondaryButton onClick={openInCore}>
                    {t('openInCore')}
                  </SecondaryButton>
                </div>
              )}
            </div>
            <BehandleForesporselDecision {...props} />
          </DecisionPanel>
        </div>
      </Layout>
    </FlowContext.Provider>
  );
}
