import { NumericInput } from '../../../../components/_shared/NumericInput';
import { RightCell } from '../../../../components/_shared/RightCell';
import { formatMoney } from '../../../../utils/formatMoney';

interface ConditionalInputCellProps {
  editable: boolean;
  initialValue: any;
  className?: string;
  labelPlaceholder?: string;
  rowId: string | number;
  columnId: string | number;
  onUpdate: any;
  useMoneyFormat?: boolean;
  thousandSeparator?: string;
  clearOnEmpty?: boolean;
}
export function ConditionalInputCell({
  editable,
  className = '',
  labelPlaceholder = '',
  initialValue,
  rowId,
  columnId,
  onUpdate,
  useMoneyFormat = false,
  thousandSeparator = '',
  clearOnEmpty = false,
}: ConditionalInputCellProps) {
  return editable ? (
    <NumericInput
      className={className}
      initialValue={initialValue}
      rowId={rowId}
      columnId={columnId}
      onUpdate={onUpdate}
      suffix={labelPlaceholder}
      thousandSeparator={thousandSeparator}
      clearOnEmpty={clearOnEmpty}
    />
  ) : (
    <RightCell
      className={`flex h-10 items-center justify-end gap-1 pr-1 ${className}`}
    >
      {columnId === 'value' || columnId === 'marketValue' || useMoneyFormat
        ? formatMoney(initialValue)
        : initialValue}
      <span className="text-misc-placeholder"> {labelPlaceholder}</span>
    </RightCell>
  );
}
