import { format } from 'date-fns';
import { Card } from '../Card';
import { PersonIcon } from '../../icons/PersonIcon';
import { useFlowContext } from '../../../hooks/useFlowContext';
import { ManualDecision } from '../../../types/ManualDecision';

type AssessmentCardProps = {
  assessment: ManualDecision;
  bgColor?: string;
  senderColor?: string;
  timestampColor?: string;
};
export function AssessmentCard({
  assessment,
  bgColor,
  senderColor,
  timestampColor,
}: AssessmentCardProps) {
  const { t } = useFlowContext();

  return (
    <Card>
      <Card.Header
        className={`justify-between text-tertiary-800 ${
          bgColor ? bgColor : 'bg-tertiary-50'
        } `}
      >
        <div
          className={`flex items-center gap-3 ${
            senderColor ? senderColor : 'text-base'
          }`}
        >
          <PersonIcon />
          <Card.HeaderTitle>{assessment?.sender?.name}</Card.HeaderTitle>
        </div>
        <div
          className={`flex items-center gap-1 font-normal text-sm ${
            timestampColor ? timestampColor : 'text-tertiary-800'
          }`}
        >
          {format(Date.parse(assessment?.timestamp), 'dd.MM.yyyy HH:mm')}
        </div>
      </Card.Header>
      <Card.Content verticalPadding={false}>
        <div className="w-full py-7">
          <div className="grid grid-cols-1 gap-2">
            {assessment?.code && (
              <div className={'flex gap-2'}>
                <span className="font-bold">{`${t('outcome')}: `} </span>
                <span>{t(assessment?.code ?? '')}</span>
              </div>
            )}
            <span className="font-bold">
              {`${t(resolveCommentKey(assessment))}: `}{' '}
            </span>
            <p className="whitespace-pre-wrap">{assessment?.comment}</p>
          </div>
        </div>
      </Card.Content>
    </Card>
  );
}

function resolveCommentKey(assessment: ManualDecision) {
  return ['signoff', 'disbursement'].includes(assessment?.decisionType ?? '')
    ? 'creditAssessment'
    : 'message';
}
