export function formatOrgNumber(orgNumber: string) {
  const orgNumberLength = orgNumber?.length;
  if (orgNumberLength === 9) {
    return `${orgNumber.substring(0, 3)} ${orgNumber.substring(
      3,
      6,
    )} ${orgNumber.substring(6, 9)}`;
  }
  return orgNumber;
}
