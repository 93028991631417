import {
  Asset,
  AssetStatus,
} from '../components/_shared/AssetsCard/AssetsCard.types';

export function isSalesObject(asset: Asset): boolean {
  return (
    asset.assetStatus === AssetStatus.SOLD ||
    asset.assetStatus === AssetStatus.TO_BE_SOLD
  );
}
