export function HistoryIcon() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.29175 4.17969V7.29332H10.4054" stroke="currentColor" />
      <path
        d="M2.60365 12.9341L2.22534 9.92578L5.17914 10.7488"
        stroke="currentColor"
      />
      <path
        d="M2.22542 9.92422C2.86849 11.163 3.94316 12.1233 5.24624 12.6235C6.54933 13.1237 7.99051 13.1291 9.29732 12.6387C10.6041 12.1483 11.686 11.1961 12.3383 9.96219C12.9907 8.72824 13.1683 7.29803 12.8377 5.94198C12.507 4.58593 11.691 3.39802 10.5438 2.60285C9.39669 1.80768 7.99797 1.46036 6.61212 1.62656C5.22626 1.79276 3.94933 2.46096 3.02274 3.50482C2.09616 4.54868 1.58414 5.89586 1.5835 7.29164"
        stroke="currentColor"
      />
    </svg>
  );
}
