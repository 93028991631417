import { useState } from 'react';
import {
  Income,
  IncomeType,
} from '../../../../components/_shared/IncomeCard/IncomeCard.types';
import { nanoid } from 'nanoid';

export function useIncomeState(data: any, onUpdateFn?: (data: any) => void) {
  const [incomes, setIncomes] = useState<Income[]>(
    data.households[0]?.situations?.current?.situation?.incomes,
  );

  const stakeholders = data.stakeholders;

  const onAdd = (
    stakeholderId: string,
    incomeType: IncomeType,
    incomeLabel?: string | IncomeType,
  ) => {
    const stakeholder = stakeholders.find(
      (stakeholder: any) => stakeholder.nationalId === stakeholderId,
    );
    if (!stakeholder) return;

    const newIncome: Income = {
      id: nanoid(20),
      ownerMeta: {
        id: stakeholder.nationalId,
        name: stakeholder.fullName,
        context: stakeholder.type,
        workSituation: '',
      },
      workSituation: '',
      workPlace: { name: incomeLabel ?? incomeType },
      incomeStated: 0,
      value: 0,
      type: incomeType,
      incomeSbl: 0,
      owner: stakeholder.nationalId,
    };
    setIncomes([...incomes, newIncome]);
  };

  const onDelete = (incomeData: any) => {
    const updatedIncomes = incomes.filter(
      (income: Income) => income.id !== incomeData.id,
    );
    setIncomes(updatedIncomes);
    if (incomeData.value !== 0) {
      onUpdateFn && onUpdateFn(updatedIncomes);
    }
  };

  const onUpdate = (incomeData: any) => {
    const changedField = incomeData.columnId;
    const updatedIncomes = incomes.map((income: any) => {
      if (income.id === incomeData.rowId) {
        return { ...income, [changedField]: incomeData.value };
      }
      return income;
    });
    setIncomes(updatedIncomes);
    onUpdateFn && onUpdateFn(updatedIncomes);
  };

  return { incomes, onAdd, onDelete, onUpdate };
}
