import type { Stakeholder } from '../types/Stakeholder';
import { Person } from './Person';

export function Stakeholders({
  stakeholders,
}: {
  stakeholders: Stakeholder[];
}) {
  return (
    <div className="grid gap-4">
      {stakeholders?.map((stakeholder) => (
        <Person key={stakeholder.nationalId} person={stakeholder} />
      ))}
    </div>
  );
}
