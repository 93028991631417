import { useFlowContext } from '../../../hooks/useFlowContext';

export default function BannerCard() {
  const { t } = useFlowContext();
  return (
    <div className="col-span-2 bg-negative-50 py-5 px-5 text-negative-600">
      <span>{t('pepHitBannerText')}</span>
    </div>
  );
}
