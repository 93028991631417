export function EmailIcon({
  width = 24,
  height = 24,
}: {
  width?: number;
  height?: number;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.58678 4L12 11.6485L20.4133 4H3.58678ZM22 5.26047L12 14.3515L2 5.26039V19C2 19.5523 2.44771 20 3 20H21C21.5523 20 22 19.5523 22 19V5.26047ZM0 5C0 3.34315 1.34315 2 3 2H21C22.6569 2 24 3.34315 24 5V19C24 20.6569 22.6569 22 21 22H3C1.34315 22 0 20.6569 0 19V5Z"
        fill="currentColor"
      />
    </svg>
  );
}
