import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { TextArea } from '../../../components/_shared/TextArea';
import { DateInput } from '../../../components/_shared/DateInput';
import { useFlowContext } from '../../../hooks/useFlowContext';
import { Loan } from '../../../types/DisbursementTypes';
import { PrimaryProgressButton, SecondaryProgressButton } from '@flow/buttons';
import { TaskProps } from '../../../types/TaskProps';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { DecisionError } from '../../components/DecisionError';
import { RadioGroupBox } from '../../../components/_shared/RadioGroupBox';
import { useDisbursementControlStore } from '../../stores/useDisbursementStore';
import { fetchErrorMessage } from './utils/fetchErrorMessage';
import { fetchAccountNumberValidationError } from './utils/fetchAccountNumberValidationError';
import { calculateTotalCorrectedAmount } from './utils/calculateTotalCorrectedAmount';
import { calculateTotalFeesAmount } from './utils/calculateTotalFeesAmount';
import { fetchDefaultValues } from './utils/fetchDefaultValues';

type ProofOfFinanceDecisionProps = {
  save: TaskProps['save'];
  complete: TaskProps['complete'];
  t: any;
};

export function DecisionProofOfFinance({
  save,
  complete,
  t,
}: ProofOfFinanceDecisionProps) {
  const { task } = useFlowContext();
  const { loans } = useDisbursementControlStore();

  const method = useForm({
    defaultValues: getDefaultValues(task),
    resolver: zodResolver(DecisionProofOfFinance.validationSchema),
  });

  const { handleSubmit, getValues } = method;

  const totalAmount =
    (loans ?? [])?.reduce(
      (total: number, loan: Loan) => total + loan.amount,
      0,
    ) ?? 0;

  const feeAmount = calculateTotalFeesAmount(loans);

  const [errorState, setErrorState] = useState({
    hasError: false,
    errorText: '',
  });
  const { hasError, errorText } = errorState;

  const isBridgingLoan =
    loans.some((loan) => loan.product === 'bridgingLoan') || false;

  function handleSave(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    const data = getValues();
    save({
      loans,
      redemptionDate: data.redemptionDate,
      internalComment: data.internalComment,
      checklistFinancingMain: data.checklistFinancingMain,
      ...(isBridgingLoan
        ? { checklistFinancingBridging: data.checklistFinancingBridging }
        : {}),
    });
  }

  const options = [
    {
      value: true,
      label: t('ok', 'OK'),
    },
    { value: false, label: t('not-applicable', 'Ikke aktuelt') },
  ];

  const onSubmit = (data: any) => {
    const { disbursementAccountNoError, payoutAccountNoError } =
      fetchAccountNumberValidationError(loans);
    const hasAccountError = disbursementAccountNoError || payoutAccountNoError;

    const totalCustomerPayout =
      totalAmount - calculateTotalCorrectedAmount(loans) - feeAmount;
    const hasTotalAmountError = totalCustomerPayout < 0;

    if (hasAccountError || hasTotalAmountError) {
      setErrorState({
        hasError: true,
        errorText: fetchErrorMessage(hasAccountError, hasTotalAmountError, t),
      });
      return;
    }

    setErrorState({
      hasError: false,
      errorText: '',
    });

    complete({
      loans,
      redemptionDate: data.redemptionDate,
      internalComment: data.internalComment,
      checklistFinancingMain: data.checklistFinancingMain,
      ...(isBridgingLoan
        ? { checklistFinancingBridging: data.checklistFinancingBridging }
        : {}),
    });
  };

  return (
    <FormProvider {...method}>
      <form className="grid gap-2 py-8" onSubmit={handleSubmit(onSubmit)}>
        <div className="grid gap-6 p-4">
          {isBridgingLoan && (
            <div className="grid gap-4">
              <h2 className="text-sm font-semibold text-black">
                {t('control-routine-sale', 'Kontrollrutine - salget')}
              </h2>
              <RadioGroupBox
                options={options}
                label={t('isTransportReceived', 'Transport mottatt')}
                name="checklistFinancingBridging.isTransportReceived"
              />
              <RadioGroupBox
                options={options}
                label={t(
                  'isMortgageDocumentReceivedByPaperForSale',
                  'Pantedokument ved salg mottatt fra kunde på papir',
                )}
                name="checklistFinancingBridging.isMortgageDocumentReceivedByPaperForSale"
              />
              <RadioGroupBox
                options={options}
                label={t(
                  'isBrokerConsentReceivedForLandRegistry',
                  'Samtykke til tinglysing mottatt av megler',
                )}
                name="checklistFinancingBridging.isBrokerConsentReceivedForLandRegistry"
              />
              <RadioGroupBox
                options={options}
                label={t(
                  'isMortgageDocumentRegistrationComplete',
                  'Pantedokumentet i salget ferdig tinglyst',
                )}
                name="checklistFinancingBridging.isMortgageDocumentRegistrationComplete"
              />
            </div>
          )}
          <div className="grid gap-4">
            <h2 className="text-sm font-semibold text-black">
              {t('control-routine-purchase', 'Kontrollrutine - kjøpet')}
            </h2>
            <RadioGroupBox
              options={options}
              label={t(
                'isMortgageDocumentReceivedByPaperForPurchase',
                'Pantedokument ved kjøp mottatt fra kunden på papir',
              )}
              name="checklistFinancingMain.isMortgageDocumentReceivedByPaperForPurchase"
            />
            <RadioGroupBox
              options={options}
              label={t(
                'isMortgageDocumentSentByPostToBroker',
                'Pantedokumentet sendt per post til megler',
              )}
              name="checklistFinancingMain.isMortgageDocumentSentByPostToBroker"
            />
          </div>
          <DateInput label={t('redemption-date')} id={'redemptionDate'} />
          <TextArea id="internalComment" label={t('internal-comment')} />
        </div>
        {hasError && <DecisionError text={errorText} />}
        <div className="grid grid-cols-2 gap-4 pt-2">
          <PrimaryProgressButton type="submit">
            {t('complete')}
          </PrimaryProgressButton>
          <SecondaryProgressButton onClick={handleSave} type={'button'}>
            {t('save')}
          </SecondaryProgressButton>
        </div>
      </form>
    </FormProvider>
  );
}

DecisionProofOfFinance.validationSchema = z
  .object({
    isBridgingLoan: z.boolean({
      required_error: 'isBridgingLoan-is-required',
      invalid_type_error: 'isBridgingLoan-is-required',
    }),
    checklistFinancingBridging: z
      .object({
        isTransportReceived: z.boolean().optional(),
        isMortgageDocumentReceivedByPaperForSale: z.boolean().optional(),
        isBrokerConsentReceivedForLandRegistry: z.boolean().optional(),
        isMortgageDocumentRegistrationComplete: z.boolean().optional(),
      })
      .optional(),
    checklistFinancingMain: z
      .object({
        isMortgageDocumentReceivedByPaperForPurchase: z.boolean().optional(),
        isMortgageDocumentSentByPostToBroker: z.boolean().optional(),
      })
      .optional(),
    redemptionDate: z
      .string({
        required_error: 'Innfrielsesdato er påkrevd',
        invalid_type_error: 'Innfrielsesdato er påkrevd',
      })
      .min(1, 'redemption-date-required')
      .refine((date) => {
        const day = new Date(Date.parse(date.toString())).getDay();
        return day !== 0 && day !== 6;
      }, 'redemption-date-invalid'),
    internalComment: z.string().optional(),
  })
  .superRefine((val, ctx) => {
    if (
      val.isBridgingLoan &&
      !!val?.checklistFinancingBridging &&
      val.checklistFinancingBridging.isTransportReceived === undefined
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `isTransportReceived-is-required`,
        path: ['checklistFinancingBridging.isTransportReceived'],
      });
    }
    if (
      val.isBridgingLoan &&
      !!val?.checklistFinancingBridging &&
      val.checklistFinancingBridging
        .isMortgageDocumentReceivedByPaperForSale === undefined
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `isMortgageDocumentReceivedByPaperForSale-is-required`,
        path: [
          'checklistFinancingBridging.isMortgageDocumentReceivedByPaperForSale',
        ],
      });
    }
    if (
      val.isBridgingLoan &&
      val.checklistFinancingBridging &&
      val.checklistFinancingBridging.isBrokerConsentReceivedForLandRegistry ===
        undefined
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `isBrokerConsentReceivedForLandRegistry-is-required`,
        path: [
          'checklistFinancingBridging.isBrokerConsentReceivedForLandRegistry',
        ],
      });
    }
    if (
      val.isBridgingLoan &&
      val.checklistFinancingBridging &&
      val.checklistFinancingBridging.isMortgageDocumentRegistrationComplete ===
        undefined
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `isMortgageDocumentRegistrationComplete-is-required`,
        path: [
          'checklistFinancingBridging.isMortgageDocumentRegistrationComplete',
        ],
      });
    }

    if (
      !!val?.checklistFinancingMain &&
      val.checklistFinancingMain
        .isMortgageDocumentReceivedByPaperForPurchase === undefined
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `isMortgageDocumentReceivedByPaperForPurchase-is-required`,
        path: [
          'checklistFinancingMain.isMortgageDocumentReceivedByPaperForPurchase',
        ],
      });
    }
    if (
      !!val?.checklistFinancingMain &&
      val.checklistFinancingMain.isMortgageDocumentSentByPostToBroker ===
        undefined
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `isMortgageDocumentSentByPostToBroker-is-required`,
        path: ['checklistFinancingMain.isMortgageDocumentSentByPostToBroker'],
      });
    }
  });

function getDefaultValues(task: TaskProps['task']): any {
  const isBridgingLoan = !!task.context.loans.find(
    (loan: Loan) => loan.product === 'bridgingLoan',
  );

  const {
    internalComment,
    redemptionDate,
    checklistFinancingBridging,
    checklistFinancingMain,
  } = fetchDefaultValues(task);

  return {
    internalComment,
    redemptionDate,
    isBridgingLoan: isBridgingLoan,
    checklistFinancingBridging: isBridgingLoan
      ? checklistFinancingBridging
      : undefined,
    checklistFinancingMain,
  };
}
