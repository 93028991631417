import { TaskProps } from '../../../../../types/TaskProps';
import React from 'react';
import { EmptyState } from './EmptyState';
import { LazyAbilityToPay } from '../../../../../components/Insight/AbilityToPay/LazyAbilityToPay';
import { SuspenseWrapper } from '../../../SuspenseWrapper';

export default function AbilityToPayTab(props: TaskProps) {
  const { flow, t } = props;
  const { data } = flow;

  const hasSituation =
    !!data?.households?.[0]?.situations?.current?.betjeningsevne;

  if (!hasSituation) {
    return <EmptyState t={t} tab={t('abilityToPay', 'Betjeningsevne')} />;
  }

  return (
    <SuspenseWrapper>
      <LazyAbilityToPay {...props} className="px-12" />
    </SuspenseWrapper>
  );
}
