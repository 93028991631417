export function DurationDisplay({
  durationMonths,
  termsPerYear = 12,
}: {
  durationMonths: number;
  termsPerYear?: number;
}) {
  const remainder = durationMonths % termsPerYear;
  const years = Math.floor(durationMonths / termsPerYear);

  const Block = ({ label, value }: { label: string; value: number }) => (
    <span className="whitespace-nowrap">
      {value} <span className="text-misc-placeholder">{label}</span>
    </span>
  );
  return (
    <span className="flex items-center whitespace-nowrap justify-end gap-1">
      {years > 0 && <Block label="år" value={years} />}
      {remainder > 0 && <Block label="mnd" value={remainder} />}
    </span>
  );
}
