import { twMerge } from 'tailwind-merge';

interface TextAreaProps {
  id: string;
  name: string;
  label: string;
  value: string;
  placeholder?: string;
  disabled?: boolean;
  onChange?: (e: any) => void;
  styles?: string;
}

export function TextAreaSimple(props: TextAreaProps) {
  const { id, placeholder, disabled, value, onChange, styles, label } = props;
  return (
    <div className="flex flex-col gap-2">
      <label className="text-sm font-semibold text-black" htmlFor={id}>
        {label}
      </label>
      <textarea
        disabled={disabled}
        id={id}
        name={id}
        placeholder={placeholder ?? ''}
        className={twMerge(
          'h-80 rounded border-2 border-solid border-gray-200 p-2',
          styles ?? '',
        )}
        value={value}
        onChange={onChange}
      />
    </div>
  );
}
