import { downloadAttachment } from '../../../utils/attachments';
import { useQuery } from '@tanstack/react-query';
import { LoadingSpinner } from '../../icons/LoadingSpinner';

type FilePreviewProps = {
  attachmentKey: string;
  mimeType: string;
  flowId: string;
};
export function DocumentationFilePreview({
  attachmentKey,
  mimeType,
  flowId,
}: FilePreviewProps) {
  const { data: file, isFetching } = useQuery({
    queryKey: ['file', attachmentKey],
    queryFn: () => downloadAttachment(flowId, attachmentKey, mimeType),
  });

  if (isFetching)
    return (
      <div>
        <LoadingSpinner width={24} height={24} className="my-5 w-full" />
      </div>
    );
  const isImage = mimeType.includes('image');
  return (
    <div className={'grid place-items-center'}>
      {isImage ? (
        <img src={file} alt="previewed document" />
      ) : (
        <iframe title="file" src={file} className="h-screen w-full" />
      )}
    </div>
  );
}
