export function ApplicantIcon() {
  return (
    <svg width="20" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13 15H7a6 6 0 0 0-6 6v1s3.125 1 9 1 9-1 9-1v-1a6 6 0 0 0-6-6ZM5 6a5 5 0 0 1 10 0c0 2.761-2.239 6-5 6S5 8.761 5 6Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
}
