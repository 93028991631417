import React from 'react';
interface Props {
  children?: React.ReactNode;
  className?: string;
}
function Header({ children }: Props) {
  return <thead className={'h-10'}>{children}</thead>;
}
function Content({ children }: Props) {
  return <tbody>{children}</tbody>;
}
export function DisbursementControlTable({ children }: Props) {
  return <table className={'g-0 w-full'}>{children}</table>;
}

DisbursementControlTable.Header = Header;
DisbursementControlTable.Content = Content;
