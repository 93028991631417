import { useFlowContext } from '../../../hooks/useFlowContext';

export function HeaderLabel({ labelKey }: { labelKey: string }) {
  const { t } = useFlowContext();

  return (
    <div className="flex justify-between pb-1 font-semibold">
      <label>{t(labelKey)}</label>
    </div>
  );
}
