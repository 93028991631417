import { RadioSelect } from '../../components/common/RadioSelect';
import { Input } from '../../components/common/Input';

export function PropertyInput() {
  const options = ['cadastre', 'share'];

  return (
    <div className="grid grid-cols-1">
      <Input
        id="propertyInfo.key"
        label="propertyKey"
        placeholder="Skriv inn..."
      />
      <Input
        id="propertyInfo.customText"
        label="customText"
        placeholder="Skriv inn..."
      />
      <RadioSelect id="propertyInfo.type" className="" options={options} />
    </div>
  );
}
