import { TaskProps } from '../../../../../types/TaskProps';
import React from 'react';
import { EmptyState } from './EmptyState';
import { LazyAbilityToPay } from '../../../../../components/Insight/AbilityToPay/LazyAbilityToPay';
import { SuspenseWrapper } from '../../../SuspenseWrapper';

export default function AbilityToPayTabOriginal(props: TaskProps) {
  const { flow, t } = props;
  const { data } = flow;

  const hasSituation =
    !!data?.households?.[0]?.situations?.original?.betjeningsevne;

  const originalSituation = data?.households?.[0]?.situations?.original;

  if (!hasSituation) {
    return <EmptyState t={t} tab={t('abilityToPay', 'Betjeningsevne')} />;
  }

  return (
    <SuspenseWrapper>
      <LazyAbilityToPay
        {...props}
        householdSituation={originalSituation}
        className="px-12"
      />
    </SuspenseWrapper>
  );
}
