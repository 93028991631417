import { WalletIcon } from '../../../../components/icons/WalletIcon';
import { Card } from '../../../../components/_shared/Card';
import { List } from '../../../../components/_shared/List';
import { useFlowContext } from '../../../../hooks/useFlowContext';
import { formatMoney } from '../../../../utils/formatMoney';

interface ScoreItem {
  label: string;
  value: string | number;
  decisionCode?: string;
}

interface Props {
  economyItems: ScoreItem[];
}

export function EconomyCard({ economyItems }: Props) {
  const { t } = useFlowContext();

  const mappedItems = economyItems?.map((item, index) => ({
    label: item.label,
    value:
      item.label === 'disposableLiquidity'
        ? formatMoney(item.value)
        : Number(item.value ?? 0).toFixed(2),
    decisionCode: item.decisionCode,
  }));

  return (
    <Card>
      <Card.Header className="bg-note-100 text-note-900">
        <div className="flex gap-3">
          <WalletIcon />
          <Card.HeaderTitle>{t('economy')}</Card.HeaderTitle>
        </div>
      </Card.Header>
      <Card.Content>
        <List items={mappedItems} />
      </Card.Content>
    </Card>
  );
}
