import type { ListItemType } from '../../../../components/_shared/ListItem';
import { useFlowContext } from '../../../../hooks/useFlowContext';
import { Stakeholder } from '../../../../types/Stakeholder';
import { formatMoney } from '../../../../utils/formatMoney';
import WarningCard from './WarningCard';

export default function PaymentsOver100K({
  stakeholder,
}: {
  stakeholder: Stakeholder;
}) {
  const { t } = useFlowContext();
  const title = t('decision-kyc-expecting-payments-from-abroad');
  const declaration = stakeholder?.declaration?.paymentsDeclaration;
  const shouldRender = declaration?.isExpectingPaymentsAbove100k;

  if (!shouldRender) return null;

  const country = declaration?.country?.name as string;
  const reason = declaration?.foreignPayments;
  const amount = declaration?.yearlyAmount;
  const formatAmount = (val: string | number) => {
    if (val.toString().includes('>')) {
      return val?.toString().replace('>', 'Mer enn ');
    }
    return val;
  };

  const items: ListItemType[] = [
    {
      label: t('whichCountryAreYouExpectingPaymentsFrom'),
      value: country,
    },
    {
      label: t('whyAreYouExpectingPaymentsAbove100k'),
      value: reason,
    },
    {
      label: t('howMuchDoYouExpectToReceive'),
      value: amount
        ? isNaN(amount)
          ? formatAmount(amount)
          : formatMoney(amount)
        : '-',
    },
  ];

  return <WarningCard title={title} items={items} />;
}
