import { useMemo, useState } from 'react';
import { useFlowContext } from '../../../hooks/useFlowContext';
import { convertToBlob } from '../../../utils/convertToBlob';
import { CrossIcon } from '../../../components/icons/CrossIcon';

export default function File({
  file,
  deleteFile,
}: {
  file: File;
  deleteFile: () => void;
}) {
  const { t } = useFlowContext();

  const showIframe = file.type.includes('pdf');
  const blob = useMemo(() => convertToBlob(file), [file]);
  const [imgError, setImgError] = useState(false);

  const handleError = () => {
    setImgError(true);
  };

  return (
    <>
      {showIframe && (
        <iframe className="h-screen w-full" title="file" src={blob} />
      )}
      {!showIframe && (
        <div className="grid h-full w-full items-center justify-center object-fill">
          {!imgError ? (
            <img
              src={blob}
              alt="file"
              className="w-full"
              onError={handleError}
            />
          ) : (
            <div className="grid gap-6 justify-center items-center">
              <div className="text-red-500">
                {t('could-not-preview-file', 'Kunne ikke forhåndsvise fil')}
              </div>
              <div className="flex justify-center">
                <a
                  href={blob}
                  download={file?.name}
                  className="bg-blue-500 text-white px-4 py-2 rounded text-center"
                >
                  {t('download', 'Last ned')}
                </a>
              </div>
            </div>
          )}
        </div>
      )}
      <div>
        <button
          onClick={deleteFile}
          className="absolute bottom-5 left-5 bg-white border-sea-20 border px-2 py-1 rounded-md text-sea-20 flex gap-2 align-middle"
        >
          <div className="my-auto">
            <CrossIcon height={16} width={16} />
          </div>
          {t('delete-file', 'Slett dokument')}
        </button>
      </div>
    </>
  );
}
