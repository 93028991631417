import { TaskProps, Translation } from '../../../../../types/TaskProps';
import { ListItemType } from '../../../../../components/_shared/ListItem';

type Props = {
  data: TaskProps['flow']['data'];
  t: Translation;
};

export function formatDueDateSummary({ data, t }: Props) {
  const summaryData = {
    coreAccount: data?.accountNumber,
    newDueDate: `${data?.newDueDate}. ${t('nthMonth')}`,
    previousDueDate: `${new Date(data?.prevDueDate).getDate()}. ${t(
      'nthMonth',
    )}`,
  };

  return Object.entries(summaryData).map(
    ([label, value]): ListItemType => ({
      label,
      value,
    }),
  );
}
