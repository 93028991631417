import { EmailCard } from '../EmailCard/EmailCard';
import { useFlowContext } from '../../../hooks/useFlowContext';
import { Disbursement } from '../../../types/DisbursementTypes';
import { toPlainText } from '@portabletext/react';
import { fetchNextTwoWorkDays } from '../../../utils/fetchNextTwoWorkDays';
import { isCadastre, Property, Security } from '../../../types/LoanOffer';

interface EmailDraft {
  title: string;
  email: string;
}

function splitEveryTwo(str: string) {
  const result = [];
  while (str?.length > 0) {
    result?.push(str?.substring(0, 2));
    str = str?.substring(2);
  }
  return result?.join('.');
}

export function EmailView() {
  const { flow, task } = useFlowContext();
  const flowData = flow?.data;
  const loan = flowData?.loanOffer?.loans?.[0];
  const disbursements: Disbursement[] = loan?.disbursements;
  const emailDrafts = task?.context?.emailDrafts;
  const nextWorkDays = fetchNextTwoWorkDays().join(' og ');

  function housingSituation(property: any): string {
    const cadastre = property?.key?.replaceAll('-', '/');
    const municipality = property?.municipalityName?.toLowerCase();
    const municipalityName = municipality
      ? municipality?.charAt(0)?.toUpperCase() + municipality?.slice(1)
      : '';
    const housingCooperativeName = property?.housingCooperativeName;
    const shareNumber = property?.shareNumber;

    return isCadastre(property)
      ? `${cadastre} i ${municipalityName} kommune.`
      : `Andel ${shareNumber} i ${housingCooperativeName}.`;
  }

  function mapLenders(owners: any): string {
    return owners
      ?.map((stakeholder: any) => {
        return `${stakeholder?.name}, født: ${splitEveryTwo(
          stakeholder?.nationalId?.substring(0, 6),
        )}`;
      })
      ?.join('\n');
  }

  const properties = flowData?.loanOffer?.securities.flatMap(
    (security: Security) => security.properties,
  );

  const uniqueProperties = properties?.filter(
    (property: Property, index: number, acc: Property[]) =>
      acc.findIndex((p: Property) => p.id === property.id) === index,
  );

  const emails: EmailDraft[] = uniqueProperties?.map((property: Property) => {
    const data: any = {
      lenders: mapLenders(property.owners),
      nextWorkDays,
      address: property.address,
      housingSituation: housingSituation(property),
    };
    return {
      title: `Epost-utkast ${disbursements[0]?.nameOfBank.toUpperCase()}`,
      email: toPlainText(emailDrafts?.[0]?.body ?? '')?.replace(
        /#\{([^}]+)\}/g,
        (match, key: any) => {
          return data[key];
        },
      ),
    };
  });

  return (
    <div className={'grid grid-cols-2 gap-6'}>
      {emails?.map(
        (draft, index: number) =>
          draft?.email && (
            <EmailCard data={draft} key={`email-card-nr-${index}`} />
          ),
      )}
    </div>
  );
}
