import { formatNationalId } from '../utils/formatNationalId';
import { isEven } from '../utils/isEven';
import CopyToClipBoard from './_shared/CopyToClipBoard';
import { useState } from 'react';

export function Person({
  person,
  styling = 'bg-primary-50 p-4',
  showType = true,
}: {
  person: any;
  styling?: string;
  showType?: boolean;
}) {
  const personDataMap = [
    'fullName' in person && {
      key: 'fullName',
      value: person.fullName,
    },
    'phoneNumber' in person && {
      key: 'phoneNumber',
      value: person.phoneNumber,
    },
    'nationalId' in person && {
      key: 'nationalId',
      value: person.nationalId,
    },
    'email' in person && {
      key: 'email',
      value: person.email,
    },
  ].filter(function (elem) {
    return elem && elem?.value !== '';
  });

  const [copiedId, setCopiedId] = useState('');

  function handleCopy(text: string, id: string) {
    navigator.clipboard.writeText(text).then(() => {
      setCopiedId(id);
      const timer = setTimeout(() => {
        setCopiedId('');
      }, 2000);
      return () => clearTimeout(timer);
    });
  }

  return (
    <div className={'flex w-full'}>
      {showType && (
        <div className="grid items-center rounded-bl-md rounded-tl-md bg-blue-100 px-2 text-xs font-semibold text-blue-500">
          {person?.type === 'coApplicant' ? 'MLT' : 'HLT'}
        </div>
      )}
      <div className={`grid w-full grid-cols-2 gap-1 ${styling}`}>
        {personDataMap?.map((data: any, index) =>
          data.key === 'phoneNumber' ? (
            <a
              key={`phone-nr-link-${index}`}
              className={`flex items-center text-blue-400 ${
                isEven(index)
                  ? 'justify-start text-left'
                  : 'justify-end text-right'
              }`}
              href={`tel:${data.value}`}
            >
              {data?.value}
            </a>
          ) : data?.key === 'email' ? (
            <div key={`mail-link-${index}`}>
              <a
                className={`block items-center overflow-hidden overflow-ellipsis whitespace-nowrap text-blue-400 ${
                  isEven(index)
                    ? 'justify-start text-left'
                    : 'justify-end text-right'
                }`}
                href={`mailto:${data?.value}`}
              >
                {data?.value}
              </a>
            </div>
          ) : (
            <div
              key={`${data.value}-display-${index}`}
              className={`flex items-center break-words font-bold text-gray-700  ${
                isEven(index)
                  ? 'justify-start text-left'
                  : 'justify-end text-right'
              }`}
            >
              <span>
                {data.key === 'nationalId' && !!data.value ? (
                  <span className="flex gap-2 items-center">
                    {formatNationalId(data.value)}
                    <CopyToClipBoard
                      value={data.value}
                      id={`${data.key}-${person.type}`}
                      copiedId={copiedId}
                      handleCopy={handleCopy}
                    />
                  </span>
                ) : (
                  data.value
                )}
              </span>
            </div>
          ),
        )}
      </div>
    </div>
  );
}
