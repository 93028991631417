import { getGrunnboksutskriftPdf } from '../../../customer/tasks/ManuellBehandling/utils/attachmentsHelper';
import { PdfIcon } from '../../icons/PdfIcon';

export function GrunnbokUtskrift({
  flowId,
  id,
}: {
  flowId: string;
  id: string;
}) {
  return (
    <button
      onClick={() => getGrunnboksutskriftPdf(flowId, id)}
      className="hover:pointer flex gap-2 text-sea-30 hover:underline whitespace-nowrap overflow-ellipsis"
    >
      {id}
      <PdfIcon />
    </button>
  );
}
