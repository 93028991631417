import { BusinessIcon } from '../../icons/BusinessIcon';
import { Card } from '../Card';
import type { Stakeholder } from '../../../types/Stakeholder';
import * as Collapsible from '@radix-ui/react-collapsible';
import { Chevron } from '../../icons/Chevron';
import { useFlowContext } from '../../../hooks/useFlowContext';

const tableHeaders = [
  'orgId',
  'name',
  'type',
  'attachment',
  'share',
  'positionHeader',
  'status',
];

export default function CommercialInterests({
  stakeholder,
}: {
  stakeholder: Stakeholder;
}) {
  const { t } = useFlowContext();
  const numberOfInterests =
    stakeholder?.creditData?.NaringsInteresser?.length || 0;
  return (
    <Collapsible.Root>
      <Card>
        <Collapsible.CollapsibleTrigger className="group w-full">
          <Card.Header className="flex justify-between bg-tertiary-50 text-tertiary-800">
            <div className="flex gap-3">
              <BusinessIcon size={24} />
              {t('næringsInteresser')}
            </div>
            <div className="flex gap-3">
              <span>{numberOfInterests}</span>
              <div className="my-auto">
                <Chevron className="transform group-radix-state-open:rotate-180" />
              </div>
            </div>
          </Card.Header>
        </Collapsible.CollapsibleTrigger>
        <Collapsible.Content>
          <Card.Content horizontalPadding={false}>
            {numberOfInterests === 0 ? (
              <EmptyState />
            ) : (
              <table className="w-full text-basic-ink">
                <thead>
                  <tr>
                    {tableHeaders?.map((header) => (
                      <th
                        key={header}
                        className="pb-2 text-left text-xs first:pl-5 last:pr-5"
                      >
                        {t(header)?.toUpperCase()}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {stakeholder?.creditData?.NaringsInteresser?.map(
                    (interest, index) => (
                      <tr
                        key={`${interest.Orgnr}-${index}`}
                        className="border-t border-misc-ui-separator"
                      >
                        <td className="py-2 pl-5">{interest?.Orgnr}</td>
                        <td>{interest?.Navn}</td>
                        <td>{interest?.SelskForm}</td>
                        <td>{interest?.Rolle}</td>
                        <td>{interest?.Eierandel}%</td>
                        <td>{interest?.VervTekst}</td>
                        <td>{interest?.StatusTekst}</td>
                      </tr>
                    ),
                  )}
                </tbody>
              </table>
            )}
          </Card.Content>
        </Collapsible.Content>
      </Card>
    </Collapsible.Root>
  );
}

function EmptyState() {
  const { t } = useFlowContext();
  return (
    <div className="flex flex-col gap-5 py-10 text-center font-bold text-black">
      <div className="flex justify-center">
        <BusinessIcon />
      </div>
      <span>{t('noBusinessInterests')}</span>
    </div>
  );
}
