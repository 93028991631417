import { create } from 'zustand';

type Product = {
  rateCode: string;
  rateValue: number;
  fromRatio: number;
};

export type LtvProduct = {
  calculatedLtv: number;
  product: Product;
};
interface ProductState {
  availableProducts: Product[];
  ltvProduct: LtvProduct;
  ltvProductShortTerm?: LtvProduct;
  setAvailableProducts: (products: Product[]) => void;
  setLtvProduct: (ltvProduct: LtvProduct) => void;
  setLtvProductShortTerm: (ltvProduct: LtvProduct) => void;
}

export const useProductStore = create<ProductState>((set) => ({
  availableProducts: [],
  ltvProduct: {
    calculatedLtv: 0,
    product: {
      rateCode: '',
      rateValue: 0,
      fromRatio: 0,
    },
  },
  ltvProductShortTerm: undefined,
  setLtvProduct: (ltvProduct: LtvProduct) => set({ ltvProduct }),
  setAvailableProducts: (products: Product[]) =>
    set({ availableProducts: products }),
  setLtvProductShortTerm: (ltvProduct: LtvProduct) =>
    set({ ltvProductShortTerm: ltvProduct }),
}));

export const initProductStore = (
  ltvProduct: LtvProduct,
  products: Product[],
  ltvProductShortTerm?: LtvProduct,
) => {
  const store = useProductStore.getState();
  store.setAvailableProducts(products);
  store.setLtvProduct(ltvProduct);
  if (ltvProductShortTerm) {
    store.setLtvProductShortTerm(ltvProductShortTerm);
  }
};
