import { Mortgagor } from '../AssetsCard/AssetsCard.types';
import { Fragment } from 'react';

export function Mortgagors({ mortgagors }: { mortgagors: Mortgagor[] }) {
  return (
    <div>
      {(mortgagors || []).map((mortgagor, index) => {
        return (
          <Fragment key={index}>
            {mortgagor?.name}
            {index < mortgagors.length - 1 ? ', ' : ''}
          </Fragment>
        );
      })}
    </div>
  );
}
