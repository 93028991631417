import { SvgIconProps } from './SvgIcon.types';

export function ChevronRight({ size = 18, color = '#145BFF' }: SvgIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="m10.164 16.75-1.414-1.5 3.5-3.5-3.5-3.5 1.414-1.5 5.086 5-5.086 5Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
