import { TaskProps } from '../../../types/TaskProps';
import { FormProvider, useForm } from 'react-hook-form';
import { TextArea } from '../../../components/_shared/TextArea';
import { RadioButtonList } from '../../../components/_shared/RadioButtonList';
import { useFlowContext } from '../../../hooks/useFlowContext';
import { DecisionButtonGroup } from '../../components/DecisionButtonGroup';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { DateInput } from '../../../components/_shared/DateInput';
import React, { useEffect } from 'react';
import { SameUserError } from '../../../components/_shared/SameUserError/SameUserError';

export function Decision({ save, complete }: TaskProps) {
  const {
    t,
    user,
    task: { context, permissions },
  } = useFlowContext();
  const method = useForm({
    resolver: zodResolver(Decision.validationSchema),
    defaultValues: {
      decision: '',
      assessment: '',
      redemptionDate: '',
    },
  });

  const { handleSubmit, watch, setValue } = method;

  useEffect(() => {
    if (context?.redemptionDate) {
      setValue('redemptionDate', context.redemptionDate);
    }
  }, [context, setValue]);

  const decision = watch('decision');

  const decisionOptions = [
    { value: 'approve', label: t('approvePayout') },
    { value: 'reject', label: t('sendBackToCaseWorker') },
  ];

  const onSubmit = (data: any) => {
    complete(data);
  };

  const isSameUser =
    user?.profile?.username === context?.sentToTaskBy?.username;

  if (isSameUser && !permissions.complete) {
    return (
      <FormProvider {...method}>
        <form className="grid gap-2 py-8">
          <div className="grid gap-2">
            <h2 className="text-sm font-semibold text-black">
              {t('priority-status')}
            </h2>
            <p className="text-sm font-medium text-gray-700">
              {context.priorityStatus === 'CORRECT-PRIORITY'
                ? t('correct-priority-option')
                : t('other-bank-pant-option')}
            </p>
          </div>
          <div className="grid gap-6">
            <DateInput
              label={t('redemption-date')}
              id={'redemptionDate'}
              disabled
            />
          </div>
        </form>
        <SameUserError />
      </FormProvider>
    );
  }

  return (
    <FormProvider {...method}>
      <form className="grid gap-2 py-8" onSubmit={handleSubmit(onSubmit)}>
        <div className="grid gap-6">
          <div className="grid gap-2">
            <h2 className="text-sm font-semibold text-black">
              {t('priority-status')}
            </h2>
            <p className="text-sm font-medium text-gray-700">
              {context.priorityStatus === 'CORRECT-PRIORITY'
                ? t('correct-priority-option')
                : t('other-bank-pant-option')}
            </p>
          </div>
          <DateInput
            label={t('redemption-date')}
            id={'redemptionDate'}
            disabled
          />
          <h2 className="text-sm font-semibold text-black">
            {t('evaluateSetting')}
          </h2>
          <RadioButtonList id="decision" options={decisionOptions} />
          <TextArea
            id="assessment"
            label={
              decision === 'reject'
                ? t('messageToCaseWorker')
                : t('internalComment')
            }
          />
          <DecisionButtonGroup save={save} />
        </div>
      </form>
    </FormProvider>
  );
}

Decision.validationSchema = z
  .object({
    decision: z.string(),
    assessment: z.string().optional(),
    redemptionDate: z.string({
      required_error: 'Innfrielsesdato er påkrevd',
      invalid_type_error: 'Innfrielsesdato er feil type',
    }),
  })
  .superRefine((val, context) => {
    if (
      val.decision === 'reject' &&
      (!val?.assessment || val?.assessment === '')
    ) {
      context.addIssue({
        code: 'custom',
        message: 'Melding er påkrevd',
        path: ['assessment'],
      });
    }
  });
