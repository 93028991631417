import { TaskProps } from '../../../types/TaskProps';
import { FormProvider, useForm } from 'react-hook-form';
import { TextArea } from '../../../components/_shared/TextArea';
import { RadioButtonList } from '../../../components/_shared/RadioButtonList';
import { DecisionButtonGroup } from '../../components/DecisionButtonGroup';
import { EmailPreview } from './EmailPreview';
import { useFlowContext } from '../../../hooks/useFlowContext';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { DecisionError } from '../../components/DecisionError';
import { AdditionalValuesView } from '../../components/common/AdditionalValuesView';

const decisionOptions = [
  { value: 'approve', label: 'Godkjenn' },
  { value: 'reject', label: 'Avslå' },
];

export function Decision({ save, complete }: TaskProps) {
  const { task, t } = useFlowContext();
  const method = useForm({
    defaultValues: getDefaultValues(task),
    resolver: zodResolver(Decision.validationSchema),
  });
  const {
    handleSubmit,
    formState: { errors },
  } = method;

  const onSubmit = (data: any) => {
    complete({ isApproved: data.decision === 'approve', ...data });
  };

  return (
    <FormProvider {...method}>
      <form className="grid gap-2 py-8" onSubmit={handleSubmit(onSubmit)}>
        <AdditionalValuesView />
        <h2 className="text-sm font-semibold text-black">{t('decision')}</h2>
        <div className="grid gap-6">
          <RadioButtonList id="decision" options={decisionOptions} />
          {method.watch('decision') === 'reject' && (
            <>
              <TextArea
                id="reason"
                label="Beskjed som sendes til kunde per e-post"
                placeholder="Eventuell begrunnelse"
              />
              <EmailPreview reason={method.watch('reason')} />
            </>
          )}
          <TextArea id="internalComment" label="internalComment" />
          <DecisionButtonGroup save={save} />
        </div>
        {Object.keys(errors).map((index) => {
          return (
            <DecisionError
              key={index}
              text={errors[index]?.message?.toString() ?? ''}
            />
          );
        })}
      </form>
    </FormProvider>
  );
}

function getDefaultValues(task: TaskProps['task']): any {
  return {
    decision: task.data?.decision,
    reason: task.data?.reason,
    internalComment: task.data.internalComment ?? '',
  };
}

Decision.validationSchema = z.object({
  decision: z.string({
    required_error: 'decisionRequired',
    invalid_type_error: 'decisionRequired',
  }),
  reason: z.string().optional(),
  internalComment: z.string().optional(),
});
