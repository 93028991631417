import WarningIcon from '../../icons/WarningIcon';
import { Card } from '../Card';
import type { Stakeholder } from '../../../types/Stakeholder';
import * as Collapsible from '@radix-ui/react-collapsible';
import { Chevron } from '../../icons/Chevron';
import { useFlowContext } from '../../../hooks/useFlowContext';
import { ApprovedCircleIcon } from '../../icons/ApprovedCircleIcon';
import { formatMoney } from '../../../utils/formatMoney';
import { format } from 'date-fns';
import { BetaDetaljer } from '../../../types/CreditCheck';

export default function PaymentNotes({
  stakeholder,
}: {
  stakeholder: Stakeholder;
}) {
  const { t } = useFlowContext();
  const creditData = stakeholder?.creditData;
  const numberOfPaymentNotes = creditData?.BetaDetaljer?.length || 0;
  const hasPaymentNotes = numberOfPaymentNotes > 0;

  return (
    <Collapsible.Root defaultOpen={hasPaymentNotes}>
      <Card>
        <Collapsible.CollapsibleTrigger className="w-full bg-caution-100 text-note-900">
          <Card.Header className="flex justify-between">
            <div className="flex gap-3 ">
              <WarningIcon size={24} color="#474300" />
              {t('paymentNotes')}
            </div>
            <div className="flex gap-3">
              <span>{numberOfPaymentNotes}</span>
              <div className="my-auto">
                <Chevron />
              </div>
            </div>
          </Card.Header>
        </Collapsible.CollapsibleTrigger>
        <Collapsible.Content>
          <Card.Content horizontalPadding={false}>
            {hasPaymentNotes ? (
              <Remarks remarks={creditData?.BetaDetaljer!} />
            ) : (
              <EmptyState />
            )}
          </Card.Content>
        </Collapsible.Content>
      </Card>
    </Collapsible.Root>
  );
}

function EmptyState() {
  const { t } = useFlowContext();
  return (
    <div className="flex flex-col gap-5 py-10 text-center font-bold text-black">
      <div className="flex justify-center">
        <ApprovedCircleIcon />
      </div>
      <span>{t('noPaymentNotesDescription')}</span>
    </div>
  );
}

function Remarks({ remarks }: { remarks: BetaDetaljer[] }) {
  const { t } = useFlowContext();
  return (
    <div className=" flex flex-col gap-5 text-basic-ink">
      <table className="my-5 w-full">
        <thead>
          <tr>
            <th className="pb-2 pl-5 text-left text-xs">{t('type')}</th>
            <th className="pb-2 text-left text-xs">{t('source')}</th>
            <th className="pb-2 text-left text-xs">{t('reference')}</th>
            <th className="pb-2 text-left text-xs">{t('creditor')}</th>
            <th className="pb-2 text-right text-xs">{t('date')}</th>
            <th className="pb-2 pr-5 text-right text-xs">{t('amount')}</th>
          </tr>
        </thead>
        <tbody>
          {remarks?.map((remark: any, index: number) => {
            return (
              <tr key={index} className="border-t border-misc-ui-separator">
                <td className="py-2 pl-5">{remark?.BetaTekst ?? ''}</td>
                <td>{remark?.KildeTekst || remark?.KildeKode}</td>
                <td>{remark?.KildeReferansenr ?? ''}</td>
                <td>{remark?.Kreditor ?? ''}</td>
                <td className="text-right">
                  {remark.RegistrertDato
                    ? format(Date.parse(remark.RegistrertDato), 'dd.MM.yyyy')
                    : ''}
                </td>
                <td className="pr-5 text-right">
                  {formatMoney(remark?.BetaBelop)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
