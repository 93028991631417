import { ListItem } from '../../../components/_shared/ListItem';
import { useFlowContext } from '../../../hooks/useFlowContext';

type AdditionalValuesViewProps = {
  values?: [{ label: string; value: string | number }];
};
export function AdditionalValuesView({ values }: AdditionalValuesViewProps) {
  const { flow } = useFlowContext();

  const mappedValues = [
    ...(values || []),
    { label: 'referenceId', value: flow.referenceId },
  ];

  return (
    <ul className="grid text-xs grid-cols-1">
      {mappedValues?.map(
        (item: { label: string; value: string | number }, index: number) => (
          <ListItem
            key={index}
            item={item}
            styling="first:border-t-0 divide-gray-200 border-t first:text-sm"
          />
        ),
      )}
    </ul>
  );
}
