import type { ListItemType } from '../../../../components/_shared/ListItem';
import { useFlowContext } from '../../../../hooks/useFlowContext';
import type { Stakeholder } from '../../../../types/Stakeholder';
import WarningCard from './WarningCard';

export default function Pep({ stakeholder }: { stakeholder: Stakeholder }) {
  const { t } = useFlowContext();
  const title = t('higherPosition');
  const declaration = stakeholder?.declaration?.pepDeclaration;
  const shouldRender = declaration?.isPep;

  if (!shouldRender) return null;

  const nameAndOrg = `${declaration?.name}, ${declaration?.internationalOrganizations}`;

  const familyRelation = declaration?.familyRelation;
  const coWorkerRelation = declaration?.coworkerRelation;

  const items: ListItemType[] = [
    {
      label: t('who'),
      value: declaration?.relationType ? t(declaration?.relationType) : '-',
    },
    ...(!!declaration?.name
      ? [
          {
            label: t('nameAndTitle'),
            value: nameAndOrg,
          },
        ]
      : []),
    {
      label: t('position'),
      value: declaration?.workTitle,
    },
    ...(!!declaration?.birthDate
      ? [
          {
            label: t('birthDate'),
            value: new Date(declaration?.birthDate).toLocaleDateString(),
          },
        ]
      : []),
    ...(!!familyRelation
      ? [
          {
            label: t('yourRelation'),
            value: t('family'),
          },
        ]
      : []),
    ...(!!coWorkerRelation
      ? [
          {
            label: t('yourRelation'),
            value: t('coworker'),
          },
        ]
      : []),
    {
      label: t('countryOrOrganization'),
      value: declaration?.internationalOrganizations,
    },
    {
      label: t('dateFrom'),
      value: declaration?.dateRange
        ? new Date(declaration?.dateRange).toLocaleDateString()
        : '-',
    },
    {
      label: t('status'),
      value: declaration.timePeriod ? t(declaration.timePeriod) : '-',
    },
  ];

  return <WarningCard title={title} items={items} />;
}
