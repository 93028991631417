import { useQuery } from '@tanstack/react-query';
import { CenteredSpinner } from '@flow/icons';
import { FlowContext } from '../../../hooks/useFlowContext';
import { TaskProps } from '../../../types/TaskProps';
import {
  fetchAttachmentByKey,
  fetchAttachmentsByFlowId,
} from '../../../utils/fetchAttachments';
import { DocumentsCard } from './DocumentsCard';
import axios from 'axios';
import { useState } from 'react';

export function Files(props: TaskProps) {
  const { flow, t } = props;
  const { flowId } = flow;
  const [errorText, errorTextSet] = useState('');

  const { data: files, isFetching } = useQuery({
    queryKey: ['files', flowId],
    queryFn: async () => {
      try {
        errorTextSet('');
        const { attachments } = await fetchAttachmentsByFlowId(flowId);
        const attachmentsWithVersions = await Promise.all(
          attachments.map(async (attachment: { attachmentKey: string }) => {
            let data;
            try {
              data = await fetchAttachmentByKey(
                flowId,
                attachment.attachmentKey,
              );
            } catch (err) {
              if (axios.isAxiosError(err)) {
                data = null;
              }
            }

            const version = data?.attachment;
            if (!version) {
              console.error('failed to fetch attachment:', attachment);
              return {
                attachmentKey: attachment.attachmentKey,
                owner: '',
                filename: attachment.attachmentKey,
                hasFailed: true,
              };
            }
            if (version.previousVersions) {
              const versions = [
                { attachmentKey: attachment.attachmentKey, ...version },
              ];
              for (const element of Object.values(version.previousVersions)) {
                if (typeof element === 'object' && element !== null) {
                  versions.push({
                    attachmentKey: attachment.attachmentKey,
                    ...element,
                  });
                }
              }
              return versions;
            } else {
              return { attachmentKey: attachment.attachmentKey, ...version };
            }
          }),
        );
        return {
          flowId,
          attachments: attachmentsWithVersions
            .flat()
            .sort((a, b) => b.version - a.version),
        };
      } catch (error) {
        errorTextSet('Kunne ikke hente filer. Vennligst prøv på nytt');
      }
    },
  });

  const customerDocuments = files?.attachments
    ?.filter(
      (file: any) =>
        file.owner === 'mainApplicant' || file.owner === 'coApplicant',
    )
    .concat(props?.task?.context?.cloudmersiveErrors ?? []);

  const caseWorkerDocuments = files?.attachments?.filter(
    (file: any) => file.owner === 'caseWorker',
  );

  const remainingDocuments = files?.attachments?.filter(
    (file: any) =>
      file.owner !== 'mainApplicant' &&
      file.owner !== 'coApplicant' &&
      file.owner !== 'caseWorker',
  );

  if (isFetching) return <CenteredSpinner />;

  if (!!errorText) {
    return <div> {errorText}</div>;
  }

  return (
    <FlowContext.Provider value={props}>
      <div className="flex flex-col gap-5">
        <DocumentsCard
          title={t('customerUploadedDocuments')}
          data={customerDocuments ?? []}
          color="blue"
        />
        <DocumentsCard
          title={t('caseWorkerUploadedDocuments')}
          data={caseWorkerDocuments ?? []}
          color="yellow"
        />
        <DocumentsCard
          title={t('flowUploadedDocuments')}
          data={remainingDocuments ?? []}
          color="green"
        />
      </div>
    </FlowContext.Provider>
  );
}
