import Overview from './Overview';
import { FlowContext } from '../../../hooks/useFlowContext';
import { Stakeholders } from '../../../components/Stakeholders';
import { Decision } from './DecisionCaseworker';
import { DecisionPanel } from '../../../components/Layout/DecisionPanel';
import { TaskProps } from '../../../types/TaskProps';
import { Layout } from '../../../components/Layout/Layout';
import { Tab } from '@headlessui/react';
import { Fragment, useEffect } from 'react';
import type { Stakeholder } from '../../../types/Stakeholder';
import Trapets from './Trapets';
import { DecisionsCard } from '../../../components/_shared/DecisionCard/DecisionCard';
import { EconomyCard } from '../ManuellBehandling/EconomyCard/EconomyCard';
import { FamilyCard } from '../../../components/_shared/FamilyCard/FamilyCard';
import { IncomeCard } from '../../../components/_shared/IncomeCard/IncomeCard';
import { DebtCard } from '../../../components/_shared/DebtCard/DebtCard';
import { AssetsCard } from '../../../components/_shared/AssetsCard/AssetsCard';
import { ScoringCardApplicant } from '../../../components/_shared/ScoringCard/ScoringCardApplicant';
import { ScoringCardLoan } from '../../../components/_shared/ScoringCard/ScoringCardLoan';
import { ScoringCardOther } from '../../../components/_shared/ScoringCard/ScoringCardOther';
import SBL from '../../../components/_shared/SBL';
import CreditCheck from '../../../components/_shared/CreditCheck';
import { Files } from '../../../components/_shared/Files';
import { useSituationState } from '../../../utils/useSituationState';
import { useQuery } from '@tanstack/react-query';
import { fetchAttachmentsByFlowId } from '../../../utils/attachments';
import { AssessmentView } from '../../../components/_shared/Assessment/AssessmentView';
import { ScoringCardPurpose } from '../../../components/_shared/ScoringCard/ScoringCard.Purpose';
import {
  initSituationStore,
  useSituationStore,
} from '../../stores/useSituationStore';
import { SuspenseWrapper } from '../../components/SuspenseWrapper';
import { LazyAgreementRegister } from '../../../components/Insight/AgreementRegister/LazyAgreementRegister';
import { TaskSummary } from '../../components/Summary/TaskSummary/TaskSummary';

export function PepKycCaseworker(props: TaskProps) {
  const {
    flow,
    task: { context },
    t,
  } = props;
  const { data, flowId } = flow;
  const { decisions, economyItems, persons, incomes } = useSituationState(data);
  const stakeholders: Stakeholder[] = data.stakeholders;
  const { data: files } = useQuery({
    queryKey: ['files', flowId],
    queryFn: () => fetchAttachmentsByFlowId(flowId),
  });
  const attachmentLength = files?.attachments.length ?? 0;
  const assessmentsLength = context?.assessments?.length ?? 0;

  const tabs = [
    'Oversikt',
    'Trapets',
    'Søknad',
    'Scoring',
    'SBL',
    t('sikkerheter', 'Sikkerheter'),
    t('creditCheck', 'Kredittsjekk'),
    `Filer (${attachmentLength})`,
    ...(assessmentsLength > 0
      ? [`${t('assessments')} (${assessmentsLength})`]
      : []),
  ];

  useEffect(() => {
    initSituationStore(data);
  }, [data]);

  const { debts, assets } = useSituationStore();

  return (
    <FlowContext.Provider value={props}>
      <Layout>
        <div>
          <Tab.Group>
            <Tab.List className="sticky top-0 z-50 bg-white">
              {tabs.map((tab, index) => (
                <Tab as={Fragment} key={index}>
                  {({ selected }) => (
                    <button
                      className={`whitespace-nowrap border-b-2 border-transparent py-4 px-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700 ${
                        selected ? 'border-b-2 border-basic-ink' : ''
                      }`}
                    >
                      {tab}
                    </button>
                  )}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels className="h-full overflow-auto bg-gray-100 p-4">
              <Tab.Panel className=" space-y-12">
                <Overview />
              </Tab.Panel>
              <Tab.Panel>
                <Trapets />
              </Tab.Panel>
              <Tab.Panel className=" space-y-12">
                <div className="grid grid-cols-2 gap-6">
                  <DecisionsCard {...decisions} />
                  <EconomyCard {...economyItems} />
                </div>

                <FamilyCard {...persons} />
                <IncomeCard {...incomes} />
                <DebtCard debts={debts} />
                <AssetsCard assets={assets} />
              </Tab.Panel>
              <Tab.Panel className="grid grid-cols-2 gap-7">
                {data.stakeholders.map((stakeholder: any, index: number) => (
                  <ScoringCardApplicant key={index} applicant={stakeholder} />
                ))}

                <ScoringCardLoan />
                <ScoringCardPurpose />
                <ScoringCardOther />
              </Tab.Panel>
              <Tab.Panel>
                <SBL />
              </Tab.Panel>
              <Tab.Panel className="h-full agreementregister-disabled">
                <SuspenseWrapper>
                  <LazyAgreementRegister {...props} />
                </SuspenseWrapper>
              </Tab.Panel>
              <Tab.Panel>
                <CreditCheck />
              </Tab.Panel>
              <Tab.Panel>
                <Files {...props} />
              </Tab.Panel>
              {assessmentsLength > 0 && (
                <Tab.Panel>
                  <AssessmentView assessments={context?.assessments} />
                </Tab.Panel>
              )}
            </Tab.Panels>
          </Tab.Group>
        </div>
        <div>
          <DecisionPanel>
            <Stakeholders stakeholders={stakeholders} />
            <TaskSummary />
            <Decision {...props} />
          </DecisionPanel>
        </div>
      </Layout>
    </FlowContext.Provider>
  );
}
