import { useState, forwardRef } from 'react';
import { Rifm } from 'rifm';
import { InputProps } from './types';

export const MaskedInput = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      onChange,
      value,
      name,
      type,
      inputMode,
      placeholder,
      invalid,
      errorId,
      mask,
      disabled,
      autoComplete,
      className,
      id,
      onBlur,
      defaultValue,
    },
    ref,
  ) => {
    const [maskedValue, setMaskedValue] = useState(value);

    const handleChange = (val: any) => {
      setMaskedValue(val);
      onChange(mask.parser(val));
    };
    return (
      <Rifm
        format={mask.formatter}
        value={maskedValue?.toString()!}
        onChange={handleChange}
      >
        {({ value, onChange }) => (
          <input
            type={type}
            inputMode={inputMode}
            ref={ref}
            name={name}
            onBlur={onBlur}
            id={id}
            className={className}
            placeholder={placeholder}
            aria-invalid={invalid}
            aria-errormessage={errorId}
            value={value}
            onChange={onChange}
            autoComplete={autoComplete}
            disabled={disabled}
            defaultValue={defaultValue}
          />
        )}
      </Rifm>
    );
  },
);

MaskedInput.displayName = 'InputBoxMaskedInput';
