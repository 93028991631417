import { Disbursement } from '../../../../types/DisbursementTypes';

export function fetchTotalCorrectedDisbursementAmount(
  disbursements: Disbursement[],
): number {
  return disbursements.reduce(
    (total: number, disbursement: any) =>
      total + Number(disbursement?.correctedAmount ?? 0),
    0,
  );
}
