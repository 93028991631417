import { DocumentControl } from './tasks/DocumentControl';
import { ScoringOverviewEditable } from './tasks/ManuellBehandling/ScoringOverviewEditable';
import { AskForDocumentation } from './tasks/AskForDocumentation/AskForDocumentation';
import { CaseSummary } from './components/CaseSummary';
import { QueueItem } from './components/QueueItem';
import { ScoringOverview } from './tasks/VurderBehandlingsbehov/ScoringOverview';
import EndreForfallsdatoMain from './tasks/EndreForfallsdato';
import EndreAvdragsfrihetMain from './tasks/EndreAvdragsfrihet';
import EndreNedbetalingsTidMain from './tasks/EndreNedbetalingsTid';
import { FireOyne } from './tasks/FireOyne/FireOyne';
import { DisbursementControl } from './tasks/GjennomforUtbetalingsKontroll/DisbursementControl';
import { BehandleAnkeOversikt } from './tasks/BehandleAnke/BehandleAnkeOversikt';
import { queueDefinition } from './caseManagerConfig/queues/queueDefinitions';
import { menuStructure } from './caseManagerConfig/queues/menuStructure';
import { LastOppDokument } from './tasks/LastOppDokument';
import { taskLists } from './caseManagerConfig/taskLists';
import { BehandleForesporsel } from './tasks/BehandleForesporsel';
import { PepKycCaseworker, PepKycCompliance } from './tasks/PepKyc';
import { tabs } from './caseManagerConfig/tabs';
import { RegisterContractDetails } from './tasks/RegisterContractDetails/RegisterContractDetails';
import { ReadySettlement } from './tasks/ReadySettlement/ReadySettlement';

const customWidth = '95%';
const customHeight = '95%';

export const customComponents = {
  case: {
    caseSummary: {
      component: CaseSummary,
    },
    tasks: {
      taskLists,
      taskComponents: {
        'vurder-behandlings-behov': {
          component: ScoringOverview,
          customWidth,
          customHeight,
        },
        'vurder-alle-beslutninger-kontroll': {
          component: ScoringOverview,
          customWidth,
          customHeight,
        },
        'godkjenn-dokument': {
          component: DocumentControl,
          titleMap: (task: any) =>
            task.variables.document.documentType === 'other'
              ? task.variables.document.description
              : task.variables.document.documentType,
          size: 'large',
        },
        'be-om-dokumentasjon': {
          component: AskForDocumentation,
          size: 'large',
        },
        'endre-forfall': {
          component: EndreForfallsdatoMain,
          size: 'large',
        },
        'endre-avdragsfrihet': {
          component: EndreAvdragsfrihetMain,
          size: 'large',
        },
        'endre-nedbetalingstid': {
          component: EndreNedbetalingsTidMain,
          size: 'large',
        },
        analyse: {
          component: ScoringOverviewEditable,
          customWidth,
          customHeight,
        },
        signoff: {
          component: ScoringOverview,
          customWidth,
          customHeight,
        },
        'gjennomfor-utbetalings-kontroll': {
          component: DisbursementControl,
          customWidth,
          customHeight,
        },
        'kyc-handtering-compliance': {
          component: PepKycCompliance,
          size: 'large',
        },
        'kyc-handtering-caseworker': {
          component: PepKycCaseworker,
          size: 'large',
        },
        'fire-oyne': {
          component: FireOyne,
          customWidth,
          customHeight,
        },
        'behandle-anke': {
          component: BehandleAnkeOversikt,
          customHeight,
          customWidth,
        },
        'handter-foresporsel': {
          component: BehandleForesporsel,
        },
        'last-opp-dokument': {
          component: LastOppDokument,
          size: 'large',
        },
        'vurder-kjop': {
          component: ScoringOverviewEditable,
          customWidth,
          customHeight,
        },
        'registrer-kontraktsdetaljer': {
          component: RegisterContractDetails,
          customWidth,
          customHeight,
        },
        'klargjor-oppgjor': {
          component: ReadySettlement,
          customWidth,
          customHeight,
        },
        'signoff-kjop': {
          component: ScoringOverview,
          customWidth,
          customHeight,
        },
      },
    },
    tabs,
  },
  queue: {
    queueItem: QueueItem,
  },
  menu: {
    menuStructure: menuStructure,
  },
  queueDefinition: queueDefinition,
};

export const startableProcesses = ['mortgage'];

export const caseAssignmentConfiguration = {
  caseownersTeamId: (flow: any) => {
    return 'CASEWORKERS';
  },
};
