import { TaskProps } from '../../../../../types/TaskProps';
import React from 'react';
import { EmptyState } from './EmptyState';
import { AnalyseTabContent } from './AnalyseTab.Content';

export default function AnalysisTab(props: TaskProps) {
  const { flow, t } = props;
  const { data } = flow;

  const hasSituation =
    !!data?.households?.[0]?.situations?.current?.betjeningsevne;

  if (!hasSituation) {
    return <EmptyState t={t} tab={t('analysis', 'Analyse')} />;
  }

  return <AnalyseTabContent {...props} />;
}
