import React from 'react';
import { useFlowContext } from '../../../../hooks/useFlowContext';

export function ControlCardTableHeader() {
  const { t } = useFlowContext();
  return (
    <tr className={'border-b border-gray-300'}>
      <td className={'pl-4'}>{t('type')}</td>
      <td className={'pr-2 text-right'}>{t('given-loan-number-account-nr')}</td>
      <td className={'pr-2 text-right'}>{t('given-amount')}</td>
      <td className={'text-right'}>{t('account-nr')}</td>
      <td className={'text-right'}>{t('ocr')}</td>
      <td className={'text-right'}>{t('message')}</td>
      <td className={'text-right'}>{t('amount')}</td>
      <td className={'pr-4'}></td>
    </tr>
  );
}
