import { BookOpenIcon } from '../../../../../components/icons/BookOpenIcon';
import { useFlowContext } from '../../../../../hooks/useFlowContext';
import { Fee } from '../../../../../types/DisbursementTypes';

type Props = {
  fees: Fee[];
};

export function DisbursementControlCardFeeRow({ fees }: Props) {
  const { t } = useFlowContext();

  if (!fees) {
    return null;
  }

  return (
    <>
      {fees
        ?.filter((fee: any) => fee?.amount || fee?.amount === 0)
        .map((fee: { feeCode: string; amount: number }, index: number) => (
          <tr
            className={'h-10 border-b border-gray-300 '}
            key={`${fee?.feeCode}-row-${index}`}
          >
            <td className={'flex h-10 items-center gap-x-2 pl-4'}>
              <BookOpenIcon />
              {t(fee?.feeCode)}
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td className="p-2 text-right">{fee?.amount}</td>
          </tr>
        ))}
    </>
  );
}
