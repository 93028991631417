export function OfficeIcon({
  width = 24,
  height = 24,
}: {
  width?: number;
  height?: number;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6132_10791)">
        <path
          d="M7 10H5V1C5 0.447 5.447 0 6 0H18C18.553 0 19 0.447 19 1V7H17V2H7V10Z"
          fill="currentColor"
        />
        <path
          d="M23 8H14C13.447 8 13 8.447 13 9V22H11V13C11 12.447 10.553 12 10 12H1C0.447 12 0 12.447 0 13V23C0 23.553 0.447 24 1 24H23C23.553 24 24 23.553 24 23V9C24 8.447 23.553 8 23 8ZM7 21H4V19H7V21ZM7 17H4V15H7V17ZM20 21H17V19H20V21ZM20 17H17V15H20V17ZM20 13H17V11H20V13Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_6132_10791">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
