import React from 'react';

interface Props {
  children?: React.ReactNode;
  className?: string;
  horizontalPadding?: boolean;
  verticalPadding?: boolean;
}
function Header({ children, className }: Props) {
  let styling = `flex space-x-3 items-center text-base font-semibold px-5 h-12 rounded-t-md ${
    className ? className : 'bg-caution-100'
  }`;

  return <div className={styling}>{children}</div>;
}

function HeaderTitle({ children }: Props) {
  return <h2>{children}</h2>;
}

function Content({
  children,
  className,
  horizontalPadding = true,
  verticalPadding = true,
}: Props) {
  let styling = `${verticalPadding ? 'py-4 pb-6' : ''} ${
    horizontalPadding ? 'px-5' : ''
  } ${className ? className : ''}`;
  return <div className={styling}>{children}</div>;
}

export function Card({ children }: Props) {
  return (
    <div className="h-fit-content rounded-md bg-white shadow">{children}</div>
  );
}

Card.Header = Header;
Card.HeaderTitle = HeaderTitle;
Card.Content = Content;
