import { Owner } from '../../../customer/tasks/ManuellBehandling/utils/borrowersHelper';

export interface Debt {
  id: any;
  type?: string;
  loanProduct: LoanType;
  loanOwners?: Owner[];
  balance?: number | undefined; //verdien som skal til betjeningsevne
  usedCredit?: number;
  durationMonths?: number;
  nominalInterestRate?: number;
  gracePeriodDuration?: number;
  toBeRedeemed?: boolean;
  isNew?: boolean;
  excludeFromCalculation?: boolean;
  loanType?: string;
  nameOfBank?: string;
  grantedCredit?: number; //verdien som skal til betjeningsevne (for kreditt)
  value: number;
  owner?: string;
  isSelected?: boolean;
  isInternalLoan?: boolean;
  sourceSystemCode?: string;
  accountId?: string;
}

export interface DebtCardProps {
  debts: Debt[];
}

export enum LoanType {
  LOAN_INSTALLMENT = 'INSTALLMENT',
  CREDIT = 'CREDIT',
  CAR_BOAT_MC = 'carBoatAndMcMortgage',
  STUDENT_LOAN = 'studentLoan',
  OTHER_MORTGAGE = 'otherMortgage',
  VEHICLES_TOTAL_LOAN = 'vehiclesTotalLoan',
  MOVE_LOAN = 'moveLoan',
  APPLY_LOAN = 'applyLoan',
  HOUSE_MORTGAGE = 'houseMortgage',
  COOP_DEBT = 'coopDebt',
  MAIN_LOAN = 'mainLoan',
  INCREASE_LOAN = 'increaseLoan',
  APPLY_FINANCE = 'applyFinance',
  BUY_PROPERTY = 'buyProperty',
  BRIDGING_LOAN = 'bridgingLoan',
}

export interface DebtCardAnalysisProps {
  debts: Debt[];
  editable?: boolean;
  onDelete?: any;
  onUpdate?: any;
  onAdd?: any;
  loanOwnerOptions?: Owner[];
}

export enum UnsecuredDebtType {
  RepaymentLoan = 'RepaymentLoan',
  CreditFacility = 'CreditFacility',
  ChargeCard = 'ChargeCard',
}
