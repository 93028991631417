import { Layout } from '../../../../components/Layout/Layout';
import { DecisionPanel } from '../../../../components/Layout/DecisionPanel';
import { TaskProps } from '../../../../types/TaskProps';
import { Stakeholders } from '../../../../components/Stakeholders';
import { Tab } from '@headlessui/react';
import React, { Fragment, useEffect, useRef } from 'react';
import { FlowContext } from '../../../../hooks/useFlowContext';
import { initSituationStore } from '../../../stores/useSituationStore';
import { isFinancingApplication } from '../../../tasks/ManuellBehandling/utils/loanPurposeContextHelper';
import { initProofOfFinancingStore } from '../../../stores/useProofOfFinancingStore';
import { TaskSummary } from '../TaskSummary/TaskSummary';
import AnalysisTabOriginal from './components/AnalysisTabOriginal';
import ScoringTabOriginal from './components/ScoringTabOriginal';
import AbilityToPayTabOriginal from './components/AbilityToPayTabOriginal';
import PaymentPlanTabOriginal from './components/PaymentPlanTabOriginal';
import SBLTab from './components/SBLTab';
import CreditCheckTab from './components/CreditCheckTab';

export function CaseDetailsOriginal(props: TaskProps) {
  const { flow, t } = props;
  const isInitializedStoresRef = useRef(false);
  const { data } = flow;
  const hasCurrentSituation =
    data?.households?.[0]?.situations?.current?.betjeningsevne;

  const tabs = [
    t('overview', 'Oversikt'),
    t('scoring', 'Scoring'),
    t('sbl', 'SBL'),
    t('abilityToPay', 'Betjeningsevne'),
    t('creditCheck', 'Kredittsjekk'),
    t('paymentPlan', 'Nedbetalingsplan'),
  ];
  useEffect(() => {
    if (!isInitializedStoresRef.current) {
      initSituationStore(data);
      if (isFinancingApplication(data)) {
        initProofOfFinancingStore(data?.proofOfFinance);
      }

      isInitializedStoresRef.current = true;
    }
  }, [data]);

  return (
    <FlowContext.Provider value={props}>
      <Layout>
        <div>
          <Tab.Group>
            <Tab.List className="sticky top-0 z-50 bg-white">
              {tabs.map((tab, index) => (
                <Tab as={Fragment} key={index}>
                  {({ selected }) => (
                    <button
                      className={`whitespace-nowrap border-b-2 border-transparent py-4 px-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700 ${
                        selected ? 'border-b-2 border-basic-ink' : ''
                      }`}
                    >
                      {tab}
                    </button>
                  )}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels className="h-full overflow-auto bg-gray-100 p-4">
              <Tab.Panel>
                <AnalysisTabOriginal {...props} />
              </Tab.Panel>
              <Tab.Panel>
                <ScoringTabOriginal {...props} />
              </Tab.Panel>
              <Tab.Panel>
                <SBLTab {...props} />
              </Tab.Panel>
              <Tab.Panel>
                <AbilityToPayTabOriginal {...props} />
              </Tab.Panel>
              <Tab.Panel>
                <CreditCheckTab {...props} />
              </Tab.Panel>
              <Tab.Panel>
                <PaymentPlanTabOriginal {...props} />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
        <div className="text-sm">
          <DecisionPanel>
            <Stakeholders stakeholders={data?.stakeholders} />
            {hasCurrentSituation && <TaskSummary />}
          </DecisionPanel>
        </div>
      </Layout>
    </FlowContext.Provider>
  );
}
