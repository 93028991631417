import { ListItemType } from '../../../../components/_shared/ListItem';
import { formatMoney } from '../../../../utils/formatMoney';
import { formatDate } from '../../../../utils/formatDate';
import { formatMoneyValue } from '../utils/formatMoneyValue';

interface CaseSummaryLoanOffer {
  data: any;
}
export function formatProofOfFinanceSummary({
  data,
}: CaseSummaryLoanOffer): ListItemType[] {
  const proofOfFinance = data.proofOfFinance;
  const takeOverDate =
    proofOfFinance?.takeOverDate || proofOfFinance?.purchase?.takeoverDate;
  const selectedProperty = proofOfFinance?.equity?.sale?.selectedProperty;

  const {
    inheritAndGiftAmount = 0,
    savedFundsAmount = 0,
    soldPropertyAmount = 0,
    otherAmount = 0,
  } = proofOfFinance?.equity?.equityUtilized ?? {};

  const unboundEquity =
    inheritAndGiftAmount + savedFundsAmount + soldPropertyAmount + otherAmount;

  const summaryData = {
    municipality: proofOfFinance?.municipality?.municipalityName ?? '-',
    propertyType: proofOfFinance?.propertyInfo?.propertyType ?? '-',
    desiredPurchaseAmount: formatMoneyValue(proofOfFinance?.purchaseAmount),
    unboundEquity: formatMoneyValue(unboundEquity),
    takeOverDate: takeOverDate ? formatDate(new Date(takeOverDate)) : '-',
    estimatedLoanAmount: formatMoneyValue(proofOfFinance?.loanAmount),
    costs: formatMoneyValue(proofOfFinance?.purchase?.calculatedCosts),
    maxBid: formatMoneyValue(proofOfFinance?.maxBid),
  };

  return [
    ...(!!selectedProperty
      ? [
          {
            label: `Salg av ${selectedProperty.address}`,
            value: formatMoney(
              selectedProperty.equityAmount ??
                proofOfFinance?.equity?.sale?.equityAmount,
            ),
          },
        ]
      : []),
    ...Object.entries(summaryData).map(
      ([label, value]): ListItemType => ({
        label,
        value,
      }),
    ),
  ].filter(Boolean);
}
