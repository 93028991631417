import { z } from 'zod';

import { HeaderLabel } from '../../components/common/HeaderLabel';
import { Input } from '../../components/common/Input';
import { useFlowContext } from '../../../hooks/useFlowContext';
import { useFormContext } from 'react-hook-form';
import {
  OrganizationDetailsSchema,
  OrganizationLookup,
} from '../RegisterContractDetails/OrganizationLookup';

export const SettlementSchema = z.object({
  settlementInstance: OrganizationDetailsSchema,
  settlementNumber: z.string(),
});

export type Settlement = z.infer<typeof SettlementSchema>;

export function SettlementInputSale() {
  const { formState } = useFormContext<{
    settlement: Settlement;
  }>();
  const { t } = useFlowContext();

  const hasSettlementInstanceError =
    formState.errors.settlement?.settlementInstance?.orgNumber;

  return (
    <div className="w-full h-full bg-primary-50 rounded border">
      <div className="p-4 grid grid-cols-1 gap-4">
        <div>
          <OrganizationLookup
            id="settlement.settlementInstance"
            headerLabel={'settlementInstanceSale'}
          />
        </div>
        {hasSettlementInstanceError && (
          <span className="text-negative-400 text-right">
            {t('settlementInstanceNeedsOrgNumber')}
          </span>
        )}
        <div>
          <HeaderLabel labelKey="settlementDetails" />
          <Input
            id="settlement.settlementNumber"
            label={t('settlementNumber')}
          />
        </div>
      </div>
    </div>
  );
}
