import { CalendarIcon } from '../../../components/icons/CalendarIcon';
import { Card } from '../../../components/_shared/Card';
import { List } from '../../../components/_shared/List';
import type { ListItemType } from '../../../components/_shared/ListItem';
import { useFlowContext } from '../../../hooks/useFlowContext';
import { openAccountInCore } from '../../../utils/openInCore';
import { PrimaryButton } from '@flow/buttons';

export default function EndreForfallsdato({ data }: any) {
  const { t } = useFlowContext();
  const { newDueDate, prevDueDate } = data;
  const items: ListItemType[] = [
    {
      label: t('existingDueDate'),
      value: `${prevDueDate}. ${t('nthMonth')}`,
    },
    {
      label: t('newDueDate'),
      value: `${newDueDate}. ${t('nthMonth')}`,
    },
  ];
  function handleOpenInCore() {
    openAccountInCore(data?.accountNumber);
  }
  return (
    <div className="flex flex-col gap-5">
      <Card>
        <Card.Header>
          <CalendarIcon />
          <Card.HeaderTitle>{t('changeDueDate')}</Card.HeaderTitle>
        </Card.Header>
        <Card.Content>
          <List items={items} />
        </Card.Content>
      </Card>
      <div>
        <PrimaryButton onClick={handleOpenInCore}>
          {t('openInCore')}
        </PrimaryButton>
      </div>
    </div>
  );
}
