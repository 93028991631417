import { formatMoney } from '../../../../utils/formatMoney';
import { HouseIcon } from '../../../../components/icons/HouseIcon';
import { Card } from '../../../../components/_shared/Card';
import DropdownMenu, {
  MenuItem,
} from '../../../../components/_shared/DropdownMenu';
import { CarIcon } from '../../../../components/icons/CarIcon';
import { PalaceIcon } from '../../../../components/icons/PalaceIcon';

import {
  AssetType,
  Asset,
} from '../../../../components/_shared/AssetsCard/AssetsCard.types';
import { AssetHeader } from './AssetHeader';
import { AssetContent } from './AssetContent';
import { useSituationStore } from '../../../stores/useSituationStore';

export const AssetColumnIds = {
  ASSET_TYPE: 'assetType',
  DETAILS: 'details',
  USAGE_ACREAGE: 'usageAcreage',
  COOP_DEBT: 'coopDebt',
  MARKET_VALUE: 'marketValue',
  STATED_VALUE: 'statedValue',
  FETCHED_VALUE: 'fetchedValue',
  BUILD_YEAR: 'buildYear',
};

export function AssetsCard({ editable = false }: { editable?: boolean }) {
  const { assets, addAsset, updateAssetNew } = useSituationStore();
  const headerDropdownItems: MenuItem[] = [
    {
      label: 'Fast eiendom',
      icon: <HouseIcon color={'#3158B2'} />,
      handleClick: () => addAsset(AssetType.OWNED_HOUSING),
    },
    {
      label: 'Borettslag',
      icon: <HouseIcon color={'#3158B2'} />,
      handleClick: () => addAsset(AssetType.SHARED_HOUSING),
    },
    {
      label: 'Motorvogn',
      icon: <CarIcon color={'#3158B2'} />,
      handleClick: () => addAsset(AssetType.VEHICLE),
    },
  ];

  return (
    <Card>
      <Card.Header className="justify-between bg-primary-50 text-primary-800 ">
        <div className="flex items-center gap-3">
          <PalaceIcon />
          <Card.HeaderTitle>Eiendeler</Card.HeaderTitle>
        </div>
        <div className="flex items-center gap-1">
          <span>{formatMoney(findTotalValue(assets))}</span>
          <span className="text-primary-200">NOK</span>
          {editable && <DropdownMenu items={headerDropdownItems} />}
        </div>
      </Card.Header>
      <Card.Content>
        <div
          className="grid items-center "
          style={{
            gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr auto',
          }}
        >
          <AssetHeader />
          <AssetContent editable={editable} onUpdate={updateAssetNew} />
        </div>
      </Card.Content>
    </Card>
  );
}

function findTotalValue(assets: Asset[]): number {
  return assets.reduce(
    (a: number, b: Asset) => a + Number(b?.marketValue ?? 0),
    0,
  );
}
