export enum LoanType {
  HOUSE_MORTGAGE = 'houseMortgage',
  CAR_BOAT_MC = 'carBoatAndMcMortgage',
  STUDENT_LOAN = 'studentLoan',
  MAIN_LOAN = 'mainLoan',
  BRIDGING_LOAN = 'bridgingLoan',
}

export type LoanInput = {
  id: string;
  value: string;
  initialValue: string;
  context: string;
};

export type Fee = {
  feeCode: string;
  amount: number;
};

export interface Disbursement {
  id: string;
  nameOfBank: string;
  loanNumber: string;
  loanAmount: number;
  loanFor?: string;
  ocr?: string;
  message?: string;
  correctedAmount: number;
  accountNumber: string;
  isInternalLoan?: boolean;
}

export interface Payout {
  accountNumber: string;
  amount: number;
  ocr?: string;
  message?: string;
  correctedAccountNumber?: string;
  correctedAmount?: number;
}

export interface Loan {
  id: string;
  product: string;
  amount: number;
  reserveAccount?: string;
  disbursements: Disbursement[];
  payout: Payout;
  fees: Fee[];
}

export interface DisbursementsState {
  redemptionDate?: string;
  storeId: string;
  loans: Loan[];
}

export interface RedeemAccount {
  accountNo: string;
  calculateOnly: boolean;
  correlationId: string;
  loanId: string;
  redemptionDueDate: string;
  result: boolean;
  resultBankAccountNo: string;
  resultDetails: string;
  resultObjectDescription: string;
  resultPaymentReference: string;
  resultRedeemLines: string;
  resultRedemptionAmount: number;
  serviceVersion: string;
}
