import { TaskProps } from '../../../types/TaskProps';
import { FormProvider, useForm } from 'react-hook-form';
import { TextArea } from '../../../components/_shared/TextArea';
import { useFlowContext } from '../../../hooks/useFlowContext';
import { DecisionButtonGroup } from '../../components/DecisionButtonGroup';

type DecisionProps = TaskProps & {
  documentRef:
    | {
        id: string;
        name: string;
      }
    | undefined;
};

export function Decision({ save, complete, documentRef }: DecisionProps) {
  const { t, task } = useFlowContext();
  const method = useForm({
    defaultValues: getDefaultValues(task),
  });
  const { handleSubmit } = method;

  function handleSave() {
    if (documentRef) {
      save({ documentRef: { id: documentRef?.id, name: documentRef?.name } });
    }
  }
  const onSubmit = () => {
    if (documentRef)
      complete({ documentType: task?.taskId, documentRefs: [documentRef?.id] });
  };

  return (
    <FormProvider {...method}>
      <form className="grid gap-8 py-8" onSubmit={handleSubmit(onSubmit)}>
        <TextArea id="internalComment" label={t('internalComment')} />
        <DecisionButtonGroup save={handleSave} />
      </form>
    </FormProvider>
  );
}

function getDefaultValues(task: TaskProps['task']): any {
  return {
    internalComment: task?.data?.internalComment ?? '',
  };
}
