import { formatMoneyValue } from './formatMoneyValue';
import { buildLoanDurationString } from '../../../../utils/loanDetails';
import { formatDate } from '../../../../utils/formatDate';
import { Debt } from '../../../../components/_shared/DebtCard/DebtCard.types';
import { Asset } from '../../../../components/_shared/AssetsCard/AssetsCard.types';
import { Translation } from '../../../../types/TaskProps';

interface LoanData {
  referenceId: string;
  selectedLoan: Debt;
  property: Asset;
  t: Translation;
  loanPurpose: string;
  loanToValue: number;
  productText: string;
  takeOverDate?: Date;
  combinedLtvRatio?: number;
  showCombinedLtvRatio?: boolean;
}
export const formatLoanData = ({
  referenceId,
  selectedLoan,
  property,
  t,
  loanPurpose,
  loanToValue,
  productText,
  takeOverDate,
  combinedLtvRatio,
  showCombinedLtvRatio = false,
}: LoanData) => {
  return {
    ...(referenceId ? { referenceId: referenceId } : {}),
    priorityMortgageAmount:
      property?.priority && property?.priority >= 1
        ? formatMoneyValue(property?.priorityMortgageAmount)
        : '-',
    loanType: selectedLoan?.loanType ?? '-',
    coopDebt: formatMoneyValue(property?.coopDebt),
    loanDuration: buildLoanDurationString({
      durationMonths: selectedLoan?.durationMonths ?? 0,
      t,
    }),
    lanebelop: formatMoneyValue(selectedLoan?.value),
    gracePeriod:
      typeof selectedLoan?.gracePeriodDuration === 'number'
        ? buildLoanDurationString({
            durationMonths: selectedLoan?.gracePeriodDuration,
            t,
          })
        : '-',
    propertyValue: formatMoneyValue(property?.marketValue),
    loanPurpose: loanPurpose ? `${loanPurpose}-loan-purpose` : '-',
    ...(showCombinedLtvRatio
      ? {
          loanToValuePurchaseObject: isNaN(loanToValue)
            ? '-'
            : `${(loanToValue * 100).toFixed(2)} %`,
        }
      : {
          loanToValue: isNaN(loanToValue)
            ? '-'
            : `${(loanToValue * 100).toFixed(2)} %`,
        }),
    ...(showCombinedLtvRatio &&
      combinedLtvRatio && {
        combinedLoanToValueRatio: isNaN(combinedLtvRatio)
          ? '-'
          : `${(combinedLtvRatio * 100).toFixed(2)} %`,
      }),
    product: productText,
    ...(!!takeOverDate && {
      takeOverDate: formatDate(new Date(takeOverDate)),
    }),
  };
};
