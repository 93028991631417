import { useFormConnect } from '../../../hooks/useFormConnect';
import { useFlowContext } from '../../../hooks/useFlowContext';

export function Input({
  id,
  label,
  placeholder = '',
}: {
  id: string;
  label: string;
  placeholder?: string;
}) {
  const { value, onChange, name, error } = useFormConnect(id);
  const { t } = useFlowContext();

  return (
    <div className="flex flex-col">
      <div className="flex justify-between px-2 py-2 items-center">
        <label htmlFor={name}>{t(label)}</label>
        <div className="flex flex-col w-1/2">
          <input
            type="text"
            id={name}
            name={name}
            onChange={onChange}
            value={value || ''}
            placeholder={placeholder}
            className="border rounded p-2 border-gray-200"
          />
        </div>
      </div>
      {error && (
        <span className="text-red-500 text-right px-2">{t(error)}</span>
      )}
    </div>
  );
}
