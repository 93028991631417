// Tilpasset fra https://no.wikipedia.org/wiki/MOD11#Beregning_av_kontrollsiffer_med_Modulus11

export function sanitizeInput(input: string) {
  return input.trim().replace(/\./g, '').replace(/\s/g, '');
}
export function validateAccountNumberMOD11(input: string | null): boolean {
  if (!input) {
    return false;
  }
  const accountNumber = sanitizeInput(input);

  if (accountNumber?.length !== 11) {
    return false;
  }
  const weights = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2];

  const checkDigit = parseInt(accountNumber.charAt(10), 10);
  const accountNumberWithoutCheckDigit = accountNumber.substring(0, 10);
  let sum = 0;
  for (let index = 0; index < 10; index++) {
    sum +=
      parseInt(accountNumberWithoutCheckDigit.charAt(index), 10) *
      weights[index];
  }
  const remainder = sum % 11;
  return checkDigit === (remainder === 0 ? 0 : 11 - remainder);
}
