import { Fragment } from 'react';
import { TrashIcon } from '../../../../../components/icons/TrashIcon';
import { ConditionalInputCell } from '../../../../../components/_shared/ConditionalInputCell';
import DropdownMenu from '../../../../../components/_shared/DropdownMenu';
import {
  Income,
  IncomeCardProps,
  WorkPlace,
} from '../../../../../components/_shared/IncomeCard/IncomeCard.types';
import { RightCell } from '../../../../../components/_shared/RightCell';
import { useFlowContext } from '../../../../../hooks/useFlowContext';
import { classNames } from '../../../../../utils/classNames';
import { formatMoney } from '../../../../../utils/formatMoney';
import {
  getTotalIncomeByType,
  getTruncatedAverageSblIncome,
  IncomeColumn,
} from '../../utils/incomesHelper';
import { formatOrgNumber } from '../../utils/formatOrgNumber';
import { Stakeholder } from '../../../../../types/Stakeholder';

export function Incomes({
  incomes,
  onDelete,
  onUpdate,
  editable = false,
  stakeholder,
}: IncomeCardProps & { stakeholder: Stakeholder }) {
  const { t } = useFlowContext();

  const sblFetchedValue = getTruncatedAverageSblIncome(stakeholder);
  return (
    <Fragment>
      <div className="pb-2 font-semibold ">{stakeholder?.fullName}</div>
      <div className="pb-2 font-semibold ">
        {t(stakeholder?.personalEconomy?.workCondition ?? '')}
      </div>
      <RightCell className="pb-2 font-semibold">
        {formatMoney(sblFetchedValue)}
      </RightCell>
      <RightCell className="pb-2 font-semibold">
        {formatMoney(getTotalIncomeByType(incomes, IncomeColumn.INCOME_STATED))}
      </RightCell>
      <RightCell
        className={classNames(editable ? 'mr-3' : '', 'pb-2 font-semibold')}
      >
        {formatMoney(
          getTotalIncomeByType(incomes, IncomeColumn.INCOME_ANALYSIS),
        )}
      </RightCell>
      <div className={classNames(editable ? 'w-12' : '')} />
      {incomes
        ? incomes.map((income: Income, index: number) => {
            const rowDropdownItems = [
              {
                label: 'Slett',
                icon: (
                  <div className="text-grass-20">
                    <TrashIcon />
                  </div>
                ),
                handleClick: () => onDelete(income, stakeholder.nationalId),
              },
            ];
            return (
              <Fragment key={income.id}>
                {index !== 0 ? (
                  <div className="col-span-6 border-b" />
                ) : (
                  <div className="col-span-6 pb-2" />
                )}
                <div>{t(resolveWorkPlaceName(income.workPlace))}</div>
                <div>{t(income.workSituation)}</div>
                <RightCell>
                  {income.incomeSbl > 0
                    ? formatMoney(Number(income.incomeSbl))
                    : ''}
                </RightCell>
                <RightCell>
                  {income.incomeSbl > 0 && income.incomeStated === 0
                    ? ''
                    : formatMoney(Number(income.incomeStated))}
                </RightCell>
                <ConditionalInputCell
                  {...{
                    editable,
                    className: 'justify-self-end w-24',
                    initialValue: income.value,
                    rowId: income.id,
                    columnId: IncomeColumn.INCOME_ANALYSIS,
                    context: income.ownerMeta.context,
                    onUpdate,
                  }}
                />
                {editable ? (
                  <RightCell className="flex items-center">
                    <DropdownMenu items={rowDropdownItems} />
                  </RightCell>
                ) : (
                  <div />
                )}
              </Fragment>
            );
          })
        : null}
    </Fragment>
  );
}

export function resolveWorkPlaceName(workPlace: string | WorkPlace) {
  if (typeof workPlace === 'string') {
    return formatOrgNumber(workPlace);
  } else {
    if (workPlace?.name?.length > 0) {
      return workPlace.name;
    } else if (workPlace.orgNumber && workPlace.orgNumber.length > 0) {
      return formatOrgNumber(workPlace.orgNumber);
    }

    return '';
  }
}
