export interface LoanOffer {
  reserveAccount?: string;
  type: string;
  loans: Loan[];
  securities: Security[];
  isMortgageRegistered?: boolean;
  redemptionDate?: string;
  isConsentFromSpouseNeeded?: boolean;
  priorityStatus?: 'OTHER-BANK-PANT' | 'CORRECT-PRIORITY';
  isAccepted?: boolean;
}

export interface DocumentToSign {
  attachmentKey: string;
  description: string;
  signType: 'sign' | 'view';
  sortIndex: number;
}

export interface Loan {
  lenders: Lender[];
  product: string;
  amount: number;
  gracePeriods: number;
  gracePeriodMonths: number;
  loanDurationMonths?: number;

  terms: number;
  termsPerYear?: number;

  interestGroup: string;
  interest: number;
  effectiveInterest: number;
  rateType: 'variable' | 'fixed';
  amortization: 'annuity' | 'serial';
  dueDay?: number;

  accountNumber?: string;

  payout: {
    accountNumber?: string;
    amount?: number;
    loanFor?: LoanForPayout;
    ocrMessage?: string;
    message?: string;
    correctedAccountNumber?: string;
    correctedAmount?: number;
  };

  fees: Fee[];
  /**
   * @deprecated
   */
  securities?: Security[];
  disbursements: Disbursement[];
  signOrders: SignOrder[];
  documentsToSign: DocumentToSign[];
  pantedokument?: any;
}

export interface Lender {
  nationalId: string;
  fraction: number;
}

export interface Property {
  id?: string;
  key: string;
  propertyType: 'cadastre' | 'share';
  levelCode: string;
  address: string;
  postalCode: number;
  postalPlace: string;
  marketValue: number;
  depotDate: string;
  priority?: number;
  priorityMortgageAmount?: number;

  commonDebt?: number;
  commonDebtDate?: string;

  areaScore: number;
  municipalityScore: number;

  municipalityNumber?: string;
  municipalityName?: string;
  cadastralUnitNumber?: number;
  unitNumber?: number;
  sectionNumber?: number;
  leaseholdUnitNumber?: number;
  organizationNumber?: number;
  shareNumber?: number;
  housingCooperativeName?: string;
  cadastreInsurance?: {
    insuranceCompany: string;
  };
  estateType?: string;
  coreObjectNo?: string;
  pantsettere: Pantsetter[];
  owners: Owner[];
}

interface Owner {
  nationalId: string;
  name: string;
  firstName: string;
  lastName: string;
  fraction: {
    numerator: number;
    denominator: number;
  };
}

interface Fee {
  feeCode: string;
  amount: number;
}
export type LoanForPayout =
  | 'renovation'
  | 'carBoatAndMcPurchase'
  | 'equityChildrenPropertyPurchase'
  | 'advanceInheritance'
  | 'other';

interface SignOrder extends Record<string, any> {
  documents: any[];
}

export interface Pantsetter {
  nationalId: string;
  firstName: string;
  lastName: string;
  fraction: {
    numerator: number;
    denominator: number;
  };
}

export interface Security {
  /**
   * @deprecated
   */
  property?: Property;
  properties?: Property[];
  amount: number;
  /**
   * @deprecated
   */
  priority?: number;
  /**
   * @deprecated
   */
  priorityMortgageAmount?: number;
  expirationDate: Date;
  /**
   * @deprecated
   */
  cadastreInsurance?: {
    insuranceCompany: string;
  };
  /**
   * @deprecated
   */
  pantsettere?: Pantsetter[];

  registration?: {
    documentNo?: string;
    documentYear?: string;
    officeNo?: string;
    rightNo?: string;
  };
  id?: string;
  isBeroendePant?: boolean;
}

export interface Disbursement {
  loanFor:
    | 'houseMortgage'
    | 'carBoatAndMcMortgage'
    | 'studentLoan'
    | 'mainLoan'
    | string;
  nameOfBank: string;
  loanAmount: number;
  loanNumber?: string;

  accountNumber?: string;
  correctedAmount?: number;
  ocrMessage?: string;
  ocr?: string;
  message?: string;
  isInternalLoan?: boolean;
}

export function isCadastre(property: Property): boolean {
  if (!property) {
    return false;
  }
  return 'cadastralUnitNumber' in property;
}
