import { Fragment } from 'react';
import { DownArrowIcon } from '../../../../components/icons/DownArrowIcon';
import { TrashIcon } from '../../../../components/icons/TrashIcon';
import {
  Debt,
  DebtCardAnalysisProps,
  LoanType,
  UnsecuredDebtType,
} from '../../../../components/_shared/DebtCard/DebtCard.types';
import { LoanTypeCell } from '../../../../components/_shared/DebtCard/LoanTypeCell';
import DropdownMenu from '../../../../components/_shared/DropdownMenu';
import { RightCell } from '../../../../components/_shared/RightCell';
import { classNames } from '../../../../utils/classNames';
import { formatMoney } from '../../../../utils/formatMoney';
import { isSelectedLoan } from '../../../../utils/isSelectedLoan';
import { isCredit } from '../utils/betjeningsevneHelper';
import { columnIds } from './DebtCard';
import { LoanOwnerDisplay } from './LoanOwnerDisplay';
import { useSituationStore } from '../../../stores/useSituationStore';
import { useFlowContext } from '../../../../hooks/useFlowContext';
import { CustomNumericInput } from '../../../../components/_shared/Fields/CustomNumericInput';
import { getDecimalScale } from '../utils/getDecimalScale';
import { DurationDisplay } from '../../../../components/_shared/DurationDisplay';
import { CustomTextInput } from '../../GjennomforUtbetalingsKontroll/DisbursementControlCard/Components/Fields/CustomTextInput';

export function DebtContent({
  debts,
  onUpdate,
  onDelete,
  editable = false,
  loanOwnerOptions,
}: DebtCardAnalysisProps) {
  const { updateCollateral, collaterals } = useSituationStore();
  const collateral = collaterals[0];
  const state = useFlowContext().flow.data;

  const handleRedemption = (debt: Debt) => {
    const existingAccountIds =
      state?.securities?.flatMap((security: any) => security.accountIds) ?? [];
    onUpdate({
      ...debt,
      toBeRedeemed: !debt.toBeRedeemed,
    });

    updateCollateral({
      ...collateral,
      accountIds: !debt.toBeRedeemed
        ? collateral?.accountIds?.filter((id) => debt.id !== id)
        : [
            ...collateral.accountIds,
            ...(collateral.isBeroendePant &&
            (existingAccountIds || []).includes(debt.id)
              ? [debt.id]
              : []),
          ],
    });
  };
  return (
    <Fragment>
      {debts.map((debt: Debt, index: number) => {
        const rowDropdownItems = [
          ...(isRedeemable(debt)
            ? [
                {
                  label: debt.toBeRedeemed ? 'removeRedemption' : 'redeem',
                  icon: (
                    <div className=" text-negative-800">
                      <DownArrowIcon width={16} />
                    </div>
                  ),
                  handleClick: () => handleRedemption(debt),
                },
              ]
            : []),
          ...(debt.loanProduct !== LoanType.MAIN_LOAN
            ? [
                {
                  label: 'delete',
                  icon: (
                    <div className=" text-negative-800">
                      <TrashIcon />
                    </div>
                  ),
                  handleClick: () => onDelete(debt),
                },
              ]
            : []),
        ];

        const isEditableNameOfBank = isEditable(debt, columnIds.BANK_NAME);
        const isEditableLoanOwners = isEditable(debt, columnIds.LOAN_OWNERS);
        const isEditableGracePeriod = isEditable(debt, columnIds.GRACE_PERIOD);
        const isEditableDurationMonths = isEditable(
          debt,
          columnIds.LOAN_DURATION,
        );
        const isEditableInterestRate = isEditable(
          debt,
          columnIds.INTEREST_RATE,
        );
        const isEditableValue = isEditable(debt, columnIds.VALUE);
        const isEditableAction = isEditable(debt, columnIds.ACTION);

        return (
          <Fragment key={index}>
            {index !== 0 ? <DebtDivider /> : null}
            <LoanTypeCell className="w-44" debt={debt} />
            {isEditableNameOfBank ? (
              <CustomTextInput
                initialValue={debt.nameOfBank ?? ''}
                id={debt.id}
                onUpdate={(val: string) =>
                  onUpdate({ ...debt, nameOfBank: val })
                }
                name="nameOfBank"
                placeholder=""
                styles="overflow-ellipsis"
              />
            ) : (
              <span className="flex items-center overflow-ellipsis mr-2">
                {debt.nameOfBank}
              </span>
            )}
            <LoanOwnerDisplay
              editable={isEditableLoanOwners}
              loanOwners={debt.loanOwners}
              options={loanOwnerOptions}
              rowId={debt.id}
              columnId={columnIds.LOAN_OWNERS}
              onUpdate={onUpdate}
            />
            {isCredit(debt) ? (
              <RightCell className="mr-14 flex items-center justify-end">
                {debt.isNew ? '-' : formatMoney(debt.usedCredit ?? 0)}
              </RightCell>
            ) : (
              <div className="mr-14 flex items-center justify-end" />
            )}
            {isSelectedLoan(debt) ? (
              <CustomNumericInput
                initialValue={debt.gracePeriodDuration}
                handleUpdate={(val: number) =>
                  onUpdate({ ...debt, gracePeriodDuration: val })
                }
                allowNegative={false}
                suffix=" år"
                styles={classNames(
                  isEditableGracePeriod || !debt.toBeRedeemed
                    ? ''
                    : 'text-neutral-500',
                  'w-24 justify-self-end',
                )}
                editable={isEditableGracePeriod}
              />
            ) : (
              <span className="flex justify-end mr-2 text-neutral-500">
                <DurationDisplay
                  durationMonths={debt.gracePeriodDuration ?? 0}
                />
              </span>
            )}
            {!isCredit(debt) && isEditableDurationMonths ? (
              <CustomNumericInput
                initialValue={(debt.durationMonths ?? 0) / 12}
                handleUpdate={(val: number) =>
                  onUpdate({ ...debt, durationMonths: val * 12 })
                }
                allowNegative={false}
                suffix=" år"
                styles={classNames(
                  isEditableDurationMonths || !debt.toBeRedeemed
                    ? ''
                    : ' pr-2 text-neutral-500 justify-end',
                  'w-24 justify-self-end',
                )}
                editable={isEditableDurationMonths}
              />
            ) : (
              <span
                className={classNames(
                  !debt.toBeRedeemed ? '' : '  text-neutral-500',
                  'flex justify-end mr-2',
                )}
              >
                <DurationDisplay durationMonths={debt.durationMonths ?? 0} />
              </span>
            )}
            <CustomNumericInput
              initialValue={debt.nominalInterestRate}
              handleUpdate={(value: number) =>
                onUpdate({
                  ...debt,
                  nominalInterestRate: value,
                })
              }
              allowNegative={false}
              suffix=" %"
              styles={classNames(
                isEditableInterestRate || !debt.toBeRedeemed
                  ? ''
                  : 'text-neutral-500',
                'w-24 justify-end pr-2',
              )}
              editable={isEditableInterestRate}
              decimalScale={getDecimalScale(debt.nominalInterestRate ?? 0)}
              fixedDecimalScale={true}
            />
            <CustomNumericInput
              initialValue={debt.value}
              handleUpdate={(val: number) => onUpdate({ ...debt, value: val })}
              allowNegative={false}
              styles={classNames(
                isEditableValue || !debt.toBeRedeemed
                  ? ''
                  : 'text-neutral-500 justify-end pr-2',
                'w-30 justify-self-end pr-2',
              )}
              editable={isEditableValue}
            />
            {isEditableAction ? (
              <RightCell className="flex items-center">
                <DropdownMenu items={rowDropdownItems} />
              </RightCell>
            ) : (
              <div className={classNames(editable ? 'w-12' : '')} />
            )}
          </Fragment>
        );
      })}
    </Fragment>
  );
}

function isEditable(
  debt: Debt,
  columnId: (typeof columnIds)[keyof typeof columnIds],
) {
  switch (columnId) {
    case columnIds.LOAN_OWNERS:
      return (
        !debt.toBeRedeemed &&
        !isSelectedLoan(debt) &&
        !isGjeldsregisterLoan(debt)
      );
    case columnIds.GRACE_PERIOD:
      return isSelectedLoan(debt);
    case columnIds.LOAN_DURATION:
      return !debt.toBeRedeemed && !isGjeldsregisterLoan(debt);
    case columnIds.INTEREST_RATE:
      return (
        !debt.toBeRedeemed &&
        !isSelectedLoan(debt) &&
        !isGjeldsregisterLoan(debt)
      );
    case columnIds.VALUE:
      return true;
    case columnIds.ACTION:
      return !isSelectedLoan(debt);
    case columnIds.BANK_NAME:
      return debt.toBeRedeemed;
    default:
      return false;
  }
}

function isRedeemable(debt: Debt) {
  const repaymentLoan = debt.loanType === UnsecuredDebtType.RepaymentLoan;

  if (isGjeldsregisterLoan(debt)) {
    return repaymentLoan;
  }

  return !isSelectedLoan(debt);
}

function DebtDivider() {
  return <div className="col-span-9 border-t border-misc-ui-separator" />;
}

const isGjeldsregisterLoan = (debt: Debt) => {
  return Object.keys(UnsecuredDebtType).includes(debt.loanType ?? '');
};
