import { ClipboardIcon } from '../../icons/ClipboardIcon';
import { Card } from '../Card';
import { useFlowContext } from '../../../hooks/useFlowContext';
import { FileRow } from './FileRow';
import { VirusFileRow } from './VirusFileRow';
import { AddIcon } from '../../icons/AddIcon';

type Color = 'blue' | 'green' | 'yellow';

function returnColors(color: Color) {
  switch (color) {
    case 'blue':
      return 'bg-primary-100 text-primary-800';
    case 'green':
      return 'bg-grass-90 text-grass-20';
    case 'yellow':
      return 'bg-caution-100 text-caution-800';
    default:
      return '';
  }
}

export function DocumentsCard({
  title,
  data,
  color,
  handleUploadFiles,
}: {
  title: string;
  data: any[];
  color: Color;
  handleUploadFiles?: () => void;
}) {
  const { t } = useFlowContext();
  const virusData = data.filter(
    (data) => data.result && data.result?.FoundViruses?.length > 0,
  );
  const normalData = data.filter(
    (data) => !data.result || data.result?.FoundViruses?.length === 0,
  );

  return (
    <Card>
      <Card.Header className={`${returnColors(color)} justify-between`}>
        <div className="w-full gap-4 flex justify-between">
          <div className="w-full flex justify-between">
            <div className="flex gap-3 items-center">
              <ClipboardIcon width={18} height={18} />
              <span>{title}</span>
            </div>
            <span>{data.length}</span>
          </div>
        </div>
      </Card.Header>
      <Card.Content horizontalPadding={false}>
        {data?.length > 0 ? (
          <table className="w-full text-basic-ink">
            <thead>
              <tr className="w-full">
                <th className="text-xs text-left pl-5 pb-2">
                  {t('type').toUpperCase()}
                </th>
                <th className="text-xs text-left pb-2">
                  {t('uploadedBy').toUpperCase()}
                </th>
                <th className="text-xs text-left pb-2">
                  {t('fileName').toUpperCase()}
                </th>
                <th className="text-xs text-right pb-2">
                  {t('uploaded').toUpperCase()}
                </th>
              </tr>
            </thead>
            <tbody>
              {(virusData || []).map((row, i) => (
                <VirusFileRow key={row.documentType + '-' + i} data={row} />
              ))}
              {(normalData || []).map((row) => (
                <FileRow
                  key={row.attachmentId ?? row.attachmentKey}
                  data={row}
                />
              ))}
            </tbody>
          </table>
        ) : (
          <EmptyState />
        )}
        <div className="flex justify-start w-full pt-6 px-4">
          {!!handleUploadFiles && (
            <button
              type="button"
              onClick={handleUploadFiles}
              className="flex gap-2 self-start p-2 border-2 border-blue-600 text-blue-600 rounded-md items-center"
            >
              <AddIcon height={18} width={18} />
              <span>{t('upload-documents-button', 'Last opp en ny fil')}</span>
            </button>
          )}
        </div>
      </Card.Content>
    </Card>
  );
}

function EmptyState() {
  const { t } = useFlowContext();
  return (
    <div className="flex flex-col gap-5 text-center text-black font-bold pt-4">
      <div className="flex justify-center">
        <ClipboardIcon />
      </div>
      <span>{t('noDocuments')}</span>
    </div>
  );
}
