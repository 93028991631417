import { Layout } from '../../../components/Layout/Layout';
import { DecisionPanel } from '../../../components/Layout/DecisionPanel';
import { TaskProps } from '../../../types/TaskProps';
import { Stakeholders } from '../../../components/Stakeholders';
import { Tab } from '@headlessui/react';
import { ScoringCardOther } from '../../../components/_shared/ScoringCard/ScoringCardOther';
import { Fragment, useEffect } from 'react';
import { EconomyCard } from '../ManuellBehandling/EconomyCard/EconomyCard';
import { FlowContext } from '../../../hooks/useFlowContext';
import { ManualDecisionAssessment } from './ManualDecisionAssessment';
import { useSituationState } from '../../../utils/useSituationState';
import { ManualDecisionSignoff } from '../Signoff/ManualDecisionSignoff';
import { ManualDecisionControl } from '../VurderAlleBeslutningerKontroll/ManualDecisionControl';
import SBL from '../../../components/_shared/SBL';
import { fetchAttachmentsByFlowId } from '../../../utils/attachments';
import { Files } from '../../../components/_shared/Files';
import { useQuery } from '@tanstack/react-query';
import CreditCheck from '../../../components/_shared/CreditCheck';
import { ScoringCardApplicant } from '../../../components/_shared/ScoringCard/ScoringCardApplicant';
import { ScoringCardLoan } from '../../../components/_shared/ScoringCard/ScoringCardLoan';
import { PaymentPlanWrapper } from '../../../components/_shared/Paymentplan';
import { isSelectedLoan } from '../../../utils/isSelectedLoan';
import { AssessmentView } from '../../../components/_shared/Assessment/AssessmentView';
import { DecisionsCard } from '../../../components/_shared/DecisionCard/DecisionCard';
import { FamilyCard } from '../../../components/_shared/FamilyCard/FamilyCard';
import { IncomeCard } from '../../../components/_shared/IncomeCard/IncomeCard';
import { DebtCard } from '../../../components/_shared/DebtCard/DebtCard';
import { AssetsCard } from '../../../components/_shared/AssetsCard/AssetsCard';
import { ScoringCardPurpose } from '../../../components/_shared/ScoringCard/ScoringCard.Purpose';
import {
  initSituationStore,
  useSituationStore,
} from '../../stores/useSituationStore';
import { SuspenseWrapper } from '../../components/SuspenseWrapper';
import { LazyAbilityToPay } from '../../../components/Insight/AbilityToPay/LazyAbilityToPay';
import { isFinancingApplication } from '../ManuellBehandling/utils/loanPurposeContextHelper';
import { ProofOfFinance } from '../../../components/Insight/ProofOfFinance/LazyProofOfFinance';
import { initProofOfFinancingStore } from '../../stores/useProofOfFinancingStore';
import { LazyAgreementRegister } from '../../../components/Insight/AgreementRegister/LazyAgreementRegister';
import { ManualDecisionSignoffKjop } from '../SignoffKjop/ManualDecisionSignoffKjop';
import { TaskSummary } from '../../components/Summary/TaskSummary/TaskSummary';

export function ScoringOverview(props: TaskProps) {
  const { flow, t, task } = props;
  const { data } = flow;
  const { decisions, economyItems, persons, incomes } = useSituationState(data);

  const {
    taskType,
    context: { assessments },
  } = task;
  const assessmentsLength = assessments?.length ?? 0;

  const { flowId } = flow;
  const { data: files } = useQuery({
    queryKey: ['files', flowId],
    queryFn: () => fetchAttachmentsByFlowId(flowId),
  });

  const attachmentLength = files?.attachments.length ?? 0;

  const tabs = [
    t('overview', 'Oversikt'),
    t('scoring', 'Scoring'),
    t('sbl', 'SBL'),
    t('abilityToPay', 'Betjeningsevne'),
    t('sikkerheter', 'Sikkerheter'),
    ...(isFinancingApplication(data)
      ? [t('proofOfFinance', 'Finansieringsbevis')]
      : []),
    t('creditCheck', 'Kredittsjekk'),
    t('filesWithCount', { count: attachmentLength }),
    t('paymentPlan', 'Nedbetalingsplan'),
    ...(assessmentsLength > 0
      ? [`${t('assessments')} (${assessmentsLength})`]
      : []),
  ];

  useEffect(() => {
    initSituationStore(data);
    if (isFinancingApplication(data)) {
      initProofOfFinancingStore(data.proofOfFinance);
    }
  }, [data]);

  const { debts, assets } = useSituationStore();

  return (
    <FlowContext.Provider value={props}>
      <Layout>
        <div>
          <Tab.Group>
            <Tab.List className="sticky top-0 z-50 bg-white">
              {tabs.map((tab, index) => (
                <Tab as={Fragment} key={index}>
                  {({ selected }) => (
                    <button
                      className={`whitespace-nowrap border-b-2 border-transparent py-4 px-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700 ${
                        selected ? 'border-b-2 border-basic-ink' : ''
                      }`}
                    >
                      {tab}
                    </button>
                  )}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels className="h-full overflow-auto bg-gray-100 p-4">
              <Tab.Panel className=" space-y-12">
                <div className="grid grid-cols-2 gap-6">
                  <DecisionsCard {...decisions} />
                  <EconomyCard {...economyItems} />
                </div>

                <FamilyCard {...persons} />
                <IncomeCard {...incomes} />
                <DebtCard debts={debts} />
                <AssetsCard assets={assets} />
              </Tab.Panel>
              <Tab.Panel className="grid grid-cols-2 gap-7">
                {data.stakeholders.map((stakeholder: any, index: number) => (
                  <ScoringCardApplicant key={index} applicant={stakeholder} />
                ))}
                <ScoringCardLoan />
                <ScoringCardPurpose />
                <ScoringCardOther />
              </Tab.Panel>
              <Tab.Panel>
                <SBL />
              </Tab.Panel>
              <Tab.Panel className="flex justify-center">
                <SuspenseWrapper>
                  <LazyAbilityToPay {...props} className="px-12" />
                </SuspenseWrapper>
              </Tab.Panel>
              <Tab.Panel className="h-full agreementregister-disabled">
                <SuspenseWrapper>
                  <LazyAgreementRegister {...props} />
                </SuspenseWrapper>
              </Tab.Panel>
              {isFinancingApplication(data) && (
                <Tab.Panel>
                  <SuspenseWrapper>
                    <ProofOfFinance />
                  </SuspenseWrapper>
                </Tab.Panel>
              )}
              <Tab.Panel>
                <CreditCheck />
              </Tab.Panel>
              <Tab.Panel>
                <Files {...props} />
              </Tab.Panel>
              <Tab.Panel>
                <PaymentPlanWrapper
                  data={data}
                  caseData={flow}
                  loans={debts?.find(isSelectedLoan) ?? []}
                />
              </Tab.Panel>
              <Tab.Panel>
                <AssessmentView assessments={assessments} />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
        <div>
          <DecisionPanel>
            <Stakeholders stakeholders={data.stakeholders} />
            <TaskSummary />
            {taskType === 'vurder-behandlings-behov' && (
              <ManualDecisionAssessment {...props} />
            )}
            {taskType === 'signoff' && <ManualDecisionSignoff {...props} />}
            {taskType === 'signoff-kjop' && (
              <ManualDecisionSignoffKjop {...props} />
            )}
            {taskType === 'vurder-alle-beslutninger-kontroll' && (
              <ManualDecisionControl {...props} />
            )}
          </DecisionPanel>
        </div>
      </Layout>
    </FlowContext.Provider>
  );
}
