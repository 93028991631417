import { Fragment } from 'react';
import { ContentHeader } from '../../../../../components/_shared/ContentHeader';
import { useFlowContext } from '../../../../../hooks/useFlowContext';

export function IncomeHeaderRow() {
  const { t } = useFlowContext();
  return (
    <Fragment>
      <ContentHeader>{t('person')}</ContentHeader>
      <ContentHeader>{t('work-situation')}</ContentHeader>
      <ContentHeader className="text-right">{t('sblIncome')}</ContentHeader>
      <ContentHeader className="text-right">{t('stated-income')}</ContentHeader>
      <ContentHeader className="text-right">
        {t('utilized-income')}
      </ContentHeader>
      <div />
    </Fragment>
  );
}
