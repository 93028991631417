import { CaseManager, APPS, FEATURES } from '@flow/case-manager';
import { customComponents, caseAssignmentConfiguration } from './customer';
import translatesInbox from './translate/inbox.json';
import manualCaseManagement from './translate/caseManagement.json';
import translatesDecisions from './translate/decisions.json';
import translatesInboxEn from './translate_en/inbox.json';
import manualCaseManagementEn from './translate_en/caseManagement.json';
import translatesDecisionsEn from './translate_en/decisions.json';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { MainLogo } from './customer/branding/MainLogo';
import { searchConfiguration } from './customer/caseManagerConfig/searchConfiguration';
import './globals.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  },
});

const skin = {
  mainLogo: () => <MainLogo />,
};

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <CaseManager
        configuration={() => {
          return {
            homeUrl: '/inbox',
            apps: [APPS.Inbox, APPS.Search, APPS.Process],
            skin,
            inboxConfiguration: {
              queuesPollingEnabled: true,
              features: [FEATURES.CaseAssignment, FEATURES.CaseNotes],
            },
            caseNotesConfiguration: {
              sort: 'DESC',
            },
            searchConfiguration,
            caseAssignmentConfiguration,
            customComponents,
            translates: [
              {
                language: 'no',
                translates: manualCaseManagement,
              },
              {
                language: 'no',
                translates: translatesDecisions,
              },
              {
                language: 'no',
                translates: translatesInbox,
              },
              {
                language: 'en',
                translates: manualCaseManagementEn,
              },
              {
                language: 'en',
                translates: translatesDecisionsEn,
              },
              {
                language: 'en',
                translates: translatesInboxEn,
              },
            ],
            language: localStorage.getItem('language') === 'en' ? 'en' : 'no',
          };
        }}
      />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}
