import { Fragment } from 'react';
import { formatMoney } from '../../../../utils/formatMoney';
import { HandMoneyIcon } from '../../../../components/icons/HandMoneyIcon';
import { Card } from '../../../../components/_shared/Card';
import { useFlowContext } from '../../../../hooks/useFlowContext';
import { IncomeDropdownMenu } from './components/IncomeDropdownMenu';
import { IncomeHeaderRow } from './components/IncomeHeaderRow';
import { getTotalIncome } from '../utils/incomesHelper';
import { Incomes } from './components/Incomes';
import { IncomeCardProps } from '../../../../components/_shared/IncomeCard/IncomeCard.types';
import { Stakeholder } from '../../../../types/Stakeholder';

export function IncomeCard({
  incomes,
  onDelete,
  onUpdate,
  onAdd,
  editable = false,
}: IncomeCardProps) {
  const {
    t,
    flow: { data },
  } = useFlowContext();
  return (
    <Card>
      <Card.Header className="bg-grass-90 text-grass-20 justify-between ">
        <div className="flex gap-3 ">
          <HandMoneyIcon size={24} />
          <Card.HeaderTitle>{t('incomes')}</Card.HeaderTitle>
        </div>
        <div className="flex gap-1">
          <span>{formatMoney(getTotalIncome(incomes))}</span>
          <span className="text-secondary-200">NOK</span>
          {editable && (
            <IncomeDropdownMenu
              stakeholders={data?.stakeholders || []}
              onAdd={onAdd}
            />
          )}
        </div>
      </Card.Header>
      <Card.Content>
        <div
          className="grid items-center py-4"
          style={{
            gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr auto',
          }}
        >
          <IncomeHeaderRow />

          {data.stakeholders.map((stakeholder: Stakeholder, index: number) => {
            const stakeholderIncomes = incomes.filter(
              (income) =>
                income.owner === stakeholder.nationalId ||
                income.ownerMeta.id === stakeholder.nationalId,
            );

            return (
              <Fragment key={stakeholder.nationalId}>
                {index > 0 && <div className="h-11 col-span-6" />}
                <Incomes
                  incomes={stakeholderIncomes}
                  editable={editable}
                  onUpdate={onUpdate}
                  onDelete={onDelete}
                  stakeholder={stakeholder}
                />
              </Fragment>
            );
          })}
        </div>
      </Card.Content>
    </Card>
  );
}
