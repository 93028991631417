export const EditIcon = ({ width = 24, height = 24 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
    >
      <title>pen 01</title>
      <g
        strokeLinecap="round"
        fill="currentColor"
        strokeLinejoin="round"
        className="nc-icon-wrapper"
      >
        <line
          data-cap="butt"
          data-color="color-2"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          x1="19"
          y1="9"
          x2="15"
          y2="5"
        ></line>
        <polygon
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          points="7,21 2,22 3,17 18,2 22,6 "
        ></polygon>
      </g>
    </svg>
  );
};
