import { formatMoney } from '../../../utils/formatMoney';
import { Chevron } from '../../icons/Chevron';
import { useMemo, useState } from 'react';
import { List } from '../List';
import { ListItemType } from '../ListItem';
import { DataTable } from '../DataTable';
import { useFlowContext } from '../../../hooks/useFlowContext';
import { classNames } from '../../../utils/classNames';
import * as Collapsible from '@radix-ui/react-collapsible';

type PaymentPlanYearProps = {
  data: any;
  effRate: number;
};

export default function PaymentPlanYear({
  data,
  effRate,
}: PaymentPlanYearProps) {
  const [open, setOpen] = useState(false);
  const { t } = useFlowContext();

  const itemsLeft: ListItemType[] = [
    {
      label: t('restAmount'),
      value: formatMoney(data.restAmount),
    },
    {
      label: t('interestRates'),
      value: `${data.interestRate * 100}% / ${effRate.toFixed(2)}% eff.`,
    },
  ];

  const itemsRight: ListItemType[] = [
    {
      label: t('amountPaid'),
      value: formatMoney(data.yearlyPayment),
    },
    {
      label: t('avdragAndInterest'),
      value:
        formatMoney(data.yearlyPayment - data.interestAmount) +
        ' / ' +
        formatMoney(data.interestAmount),
    },
  ];

  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'monthName',
        Cell: (props: any) => {
          return <div className="text-left">{props.cell.value}</div>;
        },
      },
      {
        Header: t('interestRate'),
        accessor: 'interestRate',

        Cell: (props: any) => <div>{effRate.toFixed(2)}% eff</div>,
      },
      {
        Header: t('restAmount'),
        accessor: 'restAmount',

        Cell: (props: any) => {
          return <div>{formatMoney(props.cell.value)}</div>;
        },
      },
      {
        Header: t('interestRate'),
        accessor: 'interestAmount',
        Cell: (props: any) => <div>{formatMoney(props.cell.value)}</div>,
      },
      {
        Header: () => (
          <span className="[&>*>th]:text-right text-right">
            {t('monthlyPayment')}
          </span>
        ),
        accessor: 'monthlyPayment',
        Cell: (props: any) => (
          <div className="text-right">{formatMoney(props.cell.value)}</div>
        ),
      },
    ],
    [t, effRate],
  );

  return (
    <Collapsible.Root open={open}>
      <Collapsible.Trigger onClick={() => setOpen(!open)} className="w-full">
        <div className="flex cursor-pointer flex-row items-center justify-between p-4">
          <span>{data?.year}</span>
          <div className="flex flex-row">
            <span>{formatMoney(data?.restAmount)}</span>
            <div>
              <Chevron
                className={classNames(
                  open ? 'rotate-180' : 'rotate-0',
                  'transform text-blue-500 duration-150 ease-in-out',
                )}
              />
            </div>
          </div>
        </div>
      </Collapsible.Trigger>
      <Collapsible.CollapsibleContent className="grid gap-6 bg-neutral-200">
        <div className="grid grid-cols-2 gap-10 p-4">
          <List items={itemsLeft}></List>
          <List items={itemsRight}></List>
        </div>
        <div className="p-4 text-right">
          <DataTable textLeft={false} data={data.terms} columns={columns} />
        </div>
      </Collapsible.CollapsibleContent>
    </Collapsible.Root>
  );
}
