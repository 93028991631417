import { TaskProps } from '../../../types/TaskProps';
import { FormProvider, useForm } from 'react-hook-form';
import { TextArea } from '../../../components/_shared/TextArea';
import { DecisionButtonGroup } from '../../components/DecisionButtonGroup';
import { useFlowContext } from '../../../hooks/useFlowContext';
import { RadioButtonList } from '../../../components/_shared/RadioButtonList';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

interface Props {
  save: TaskProps['save'];
  complete: TaskProps['complete'];
  task: TaskProps['task'];
}

export function BehandleAnkeDecision({ save, complete }: Props) {
  const { t, task } = useFlowContext();
  const method = useForm({
    defaultValues: getDefaultValues(task),
    resolver: zodResolver(BehandleAnkeDecision.validationSchema),
  });
  const { handleSubmit } = method;
  const decisionOptions = [
    { value: 'approve', label: t('sendToVurderBehandlingsbehov') },
    { value: 'reject', label: t('maintainRejection') },
  ];

  const onSubmit = (data: any) => {
    const shouldOverrideAutomaticDecision =
      data.shouldOverrideAutomaticDecision === 'approve';
    complete({
      ...data,
      shouldOverrideAutomaticDecision,
    });
  };

  return (
    <FormProvider {...method}>
      <form className="grid gap-2 py-8" onSubmit={handleSubmit(onSubmit)}>
        <h2 className="text-sm font-semibold text-black">{t('decision')}</h2>
        <div className="grid gap-6">
          <RadioButtonList
            id="shouldOverrideAutomaticDecision"
            options={decisionOptions}
          />
          <TextArea id="internalComment" label={t('internalComment')} />
          <DecisionButtonGroup save={save} />
        </div>
      </form>
    </FormProvider>
  );
}

BehandleAnkeDecision.validationSchema = z.object({
  shouldOverrideAutomaticDecision: z.string({
    required_error: 'Avgjørelse er påkrevd',
    invalid_type_error: 'Avgjørelse er påkrevd',
  }),
  internalComment: z.string().optional(),
});

function getDefaultValues(task: TaskProps['task']): any {
  return {
    shouldOverrideAutomaticDecision:
      task?.data?.shouldOverrideAutomaticDecision ?? false,
    internalComment: task?.data?.internalComment ?? '',
  };
}
