import WarningIcon from '../../../../../components/icons/WarningIcon';
import * as Tooltip from '@radix-ui/react-tooltip';

type Props = {
  text: string;
};

export function WarningTooltip({ text }: Props) {
  return (
    <Tooltip.Provider>
      <Tooltip.Root>
        <Tooltip.Trigger asChild>
          <button type={'button'}>
            <WarningIcon size={12} />
          </button>
        </Tooltip.Trigger>
        <Tooltip.Content
          className={'rounded border-2 border-gray-200 bg-white p-2'}
        >
          {text}
          <Tooltip.Arrow className={' fill-current text-white'} />
        </Tooltip.Content>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
}
