import EndreForfallsdato from './EndreForfallsdato';
import { FlowContext } from '../../../hooks/useFlowContext';
import { Stakeholders } from '../../../components/Stakeholders';
import { Decision } from './Decision';
import { DecisionPanel } from '../../../components/Layout/DecisionPanel';
import { TaskProps } from '../../../types/TaskProps';
import { Layout } from '../../../components/Layout/Layout';

export default function EndreForfallsdatoMain(props: TaskProps) {
  const { task } = props;
  const { context } = task;
  return (
    <FlowContext.Provider value={props}>
      <Layout>
        <div className="max-w-md mx-auto my-10 container">
          <EndreForfallsdato data={context} />
        </div>
        <div>
          <DecisionPanel>
            <Stakeholders stakeholders={context.stakeholders} />
            <Decision {...props} />
          </DecisionPanel>
        </div>
      </Layout>
    </FlowContext.Provider>
  );
}
