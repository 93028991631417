import { useState } from 'react';
import {
  DecisionCode,
  DecisionId,
  findDecisionCode,
} from '../../../../components/_shared/ScoringCard/DecisionsHelper';
import { Betjeningsevne } from '../DecisionsCard/useDecisionsState';

export function useEconomyState(data: any) {
  const mappedScoring = mapDecisionsData(
    data.households[0]?.situations?.current?.betjeningsevne,
    data.decisions,
  );
  const [economyItems, setEconomyItems] = useState(mappedScoring);

  return { economyItems, setEconomyItems };
}

export function mapDecisionsData(
  betjeningsevneData: Betjeningsevne,
  decisions: any,
) {
  const betjeningsevne = betjeningsevneData?.requestedLoan;

  const disposableLiquidity = betjeningsevne?.disposableLiquidity;
  const liquidityIndicator = betjeningsevne?.liquidityIndicator;
  const debtDegree = betjeningsevne?.debtDegree;
  const decisionsData = [
    {
      label: 'disposableLiquidity',
      value: disposableLiquidity,
      decisionCode:
        disposableLiquidity < 0
          ? DecisionCode.RED
          : findDecisionCode(decisions, DecisionId.NOKKELTALL_INSOLVENCY),
    },
    {
      label: 'debtDegree',
      value: debtDegree,
      decisionCode: findDecisionCode(
        decisions,
        DecisionId.NOKKELTALL_HIGH_DEBT_DEGREE,
      ),
    },
    {
      label: 'liquidityIndicator',
      value: liquidityIndicator,
      decisionCode: findDecisionCode(
        decisions,
        DecisionId.NOKKELTALL_INSOLVENCY,
      ),
    },
  ];

  return decisionsData;
}
