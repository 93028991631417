import React from 'react';
import DropdownMenu, {
  MenuItem,
} from '../../../../components/_shared/DropdownMenu';
import { Loan, LoanInput, LoanType } from '../../../../types/DisbursementTypes';
import { Card } from '../../../../components/_shared/Card';
import { HouseIcon } from '../../../../components/icons/HouseIcon';
import { formatMoney } from '../../../../utils/formatMoney';
import { TrendDownIcon } from '../../../../components/icons/TrendDown';
import { useFlowContext } from '../../../../hooks/useFlowContext';
import { DisbursementControlTable } from '../../../../components/_shared/DisbursementControlTable/DisbursementControlTable';
import { DisbursementControlCardTableBody } from './Components/DisbursementControlCard.TableBody';
import { DisbursementControlCardTableHeader } from './Components/DisbursementControlCard.TableHeader';
import { useDisbursementControlStore } from '../../../stores/useDisbursementStore';

type DisbursementControlProps = {
  id: string;
};

export function DisbursementControlCard({ id }: DisbursementControlProps) {
  const { t } = useFlowContext();
  const {
    loans,
    updateDisbursement,
    deleteDisbursement,
    addDisbursement,
    updatePayout,
  } = useDisbursementControlStore();

  const loanValue = loans
    ? loans?.find((elem: Loan) => elem.id === id)
    : emptyLoanValue;

  const disbursements = loanValue?.disbursements ?? [];

  const feeAmount =
    (loanValue?.fees ?? [])?.reduce(
      (
        total: number,
        fee: {
          feeCode: string;
          amount: number;
        },
      ) => total + fee.amount,
      0,
    ) ?? 0;

  function handleDeleteValue(elem: any) {
    const disbursementToDelete = disbursements.find(
      (disbursement) => disbursement.id === elem?.id,
    );

    if (disbursementToDelete) {
      deleteDisbursement(
        loanValue ? loanValue.id : '',
        disbursementToDelete.id,
      );
    }
  }

  const calculatedTotal =
    (loanValue ? loanValue?.amount || 0 : 0) -
    findTotalCorrectedAmount(disbursements) -
    feeAmount;

  function handleInputChange(elem: LoanInput) {
    const editedDisbursement = disbursements?.find(
      (disbursement) => disbursement.id === elem.context,
    );

    if (!!editedDisbursement) {
      updateDisbursement(loanValue ? loanValue.id : '', editedDisbursement.id, {
        ...editedDisbursement,
        [elem.id]: elem.value,
      });
    }
  }

  const headerDropdownItems: MenuItem[] = [
    {
      label: t('other-loan'),
      icon: <HouseIcon />,
      handleClick: () =>
        addDisbursement(LoanType.HOUSE_MORTGAGE, loanValue?.id ?? ''),
    },
  ];

  function handlePayoutChange(elem: any) {
    if (!loanValue) {
      return;
    }
    updatePayout(loanValue.id, {
      accountNumber: '',
      amount: 0,
      ...loanValue.payout,
      [elem?.id]: elem?.value,
    });
  }

  return (
    <Card>
      <Card.Header className="justify-between bg-tertiary-50 pl-5 pr-1 text-tertiary-800">
        <div className="flex items-center gap-3">
          <TrendDownIcon />
          <Card.HeaderTitle>Utbetalinger</Card.HeaderTitle>
        </div>
        <div className="flex items-center gap-1">
          <span>{formatMoney(loanValue?.amount ?? 0)}</span>
          <span className="text-tertiary-400">NOK</span>
          <DropdownMenu items={headerDropdownItems} />
        </div>
      </Card.Header>
      <Card.Content horizontalPadding={false}>
        <DisbursementControlTable>
          <DisbursementControlTable.Header>
            <DisbursementControlCardTableHeader />
          </DisbursementControlTable.Header>
          <DisbursementControlTable.Content>
            <DisbursementControlCardTableBody
              disbursements={disbursements}
              handleInputChange={handleInputChange}
              handleDeleteValue={handleDeleteValue}
              customerPayout={loanValue?.payout}
              handlePayoutChange={handlePayoutChange}
              calculatedTotal={calculatedTotal}
              fees={loanValue?.fees ?? []}
            />
          </DisbursementControlTable.Content>
        </DisbursementControlTable>
      </Card.Content>
    </Card>
  );
}

function findTotalCorrectedAmount(disbursements: any) {
  return (disbursements ?? []).reduce(
    (total: number, disbursement: any) =>
      total + Number(disbursement?.correctedAmount ?? 0),
    0,
  );
}

const emptyLoanValue = {
  id: `no-loan-state`,
  product: '',
  amount: 0,
  disbursements: [],
  payout: {},
  fees: [],
};
