import { Fragment, ReactNode } from 'react';
import { useFlowContext } from '../../../../hooks/useFlowContext';
import { AssetColumnIds } from './AssetsCard';

export function AssetHeader() {
  const { t } = useFlowContext();
  return (
    <Fragment>
      <ContentHeader>{t(AssetColumnIds.ASSET_TYPE)}</ContentHeader>
      <ContentHeader>{t(AssetColumnIds.DETAILS)}</ContentHeader>
      <ContentHeader className="text-right ">
        {t(AssetColumnIds.USAGE_ACREAGE)}
      </ContentHeader>
      <ContentHeader className="text-right ">
        {t(AssetColumnIds.BUILD_YEAR)}
      </ContentHeader>
      <ContentHeader className="text-right ">
        {t(AssetColumnIds.COOP_DEBT)}
      </ContentHeader>
      <ContentHeader className="text-right ">
        {t(AssetColumnIds.FETCHED_VALUE)}
      </ContentHeader>
      <ContentHeader className="text-right ">
        {t(AssetColumnIds.STATED_VALUE)}
      </ContentHeader>
      <ContentHeader className="text-right">{t('usedValue')}</ContentHeader>
      <div />
    </Fragment>
  );
}

function ContentHeader({ children, className }: HeaderProps) {
  return (
    <div className={`pb-4 text-xs uppercase ${className}`}>{children}</div>
  );
}

export interface HeaderProps {
  children: ReactNode;
  className?: string;
}
