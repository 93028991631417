import React from 'react';
import { Disbursement, Loan } from '../../../../types/DisbursementTypes';
import { formatMoney } from '../../../../utils/formatMoney';
import { BookOpenIcon } from '../../../../components/icons/BookOpenIcon';
import { HeartHouseIcon } from '../../../../components/icons/HeartHouseIcon';
import { formatAccountNumber } from '../../../../utils/formatAccountNumber';
import { DisbursementControlCardFeeRow } from '../../GjennomforUtbetalingsKontroll/DisbursementControlCard/Components/DisbursementControlCard.FeeRow';
import { WarningTooltip } from '../../GjennomforUtbetalingsKontroll/DisbursementControlCard/Components/WarningTooltip';
import { Translation } from '../../../../types/TaskProps';

type Props = {
  loan?: Loan;
  t: Translation;
};

export function ControlCardTableBody({ loan, t }: Props) {
  if (!loan) {
    return null;
  }

  const disbursements = loan.disbursements;
  const customerPayout = loan.payout;
  const fees = loan.fees;

  const showAccountNumberWarning =
    !!customerPayout?.correctedAccountNumber &&
    customerPayout?.correctedAccountNumber !== customerPayout?.accountNumber;

  return (
    <>
      {disbursements?.map((elem: Disbursement, index: number) => {
        return (
          <tr
            key={`disbursement-${elem?.loanFor}-${index}-${elem.nameOfBank}`}
            className="h-10 border-b border-gray-300"
          >
            <td className="flex h-10 items-center gap-x-2 pl-4">
              <HeartHouseIcon className="text-basic-warning" />
              {elem?.nameOfBank}
            </td>
            <td className="pr-2 text-right">{elem?.loanNumber}</td>
            <td className="pr-2 text-right">
              {formatMoney(elem?.loanAmount ?? 0)}
            </td>
            <td className="pr-2 text-right">
              {formatAccountNumber(elem?.accountNumber)}
            </td>
            <td className="pr-2 text-right">{elem?.ocr}</td>
            <td className="pr-2 text-right">{elem?.message}</td>
            <td className="pr-2 text-right">
              {formatMoney(elem?.correctedAmount ?? 0)}
            </td>
          </tr>
        );
      })}
      <DisbursementControlCardFeeRow fees={fees} />
      <tr className="h-10">
        <td className="flex h-10 items-center gap-x-2 pl-4">
          <BookOpenIcon /> Kundeutbetaling
        </td>
        <td className="pr-2 text-right">
          <span className="flex gap-1 justify-end">
            {customerPayout?.accountNumber &&
              formatAccountNumber(customerPayout?.accountNumber)}
            {showAccountNumberWarning && (
              <WarningTooltip text={t('account-number-mismatch')} />
            )}
          </span>
        </td>
        <td className="pr-2 text-right">
          {formatMoney(customerPayout?.amount ?? 0)}
        </td>
        <td className="pr-2 text-right">
          {formatAccountNumber(customerPayout?.correctedAccountNumber)}
        </td>
        <td className="pr-2 text-right">
          <div />
        </td>
        <td className="pr-2 text-right">{customerPayout?.message}</td>
        <td className="pr-2 text-right">
          {formatMoney(customerPayout?.correctedAmount ?? 0)}
        </td>
      </tr>
    </>
  );
}
