import { Debt } from '../../../../components/_shared/DebtCard/DebtCard.types';
import { Asset } from '../../../../components/_shared/AssetsCard/AssetsCard.types';
import { Translation } from '../../../../types/TaskProps';
import { Product } from '../../../../utils/coreProducts';
import { ListItemType } from '../../../../components/_shared/ListItem';
import { formatLoanData } from '../utils/formatLoanData';

interface TaskSummaryData {
  referenceId: string;
  loan: Debt;
  property: Asset;
  t: Translation;
  loanProduct: string;
  loanToValue: number;
  coreProduct: Product;
  proofOfFinanceSummary?: {
    takeOverDate?: Date;
    combinedLtvRatio?: number;
    showCombinedLtvRatio?: boolean;
  };
}

export function formatTaskSummary({
  referenceId,
  loan,
  loanProduct,
  property,
  t,
  loanToValue,
  coreProduct,
  proofOfFinanceSummary,
}: TaskSummaryData): ListItemType[] {
  const productText = coreProduct
    ? `${t(coreProduct?.rateCode)} - ${(coreProduct?.rateValue * 100).toFixed(
        2,
      )}%`
    : t('noProduct');

  const summaryData = formatLoanData({
    referenceId,
    selectedLoan: loan,
    property,
    loanPurpose: loanProduct,
    t,
    loanToValue,
    productText,
    takeOverDate: proofOfFinanceSummary?.takeOverDate,
    combinedLtvRatio: proofOfFinanceSummary?.combinedLtvRatio,
    showCombinedLtvRatio: proofOfFinanceSummary?.showCombinedLtvRatio,
  });

  return Object.entries(summaryData).map(
    ([label, value]): ListItemType => ({
      label,
      value,
    }),
  );
}
