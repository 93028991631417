import axios from 'axios';

export async function fetchAttachmentsByFlowId(flowId: string) {
  return axios
    .get(`/api/flow/flows/${flowId}/attachments`)
    .then((response) => response.data);
}

export async function fetchAttachmentByKey(
  flowId: string,
  attachmentKey: string,
) {
  return axios
    .get(`/api/flow/flows/${flowId}/attachments/${attachmentKey}`)
    .then((response) => response.data);
}

export async function downloadAttachment(
  flowId: string,
  attachmentKey: string,
  mimeType: string,
  version?: number,
) {
  const { data } = await axios.get(
    `/api/flow/flows/${flowId}/attachments/${attachmentKey}/download${
      version ? `?version=${version}` : ''
    }`,
    {
      responseType: 'blob',
    },
  );
  const file = new Blob([data], { type: mimeType });

  return URL.createObjectURL(file);
}
