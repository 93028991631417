import { TaskProps } from '../../../types/TaskProps';
import { FormProvider, useForm } from 'react-hook-form';
import { TextArea } from '../../../components/_shared/TextArea';
import { RadioButtonList } from '../../../components/_shared/RadioButtonList';
import { DecisionButtonGroup } from '../../components/DecisionButtonGroup';
import { useFlowContext } from '../../../hooks/useFlowContext';
import { DecisionError } from '../../components/DecisionError';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { SameUserError } from '../../../components/_shared/SameUserError/SameUserError';
import { TextAreaSimple } from '../../../components/_shared/TextAreaSimple';

const availableDecisions = {
  RETURN_TO_CASEWORKER: 'returnToCaseworker',
  MAINTAIN_REJECTION: 'maintainRejection',
  MAINTAIN_APPROVAL: 'maintainApproval',
};

export function ManualDecisionSignoff({ save, complete }: TaskProps) {
  const { task } = useFlowContext();
  const method = useForm({
    defaultValues: getDefaultValues(task),
    resolver: zodResolver(ManualDecisionSignoff.validationSchema),
  });
  const { handleSubmit, formState } = method;
  const { errors } = formState;

  const onSubmit = (data: any) => {
    complete(data);
  };

  const {
    t,
    user,
    task: { context, permissions },
  } = useFlowContext();

  const isSameUser =
    user?.profile?.username === context?.sentToTaskBy?.username;

  if (isSameUser && !permissions.complete) {
    return <SameUserError />;
  }

  const isRejection = task.context?.mostRecentAssessment.code === 'reject';

  const decisionOptions = [
    {
      value: isRejection
        ? availableDecisions.MAINTAIN_REJECTION
        : availableDecisions.MAINTAIN_APPROVAL,
      label: isRejection ? t('maintainRejection') : t('maintainApproval'),
    },
    {
      value: availableDecisions.RETURN_TO_CASEWORKER,
      label: t('returnToCaseworker'),
    },
  ];

  return (
    <FormProvider {...method}>
      <form className="grid gap-2 py-8" onSubmit={handleSubmit(onSubmit)}>
        <h2 className="text-sm font-semibold text-black">
          {t('considerDecision')}
        </h2>
        <div className="grid gap-6">
          <RadioButtonList id="decision" options={decisionOptions} />
          {!!context?.overrideSikkerhetsdekning && (
            <span className="p-4 bg-red-200 rounded items-center flex gap-4 text-gray-700">
              <p>{t('override-sikkerhets-dekning-decision')}</p>
            </span>
          )}
          <TextArea id="creditAssessment" label="creditAssessment" />
          {isRejection && (
            <TextAreaSimple
              id={'rejectionReason'}
              name={'rejectionReason'}
              value={context.rejectionReason}
              disabled={true}
              label={t('rejectionReason')}
              styles={'h-40'}
            />
          )}
          <DecisionButtonGroup save={save} />
        </div>
        {Object.keys(errors).length > 0 && (
          <DecisionError text="message-required-error-message" />
        )}
      </form>
    </FormProvider>
  );
}

ManualDecisionSignoff.validationSchema = z
  .object({
    decision: z.string({
      required_error: 'decisionRequired',
      invalid_type_error: 'decisionRequired',
    }),
    creditAssessment: z.string().optional(),
  })
  .superRefine((val, context) => {
    if (
      val.decision === 'returnToCaseworker' &&
      (!val?.creditAssessment || val?.creditAssessment === '')
    ) {
      context.addIssue({
        code: 'custom',
        message: 'Melding er påkrevd',
      });
    }
  });

function getDefaultValues(task: TaskProps['task']): any {
  return {
    decision: task.data?.decision,
    creditAssessment: task.data?.creditAssessment ?? '',
  };
}
