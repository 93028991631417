import { useMemo } from 'react';
import { ChildIcon } from '../../../../components/icons/ChildIcon';
import { FamilyIcon } from '../../../../components/icons/FamilyIcon';
import { PersonIcon } from '../../../../components/icons/PersonIcon';
import { Card } from '../../../../components/_shared/Card';
import { DataTable } from '../../../../components/_shared/DataTable';
import { RightCell } from '../../../../components/_shared/RightCell';
import { NumericInput } from '../../../../components/_shared/NumericInput';
import DropdownMenu, {
  MenuItem,
} from '../../../../components/_shared/DropdownMenu';
import { TrashIcon } from '../../../../components/icons/TrashIcon';
import { ListboxCell } from '../../../../components/_shared/ListboxCell';
import { useFlowContext } from '../../../../hooks/useFlowContext';
import { FamilyCardProps } from './FamilyCard.types';

const optionsAdult = [
  'married',
  'single',
  'cohabitant',
  'divorced',
  'widow',
  'separated',
];
const optionsChild = ['full', 'partial'];

export function FamilyCard({
  persons,
  onDelete,
  onUpdate,
  onAdd,
  editable = false,
}: FamilyCardProps) {
  const { t } = useFlowContext();
  const headerDropdownItems: MenuItem[] = [
    {
      label: 'Legg til barn',
      icon: <ChildIcon />,
      handleClick: onAdd,
    },
  ];

  const rowDropdownItems: MenuItem[] = useMemo(
    () => [
      {
        label: 'Slett',
        icon: <TrashIcon />,
        handleClick: onDelete,
      },
    ],
    [onDelete],
  );

  const listboxOptions = (age: string | number) =>
    Number(age) < 18 ? optionsChild : optionsAdult;

  const columns = useMemo(
    () => [
      {
        Header: t('name'),
        accessor: 'firstName',
        columnType: 'left',
        Cell: (props: any) => NameCell(props.row.values.age, props.cell.value),
      },
      {
        Header: 'Status',
        accessor: 'status',
        columnType: 'left',
        Cell: ({ row, cell }: any) =>
          editable && isChild(row.original.role) ? (
            <div className="relative w-28">
              <ListboxCell
                initialValue={cell.value}
                options={listboxOptions(row.values.age)}
                rowId={row.values.id}
                columnId={cell.column.id}
                onUpdate={onUpdate}
              />
            </div>
          ) : (
            <div>{t(cell.value)}</div>
          ),
      },
      {
        Header: () => t('age'),
        accessor: 'age',
        columnType: 'right',
        Cell: ({ cell, row }: any) =>
          editable && isChild(row.original.role) ? (
            <NumericInput
              className="-mr-2 w-10"
              initialValue={cell.value}
              rowId={row.values.id}
              columnId={cell.column.id}
              onUpdate={onUpdate}
              isAllowedFn={(value: number) => value >= 0 && value <= 17}
            />
          ) : (
            cell.value
          ),
      },
      {
        Header: () => <RightCell>{t('role')}</RightCell>,
        accessor: 'role',
        columnType: 'right',
        Cell: ({ cell }: any) => t(cell.value),
      },
      {
        Header: '',
        accessor: 'id',
        columnType: 'action',
        cellStyles: editable ? 'w-12' : '',
        Cell: ({ row }: any) => {
          if (editable && isChild(row.original.role)) {
            rowDropdownItems[0].handleClick = () => onDelete(row.values);
            return <DropdownMenu items={rowDropdownItems} />;
          }
          return null;
        },
      },
    ],
    [t, editable, onUpdate, rowDropdownItems, onDelete],
  );

  return (
    <Card>
      <Card.Header className="justify-between bg-caution-100 text-caution-800">
        <div className="flex items-center gap-3">
          <FamilyIcon />
          <Card.HeaderTitle>Familie</Card.HeaderTitle>
        </div>
        {editable && <DropdownMenu items={headerDropdownItems} />}
      </Card.Header>
      <Card.Content>
        <DataTable data={persons} columns={columns} isEditable={editable} />
      </Card.Content>
    </Card>
  );
}

function NameCell(age: number, data: string) {
  return (
    <div className="flex gap-2">
      {age < 18 ? <ChildIcon /> : <PersonIcon />} {data}
    </div>
  );
}

const isChild = (role: string) => role === 'child';
