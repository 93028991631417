import Decimal from 'decimal.js';
import { Debt } from '../../../../components/_shared/DebtCard/DebtCard.types';
import { components } from '../../../../types/schemas/betjeningsevne';
import { isSelectedLoan } from '../../../../utils/isSelectedLoan';
import { isProofOfFinanceProduct } from './loanPurposeContextHelper';

export function isCredit(debt: Debt) {
  return debt.type === 'CREDIT';
}

export function mapAnalysisParams(
  currentSituation: any,
  oldAnalysisParams: any,
) {
  const isProofOfFinancing = currentSituation?.debts.some((debt: Debt) =>
    isProofOfFinanceProduct(debt.loanProduct),
  );
  const requestedLoan = currentSituation?.debts?.find(isSelectedLoan);
  return {
    ...oldAnalysisParams,
    requestedLoan: requestedLoan?.value ?? 0,
    interest: requestedLoan?.nominalInterestRate ?? 0,
    years: Math.floor((requestedLoan?.durationMonths ?? 0) / 12),
    onlyCalculateRequestedLoan: !isProofOfFinancing,
    termFeeAmount: 0,
  };
}

type Details = components['schemas']['Details'];
interface DetailsExtension extends Details {
  shortTermLiquidityYearly?: number;
}

export function getShortTermLiquidity(details: DetailsExtension) {
  return new Decimal(details?.shortTermLiquidityYearly ?? 0)
    .dividedBy(12)
    .floor()
    .toNumber();
}
