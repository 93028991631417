import { formatMoney } from '../../../utils/formatMoney';
import { DownArrowIcon } from '../../icons/DownArrowIcon';
import { Card } from '../Card';
import { List } from '../List';
import { ListItemType } from '../ListItem';
import { useFlowContext } from '../../../hooks/useFlowContext';
import { isSelectedLoan } from '../../../utils/isSelectedLoan';
import { useProductStore } from '../../../customer/stores/useProductStore';

export default function LoanDetails({
  data,
  termsInfo,
  caseData,
  loan,
  situation,
}: any) {
  const { t } = useFlowContext();
  const usedSituation =
    situation.situation ??
    data?.households?.[0]?.situations?.current?.situation;

  const selectedLoan = loan ? loan : usedSituation?.debts?.find(isSelectedLoan);

  const storeLtvProduct = useProductStore((s) => s.ltvProduct);
  const ltvProduct = situation ? situation?.ltvProduct : storeLtvProduct;

  const coreProduct = ltvProduct?.product;
  const productText = coreProduct
    ? `${t(coreProduct?.rateCode)} - ${(coreProduct?.rateValue * 100).toFixed(
        2,
      )}%`
    : t('noProduct');
  const loanDetailsData = {
    runningTime: selectedLoan?.durationMonths / 12,
    establishmentFee: 0, // Kassa does not have establishment fees
    interestNom: selectedLoan?.nominalInterestRate.toFixed(2),
    interestEff: (termsInfo?.effectiveInterestRate * 100)?.toFixed(2),
    termFee: 0, // Kassa does not have term fees
    termPerYear: termsInfo?.yearPaymentPlan?.[1]?.terms?.length,
    totalAmount: selectedLoan?.value,
    gracePeriod: selectedLoan?.gracePeriodDuration ?? 0,
    loanType: selectedLoan?.loanType,
  };

  const leftItems: ListItemType[] = [
    {
      label: t('runningTime'),
      value: `${loanDetailsData.runningTime} ${t('years')}`,
    },
    {
      label: t('deductionFreePeriod'),
      value: `${loanDetailsData.gracePeriod} ${t('terms')}`,
    },
    {
      label: t('interestNom'),
      value: `${loanDetailsData.interestNom}%`,
    },
    {
      label: t('interestEff'),
      value: `${loanDetailsData.interestEff}%`,
    },
  ];

  const rightItems: ListItemType[] = [
    {
      label: t('innvilgetAmount'),
      value: `${formatMoney(loanDetailsData.totalAmount)} kr`,
    },
    {
      label: t('termPerYear'),
      value: loanDetailsData.termPerYear,
    },
    {
      label: t('typeOfLoan'),
      value: loanDetailsData.loanType,
    },
    {
      label: t('loanCategory'),
      value: productText,
    },
  ];

  return (
    <Card>
      <Card.Header className=" bg-green-100 font-bold text-green-900">
        <DownArrowIcon />
        <Card.HeaderTitle>{t('theLoanDetails')}</Card.HeaderTitle>
      </Card.Header>
      <Card.Content>
        <div className="grid grid-cols-2 gap-10">
          <List items={leftItems} />
          <List items={rightItems} />
        </div>
      </Card.Content>
    </Card>
  );
}
