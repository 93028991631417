import { useCollateralsState } from '../components/_shared/CollateralsCard/useCollateralsState';
import { useDecisionsState } from '../customer/tasks/ManuellBehandling/DecisionsCard/useDecisionsState';
import { useEconomyState } from '../customer/tasks/ManuellBehandling/EconomyCard/useEconomyState';
import { useFamilyState } from '../customer/tasks/ManuellBehandling/FamilyCard/useFamilyState';
import { useIncomeState } from '../customer/tasks/ManuellBehandling/IncomesCard/useIncomeState';

export function useSituationState(
  data: any,
  onMutate?: (changes: any[]) => void,
) {
  const persons = useFamilyState(data, onMutate);

  const incomes = useIncomeState(data, onMutate);

  const decisions = useDecisionsState(
    data.decisions,
    data.households?.[0]?.situations?.current,
    data.loans,
  );

  const collaterals = useCollateralsState(data);

  const economyItems = useEconomyState(data);

  return {
    persons,
    incomes,
    decisions,
    collaterals,
    economyItems,
  };
}
