import { create } from 'zustand';
import { CollateralSlice, createCollateralSlice } from './collateralSlice';
import { TaskProps } from '../../types/TaskProps';
import { AssetSlice, createAssetSlice } from './assetSlice';
import { initProductStore } from './useProductStore';
import { DebtSlice, createDebtSlice } from './debtSlice';
import {
  BetjeningsevneSlice,
  createBetjeningsevneSlice,
} from './betjeningsevneSlice';

interface SituationState
  extends CollateralSlice,
    AssetSlice,
    DebtSlice,
    BetjeningsevneSlice {}

export const useSituationStore = create<SituationState>()((...a) => ({
  ...createCollateralSlice(...a),
  ...createAssetSlice(...a),
  ...createDebtSlice(...a),
  ...createBetjeningsevneSlice(...a),
  // TODO: create slices for incomes and persons.
}));

export const initSituationStore = async (
  state: TaskProps['flow']['data'],
  updateFn?: any,
) => {
  const store = useSituationStore.getState();
  if (updateFn) {
    store.setUpdateFn(updateFn);
    store.setUpdateFnDebt(updateFn);
  }
  const current = state?.households?.[0]?.situations?.current;
  const situation = current?.situation;
  const betjeningsevne = current?.betjeningsevne;

  if (betjeningsevne) {
    store.setBetjeningsevne(betjeningsevne);
  }

  const betjeningsevneShortTerm = current?.betjeningsevneShortTerm;

  if (betjeningsevneShortTerm) {
    store.setBetjeningsevneShortTerm(betjeningsevneShortTerm);
  }

  store.setCollateralAgreements(situation?.collaterals);
  store.setAssets(situation?.assets);
  store.setDebts(situation?.debts);

  const ltvProduct = current?.ltvProduct;
  const ltvProductShortTerm = current?.ltvProductShortTerm;
  initProductStore(ltvProduct, state.products, ltvProductShortTerm);
};
