import axios from 'axios';
import { Equity } from '../types/ProofOfFinance';

export async function checkIsReevaluating(flowId: string) {
  const { data } = await axios.get(
    `/api/flow/flows?flowId=${flowId}&view=reEvaluate`,
  );
  return data[0];
}

async function fetchMessageTask(flowId: string, taskType: string) {
  return await axios.get(
    `/api/flow/tasks?flowId=${flowId}&taskType=${taskType}&status=pending`,
  );
}

/**
 * Triggers a message task that will trigger the re-evaluation of betjeningsevne and decisions
 * @param taskId
 * @param situation the current situation
 * @param equityUtilized the equity utilized in the current situation
 * @returns
 */
export async function triggerMessageTask(
  flowId: string,
  situation: any,
  proofOfFinancingData?: {
    equityUtilized: Equity['equityUtilized'];
  },
) {
  const { data: tasks } = await fetchMessageTask(
    flowId,
    'evaluering-analyse-start',
  );

  const { taskId } = tasks[0];
  return await axios.post(`/api/flow/tasks/${taskId}/trigger`, {
    situation,
    proofOfFinancingData,
  });
}

export async function fetchUpdatedData(flowId: string) {
  const { data } = await checkIsReevaluating(flowId);
  return data;
}

export function debouncePromise<T>(
  func: () => Promise<T>,
  wait: number,
): () => Promise<T> {
  let timeout: NodeJS.Timeout | null = null;
  return function () {
    if (timeout) {
      clearTimeout(timeout);
    }
    return new Promise((resolve) => {
      timeout = setTimeout(async () => {
        timeout = null;
        resolve(await func());
      }, wait);
    });
  };
}
