import { Asset } from '../../../../components/_shared/AssetsCard/AssetsCard.types';
import { Debt } from '../../../../components/_shared/DebtCard/DebtCard.types';

export function calculateCollateralCoverage(
  collateralLoan: Debt,
  collateralObject: Asset,
  collateralValue: number,
): number {
  if (collateralObject.marketValue! <= 0) {
    return 0;
  }
  const loanBalance = collateralLoan.value ?? 0;
  const marketValueExemptPriority =
    (collateralObject.marketValue ?? 0) -
    (collateralObject.priorityMortgageAmount ?? 0);

  const usedCollateralValue = Math.min(
    marketValueExemptPriority,
    collateralValue,
  );

  return Math.round((usedCollateralValue / loanBalance) * 100);
}

export function calculateCollateralCoveragePure({
  loanBalance,
  marketValueExemptPriority,
  collateralValue,
}: {
  loanBalance: number;
  marketValueExemptPriority: number;
  collateralValue: number;
}): number {
  if (marketValueExemptPriority <= 0) {
    return 0;
  }

  const usedCollateralValue = Math.min(
    marketValueExemptPriority,
    collateralValue,
  );

  return Math.round((usedCollateralValue / loanBalance) * 100);
}
