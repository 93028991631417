import { TinglysingCard } from './TinglysingCard';
import { TinglysingViewProps } from './TinglysingCard.types';

export function TinglysingView({ tinglysing }: TinglysingViewProps) {
  // backwards compatibility
  if ('status' in tinglysing) {
    const tinglysingItem = tinglysing as any;
    return (
      <TinglysingCard
        tinglysing={tinglysingItem}
        key={tinglysingItem.status.id}
      />
    );
  }

  return (
    <>
      {Object.values(tinglysing).map((tinglysingItem, index) => {
        return (
          <TinglysingCard
            tinglysing={tinglysingItem}
            key={tinglysingItem.status.id}
          />
        );
      })}
    </>
  );
}
