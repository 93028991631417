import { TaskProps } from '../../../../../types/TaskProps';
import React from 'react';
import { EmptyState } from './EmptyState';
import { SuspenseWrapper } from '../../../SuspenseWrapper';
import { LazyAgreementRegister } from '../../../../../components/Insight/AgreementRegister/LazyAgreementRegister';

export default function AgreementRegisterTab(props: TaskProps) {
  const { flow, t } = props;
  const { data } = flow;

  const hasSituation =
    !!data?.households?.[0]?.situations?.current?.betjeningsevne;

  if (!hasSituation) {
    return <EmptyState t={t} tab={t('sikkerheter', 'Sikkerheter')} />;
  }

  return (
    <SuspenseWrapper>
      <LazyAgreementRegister {...props} />
    </SuspenseWrapper>
  );
}
