import axios from 'axios';

export async function fetchMessageTasks(
  flowId: string,
  taskType: string,
  status: string,
) {
  return await axios.get(
    `/api/flow/tasks?flowId=${flowId}&taskType=${taskType}&status=${status}`,
  );
}

/**
 * This function assumes that there is only one pending message task of the given type at any given time
 * @param flowId
 * @param taskType
 * @param requestBody the request body
 * @returns Promise
 */
export async function triggerPendingMessageTask(
  flowId: string,
  taskType: string,
  requestBody: any,
) {
  const { data: tasks } = await fetchMessageTasks(flowId, taskType, 'pending');
  const { taskId } = tasks[0];
  return await axios.post(`/api/flow/tasks/${taskId}/trigger`, requestBody);
}
