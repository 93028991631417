interface TextInputProps {
  initialValue: any;
  onUpdate: any;
  id: string;
  className?: string;
  context?: string;
  name: string;
  readOnly?: boolean;
  maxLength?: number;
}

export function TextInput({
  initialValue,
  onUpdate,
  id,
  className,
  context = '',
  name,
  maxLength,
  readOnly = false,
}: TextInputProps) {
  const handleInputChange = (value: any) => {
    onUpdate({
      id,
      value: value,
      initialValue,
      context,
    });
  };

  return (
    <input
      className={`focus:outline-none float-right justify-self-end border border-misc-ui-separator bg-input p-2.5 focus:border-transparent focus:ring-2 ${
        className ?? ''
      }`}
      id={id}
      name={name}
      onChange={(e) => handleInputChange(e.target.value)}
      type={'text'}
      maxLength={maxLength}
      value={initialValue}
      readOnly={readOnly}
    />
  );
}
