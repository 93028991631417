import WarningIcon from '../../../../../components/icons/WarningIcon';
import { useFlowContext } from '../../../../../hooks/useFlowContext';
import { formatAccountNumber } from '../../../../../utils/formatAccountNumber';
import { ResultData } from '../../../../../types/ResultData';

type Props = {
  data?: ResultData[];
};

export function KARValidationView({ data }: Props) {
  const { t } = useFlowContext();

  if (!data || data.length === 0) {
    return (
      <div className="flex items-center gap-2 rounded-md bg-negative-200 p-4 align-middle font-bold shadow">
        <WarningIcon color="#2B2B2B" />
        <p className="text-basic-ink">
          {t(
            'no-kar-validation',
            'Kunne ikke utføre en validering mot KAR. Kontonummer må kontrolleres manuelt.',
          )}
        </p>
      </div>
    );
  }

  const oneOfApplicantsOwnsAccount = data?.some(
    (elem: any) => elem?.resultCode === '01',
  );

  if (oneOfApplicantsOwnsAccount) {
    return null;
  }

  return (
    <div className="flex flex-col gap-2">
      {data.map((elem: ResultData, index: number) => {
        return (
          <div
            key={`elem.accountNumber-${index}`}
            className="flex items-center gap-2 rounded-md bg-caution-100 p-4 align-middle font-bold shadow"
          >
            <WarningIcon color="#946300" />
            <p className="text-caution-800">
              {t('account-nr', 'Kontonr')}:{' '}
              {formatAccountNumber(elem?.accountNumber)} - {elem?.resultText} -{' '}
              {t(elem?.role ?? 'applicant', 'Låntaker')}
            </p>
          </div>
        );
      })}
    </div>
  );
}
