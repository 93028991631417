import { Layout } from '../../../components/Layout/Layout';
import { DecisionPanel } from '../../../components/Layout/DecisionPanel';
import { TaskProps } from '../../../types/TaskProps';
import { Decision } from './Decision';
import { DocumentIcon } from '../../../components/icons/DocumentIcon';
import { FlowContext } from '../../../hooks/useFlowContext';

export function AskForDocumentation(props: TaskProps) {
  return (
    <FlowContext.Provider value={props}>
      <Layout>
        <div className="flex flex-col gap-4 justify-center items-center">
          <DocumentIcon />
          <h1 className="text-2xl text-black">Ingen dokumenter</h1>
        </div>
        <DecisionPanel>
          <Decision {...props} />
        </DecisionPanel>
      </Layout>
    </FlowContext.Provider>
  );
}
