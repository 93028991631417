export interface IncomeOwner {
  id: string | number;
  name: string;
  context: string;
  workSituation: string;
}

export type WorkPlace = {
  name: string;
  orgNumber?: string;
};
export interface Income {
  id: string | number;
  ownerMeta: IncomeOwner;
  workSituation: string;
  workPlace: WorkPlace;
  incomeSbl: number;
  incomeStated: number;
  value: number; //verdien som skal til betjeningsevne
  type: IncomeType;
  owner?: string;
}

export interface IncomeCardProps {
  incomes: Income[];
  editable?: boolean;
  onDelete?: any;
  onUpdate?: any;
  onAdd?: any;
}

export interface IndexedIncome {
  incomes: Income[];
  name?: any;
  id: string;
  workSituation: string;
}

export interface IndexedIncomes {
  mainApplicant: IndexedIncome;
  coApplicant?: IndexedIncome;
}

export enum IncomeType {
  OTHER_TAXABLE = 'OTHER.TAXABLE',
  SALARY = 'SALARY',
  OTHER = 'OTHER',
  CHILD_SUPPORT = 'CHILD.SUPPORT',
  BUSINESS = 'BUSINESS',
  ALLOWANCE = 'ALLOWANCE',
  PENSION_TYPE_OLD_AGE = 'PENSION.TYPE.OLD.AGE',
  PENSION_TYPE_RETIREMENT_OCCUPATION = 'PENSION.TYPE.RETIREMENT.OCCUPATION',
  PENSION_TYPE_DISABILITY_OCCUPATION = 'PENSION.TYPE.DISABILITY.OCCUPATION',
  PENSION_TYPE_DISABILITY_NATIONAL = 'PENSION.TYPE.DISABILITY.NATIONAL',
}
