import { Switch } from '@headlessui/react';
import { useFlowContext } from '../../../hooks/useFlowContext';
import { useFormConnect } from '../../../hooks/useFormConnect';

export function SwitchSelect({ id, label }: { id: string; label: string }) {
  const { t } = useFlowContext();
  const { value, onChange, name } = useFormConnect(id);

  return (
    <div className="w-full flex flex-row justify-between pb-4">
      <span className="text-sm">{t(label)}</span>
      <Switch
        id={name}
        name={name}
        checked={value}
        onChange={onChange}
        className={`${
          value ? 'bg-primary-500' : 'bg-neutral-500'
        } relative inline-flex h-6 w-11 items-center rounded-full`}
      >
        <span
          className={`${
            value ? 'translate-x-6' : 'translate-x-1'
          } inline-block h-4 w-4 transform rounded-full bg-white transition`}
        />
      </Switch>
    </div>
  );
}
