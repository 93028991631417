import React from 'react';
import { Owner } from '../../../customer/tasks/ManuellBehandling/utils/borrowersHelper';
import { Card } from '../Card';
import { DataTable } from '../DataTable';
import { formatMoney } from '../../../utils/formatMoney';
import { LoanTypeCell } from './LoanTypeCell';
import { TrendDownIcon } from '../../icons/TrendDown';
import { useFlowContext } from '../../../hooks/useFlowContext';
import { DebtCardProps, Debt, UnsecuredDebtType } from './DebtCard.types';
import { DurationDisplay } from '../DurationDisplay';

export function DebtCard({ debts }: DebtCardProps) {
  const { t } = useFlowContext();

  const columns = React.useMemo(
    () => [
      {
        Header: t('type'),
        accessor: 'loanType',
        columnType: 'left',
        Cell: ({ row }: any) => (
          <LoanTypeCell className="w-44" debt={row.original} />
        ),
      },
      {
        Header: t('nameOfBank'),
        columnType: 'left',
        Cell: ({ row }: any) => (
          <span className="ml-2">{row.original.nameOfBank}</span>
        ),
      },
      {
        Header: t('loan-owners'),
        accessor: 'applicant',
        columnType: 'left',
        Cell: ({ row }: any) => (
          <div>
            {(row?.original?.loanOwners || [])
              .map((owner: Owner) => owner.name)
              .join(', ')}
          </div>
        ),
      },
      {
        Header: t('used-credit'),
        accessor: 'usedCredit',
        columnType: 'right',
        Cell: ({ cell, row }: any) => (
          <>
            {isGjeldsregisterLoan(row?.original?.loanProduct)
              ? row.original?.isNew
                ? '-'
                : formatMoney(cell.value ?? 0)
              : ''}
          </>
        ),
      },
      {
        Header: t('grace-period'),
        accessor: 'gracePeriodDuration',
        columnType: 'right',
        Cell: ({ cell }: any) => {
          if (cell.value) {
            return <DurationDisplay durationMonths={cell.value as number} />;
          } else {
            return <></>;
          }
        },
      },
      {
        Header: t('running-time'),
        accessor: 'durationMonths',
        columnType: 'right',
        Cell: ({ cell, row }: any) => {
          if (row?.original?.type !== 'CREDIT') {
            return <DurationDisplay durationMonths={cell.value as number} />;
          }
          return <></>;
        },
      },
      {
        Header: t('interest-rate'),
        accessor: 'nominalInterestRate',
        columnType: 'right',
        Cell: ({ cell, row }: any) => (
          <span className={row.original.toBeRedeemed ? 'text-neutral-500' : ''}>
            {(cell.value ?? 0).toFixed(2)}
            <span className="text-misc-placeholder">{' %'}</span>
          </span>
        ),
      },
      {
        Header: t('balance'),
        accessor: 'value',
        columnType: 'right',
        Cell: ({ cell, row }: any) => (
          <span
            className={row?.original?.toBeRedeemed ? 'text-neutral-500' : ''}
          >
            {formatMoney(cell.value)}
          </span>
        ),
      },
    ],
    [t],
  );

  function findTotalDebt(debts: Debt[]) {
    return (debts ?? [])
      .filter((debt) => !debt?.toBeRedeemed)
      .reduce((acc, debt) => acc + Number(debt?.value), 0);
  }

  return (
    <Card>
      <Card.Header className="justify-between bg-negative-50 text-negative-800">
        <div className="flex gap-3">
          <TrendDownIcon />
          <Card.HeaderTitle>{t('debt')}</Card.HeaderTitle>
        </div>
        <div className="flex items-center gap-1">
          <span>{formatMoney(findTotalDebt(debts))}</span>
          <span className="text-negative-200">NOK</span>
        </div>
      </Card.Header>
      <Card.Content>
        <DataTable data={debts ?? []} columns={columns} />
      </Card.Content>
    </Card>
  );
}

const isGjeldsregisterLoan = (loanType: string) =>
  Object.keys(UnsecuredDebtType).includes(loanType ?? '');
