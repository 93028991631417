import { useMemo } from 'react';
import { ChildIcon } from '../../icons/ChildIcon';
import { FamilyIcon } from '../../icons/FamilyIcon';
import { PersonIcon } from '../../icons/PersonIcon';
import { Card } from '../Card';
import { DataTable } from '../DataTable';
import { useFlowContext } from '../../../hooks/useFlowContext';
import { FamilyCardProps } from './FamilyCard.types';

export function FamilyCard({ persons }: FamilyCardProps) {
  const { t } = useFlowContext();

  const columns = useMemo(
    () => [
      {
        Header: t('name'),
        accessor: 'firstName',
        columnType: 'left',
        Cell: (props: any) => NameCell(props.row.values.age, props.cell.value),
      },
      {
        Header: 'Status',
        accessor: 'status',
        columnType: 'left',
        Cell: ({ cell }) => <div>{t(cell.value)}</div>,
      },
      {
        Header: () => t('age'),
        accessor: 'age',
        columnType: 'right',
        Cell: ({ cell }: any) => cell.value,
      },
      {
        Header: () => t('role'),
        accessor: 'role',
        columnType: 'right',
        Cell: ({ cell }) => t(cell.value),
      },
    ],
    [t],
  );

  return (
    <Card>
      <Card.Header className="justify-between bg-caution-100 text-caution-800">
        <div className="flex items-center gap-3">
          <FamilyIcon />
          <Card.HeaderTitle>{t('family')}</Card.HeaderTitle>
        </div>
      </Card.Header>
      <Card.Content>
        <DataTable data={persons} columns={columns} />
      </Card.Content>
    </Card>
  );
}

function NameCell(age: number, data: string) {
  return (
    <div className="flex gap-2">
      {age < 18 ? <ChildIcon /> : <PersonIcon />} {data}
    </div>
  );
}
