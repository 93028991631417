export function fetchErrorMessage(
  hasAccountError: boolean,
  hasTotalAmountError: boolean,
  t: any,
) {
  const errorArray = [
    `${hasAccountError ? t('inputted-account-number') : ''}`,
    `${hasTotalAmountError ? t('negative-total-amount') : ''}`,
  ].filter(Boolean);

  if (errorArray.length === 0) return '';

  return `${t('an-error-occurred-disbursement-control')} ${errorArray
    ?.map((elem: any) => elem)
    .join(t('and-separator'))}.`;
}
