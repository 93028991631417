import { DownArrowIcon } from '../../icons/DownArrowIcon';
import { Card } from '../Card';
import { useFlowContext } from '../../../hooks/useFlowContext';
import PaymentPlanYear from './PaymentPlanYear';
export default function PaymentPlan({ paymentPlan }: any) {
  const { t } = useFlowContext();
  const effRate = paymentPlan?.effectiveInterestRate * 100;

  return (
    <Card>
      <Card.Header className=" bg-red-100 font-bold text-red-900">
        <DownArrowIcon />
        <Card.HeaderTitle>{t('paymentPlan')}</Card.HeaderTitle>
      </Card.Header>
      <Card.Content verticalPadding={false} horizontalPadding={false}>
        {paymentPlan.yearPaymentPlan.map((year: any, index: any) => {
          return (
            <div key={index} className="border-b-2 last:border-b-0">
              <PaymentPlanYear data={year} effRate={effRate} />
            </div>
          );
        })}
      </Card.Content>
    </Card>
  );
}
