import { TaskProps } from '../../../types/TaskProps';
import { FormProvider, useForm } from 'react-hook-form';
import { TextArea } from '../../../components/_shared/TextArea';
import { RadioButtonList } from '../../../components/_shared/RadioButtonList';
import { useFlowContext } from '../../../hooks/useFlowContext';
import { DecisionButtonGroup } from '../../components/DecisionButtonGroup';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { AdditionalValuesView } from '../../components/common/AdditionalValuesView';

export function Decision({ save, complete }: TaskProps) {
  const { t, task } = useFlowContext();
  const method = useForm({
    defaultValues: getDefaultValues(task),
    resolver: zodResolver(Decision.validationSchema),
  });
  const { handleSubmit } = method;

  const onSubmit = (data: any) => {
    complete(data);
  };

  const decisionOptions = [
    { value: 'approve', label: t('changedInCore') },
    { value: 'decline', label: t('declineChange') },
  ];

  return (
    <FormProvider {...method}>
      <form className="grid gap-2 py-8" onSubmit={handleSubmit(onSubmit)}>
        <AdditionalValuesView />
        <h2 className="text-sm font-semibold text-black">{t('decision')}</h2>
        <div className="grid gap-8">
          <RadioButtonList id="decision" options={decisionOptions} />
          <TextArea id="internalComment" label={t('internalComment')} />
          <TextArea id="mailToCustomer" label={t('mailToCustomer')} />
          <DecisionButtonGroup save={save} />
        </div>
      </form>
    </FormProvider>
  );
}

function getDefaultValues(task: TaskProps['task']): any {
  return {
    decision: task.data?.decision ?? undefined,
    internalComment: task.data?.internalComment ?? '',
    mailToCustomer: task.data?.mailToCustomer ?? undefined,
  };
}

Decision.validationSchema = z.object({
  decision: z.enum(['approve', 'decline'], {
    errorMap: (issue, ctx) => ({ message: 'Beslutning påkrevd' }),
  }),
  mailToCustomer: z
    .string({ required_error: 'E-post til kunde er påkrevd' })
    .min(1, { message: 'E-post til kunde er påkrevd' }),
  internalComment: z.string().optional(),
});
