import React from 'react';
import { TaskProps } from '../../../../../types/TaskProps';
import { Stakeholder } from '../../../../../types/Stakeholder';
import { FlowContext } from '../../../../../hooks/useFlowContext';
import EquationCard from '../../../../../components/_shared/CreditCheck/EquationCard';
import CommercialInterests from '../../../../../components/_shared/CreditCheck/CommercialInterests';
import PaymentNotes from '../../../../../components/_shared/CreditCheck/PaymentNotes';
import { EmptyState } from './EmptyState';

export default function CreditCheckTab(props: TaskProps) {
  const { flow, t } = props;
  const { data } = flow;
  const stakeholders: Stakeholder[] = data?.stakeholders;
  const hasSituation =
    !!data?.households?.[0]?.situations?.current?.betjeningsevne;

  if (!hasSituation) {
    return <EmptyState t={t} tab={t('credit-check', 'Kredittsjekk')} />;
  }

  return (
    <FlowContext.Provider value={props}>
      <div className="grid w-full gap-6">
        {stakeholders?.map((stakeholder) => (
          <div key={stakeholder?.firstName} className="flex flex-col gap-5">
            <EquationCard stakeholder={stakeholder} />
            <CommercialInterests stakeholder={stakeholder} />
            <PaymentNotes stakeholder={stakeholder} />
          </div>
        ))}
      </div>
    </FlowContext.Provider>
  );
}
