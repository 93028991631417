import { Asset, AssetStatus, AssetType, HousingType } from './AssetsCard.types';
import { LabelPill } from '../Fields/LabelPill';
import { CarIcon } from '../../icons/CarIcon';
import { HouseIcon } from '../../icons/HouseIcon';
import { useFlowContext } from '../../../hooks/useFlowContext';
import { isSalesObject } from '../../../utils/isSalesObject';

interface AssetTypeCellProps {
  asset: Asset;
  className?: string;
}
export function AssetTypeCell({ asset, className = '' }: AssetTypeCellProps) {
  return (
    <div
      className={`flex w-full items-center gap-2 justify-between ${
        className ?? ''
      }`}
    >
      <div className="flex items-center gap-2">
        <ResolvedAssetType asset={asset} />
      </div>
      <ResolvedLabel asset={asset} />
    </div>
  );
}

function ResolvedAssetType({ asset }: { asset: Asset }) {
  const { t } = useFlowContext();
  const resolvedType =
    asset.housingType === HousingType.CABIN ? asset.housingType : asset.type;

  return (
    <>
      <span>
        {resolvedType === AssetType.VEHICLE ? (
          <CarIcon color={'#3158B2'} />
        ) : (
          <HouseIcon color={'#3158B2'} />
        )}
      </span>
      <span>{t(resolvedType)}</span>
    </>
  );
}

function ResolvedLabel({ asset }: { asset: Asset }) {
  if (asset.assetStatus === AssetStatus.TO_BE_PURCHASED) {
    return <LabelPill value={'purchaseObject'} />;
  }
  if (isSalesObject(asset)) {
    return <LabelPill value={'salesObject'} color="bg-negative-600" />;
  }
  if (asset.isSecurity) {
    return <LabelPill value={'security'} />;
  }
  return null;
}
