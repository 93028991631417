import type { TaskProps } from '../../../types/TaskProps';
import { FormProvider, useForm } from 'react-hook-form';
import { TextArea } from '../../../components/_shared/TextArea';
import { DecisionButtonGroup } from '../../components/DecisionButtonGroup';
import { useFlowContext } from '../../../hooks/useFlowContext';
import { RadioButtonList } from '../../../components/_shared/RadioButtonList';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { DecisionError } from '../../components/DecisionError';
import { AdditionalValuesView } from '../../components/common/AdditionalValuesView';

export function BehandleForesporselDecision({ save, complete }: TaskProps) {
  const { t, task } = useFlowContext();
  const method = useForm({
    defaultValues: getDefaultValues(task),
    resolver: zodResolver(BehandleForesporselDecision.validationSchema),
  });
  const { handleSubmit, formState, watch } = method;
  const { errors } = formState;
  const decisionOptions = [
    { value: 'handleManually', label: t('contactManually') },
    { value: 'sendEmail', label: t('sendEmail') },
  ];

  const onSubmit = (data: any) => {
    const sendEmail = data.sendEmail === 'sendEmail';
    complete({
      ...data,
      sendEmail,
    });
  };
  const showEmail = watch('sendEmail') === 'sendEmail';

  return (
    <FormProvider {...method}>
      <form className="grid gap-2 py-8" onSubmit={handleSubmit(onSubmit)}>
        <AdditionalValuesView />
        <h2 className="text-sm font-semibold text-black">{t('decision')}</h2>
        <div className="grid gap-6">
          <RadioButtonList id="sendEmail" options={decisionOptions} />
          {showEmail && <TextArea id="email" label={t('answerEmail')} />}
          <TextArea id="internalComment" label={t('internalComment')} />
          <DecisionButtonGroup save={save} />
        </div>
        {Object.keys(errors).length > 0 && (
          <DecisionError text={t('decisionRequired')} />
        )}
      </form>
    </FormProvider>
  );
}

BehandleForesporselDecision.validationSchema = z
  .object({
    sendEmail: z.string({
      required_error: 'Avgjørelse er påkrevd',
      invalid_type_error: 'Avgjørelse er påkrevd',
    }),
    internalComment: z.string().optional(),
    email: z.string().optional(),
  })
  .refine(({ sendEmail, email }) => {
    if (sendEmail === 'sendEmail' && !email) {
      return {
        path: ['email'],
        message: 'E-post er påkrevd',
      };
    }
    return true;
  });

function getDefaultValues(task: TaskProps['task']): any {
  return {
    sendEmail: task?.data?.sendEmail ?? '',
    internalComment: task?.data?.internalComment ?? '',
    email: task?.data?.email ?? '',
  };
}
