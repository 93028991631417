export interface Mask {
  parser: (string: string) => string;
  formatter: (string: string) => string;
}

// Currency mask
const integerAccept = /\d+/g;
const currencyParser = (str: string) =>
  (str?.match(integerAccept) || []).join('');

const currencyMask = {
  parser: currencyParser,
  formatter: (value: number | string | null): string => {
    value = value === null ? '' : value?.toString();

    const parsed = currencyParser(value);
    const number = Number.parseInt(parsed, 10);
    if (Number.isNaN(number)) {
      return '0';
    }
    return number.toLocaleString('no');
  },
};

// Account number mask
const accountNumberAccept = /\d+/g;
const accountNumberParser = (str: string = '') => {
  str = str === null ? '' : str;
  return (str.match(accountNumberAccept) || []).join('');
};

const accountNumberMask = {
  parser: accountNumberParser,
  formatter: (value: string): string => {
    const parsed = accountNumberParser(value);
    const chars = parsed.split('');
    return chars
      .reduce(
        (r, v, index) =>
          index === 4 || index === 6 ? `${r} ${v}` : `${r}${v}`,
        '',
      )
      .substring(0, 13);
  },
};

export { currencyMask, accountNumberMask };
