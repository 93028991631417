export function HouseIcon({ color = '#AD362B' }) {
  return (
    <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g
        clipPath="url(#a)"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M15.5 7.5 8 .5l-7.5 7M2.5 8.5v7h11v-7" />
        <path d="M10.94 7.53A1.95 1.95 0 0 0 8 7.846a1.95 1.95 0 0 0-2.941-.316 1.813 1.813 0 0 0 0 2.618L7.999 13l2.942-2.852a1.812 1.812 0 0 0 0-2.618v0Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
