import { StateCreator } from 'zustand';
import {
  Debt,
  LoanType,
} from '../../components/_shared/DebtCard/DebtCard.types';
import { nanoid } from 'nanoid';

interface StencilValues {
  durationMonthsCarLoan: number;
  durationMonthsMortgage: number;
  durationMonthsStudentLoan: number;
  nominalInterestRateCarLoan: number;
  nominalInterestRateMortgage: number;
  nominalInterestRateStudentLoan: number;
}
interface ConfiguredValuesType {
  nominalInterestRates: {
    carLoan: number;
    mortgage: number;
    studentLoan: number;
  };
}

const LoanDurationDefaultValues = {
  durationMonthsCarLoan: 10 * 12,
  durationMonthsMortgage: 30 * 12,
  durationMonthsStudentLoan: 20 * 12,
};

const NominalInterestRateDefaultValues = {
  carLoan: 0.07,
  mortgage: 0.0372,
  studentLoan: 0.02979,
};

const StencilDefaultValues = {
  nominalInterestRateCarLoan: 0.07,
  nominalInterestRateMortgage: 0.0372,
  nominalInterestRateStudentLoan: 0.02979,
};

export interface DebtSlice {
  debts: Debt[];
  addDebt: (
    type: LoanType,
    configuredValues?: ConfiguredValuesType,
    stencilValues?: StencilValues,
  ) => void;
  deleteDebt: (debt: Debt) => void;
  updateDebt: (debt: Debt) => void;
  setDebts: (debts: Debt[]) => void;
  stencilValues: StencilValues;
  setStencilValues: (values: StencilValues) => void;
  updateFnDebt?: () => void;
  setUpdateFnDebt: (fn: () => void) => void;
  configuredValues: ConfiguredValuesType;
}

export const createDebtSlice: StateCreator<DebtSlice, [], [], DebtSlice> = (
  set,
  get,
) => ({
  debts: [],
  configuredValues: {
    nominalInterestRates: {
      ...NominalInterestRateDefaultValues,
    },
  },
  stencilValues: {
    ...LoanDurationDefaultValues,
    ...StencilDefaultValues,
  },
  addDebt: (
    type: LoanType,
    configuredValues?: ConfiguredValuesType,
    stencilValues?: StencilValues,
  ) => {
    const nominalInterestRateMortgage =
      configuredValues?.nominalInterestRates.mortgage ??
      stencilValues?.nominalInterestRateMortgage ??
      get().stencilValues.nominalInterestRateMortgage;
    const durationMonthsMortgage =
      stencilValues?.durationMonthsMortgage ??
      get().stencilValues.durationMonthsMortgage;
    const durationMonthsCarLoan =
      stencilValues?.durationMonthsCarLoan ??
      get().stencilValues.durationMonthsCarLoan;
    const nominalInterestRateCarLoan =
      configuredValues?.nominalInterestRates.carLoan ??
      stencilValues?.nominalInterestRateCarLoan ??
      get().stencilValues.nominalInterestRateCarLoan;

    const debt: Debt = {
      id: nanoid(20),
      type: 'INSTALLMENT',
      loanOwners: [], // TODO: set loanowners from state.stakeholders
      loanProduct: type,
      nominalInterestRate: nominalInterestRateMortgage * 100,
      isNew: true,
      loanType: type,
      value: 0,
    };

    switch (type) {
      case LoanType.HOUSE_MORTGAGE:
        debt.durationMonths = durationMonthsMortgage;
        debt.nominalInterestRate = nominalInterestRateMortgage * 100;
        debt.balance = 0;
        break;
      case LoanType.CAR_BOAT_MC:
        debt.durationMonths = durationMonthsCarLoan;
        debt.nominalInterestRate = nominalInterestRateCarLoan * 100;
        debt.balance = 0;
        break;
      case LoanType.CREDIT:
        debt.type = LoanType.CREDIT;
        break;
    }
    set({ debts: [...get().debts, debt] });
    get().updateFnDebt?.();
  },
  deleteDebt: (debt: Debt) => {
    const debts = get().debts.filter((d) => d.id !== debt.id);
    set({ debts });
    get().updateFnDebt?.();
  },
  updateDebt: (debt: Debt) => {
    const debts = get().debts.map((d) => (d.id === debt.id ? debt : d));
    set({ debts });
    get().updateFnDebt?.();
  },
  setDebts: (debts: Debt[]) => {
    const pruned = debts?.filter((debt) => !isCoopDebt(debt));
    set({ debts: pruned });
  },
  setStencilValues: (stencilValues: StencilValues) => {
    set({ stencilValues });
  },
  setUpdateFnDebt: (fn: () => void) => {
    set({ updateFnDebt: fn });
  },
});

const isCoopDebt = (debt: Debt): boolean =>
  debt.loanProduct === LoanType.COOP_DEBT;
