import type { ListItemType } from '../../../../components/_shared/ListItem';
import { useFlowContext } from '../../../../hooks/useFlowContext';
import type { Stakeholder } from '../../../../types/Stakeholder';
import WarningCard from './WarningCard';

export default function BirthCountry({
  stakeholder,
}: {
  stakeholder: Stakeholder;
}) {
  const { t } = useFlowContext();
  const title = t('decision-kyc-born-outside');
  const declaration = stakeholder?.declaration?.countryOfBirthDeclaration;
  const shouldRender = declaration?.isBornOutsideOfNorway;

  if (!shouldRender) return null;

  const country = declaration?.country?.name as string;
  const items: ListItemType[] = [
    {
      label: t('country'),
      value: country,
    },
  ];
  return <WarningCard title={title} items={items} />;
}
