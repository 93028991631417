import { z } from 'zod';
import { DecisionPanel } from '../../../components/Layout/DecisionPanel';
import { Layout } from '../../../components/Layout/Layout';
import { Stakeholders } from '../../../components/Stakeholders';
import { ListItemType } from '../../../components/_shared/ListItem';
import { PurchaseObjectSummary } from '../../../components/_shared/PurchaseObjectSummary';
import { FlowContext } from '../../../hooks/useFlowContext';
import { Purchase } from '../../../types/ProofOfFinance';
import { TaskProps } from '../../../types/TaskProps';
import { formatMoney } from '../../../utils/formatMoney';
import { Decision } from './Decision';
import {
  Settlement,
  SettlementInput,
  SettlementSchema,
} from './SettlementInput';
import { useForm, FormProvider } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { PropertyInfoSchema, PropertyInfo } from './SearchInputProperty';
import { PropertyDetails } from './PropertyDetails';
import { AdditionalValuesView } from '../../components/common/AdditionalValuesView';
import FilePreviewTabs from '../../components/FilePreviewTabs';

export interface RegisterContractDetailsInput {
  propertyInfo: PropertyInfo;
  hasCompletePropertyKey: boolean;
  settlement: Settlement;
}

function getDefaultValues(
  savedData: TaskProps['task']['data'],
  context: TaskProps['task']['context'],
) {
  const propertyInfo = savedData.propertyInfo ??
    context.propertyInfo ?? {
      key: undefined,
      type: undefined,
    };

  const hasCompletePropertyKey =
    savedData.hasCompletePropertyKey ?? context.hasCompletePropertyKey ?? true;

  const settlement = savedData.settlement ??
    context.settlement ?? {
      flow: 'DIGITAL',
    };

  const internalComment = savedData.internalComment ?? undefined;

  const kidOrMessageSelect =
    savedData.kidOrMessageSelect ?? context.kidOrMessageSelect ?? undefined;

  return {
    propertyInfo,
    hasCompletePropertyKey,
    settlement,
    internalComment,
    kidOrMessageSelect,
  };
}

export function RegisterContractDetails(props: TaskProps) {
  const { task, flow } = props;
  const { purchase } = props.flow.data?.proofOfFinance;

  const method = useForm<RegisterContractDetailsInput>({
    defaultValues: getDefaultValues(task.data, task.context),
    resolver: zodResolver(RegisterContractDetails.validationSchema),
  });

  const { handleSubmit } = method;

  const onSubmit = handleSubmit((data) => {
    const { propertyInfo, settlement } = data;
    const submitData = {
      ...data,
      propertyInfo: {
        ...propertyInfo,
        key: propertyInfo.key?.replaceAll('/', '-'),
        propertyType: propertyInfo.type,
        propertyKeyAvailable: data.hasCompletePropertyKey,
      },
      settlement,
    };
    props.complete(submitData);
  });

  const attachments = task.context?.attachments ?? [];
  return (
    <FlowContext.Provider value={props}>
      <Layout>
        <FilePreviewTabs attachments={attachments} props={props} />
        <div>
          <FormProvider {...method}>
            <form onSubmit={onSubmit}>
              <DecisionPanel>
                <div className="grid gridcols-1 gap-4">
                  <Stakeholders stakeholders={flow.data.stakeholders} />
                  <AdditionalValuesView />
                  <PurchaseObjectSummary
                    items={mapPurchaseObjectSummary(purchase)}
                  />
                  <PropertyDetails />
                  <SettlementInput />
                  <Decision {...props} />
                </div>
              </DecisionPanel>
            </form>
          </FormProvider>
        </div>
      </Layout>
    </FlowContext.Provider>
  );
}

function mapPurchaseObjectSummary(purchaseObject: Purchase): ListItemType[] {
  return [
    {
      label: 'propertyType',
      value:
        purchaseObject?.housingType === 'cadastre' ? 'Selveier' : 'Borettslag',
    },
    {
      label: 'purchaseAmount',
      value: formatMoney(purchaseObject?.purchaseAmount),
    },
    {
      label: 'lanebelop',
      value: formatMoney(purchaseObject?.loanAmount ?? 0),
    },
    ...(purchaseObject?.housingType === 'SHAREDHOUSE'
      ? [
          {
            label: 'coopDebt',
            value: formatMoney(purchaseObject?.coopDebt ?? 0),
          },
        ]
      : []),

    {
      label: 'takeOverDate',
      value: new Date(purchaseObject?.takeoverDate)?.toLocaleDateString(),
    },
  ];
}

RegisterContractDetails.validationSchema = z
  .object({
    internalComment: z.string().optional(),
    propertyInfo: PropertyInfoSchema,
    settlement: SettlementSchema,
    hasCompletePropertyKey: z.boolean(),
  })
  .superRefine((input, ctx) => {
    if (
      !input.hasCompletePropertyKey &&
      input.settlement?.settlementFlow === 'DIGITAL'
    ) {
      ctx.addIssue({
        code: 'custom',
        message: 'contractDetailsInvalidCombination',
        path: ['settlement.settlementFlow'],
      });
    }
  });
