export function WalletIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
      <g clipPath="url(#a)">
        <path fill="#474300" d="M6 3H3a2 2 0 1 0 0 4" />
        <path
          stroke="#474300"
          strokeLinecap="square"
          strokeMiterlimit="10"
          strokeWidth="2"
          d="M6 3H3a2 2 0 1 0 0 4"
        />
        <path fill="#474300" d="M6 7V1h14v6" />
        <path
          stroke="#474300"
          strokeLinecap="square"
          strokeMiterlimit="10"
          strokeWidth="2"
          d="M6 7V1h14v6"
        />
        <path
          stroke="#474300"
          strokeLinecap="square"
          strokeMiterlimit="10"
          strokeWidth="2"
          d="M23 7H3a2 2 0 0 1-2-2v15a3 3 0 0 0 3 3h19V7Z"
        />
        <path
          stroke="#474300"
          strokeLinecap="square"
          strokeMiterlimit="10"
          strokeWidth="2"
          d="M17 17a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
