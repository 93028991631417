import { SvgIconProps } from './SvgIcon.types';

export default function WarningIcon({
  size = 24,
  color = '#AD362B',
}: SvgIconProps) {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
    >
      <g clipPath="url(#a)" fill={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.002 1.997a1.093 1.093 0 0 0-.966.582l-8.908 16.84a1.082 1.082 0 0 0 .428 1.443c.162.091.346.139.532.138h17.827a1.08 1.08 0 0 0 .962-1.58L12.968 2.578a1.093 1.093 0 0 0-.966-.582ZM1.243 18.953l-.884-.468L9.27 1.643a3.093 3.093 0 0 1 5.467 0l8.91 16.844A3.079 3.079 0 0 1 20.906 23H3.093"
        />
        <path fillRule="evenodd" clipRule="evenodd" d="M13 7v8h-2V7h2Z" />
        <path d="M12 19a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
