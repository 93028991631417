import { Asset } from '../../../../components/_shared/AssetsCard/AssetsCard.types';

export function findCollateralObject(assets: Asset[], securities: any) {
  return (assets || []).find((asset) => isCollateralObject(asset, securities));
}

function isCollateralObject(asset: Asset, securities: any) {
  const security = (securities || []).find(
    (s: any) => s.property?.key === asset.id,
  );
  return security;
}
