import { useFlowContext } from '../../hooks/useFlowContext';
import { useFormConnect } from '../../hooks/useFormConnect';

export function Select({
  label,
  id,
  options,
}: {
  label: string;
  id: string;
  options: { value: string; label: string }[];
}) {
  const { name, value, onChange, error } = useFormConnect(id);
  const { t } = useFlowContext();
  return (
    <div>
      <label className="text-sm font-semibold text-black" htmlFor={name}>
        {label}
      </label>
      <select
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        className="focus:outline-none w-full cursor-pointer border border-misc-ui-separator bg-input p-2.5 focus:border-transparent focus:ring-2"
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {error && <span className="block text-red-500">{t(error)}</span>}
    </div>
  );
}
