import { Card } from '../../../../../components/_shared/Card';
import { TrendDownIcon } from '../../../../../components/icons/TrendDown';
import { formatMoney } from '../../../../../utils/formatMoney';
import { DisbursementControlTable } from '../../../../../components/_shared/DisbursementControlTable/DisbursementControlTable';
import { ControlCardTableHeader } from '../../../../tasks/FireOyne/ControlCard/ControlCard.TableHeader';
import { ControlCardTableBody } from '../../../../tasks/FireOyne/ControlCard/ControlCard.TableBody';
import { Loan } from '../../../../../types/DisbursementTypes';
import { useFlowContext } from '../../../../../hooks/useFlowContext';
import React from 'react';
import { EmptyState } from './EmptyState';
import { formatDate } from '../../../../../utils/formatDate';
import { ListItem } from '../../../../../components/_shared/ListItem';
import { constructChecklistObjectToArray } from '../../../../tasks/FireOyne/CheckListView';
import { KARValidationView } from '../../../../tasks/GjennomforUtbetalingsKontroll/DisbursementControlCard/Components/KARValidationView';
import { ResultData } from '../../../../../types/ResultData';

type PaymentPictureTabProps = {
  loans: Loan[];
  id: string;
};

export function PaymentPictureTab({ loans, id }: PaymentPictureTabProps) {
  const { t } = useFlowContext();

  const loanValue = loans
    ? loans?.find((elem: Loan) => elem.id === id)
    : {
        id: `no-loan-state`,
        product: '',
        amount: 0,
        disbursements: [],
        payout: { amount: 0, accountNumber: '' },
        fees: [],
      };

  return (
    <Card>
      <Card.Header className="justify-between bg-tertiary-50  text-tertiary-800">
        <div className="flex items-center gap-3">
          <TrendDownIcon />
          <Card.HeaderTitle>
            {t('disbursements-heading', 'Utbetalinger')}
          </Card.HeaderTitle>
        </div>
        <div className="flex items-center gap-1">
          <span>{formatMoney(loanValue?.amount ?? 0)}</span>
          <span className="text-tertiary-400">NOK</span>
        </div>
      </Card.Header>
      <Card.Content horizontalPadding={false}>
        <DisbursementControlTable>
          <DisbursementControlTable.Header>
            <ControlCardTableHeader />
          </DisbursementControlTable.Header>
          <DisbursementControlTable.Content>
            <ControlCardTableBody loan={loanValue} t={t} />
          </DisbursementControlTable.Content>
        </DisbursementControlTable>
      </Card.Content>
    </Card>
  );
}

export function PaymentPictureTabWrapper({
  loans = [],
  karValidation,
  redemptionDate,
  priorityStatus,
  controlRoutine,
}: {
  loans: Loan[];
  karValidation?: ResultData[];
  redemptionDate: Date;
  priorityStatus?: string;
  controlRoutine?: {
    checklistFinancingBridging: {};
    checklistFinancingMain: {};
  };
}) {
  const { t } = useFlowContext();

  if (loans?.length === 0) {
    return <EmptyState t={t} tab={t('paymentPicture', 'Utbetalingsbilde')} />;
  }
  const checklistFinancingBridging =
    constructChecklistObjectToArray(
      controlRoutine?.checklistFinancingBridging,
      t,
    ) ?? [];

  const checklistFinancingMain =
    constructChecklistObjectToArray(
      controlRoutine?.checklistFinancingMain,
      t,
    ) ?? [];

  const constructedControlRoutine = [
    ...checklistFinancingBridging,
    ...checklistFinancingMain,
  ];

  return (
    <div className="grid gap-4">
      <KARValidationView data={karValidation} />
      {loans?.map((loan: Loan, index: number) => {
        return (
          <PaymentPictureTab
            loans={loans}
            id={loan.id}
            key={`loan-id-${loan.product}-${index}`}
          />
        );
      })}
      <div className="grid bg-white py-4 rounded border gap-3">
        <span className="px-4 text-xl bolder">Detaljer</span>
        <div className="grid gap-6 px-4">
          <span>
            <h2 className="text-sm font-semibold text-black">
              {t('redemption-date')}
            </h2>
            {formatDate(new Date(redemptionDate))}
          </span>
          {!!priorityStatus && (
            <span>
              <h2 className="text-sm font-semibold text-black">
                {t('priority-status')}
              </h2>
              {priorityStatus === 'CORRECT-PRIORITY'
                ? t('correct-priority-option')
                : t('other-bank-pant-option')}
            </span>
          )}
          {constructedControlRoutine.length > 0 && (
            <span className="grid gap-2">
              <div className="grid">
                <h2 className="text-sm font-semibold text-black">
                  {t('control-routine', 'Kontrollrutine')}
                </h2>
                <ul className="grid grid-cols-1 text-xs">
                  {constructedControlRoutine?.map((elem, index) => {
                    return (
                      <ListItem
                        key={index}
                        item={elem}
                        styling={`first:border-t-0  divide-gray-200 ${
                          index < 1 ? 'border-t-0' : 'border-t'
                        } `}
                      />
                    );
                  })}
                </ul>
              </div>
            </span>
          )}
        </div>
      </div>
    </div>
  );
}
