import { Fragment, useMemo } from 'react';
import {
  Asset,
  AssetType,
} from '../../../../components/_shared/AssetsCard/AssetsCard.types';
import { useSituationStore } from '../../../stores/useSituationStore';
import { RightCell } from '../../../../components/_shared/RightCell';
import { TrashIcon } from '../../../../components/icons/TrashIcon';
import { formatMoney } from '../../../../utils/formatMoney';
import { ConditionalInputCell } from '../DebtsCard/ConditionalInputCell';
import { AssetTypeCell } from '../../../../components/_shared/AssetsCard/AssetType';
import { AssetColumnIds } from './AssetsCard';
import { Details } from './Details';
import DropdownMenu from '../../../../components/_shared/DropdownMenu';
import { classNames } from '../../../../utils/classNames';
import { isSalesObject } from '../../../../utils/isSalesObject';
import { HouseIcon } from '../../../../components/icons/HouseIcon';
import { downloadAttachment } from '../../../../utils/fetchAttachments';
import { useFlowContext } from '../../../../hooks/useFlowContext';
import { useState } from 'react';
import { TaskProps } from '../../../../types/TaskProps';

export function AssetContent({
  editable = false,
  onUpdate,
}: {
  editable?: boolean;
  onUpdate: (e: any) => void;
}) {
  const { assets, deleteAsset, updateAsset } = useSituationStore();
  const { flow, t } = useFlowContext();

  const assetsMemoized = useMemo(() => assets, [assets]);

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState(null as Asset | null);

  function handleDeleteAsset(asset: Asset) {
    setModalOpen(true);
    setSelectedAsset(asset);
  }

  return (
    <Fragment>
      {assetsMemoized.map((asset: Asset, index: number) => {
        const rowDropdownItems = [
          ...(isHousing(asset.type)
            ? [
                {
                  label: t('open-land-registry', 'Åpne grunnbok'),
                  icon: (
                    <div className="text-primary-800">
                      <HouseIcon />
                    </div>
                  ),
                  handleClick: async () => {
                    const fileURL = await downloadAttachment(
                      flow.flowId,
                      'grunnboksutskrift-' + asset.key,
                      'application/pdf',
                    );

                    let tab = window.open();
                    tab!.location.href = fileURL;
                  },
                },
              ]
            : []),
          {
            label: t('delete', 'Slett'),
            icon: (
              <div className="text-primary-800">
                <TrashIcon />
              </div>
            ),
            handleClick: () => handleDeleteAsset(asset),
          },
        ];
        const willBeSold = isSalesObject(asset);
        const isEditable = !willBeSold && editable;

        return (
          <Fragment key={index}>
            {index !== 0 ? <AssetsDivider /> : null}
            <AssetTypeCell
              asset={asset}
              className={classNames(willBeSold ? 'text-neutral-500' : '')}
            />
            <Details
              asset={asset}
              onUpdate={updateAsset}
              editable={isEditable}
              className={classNames(willBeSold ? 'text-neutral-500' : '')}
            />
            {isHousing(asset.type) ? (
              <ConditionalInputCell
                {...{
                  editable,
                  className: 'w-20',
                  initialValue: asset.usageAcreage,
                  rowId: asset.id,
                  columnId: AssetColumnIds.USAGE_ACREAGE,
                }}
                onUpdate={updateAsset}
                labelPlaceholder=" m²"
              />
            ) : (
              <RightCell>{'-'}</RightCell>
            )}
            {isHousing(asset.type) ? (
              <ConditionalInputCell
                {...{
                  editable,
                  className: 'w-20',
                  initialValue: asset.buildYear ?? '',
                  rowId: asset.id,
                  columnId: AssetColumnIds.BUILD_YEAR,
                  thousandSeparator: '',
                  clearOnEmpty: true,
                }}
                onUpdate={updateAsset}
              />
            ) : (
              <RightCell>{'-'}</RightCell>
            )}
            {isHousing(asset.type) ? (
              <ConditionalInputCell
                {...{
                  editable: isEditable,
                  className: classNames(willBeSold ? 'text-neutral-500' : ''),
                  initialValue: asset.coopDebt,
                  rowId: asset.id,
                  columnId: AssetColumnIds.COOP_DEBT,
                }}
                onUpdate={updateAsset}
                useMoneyFormat={true}
              />
            ) : (
              <RightCell>{'-'}</RightCell>
            )}
            <RightCell
              className={classNames(willBeSold ? 'text-neutral-500' : '')}
            >
              {isHousing(asset.type) &&
                formatMoney(
                  asset.fetchedValue ?? asset.eiendomsverdi?.fetchedValue ?? 0,
                )}
            </RightCell>
            <RightCell
              className={classNames(willBeSold ? 'text-neutral-500' : '')}
            >
              {formatMoney(asset.statedValue ?? 0)}
            </RightCell>

            <ConditionalInputCell
              {...{
                editable: isEditable,
                className: classNames(
                  willBeSold ? 'text-neutral-500 justify-self-end h-11' : '',
                  'w-28',
                ),
                initialValue: asset.marketValue,
                rowId: asset.id,
                columnId: AssetColumnIds.MARKET_VALUE,
              }}
              onUpdate={updateAsset}
            />
            {isEditable ? (
              <RightCell className="flex items-center relative w-11">
                <Popup
                  content={
                    <DeleteAssetContent
                      selectedAsset={selectedAsset}
                      deleteAsset={deleteAsset}
                      setModalOpen={setModalOpen}
                      t={t}
                    />
                  }
                  show={modalOpen && selectedAsset?.id === asset.id}
                  onClose={() => setModalOpen(false)}
                >
                  <DropdownMenu items={rowDropdownItems} />
                </Popup>
              </RightCell>
            ) : (
              <div />
            )}
          </Fragment>
        );
      })}
    </Fragment>
  );
}

const isHousing = (assetType: AssetType) =>
  [AssetType.OWNED_HOUSING, AssetType.SHARED_HOUSING].includes(assetType);

function AssetsDivider() {
  return <div className="col-span-9 border-t border-misc-ui-separator" />;
}

function DeleteAssetContent({
  deleteAsset,
  selectedAsset,
  setModalOpen,
  t,
}: {
  deleteAsset: (e: any) => void;
  selectedAsset: Asset | null;
  setModalOpen: (e: boolean) => void;
  t: TaskProps['t'];
}) {
  return (
    <div className="py-2 text-center flex flex-col gap-2 items-center popoverWidth">
      <p>
        {t('wish-to-delete', 'Ønsker du å slette ')} <br />
        {selectedAsset?.addressInformation?.streetAddressName}{' '}
        {selectedAsset?.addressInformation?.streetAddressNumber}?
      </p>
      <div className="flex flex-col gap-2 items-center">
        <button
          onClick={() => deleteAsset(selectedAsset)}
          className=" bg-red-600 text-white p-2 px-4 rounded-md"
        >
          {t('yes-delete-asset', 'Ja, slett eiendel')}
        </button>
        <button
          onClick={() => setModalOpen(false)}
          className=" bg-blue-600 text-white p-2 px-4 rounded-md"
        >
          {t('no-cancel', 'Nei, avbryt')}
        </button>
      </div>
    </div>
  );
}

function Popup({
  show,
  children,
  content,
  onClose,
}: {
  children: any;
  show: boolean;
  content?: any;
  onClose?: () => void;
}) {
  return (
    <div
      onClick={(event) => {
        if (show) {
          event.preventDefault();
          event.stopPropagation();
        }
      }}
    >
      <div className="w-full absolute top-6" hidden={!show}>
        <div className="absolute w-4 h-4 transform left-1/2 rotate-45 bg-gray-50 border-2  bottom-full z-40" />
        <button className="" onClick={onClose}></button>
        <div className="absolute p-2 bg-gray-50 border-2 rounded-lg  bottom-1/2 transform -translate-x-2/3 -translate-y-4 z-50">
          {content}
        </div>
      </div>
      <div className="absolute bg-gray-50" />
      {children}
    </div>
  );
}
