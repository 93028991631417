import { useFlowContext } from '../../../hooks/useFlowContext';

export function LabelPill({ value, color }: { value: string; color?: string }) {
  const { t } = useFlowContext();
  return (
    <div
      className={`mr-4 flex h-6 items-center whitespace-nowrap rounded-3xl px-2.5 py-2 text-xs font-semibold text-white ${
        color ? color : 'bg-secondary-300'
      }`}
    >
      {t(value)}
    </div>
  );
}
