import { TaskProps } from '../../types/TaskProps';
import { PrimaryProgressButton, SecondaryProgressButton } from '@flow/buttons';
import { useFlowContext } from '../../hooks/useFlowContext';
import {
  hasPermissionComplete,
  hasPermissionSave,
} from '../tasks/ManuellBehandling/utils/taskPermissionsHelper';
import { useFormContext } from 'react-hook-form';
import { useEditingStore } from '../stores/useEditingStore';

export function DecisionButtonGroup({
  save,
  additionalData,
}: {
  save: TaskProps['save'];
  additionalData?: any;
}) {
  const { getValues } = useFormContext();

  function handleSave(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    const data = { ...getValues(), additionalData };
    save(data);
  }
  const { t, task, isReevaluating } = useFlowContext();
  const isEditing = useEditingStore((state) => state.isEditing);

  return (
    <div className="grid grid-cols-2 gap-4">
      <PrimaryProgressButton
        isLoading={isReevaluating}
        type="submit"
        disabled={!hasPermissionComplete(task) || isEditing}
      >
        {t('complete')}
      </PrimaryProgressButton>
      <SecondaryProgressButton
        isLoading={isReevaluating}
        onClick={handleSave}
        type="button"
        disabled={!hasPermissionSave(task) || isEditing}
      >
        {t('save')}
      </SecondaryProgressButton>
    </div>
  );
}
