import React from 'react';
import { useFlowContext } from '../../../../../hooks/useFlowContext';

export function DisbursementControlCardTableHeader() {
  const { t } = useFlowContext();
  return (
    <tr className={'border-b border-gray-300'}>
      <td className={'w-full min-w-10rem pl-4'}>{t('type')}</td>
      <td className={'w-full min-w-10rem pr-2 text-right'}>
        {t('given-loan-number-account-nr')}
      </td>
      <td className={'w-full min-w-10rem pr-2 text-right'}>
        {t('given-amount')}
      </td>
      <td className={'w-48 w-full min-w-10rem text-right'}>
        {t('account-nr')}
      </td>
      <td className={'w-full min-w-10rem text-right'}>{t('ocr')}</td>
      <td className={'w-full min-w-10rem text-right'}>{t('message')}</td>
      <td className={'w-full min-w-10rem text-right'}>{t('amount')}</td>
      <td className={'pr-4'}></td>
    </tr>
  );
}
