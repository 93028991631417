import { formatMoney } from '../../../../utils/formatMoney';
import { DownArrowIcon } from '../../../../components/icons/DownArrowIcon';
import { HouseIcon } from '../../../../components/icons/HouseIcon';
import { Card } from '../../../../components/_shared/Card';
import DropdownMenu, {
  MenuItem,
} from '../../../../components/_shared/DropdownMenu';
import { CreditCardIcon } from '../../../../components/icons/CreditCardIcon';
import { CarIcon } from '../../../../components/icons/CarIcon';
import { useFlowContext } from '../../../../hooks/useFlowContext';
import {
  Debt,
  DebtCardAnalysisProps,
  LoanType,
} from '../../../../components/_shared/DebtCard/DebtCard.types';
import { DebtHeader } from './DebtHeader';
import { DebtContent } from './DebtContent';

export const columnIds = {
  LOAN_TYPE: 'loanType',
  BANK_NAME: 'nameOfBank',
  LOAN_OWNERS: 'loanOwners',
  USED_CREDIT: 'usedCredit',
  GRACE_PERIOD: 'gracePeriod',
  LOAN_DURATION: 'durationMonths',
  INTEREST_RATE: 'nominalInterestRate',
  BALANCE: 'balance',
  VALUE: 'value',
  ACTION: 'action',
};

export function DebtCard({
  debts,
  onDelete,
  onUpdate,
  onAdd,
  loanOwnerOptions,
  editable = false,
}: DebtCardAnalysisProps) {
  const { t, task, flow } = useFlowContext();
  const headerDropdownItems: MenuItem[] = [
    {
      label: t(LoanType.LOAN_INSTALLMENT),
      icon: <HouseIcon />,
      handleClick: () =>
        onAdd(
          LoanType.HOUSE_MORTGAGE,
          flow.data.configuredValues,
          task.context?.stencilValues,
        ),
    },
    {
      label: t(LoanType.CREDIT),
      icon: <CreditCardIcon />,
      handleClick: () => onAdd(LoanType.CREDIT, flow.data.configuredValues),
    },
    {
      label: t(LoanType.CAR_BOAT_MC),
      icon: <CarIcon />,
      handleClick: () =>
        onAdd(
          LoanType.CAR_BOAT_MC,
          flow.data.configuredValues,
          task.context?.stencilValues,
        ),
    },
  ];

  return (
    <Card>
      <Card.Header className="justify-between bg-negative-50 text-negative-800 ">
        <div className="flex items-center gap-3">
          <DownArrowIcon />
          <Card.HeaderTitle>{t('debt')}</Card.HeaderTitle>
        </div>
        <div className="flex items-center gap-1">
          <span>{formatMoney(findTotalDebt(debts))}</span>
          <span className="text-negative-200">NOK</span>
          {editable && <DropdownMenu items={headerDropdownItems} />}
        </div>
      </Card.Header>
      <Card.Content>
        <div
          className="grid"
          style={{
            gridTemplateColumns:
              '1fr 1fr 1fr 0.5fr 0.20fr 0.20fr 0.20fr 0.20fr auto',
          }}
        >
          <DebtHeader />
          <DebtContent
            debts={debts}
            onDelete={onDelete}
            onUpdate={onUpdate}
            loanOwnerOptions={loanOwnerOptions}
            editable={editable}
          />
        </div>
      </Card.Content>
    </Card>
  );
}

function findTotalDebt(debts: Debt[]) {
  return debts
    .filter((debt) => !debt.toBeRedeemed)
    .reduce((acc, debt) => acc + Number(debt.value), 0);
}
