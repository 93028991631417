import { TaskProps } from '../../../../types/TaskProps';
import { format, parseISO } from 'date-fns';
import { Card } from '../../../../components/_shared/Card';
import { DocumentHouseIcon } from '../../../../components/icons/DocumentHouseIcon';
import { DocumentationElement } from '../../../../components/_shared/Documentation/Documentation';
import { ClipboardIcon } from '../../../../components/icons/ClipboardIcon';

export function NotesTab(props: TaskProps) {
  const { flow, t } = props;

  const notes = flow?.data?.documentation?.disbursement || [];

  const sortNotesByTimestamp = notes.sort(
    (a: DocumentationElement, b: DocumentationElement) =>
      Date.parse(b?.timestamp) - Date.parse(a?.timestamp),
  );

  if (notes.length === 0) {
    return (
      <div className="flex h-full w-full flex-col items-center justify-center p-48">
        <DocumentHouseIcon />{' '}
        <h3 className="mt-8 text-lg">{t('no-notes-found')}</h3>
      </div>
    );
  }

  return (
    <div className="flex w-full flex-col gap-6">
      <Card>
        <Card.Header className="justify-between bg-tertiary-50 text-tertiary-800 ">
          <div className="flex items-center gap-3">
            <ClipboardIcon width={18} height={18} />
            <Card.HeaderTitle className="text-base">
              {t('note-from-documentation')}
            </Card.HeaderTitle>
          </div>
        </Card.Header>
        <Card.Content>
          <div className="grid gap-6 document-note">
            {sortNotesByTimestamp?.map((note: DocumentationElement) => {
              return (
                <div key={`note_${note.timestamp}`} className="grid gap-4 p-4">
                  <div className="grid grid-cols-queue-item gap-4 text-sm font-bold">
                    <p>{note.sender}</p>
                    <p className="text-right">
                      {format(parseISO(note.timestamp), 'dd.MM.yyyy HH:mm')}
                    </p>
                  </div>
                  <p>{note.note}</p>
                  <p className="text-left text-sm">
                    {t('associated-document')}: {note?.attachment?.file.path}
                  </p>
                </div>
              );
            })}
          </div>
        </Card.Content>
      </Card>
    </div>
  );
}
