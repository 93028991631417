import { SvgIconProps } from './SvgIcon.types';

export function GreenIcon({ size = 16, color = '#66CD73' }: SvgIconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="8.5" r="8" fill={color} />
      <path
        d="m4.8 8.226 2.545 2.545 4.243-4.242"
        stroke="#fff"
        strokeWidth="1.5"
      />
    </svg>
  );
}
