import React from 'react';
import { TaskProps } from '../../../../../types/TaskProps';
import { FlowContext } from '../../../../../hooks/useFlowContext';
import { ScoringCardApplicant } from '../../../../../components/_shared/ScoringCard/ScoringCardApplicant';
import { ScoringCardLoan } from '../../../../../components/_shared/ScoringCard/ScoringCardLoan';
import { ScoringCardPurpose } from '../../../../../components/_shared/ScoringCard/ScoringCard.Purpose';
import { ScoringCardOther } from '../../../../../components/_shared/ScoringCard/ScoringCardOther';
import { EmptyState } from './EmptyState';
import { Stakeholder } from '../../../../../types/Stakeholder';
import { isSelectedLoan } from '../../../../../utils/isSelectedLoan';

export default function ScoringTabOriginal(props: TaskProps) {
  const { flow, t } = props;
  const { data } = flow;

  const originalSituation = data?.households?.[0]?.situations?.original;

  const hasSituation =
    !!data?.households?.[0]?.situations?.original?.betjeningsevne;

  if (!hasSituation) {
    return <EmptyState t={t} tab={t('scoring', 'Scoring')} />;
  }

  return (
    <FlowContext.Provider value={props}>
      <div className="grid grid-cols-2 gap-7 ">
        {data.stakeholders.map((stakeholder: Stakeholder, index: number) => (
          <ScoringCardApplicant
            key={index}
            applicant={stakeholder}
            situation={originalSituation}
            decisions={data?.decisionsOriginal}
          />
        ))}
        <ScoringCardLoan
          situation={originalSituation}
          selectedLoan={originalSituation?.situation?.debts?.find(
            isSelectedLoan,
          )}
        />
        <ScoringCardPurpose decisions={data.decisionsOriginal} />
        <ScoringCardOther inputSituation={originalSituation} />
      </div>
    </FlowContext.Provider>
  );
}
