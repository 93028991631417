import { Loan, RedeemAccount } from '../../../../types/DisbursementTypes';
import { getRedeemAccount } from '../../../../utils/getRedeemAccount';

export async function fetchRedemptionAccounts(
  loans: Loan[],
  nationalId: string,
  redemptionDate: string,
): Promise<RedeemAccount[]> {
  const redeemAccounts: RedeemAccount[] = [];
  for (const loan of loans) {
    for (const disbursement of (loan.disbursements || []).filter(
      (d) => d.isInternalLoan,
    )) {
      const redeemAccount = await getRedeemAccount(
        disbursement.loanNumber,
        redemptionDate,
        nationalId,
      );
      redeemAccounts.push({
        ...redeemAccount,
        loanId: loan.id,
      });
    }
  }
  return redeemAccounts;
}
