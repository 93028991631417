import { ListItem, ListItemType } from './ListItem';

interface Props {
  items: ListItemType[];
  singleRow?: boolean;
}
export function PurchaseObjectSummary({ items, singleRow = true }: Props) {
  return (
    <ul
      className={`mt-4 grid rounded-md bg-white text-xs ${
        singleRow ? 'grid-cols-1 ' : 'grid-cols-2'
      }`}
    >
      {items?.map((item: ListItemType, index: number) => {
        return (
          <ListItem
            key={`${item.label}-${index}`}
            item={item}
            styling={`px-4 first:border-t-0  divide-gray-200 ${
              index < 2 && !singleRow ? 'border-t-0' : 'border-t'
            } `}
          />
        );
      })}
    </ul>
  );
}
