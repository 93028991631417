import React from 'react';
import { PrimaryProgressButton } from '@flow/buttons';
import axios from 'axios';

interface followUpTaskProps {
  flowId: string;
  requiresFollowUp: boolean;
  t: any;
}

const FollowUpTask: React.FC<followUpTaskProps> = ({
  flowId,
  requiresFollowUp,
  t,
}) => {
  const [loading, setLoading] = React.useState(false);

  async function handleOnClick() {
    setLoading(true);
    try {
      await axios.post(`/api/followUp/${flowId}`);
    } catch (error) {
      console.error('Error with follow-up request:', error);
    } finally {
      setLoading(false);
      window.location.reload();
    }
  }

  return (
    <div>
      <div className="text-sm text-neutral-600">
        {t('optional-tasks', 'Valgfrie oppgaver')}
      </div>
      <div className="border-t border-bg-primary-50 my-4"></div>
      <div className="flex justify-between items-center">
        <div className="flex gap-5">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17Z"
              fill="#FFFFFF"
              stroke="#FFBA30"
              stroke-width="6"
            ></path>
          </svg>
          <p>{t('follow-up', 'Etterkontroll')}</p>
        </div>

        <PrimaryProgressButton
          type="button"
          onClick={handleOnClick}
          disabled={loading}
        >
          {requiresFollowUp ? t('completed', 'Fullført') : t('add', 'Legg til')}
        </PrimaryProgressButton>
      </div>
      <div className="border-t border-bg-primary-50 my-4"></div>
    </div>
  );
};

export default FollowUpTask;
