export function UploadToCloudIcon({
  width = 24,
  height = 24,
}: {
  width?: number;
  height?: number;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      viewBox="0 0 24 24"
    >
      <title>cloud upload</title>
      <g
        strokeLinecap="square"
        strokeWidth="2"
        fill="none"
        stroke="currentColor"
        strokeLinejoin="miter"
        className="nc-icon-wrapper"
        strokeMiterlimit="10"
      >
        <line
          data-cap="butt"
          x1="12"
          y1="21"
          x2="12"
          y2="12"
          strokeLinecap="butt"
          stroke="currentColor"
        ></line>
        <polyline points="8 16 12 12 16 16" stroke="currentColor"></polyline>
        <path d="M16,20.777A9,9,0,1,0,5.046,11.1,5,5,0,0,0,6,21H8"></path>
      </g>
    </svg>
  );
}
