import { CalendarIcon } from '../../../components/icons/CalendarIcon';
import { Card } from '../../../components/_shared/Card';
import { List } from '../../../components/_shared/List';
import type { ListItemType } from '../../../components/_shared/ListItem';
import { useFlowContext } from '../../../hooks/useFlowContext';
import { formatMoney } from '../../../utils/formatMoney';
import { openAccountInCore } from '../../../utils/openInCore';
import { PrimaryButton } from '@flow/buttons';

export default function EndreAvdragsfrihetCard({ data }: any) {
  const { t } = useFlowContext();

  const changes: ListItemType[] = [
    {
      label: t('loanType'),
      value: data?.loanDetails?.loanType,
    },
    {
      label: t('loanDuration'),
      value: `${data?.loanDetails?.loanLengthYears} år, ${data?.loanDetails?.loanLengthMonths} mnd`,
    },
    {
      label: t('requestedGracePeriod'),
      value: `${
        data?.gracePeriod?.numberOfTermsRequested?.terms /
        (data?.loanDetails?.termsPerYear || 1)
      } år`,
    },
  ];

  const loanDetails: ListItemType[] = [
    {
      label: t('amountOfLatePaymentsLast3years'),
      value: data?.loanDetails?.amountOfLatePaymentsLast3years,
    },
    {
      label: t('advanceDeposit'),
      value: formatMoney(data?.loanDetails?.advanceDeposit),
    },
    {
      label: t('coopDebt'),
      value: formatMoney(data?.loanDetails?.coopDebt),
    },
    {
      label: t('loanAmount'),
      value: formatMoney(data?.loanDetails?.remainingLoanAmount),
    },
    {
      label: t('propertyValue'),
      value: formatMoney(data?.loanDetails?.housingValue),
    },
    {
      label: t('loanToValue'),
      value: `${data?.loanDetails?.ltv?.toFixed(2)}%`,
    },
    {
      label: t('product'),
      value: `${data?.loanDetails?.product} - ${data?.loanDetails?.nominalInterestRate}% (${data?.loanDetails?.effectiveInterestRate}%)`,
    },
  ];

  function handleOpenInCore() {
    openAccountInCore(data?.accountNumber);
  }

  return (
    <div className="flex flex-col gap-5">
      <Card>
        <Card.Header>
          <CalendarIcon />
          <Card.HeaderTitle>{t('changeGracePeriod')}</Card.HeaderTitle>
        </Card.Header>
        <Card.Content>
          <div className="flex flex-col gap-10">
            <List items={loanDetails} />
            <List items={changes} />
          </div>
        </Card.Content>
      </Card>
      <div>
        <PrimaryButton onClick={handleOpenInCore}>
          {t('openInCore')}
        </PrimaryButton>
      </div>
    </div>
  );
}
