import { useFlowContext } from '../../../../hooks/useFlowContext';
import { useSituationStore } from '../../../stores/useSituationStore';
import { isSelectedLoan } from '../../../../utils/isSelectedLoan';
import { useProductStore } from '../../../stores/useProductStore';
import { isFinancingApplication } from '../../../tasks/ManuellBehandling/utils/loanPurposeContextHelper';
import {
  ListItem,
  ListItemType,
} from '../../../../components/_shared/ListItem';
import { formatTaskSummary } from './formatTaskSummary';
import { LoanTypeToggle } from './LoanTypeToggle';
import { Debt } from '../../../../components/_shared/DebtCard/DebtCard.types';
import { Asset } from '../../../../components/_shared/AssetsCard/AssetsCard.types';

type Props = {
  onUpdate?: (debt: Debt) => void;
  editable?: boolean;
};
export function TaskSummary({ onUpdate, editable = false }: Props) {
  const { t, flow } = useFlowContext();
  const { data } = flow;
  const { debts } = useSituationStore();
  const selectedLoan = debts?.find(isSelectedLoan)!;

  const property =
    data.households?.[0]?.situations?.current?.situation?.assets?.filter(
      (asset: Asset) => asset?.isSecurity,
    )[0];

  const takeOverDate = data?.proofOfFinance?.purchase?.takeoverDate;

  const { ltvProduct, combinedLoanToValueRatio } = useProductStore((s) => ({
    ltvProduct: s.ltvProduct,
    combinedLoanToValueRatio: s.ltvProductShortTerm?.calculatedLtv,
  }));

  let showCombinedLTVRatio =
    !!combinedLoanToValueRatio && !isNaN(combinedLoanToValueRatio);

  const proofOfFinanceSummary = {
    takeOverDate: takeOverDate,
    combinedLtvRatio: combinedLoanToValueRatio,
    showCombinedLtvRatio: showCombinedLTVRatio,
  };
  const isProofOfFinance = isFinancingApplication(data);

  const mappedData = formatTaskSummary({
    referenceId: flow.referenceId,
    loan: selectedLoan,
    loanProduct: flow?.data?.loanPurpose,
    property,
    t,
    loanToValue: ltvProduct?.calculatedLtv,
    coreProduct: ltvProduct?.product,
    ...(isProofOfFinance && {
      proofOfFinanceSummary,
    }),
  });

  return (
    <div className="mt-4 flex flex-col rounded-md bg-white">
      <ul className="grid text-xs grid-cols-1">
        {mappedData?.map((item: ListItemType, index: number) =>
          item.label === 'loanType' && editable ? (
            <LoanTypeToggle
              key={index}
              label={item.label}
              loan={selectedLoan}
              onUpdate={onUpdate}
              initialValue={selectedLoan?.loanType ?? 'annuity'}
              styling="first:border-t-0 divide-gray-200 border-t"
            />
          ) : (
            <ListItem
              key={index}
              item={item}
              styling="px-4 first:border-t-0 divide-gray-200 border-t first:text-sm"
            />
          ),
        )}
      </ul>
    </div>
  );
}
