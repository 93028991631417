import React from 'react';
import { CheckIcon } from '../../icons/CheckIcon';
import { CopyIcon } from '../../icons/CopyIcon';

interface CopyToClipBoardProps {
  value: string;
  id: string;
  copiedId: string;
  handleCopy: (value: string, id: string) => void;
}

const CopyToClipBoard: React.FC<CopyToClipBoardProps> = ({
  value,
  id,
  copiedId,
  handleCopy,
}) => {
  if (!value) {
    return null;
  }

  return (
    <button
      type="button"
      className="flex h-4 w-4 items-center justify-center rounded-xl bg-primary-100 p-[2px] focus:outline-offset-1 focus:outline-blue"
      onClick={() => handleCopy(value, id)}
    >
      {copiedId === id ? <CheckIcon /> : <CopyIcon />}
    </button>
  );
};

export default CopyToClipBoard;
