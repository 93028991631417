import { useFlowContext } from '../../../hooks/useFlowContext';

export function SameUserError() {
  const { t } = useFlowContext();
  return (
    <div className={'mt-4 rounded bg-negative-50 p-4 text-negative-800'}>
      {t('sameUserNotAllowedToComplete')}
    </div>
  );
}
