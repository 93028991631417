import AbilityToPayTab from '../components/Summary/CaseDetails/components/AbilityToPayTab';
import { FilesTab } from './tabs/FileTab';
import { NotesTab } from './tabs/NotesTab';

export const tabs = [
  {
    id: 'files',
    title: 'Filer',
    component: FilesTab,
  },
  {
    id: 'caseNotes',
    title: 'Vurderinger',
    component: NotesTab,
  },
  {
    id: 'abilityToPay',
    title: 'Betjeningsevne',
    component: AbilityToPayTab,
  },
];
