import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { DateInput } from '../DateInput';
import React from 'react';
import { useFlowContext } from '../../../hooks/useFlowContext';
import { PrimaryProgressButton, SecondaryProgressButton } from '@flow/buttons';
import { parse } from 'date-fns';
export function ChangeTakeoverDateModal({
  takeoverDate,
  handleClose,
  handleChangeDate,
}: {
  takeoverDate: string;
  handleClose: () => void;
  handleChangeDate: (takeoverDate: Date) => void;
}) {
  const { t } = useFlowContext();

  const method = useForm({
    defaultValues: {
      takeoverDate: formatToISODate(takeoverDate),
    },
    resolver: zodResolver(ChangeTakeoverDateModal.validationSchema),
  });
  const { handleSubmit } = method;

  const onSubmit = (data: { takeoverDate: string }) => {
    const parsedDate = parse(data.takeoverDate, 'yyyy-MM-dd', new Date());
    handleChangeDate(parsedDate);
  };

  return (
    <FormProvider {...method}>
      <form className="grid gap-6 p-8" onSubmit={handleSubmit(onSubmit)}>
        <div className="w-80 ">
          <DateInput label={t('takeover-date')} id="takeoverDate" />
        </div>
        <div className="flex gap-4 justify-start">
          <PrimaryProgressButton type="submit">
            {t('save')}
          </PrimaryProgressButton>
          <SecondaryProgressButton onClick={handleClose} type={'button'}>
            Avbryt
          </SecondaryProgressButton>
        </div>
      </form>
    </FormProvider>
  );
}

ChangeTakeoverDateModal.validationSchema = z.object({
  takeoverDate: z
    .string({
      required_error: 'takeover-date-required',
      invalid_type_error: 'takeover-date-required',
    })
    .min(1, 'takeover-date-required'),
});

function formatToISODate(dateStr: string) {
  const [day, month, year] = dateStr.split('.'); // Split the "dd.MM.yyyy" string
  return `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(
    2,
    '0',
  )}`;
}
