import { useFlowContext } from '../../../hooks/useFlowContext';
import { Stakeholder } from '../../../types/Stakeholder';
import BannerCard from './BannerCard';
import PersonCard from './PersonCard';
import BirthCountry from './WarningCards/BirthCountry';
import PaymentsOver100K from './WarningCards/PaymentsOver100K';
import Pep from './WarningCards/Pep';
import Residency from './WarningCards/Residency';
import TaxResidency from './WarningCards/TaxResidency';
import OtherMainIncome from './WarningCards/OtherMainIncome';

export default function Overview() {
  const { flow } = useFlowContext();
  const stakeholders: Stakeholder[] = flow?.data?.stakeholders || [];
  const showBanner = stakeholders.some((s) => s?.trapets?.hasHitInList);

  return (
    <div className={'flex flex-col gap-5'}>
      {showBanner && <BannerCard />}
      <div
        className={`grid gap-5 ${
          stakeholders?.length > 1 ? 'grid-cols-2' : 'grid-cols-1'
        }`}
      >
        {stakeholders?.map((stakeholder) => (
          <div key={stakeholder.firstName} className="flex flex-col gap-5">
            <PersonCard stakeholder={stakeholder} />
            <BirthCountry stakeholder={stakeholder} />
            <Residency stakeholder={stakeholder} />
            <TaxResidency stakeholder={stakeholder} />
            <PaymentsOver100K stakeholder={stakeholder} />
            <OtherMainIncome stakeholder={stakeholder} />
            <Pep stakeholder={stakeholder} />
          </div>
        ))}
      </div>
    </div>
  );
}
