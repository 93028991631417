import { useFlowContext } from '../../hooks/useFlowContext';
import WarningIcon from '../icons/WarningIcon';

export function MaliciousContentMessage({ message }: { message: string }) {
  const { t } = useFlowContext();
  return (
    <div className="flex gap-2 items-center">
      <WarningIcon />
      <p className="text-negative-600 text-bold">{t(message)}</p>
    </div>
  );
}
