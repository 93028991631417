import { useFlowContext } from '../../../hooks/useFlowContext';
import { z } from 'zod';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useDebounce } from 'usehooks-ts';
import { fetchOrganizationName } from '../../../utils/fetchOrganizationName';
import { Spinner } from '@flow/icons';
import { HeaderLabel } from '../../components/common/HeaderLabel';
import { useFormConnect } from '../../../hooks/useFormConnect';
import { MagnifyingGlassIcon } from '../../../components/icons/MagnifyingGlassIcon';

export const OrganizationDetailsSchema = z.object(
  {
    name: z.string().min(1).optional(),
    orgNumber: z
      .string({ required_error: 'settlementInstanceNeedsOrgNumber' })
      .length(9, 'orgNumberLengthError')
      .regex(/^\d+$/, 'orgNumberOnlyNumbersError'),
  },
  { required_error: 'settlementInstanceNeedsOrgNumber' },
);

export type OrganizationDetails = z.infer<typeof OrganizationDetailsSchema>;

export function OrganizationLookup({
  id,
  headerLabel = 'settlementInstance',
}: {
  id: string;
  headerLabel?: string;
}) {
  const { t } = useFlowContext();
  const { value, onChange, error } = useFormConnect(id);

  const [searchString, setSearchString] = useState<string>(
    value?.orgNumber || '',
  );
  const debouncedValue = useDebounce(searchString, 500).replaceAll(' ', '');

  const schemaParseSuccess = OrganizationDetailsSchema.safeParse({
    orgNumber: debouncedValue,
  }).success;

  const { refetch, isFetching, data } = useQuery({
    queryKey: ['orgNumber', debouncedValue],
    initialData: value?.name || '',
    queryFn: () => fetchOrganizationName(debouncedValue),
    enabled: schemaParseSuccess,
    retry: false,
    onSuccess: (data) => {
      onChange({
        name: data,
        orgNumber: debouncedValue,
      });
    },
  });

  return (
    <div className="flex flex-col">
      <HeaderLabel labelKey={headerLabel} />
      <div className="flex justify-between px-2 py-2 items-center">
        <label htmlFor="search">{'Org.nummer'}</label>
        <div className="flex ">
          <input
            name="search"
            type="text"
            onChange={(e) => setSearchString(e.target.value)}
            value={searchString}
            placeholder={'Søk...'}
            className="border rounded p-2 border-gray-200"
          />
          <button
            className="relative -ml-px inline-flex  items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-primary-600 bg-primary-500 hover:bg-primary-600 "
            type="button"
            onClick={() => refetch()}
          >
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3" />
            {isFetching ? <Spinner size={22} /> : <MagnifyingGlassIcon />}
          </button>
        </div>
      </div>
      {error && (
        <span className="text-negative-400 text-right">{t(error)}</span>
      )}
      <div className="flex justify-between px-2 py-2 items-center">
        <label htmlFor="name">{t('name')}</label>
        <span>{isFetching ? <Spinner size={22} /> : data}</span>
      </div>
    </div>
  );
}
