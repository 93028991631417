import { useState } from 'react';
import { useEditingStore } from '../../../../../stores/useEditingStore';

interface CustomTextInputProps {
  initialValue: string;
  id: string;
  name: string;
  onUpdate: (e: any) => void;
  styles?: any;
  placeholder: string;
}

export function CustomTextInput({
  initialValue,
  id,
  name,
  onUpdate,
  styles,
  placeholder = '',
}: CustomTextInputProps) {
  const [value, setValue] = useState(initialValue);
  const setIsEditing = useEditingStore((state) => state.setIsEditing);

  const handleChange = (value: string) => {
    setValue(value);
    if (value !== initialValue) {
      setIsEditing && setIsEditing(true);
    } else {
      setIsEditing && setIsEditing(false);
    }
  };

  const onBlur = () => {
    if (value !== initialValue) {
      onUpdate(value);
    }
    setTimeout(() => setIsEditing && setIsEditing(false), 100);
  };

  return (
    <input
      className={`focus:outline-none border border-misc-ui-separator bg-input p-2.5 text-left focus:border-transparent focus:ring-2  ${
        styles ?? ''
      }`}
      value={value}
      onChange={(e) => handleChange(e.target.value ?? '')}
      onBlur={onBlur}
      type={'text'}
      id={id}
      name={name}
      placeholder={placeholder}
    />
  );
}
