import { CrossIcon } from '../../../components/icons/CrossIcon';
import { useFlowContext } from '../../../hooks/useFlowContext';
import { capitalizeAllWords } from '../../../utils/capitalizeAllWords';
import { PropertyInfo } from './SearchInputProperty';

export function SelectedPropertyDetails({
  selectedProperty,
  onRemove,
}: {
  selectedProperty: PropertyInfo;
  onRemove: () => void;
}) {
  const { t } = useFlowContext();
  if (!selectedProperty || !selectedProperty.key) {
    return <></>;
  }

  const { municipality } = selectedProperty.address || {};
  const keyHeader =
    selectedProperty.type === 'cadastre'
      ? t('cadastreKeyHeader')
      : t('shareKeyHeader');

  return (
    <div className="grid grid-cols-3 pt-4 gap-4 items-start">
      <GridColumn header={t('address')}>
        <div className="text-neutral-900">
          <PropertyAddress address={selectedProperty.address} />
        </div>
      </GridColumn>
      <GridColumn header={t('municipality')}>
        <span className="text-neutral-900">{`${
          municipality?.municipalityNumber ?? ''
        } ${capitalizeAllWords(municipality?.municipalityName ?? '')}`}</span>
      </GridColumn>
      <div className="flex justify-end">
        <button className=" text-red-500" onClick={onRemove}>
          <span className="flex items-center">
            <CrossIcon width={24} height={24} />
            {t('remove', 'Fjern')}
          </span>
        </button>
      </div>
      <GridColumn header={keyHeader}>
        <span className="text-neutral-900">
          {transformKey(selectedProperty)}
        </span>
      </GridColumn>
    </div>
  );
}

function PropertyAddress({ address }: { address: PropertyInfo['address'] }) {
  if (!address) {
    return <></>;
  }
  return (
    <>
      <span className="block">
        {capitalizeAllWords(address.formatted?.line1)}
      </span>
      <span className="block">
        {capitalizeAllWords(address.formatted?.line2)}
      </span>
    </>
  );
}

function GridColumn({
  header,
  children,
}: {
  header: string;
  children: React.ReactNode;
}) {
  return (
    <div>
      <span className="block text-neutral-700 uppercase">{header}</span>
      {children}
    </div>
  );
}

function transformKey(property: any) {
  const { ident } = property || {};
  if (ident?.organizationNumber) {
    return `${ident?.organizationNumber}/${ident?.shareNumber}`;
  }
  if (ident?.cadastralUnitNumber) {
    return `${ident?.municipalityNumber ?? ''}-${
      ident?.cadastralUnitNumber ?? ''
    }/${ident?.unitNumber ?? ''}/${ident?.leaseholdUnitNumber ?? ''}/${
      ident?.sectionNumber ?? ''
    }`;
  }
}
