import { classNames } from '../../../utils/classNames';
import { Fragment } from 'react';
import { useFlowContext } from '../../../hooks/useFlowContext';
import { formatMoney } from '../../../utils/formatMoney';
import {
  getTotalIncomeByType,
  getTruncatedAverageSblIncome,
} from '../../../customer/tasks/ManuellBehandling/utils/incomesHelper';
import { IncomeColumn } from '../../../customer/tasks/ManuellBehandling/utils/incomesHelper';
import { Income } from './IncomeCard.types';
import { Stakeholder } from '../../../types/Stakeholder';
import { resolveWorkPlaceName } from '../../../customer/tasks/ManuellBehandling/IncomesCard/components/Incomes';

interface IncomeTableProps {
  incomes: Income[];
  stakeholders: Stakeholder[];
  textLeft?: boolean;
}
export function IncomeTable({
  incomes,
  stakeholders,
  textLeft = true,
}: IncomeTableProps) {
  const { t } = useFlowContext();

  return (
    <div>
      <table className="w-full text-sm">
        <thead
          className={classNames(
            textLeft ? 'text-left' : 'text-right',
            'text-xs uppercase text-basic-wcag-aa',
          )}
        >
          <tr>
            <th className="font-normal">{t('person')}</th>
            <th className="font-normal">{t('work-situation')}</th>
            <th className={'text-right font-normal'}>{t('sbl-income')}</th>
            <th className={'text-right font-normal'}>{t('stated-income')}</th>
            <th className={'text-right font-normal'}>{t('utilized-income')}</th>
          </tr>
        </thead>
        <tbody>
          {stakeholders?.map((stakeholder: any, index: number) => {
            const sblFetchedValue = getTruncatedAverageSblIncome(stakeholder);

            const stakeholderIncomes = incomes.filter(
              (income) =>
                income.owner === stakeholder.nationalId ||
                income.ownerMeta.id === stakeholder.nationalId,
            );

            return (
              <Fragment key={stakeholder.nationalId}>
                {index > 0 && <tr className="h-6" />}
                <tr className="h-11">
                  <td className={'font-bold'}>{stakeholder.fullName}</td>
                  <td className={'font-bold'}>
                    {t(stakeholder.personalEconomy.workCondition)}
                  </td>
                  <td className={'text-right font-bold'}>
                    {formatMoney(sblFetchedValue)}
                  </td>
                  <td className={'text-right font-bold'}>
                    {formatMoney(
                      getTotalIncomeByType(
                        stakeholderIncomes,
                        IncomeColumn.INCOME_STATED,
                      ),
                    )}
                  </td>
                  <td className={'text-right font-bold'}>
                    {formatMoney(
                      getTotalIncomeByType(
                        stakeholderIncomes,
                        IncomeColumn.INCOME_ANALYSIS,
                      ),
                    )}
                  </td>
                </tr>
                {stakeholderIncomes?.map((income, index: number) => (
                  <tr
                    className={`h-11 border-gray-200 ${
                      index === stakeholderIncomes?.length - 1
                        ? `border-b-0`
                        : 'border-b'
                    }`}
                    key={income.id}
                  >
                    <td>{t(resolveWorkPlaceName(income.workPlace))}</td>
                    <td>{t(income?.workSituation ?? '')}</td>
                    <td className="text-right">
                      {income.incomeSbl > 0
                        ? formatMoney(income?.incomeSbl ?? 0)
                        : ''}
                    </td>
                    <td className="text-right">
                      {income.incomeSbl > 0 && income.incomeStated === 0
                        ? ''
                        : formatMoney(income?.incomeStated ?? 0)}
                    </td>
                    <td className="text-right">
                      {formatMoney(income?.value ?? 0)}
                    </td>
                  </tr>
                ))}
              </Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
