import { useFlowContext } from '../../hooks/useFlowContext';
import { ConditionalDecisionIcon } from './ConditionalDecisionIcon';

export type ListItemWithDecisionIconType = {
  label: string;
  value: string | number | boolean;
  decisionCode?: string;
  context?: string;
};

export function ListItemWithDecisionIcon({
  item,
  styling,
}: {
  item: ListItemWithDecisionIconType;
  styling?: string;
}): JSX.Element {
  const { t } = useFlowContext();
  return (
    <li
      className={`flex h-11 place-content-between items-center ${
        styling ?? ''
      }`}
    >
      <span>{t(item.label)}</span>
      <div className="flex items-center gap-2.5">
        <span>{t(item.value?.toString())}</span>
        {item.decisionCode && (
          <ConditionalDecisionIcon decisionCode={item.decisionCode} />
        )}
      </div>
    </li>
  );
}
