import { ListItemType } from '../../../../components/_shared/ListItem';
import { TaskProps, Translation } from '../../../../types/TaskProps';
import { isSelectedLoan } from '../../../../utils/isSelectedLoan';
import { Asset } from '../../../../components/_shared/AssetsCard/AssetsCard.types';
import { formatLoanData } from '../utils/formatLoanData';

interface LoanSummaryProps {
  data: TaskProps['flow']['data'];
  t: Translation;
}
export function formatLoanSummary({
  data,
  t,
}: LoanSummaryProps): ListItemType[] {
  const property =
    data.households?.[0]?.situations?.current?.situation?.assets.filter(
      (asset: Asset) => asset.isSecurity,
    )[0];

  const selectedLoan =
    data.households?.[0]?.situations?.current?.situation?.debts?.find(
      isSelectedLoan,
    );

  const ltvProduct = data?.households?.[0]?.situations?.current?.ltvProduct;
  const { product: coreProduct, calculatedLtv: loanToValue } = ltvProduct || {};

  const productText = !!coreProduct
    ? `${t(coreProduct?.rateCode)} - ${(coreProduct?.rateValue * 100).toFixed(
        2,
      )}%`
    : '-';

  const summaryData = formatLoanData({
    referenceId: data.referenceId,
    selectedLoan,
    property,
    t,
    loanPurpose: data.loanPurpose,
    loanToValue,
    productText,
  });

  return Object.entries(summaryData).map(
    ([label, value]): ListItemType => ({
      label,
      value,
    }),
  );
}
