export function TrashIcon() {
  return (
    <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g
        clipPath="url(#a)"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
      >
        <path d="M4.667 0h6.666v4H10V1.333H6V4H4.667V0ZM3.333 7.333V14a.667.667 0 0 0 .667.667h8a.667.667 0 0 0 .667-.667V7.333H14V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V7.333h1.333Z" />
        <path d="M8.667 8.666v4H7.334v-4h1.333ZM6 8.666v4H4.667v-4H6ZM11.333 8.666v4H10v-4h1.333ZM0 2.667h16v4H0v-4ZM1.333 4v1.333h13.334V4H1.333Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
