import { useFlowContext } from '../../../../hooks/useFlowContext';
import { TaskProps } from '../../../../types/TaskProps';
import {
  ListItem,
  ListItemType,
} from '../../../../components/_shared/ListItem';
import { formatLoanSummary } from './formatLoanSummary';
import { formatProofOfFinanceSummary } from './formatProofOfFinanceSummary';
import { isFinancingApplication } from '../../../tasks/ManuellBehandling/utils/loanPurposeContextHelper';
import { formatGracePeriodSummary } from './changeLoan/formatGracePeriodSummary';
import { formatDueDateSummary } from './changeLoan/formatDueDateSummary';
import { formatRedemptionPeriodSummary } from './changeLoan/formatRedemptionPeriodSummary';
import { useState } from 'react';
import { Modal } from '../../../../components/_shared/Modal/Modal';
import { ChangeTakeoverDateModal } from '../../../../components/_shared/ChangeTakeoverDateModal';
import { useMutation } from '@tanstack/react-query';
import { triggerPendingMessageTask } from '../../../../utils/messageTasks';
import { EditIcon } from '../../../../components/icons/EditIcon';

type SummaryDetailsProps = {
  flowId: string;
  flowDefinitionId: string;
  data: TaskProps['flow']['data'];
  isCompletedAndSentToCore: boolean;
  showDetails?: any;
};
export function SummaryDetails({
  flowId,
  flowDefinitionId,
  isCompletedAndSentToCore,
  data,
  showDetails,
}: SummaryDetailsProps) {
  const { t } = useFlowContext();
  const isProofOfFinance = isFinancingApplication(data);
  const [openModal, setOpenModal] = useState(false);
  const [takeoverDate, setTakeoverDate] = useState('');

  const formatSummaryData = () => {
    let type;
    if (isProofOfFinance) {
      type = 'proofOfFinance';
    }
    if (flowDefinitionId === 'change-loan-gracePeriod') {
      type = 'gracePeriod';
    }
    if (flowDefinitionId === 'change-loan-duedate') {
      type = 'dueDate';
    }
    if (flowDefinitionId === 'change-loan-repaymentPeriod') {
      type = 'repaymentPeriod';
    }

    switch (type) {
      case 'proofOfFinance':
        return formatProofOfFinanceSummary({ data });
      case 'gracePeriod':
        return formatGracePeriodSummary({ data });
      case 'dueDate':
        return formatDueDateSummary({ data, t });
      case 'repaymentPeriod':
        return formatRedemptionPeriodSummary({ data, t });
      default:
        return formatLoanSummary({ data, t });
    }
  };

  const formattedValue = formatSummaryData();

  function handleOpenModal(data: any) {
    setOpenModal(true);
    setTakeoverDate(data);
  }

  const { mutate } = useMutation({
    mutationKey: ['trigger-change-takeover-date', takeoverDate],
    mutationFn: (date: Date) =>
      triggerPendingMessageTask(flowId, 'trigger-change-takeover-date', {
        takeoverDate: date,
      }).then(() => {
        setTakeoverDate('');
      }),
    onSuccess: () => {
      setOpenModal(false);
    },
    onError: (err: any, newData, context) => {
      if (err.response) {
        console.error(err.response?.data);
      }
    },
  });

  return (
    <div className="mt-4 flex flex-col rounded-md bg-white">
      {showDetails && showDetails}
      <ul className="grid text-xs grid-cols-2">
        {formattedValue.map((item: ListItemType, index: number) => {
          if (item.label === 'takeOverDate') {
            return (
              <li
                key={index}
                className={`flex h-11 place-content-between items-center px-4 first:border-t-0  divide-gray-200 ${
                  index < 2 ? 'border-t-0' : 'border-t'
                }`}
              >
                <div className="flex gap-2 items-center">
                  <span className="text-xs">{t(item.label)}</span>
                  {item.value !== '-' && !isCompletedAndSentToCore && (
                    <button
                      className=" hover:bg-primary-100 font-bold rounded text-blue-600 p-2 items-center gap-1 flex"
                      type="button"
                      onClick={() => handleOpenModal(item.value || '')}
                    >
                      Endre <EditIcon width={16} height={16} />
                    </button>
                  )}
                </div>
                <div className="flex items-center gap-2.5">
                  <span>{item.value ? t(item.value?.toString()) : '-'}</span>
                </div>
              </li>
            );
          }
          return (
            <ListItem
              key={index}
              item={item}
              styling={`px-4 first:border-t-0  divide-gray-200 ${
                index < 2 ? 'border-t-0' : 'border-t'
              } `}
            />
          );
        })}
      </ul>
      <Modal
        width="w-fit-content min-w-40rem"
        heading={t('change-takeover-date', 'Endre overtakelsesdato')}
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
        t={t}
      >
        <ChangeTakeoverDateModal
          takeoverDate={takeoverDate}
          handleClose={() => setOpenModal(false)}
          handleChangeDate={mutate}
        />
      </Modal>
    </div>
  );
}
