import type { SvgIconProps } from './SvgIcon.types';

export function RedIcon({ size = 24, color = '#EB5E55' }: SvgIconProps) {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
    >
      <circle cx="12" cy="12" r="12" fill={color} />
      <path
        d="m7.2 7.2 9.6 9.6M7.2 16.8l9.6-9.6"
        stroke="#fff"
        strokeWidth="3"
      />
    </svg>
  );
}
