import { TaskProps } from '../../../../../types/TaskProps';
import React from 'react';
import { EmptyState } from './EmptyState';
import { SuspenseWrapper } from '../../../SuspenseWrapper';
import { ProofOfFinance } from '../../../../../components/Insight/ProofOfFinance/LazyProofOfFinance';
import { isFinancingApplication } from '../../../../tasks/ManuellBehandling/utils/loanPurposeContextHelper';

export default function ProofOfFinanceTab(props: TaskProps) {
  const { flow, t } = props;
  const { data } = flow;

  const hasSituation =
    !!data?.households?.[0]?.situations?.current?.betjeningsevne;

  if (!hasSituation || !isFinancingApplication(data)) {
    return <EmptyState t={t} tab={t('proofOfFinance', 'Finanseringsbevis')} />;
  }

  return (
    <SuspenseWrapper>
      <ProofOfFinance />
    </SuspenseWrapper>
  );
}
