import LoanDetails from './LoanDetails';
import PaymentPlan from './PaymentPlan';
import { useSummary } from '../../../hooks/useSummary';
import { isSelectedLoan } from '../../../utils/isSelectedLoan';
import { Debt } from '../DebtCard/DebtCard.types';

export function PaymentPlanWrapper({ data, caseData, loan, situation }: any) {
  const usedSituation = situation ?? data?.households[0]?.situations.current;

  const selectedLoan: Debt = loan
    ? loan
    : usedSituation.situation.debts.find(isSelectedLoan);

  const dataToCalc = {
    loanAmount: selectedLoan?.value,
    terms: selectedLoan?.durationMonths,
    gracePeriod: selectedLoan?.gracePeriodDuration ?? 0,
    interestRate: (selectedLoan?.nominalInterestRate ?? 0) / 100,
    loanType: selectedLoan?.loanType,
  };

  const termsInfo = useSummary(dataToCalc);
  return (
    <div className="grid grid-cols-1 gap-6">
      <LoanDetails
        loan={loan}
        data={data}
        termsInfo={termsInfo}
        caseData={caseData}
        situation={usedSituation}
      />
      <PaymentPlan data={data} paymentPlan={termsInfo} />
    </div>
  );
}
