import * as Collapsible from '@radix-ui/react-collapsible';
import { useState } from 'react';
import { Chevron } from '../../../components/icons/Chevron';
import { toPlainText } from '@portabletext/react';
import { useFlowContext } from '../../../hooks/useFlowContext';
import { classNames } from '../../../utils/classNames';

export function EmailPreview({ reason }: { reason?: string }) {
  const [open, setOpen] = useState(true);
  const { t, task } = useFlowContext();
  const body = task?.context?.email?.[0]?.body;
  const email = toPlainText(body ?? '')?.replace(/#\{([^}]+)\}/g, () => {
    return reason ?? '';
  });
  return (
    <Collapsible.Root open={open} onOpenChange={() => setOpen(!open)}>
      <Collapsible.Trigger className="w-full">
        <div className="flex cursor-pointer flex-row justify-between">
          <span>{t('seeEmailBeingSent')}</span>
          <div>
            <Chevron
              className={classNames(
                open ? 'rotate-180' : 'rotate-0',
                'transform text-blue-500 duration-150 ease-in-out',
              )}
              size={20}
            />
          </div>
        </div>
      </Collapsible.Trigger>
      <Collapsible.Content>
        <div className="p-2 border-2 border-misc-ui-separator rounded-md bg-gray-200 whitespace-pre-line">
          {email}
        </div>
      </Collapsible.Content>
    </Collapsible.Root>
  );
}
