import axios from 'axios';

export async function getRedeemAccount(
  accountNo: string,
  redemptionDueDate: string | undefined,
  nationalId: string,
) {
  try {
    const { data } = await axios.post('/api/redeemAccount', {
      accountNo,
      redemptionDueDate,
      nationalId,
    });
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.data?.message);
    }
  }
}
