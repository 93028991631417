import { Layout } from '../../../components/Layout/Layout';
import { DecisionPanel } from '../../../components/Layout/DecisionPanel';
import { TaskProps } from '../../../types/TaskProps';
import { Stakeholders } from '../../../components/Stakeholders';
import { Tab } from '@headlessui/react';
import { DecisionsCard } from '../../../components/_shared/DecisionCard/DecisionCard';
import { EconomyCard } from '../../../components/_shared/EconomyCard/EconomyCard';
import { Fragment, useEffect, useRef } from 'react';
import { FlowContext } from '../../../hooks/useFlowContext';
import { useSituationState } from '../../../utils/useSituationState';
import SBL from '../../../components/_shared/SBL';
import CreditCheck from '../../../components/_shared/CreditCheck';
import { BehandleAnkeDecision } from './BehandleAnkeDecision';
import { FamilyCard } from '../../../components/_shared/FamilyCard/FamilyCard';
import { IncomeCard } from '../../../components/_shared/IncomeCard/IncomeCard';
import { DebtCard } from '../../../components/_shared/DebtCard/DebtCard';
import { AssetsCard } from '../../../components/_shared/AssetsCard/AssetsCard';
import { ScoringCardApplicant } from '../../../components/_shared/ScoringCard/ScoringCardApplicant';
import { ScoringCardLoan } from '../../../components/_shared/ScoringCard/ScoringCardLoan';
import { ScoringCardOther } from '../../../components/_shared/ScoringCard/ScoringCardOther';
import { PaymentPlanWrapper } from '../../../components/_shared/Paymentplan';
import { isSelectedLoan } from '../../../utils/isSelectedLoan';
import { useQuery } from '@tanstack/react-query';
import { fetchAttachmentsByFlowId } from '../../../utils/fetchAttachments';
import { Files } from '../../../components/_shared/Files';
import { MessageFromCustomerCard } from '../../../components/_shared/MessageFromCustomerCard/MessageFromCustomerCard';
import { ScoringCardPurpose } from '../../../components/_shared/ScoringCard/ScoringCard.Purpose';
import {
  initSituationStore,
  useSituationStore,
} from '../../stores/useSituationStore';
import { SuspenseWrapper } from '../../components/SuspenseWrapper';
import { LazyAbilityToPay } from '../../../components/Insight/AbilityToPay/LazyAbilityToPay';
import { ProofOfFinance } from '../../../components/Insight/ProofOfFinance/LazyProofOfFinance';
import { isFinancingApplication } from '../ManuellBehandling/utils/loanPurposeContextHelper';
import { LazyAgreementRegister } from '../../../components/Insight/AgreementRegister/LazyAgreementRegister';
import { initProofOfFinancingStore } from '../../stores/useProofOfFinancingStore';
import { TaskSummary } from '../../components/Summary/TaskSummary/TaskSummary';

export function BehandleAnkeOversikt(props: TaskProps) {
  const {
    flow,
    task: { data: savedData },
    t,
  } = props;
  const { flowId, data } = flow;
  const isInitializedStoresRef = useRef(false);
  const { decisions, economyItems, persons, incomes } = useSituationState(data);

  const { data: files } = useQuery({
    queryKey: ['files', flowId],
    queryFn: () => fetchAttachmentsByFlowId(flowId),
  });

  const attachmentLength = files?.attachments.length ?? 0;
  const tabs = [
    t('overview', 'Oversikt'),
    t('scoring', 'Scoring'),
    t('sbl', 'SBL'),
    t('abilityToPay', 'Betjeningsevne'),
    t('sikkerheter', 'Sikkerheter'),
    ...(isFinancingApplication(data)
      ? [t('proofOfFinance', 'Finansieringsbevis')]
      : []),
    t('creditCheck', 'Kredittsjekk'),
    t('filesWithCount', { count: attachmentLength }),
    t('paymentPlan', 'Nedbetalingsplan'),
  ];

  useEffect(() => {
    if (!isInitializedStoresRef.current) {
      initSituationStore(data, savedData.additionalData);
      if (isFinancingApplication(data)) {
        initProofOfFinancingStore(data.proofOfFinance);
      }

      isInitializedStoresRef.current = true;
    }
  }, [data, savedData.additionalData]);

  const debts = useSituationStore((state) => state.debts);
  const assets = useSituationStore((state) => state.assets);

  return (
    <FlowContext.Provider value={props}>
      <Layout>
        <div>
          <Tab.Group>
            <Tab.List className="sticky top-0 z-50 bg-white">
              {tabs.map((tab, index) => (
                <Tab as={Fragment} key={index}>
                  {({ selected }) => (
                    <button
                      className={`whitespace-nowrap border-b-2 border-transparent py-4 px-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700 ${
                        selected ? 'border-b-2 border-basic-ink' : ''
                      }`}
                    >
                      {tab}
                    </button>
                  )}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels className="h-full overflow-auto bg-gray-100 p-4">
              <Tab.Panel className=" space-y-12">
                <div className="grid grid-cols-2 gap-6">
                  <DecisionsCard {...decisions} />
                  <div className={'flex flex-col gap-4'}>
                    <EconomyCard {...economyItems} />
                    <MessageFromCustomerCard />
                  </div>
                </div>
                <FamilyCard {...persons} />
                <IncomeCard {...incomes} />
                <DebtCard debts={debts} />
                <AssetsCard assets={assets} />
              </Tab.Panel>
              <Tab.Panel className="grid grid-cols-2 gap-7">
                {data.stakeholders.map((stakeholder: any, index: number) => (
                  <ScoringCardApplicant key={index} applicant={stakeholder} />
                ))}
                <ScoringCardLoan />
                <ScoringCardPurpose />
                <ScoringCardOther />
              </Tab.Panel>
              <Tab.Panel>
                <SBL />
              </Tab.Panel>
              <Tab.Panel className="flex justify-center">
                <SuspenseWrapper>
                  <LazyAbilityToPay {...props} className="px-12" />
                </SuspenseWrapper>
              </Tab.Panel>
              <Tab.Panel className="h-full agreementregister-disabled">
                <SuspenseWrapper>
                  <LazyAgreementRegister {...props} />
                </SuspenseWrapper>
              </Tab.Panel>
              {isFinancingApplication(data) && (
                <Tab.Panel>
                  <SuspenseWrapper>
                    <ProofOfFinance />
                  </SuspenseWrapper>
                </Tab.Panel>
              )}
              <Tab.Panel>
                <CreditCheck />
              </Tab.Panel>
              <Tab.Panel>
                <Files {...props} />
              </Tab.Panel>
              <Tab.Panel>
                <PaymentPlanWrapper
                  data={data}
                  caseData={flow}
                  loan={debts.find(isSelectedLoan)}
                />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
        <div>
          <DecisionPanel>
            <Stakeholders stakeholders={data.stakeholders} />
            <TaskSummary />
            <BehandleAnkeDecision {...props} />
          </DecisionPanel>
        </div>
      </Layout>
    </FlowContext.Provider>
  );
}
