const { REACT_APP_CORE_BASE_URL, REACT_APP_CORE_COMPANY } = process.env;
export function openAccountInCore(accountNumber: string) {
  const url = new URL(
    `${REACT_APP_CORE_BASE_URL}/?page=70725575&filter=Account.'No.' IS '${accountNumber}'&company=${REACT_APP_CORE_COMPANY}`,
  );
  if (!!window) window.open(url, '_blank');
}

export function openUserInCore(contactNumber: string) {
  const url = new URL(
    `${REACT_APP_CORE_BASE_URL}/?page=5050&filter=Contact.'No.' IS '${contactNumber}'&company=${REACT_APP_CORE_COMPANY}&dc=0`,
  );
  if (!!window) window.open(url, '_blank');
}
