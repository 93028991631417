export function DownArrowIcon({ width = 22, height = 13 }) {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox={`0 0 22 13`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 12 12 2 6 8 1 3"
        stroke={'currentColor'}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M14 12h7V5"
        stroke={'currentColor'}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
}
