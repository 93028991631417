import { useFlowContext } from '../../../hooks/useFlowContext';
import { classNames } from '../../../utils/classNames';
import { HouseInvertedIcon } from '../../icons/HouseInvertedIcon';
import { HouseIcon } from '../../icons/HouseIcon';
import { CreditCardIcon } from '../../icons/CreditCardIcon';
import { CarIcon } from '../../icons/CarIcon';
import { BookIcon } from '../../icons/BookIcon';
import { Debt, LoanType } from './DebtCard.types';
import { isSelectedLoan } from '../../../utils/isSelectedLoan';
import { TaskProps } from '../../../types/TaskProps';
import { isBridgingLoanProduct } from '../../../customer/tasks/ManuellBehandling/utils/loanPurposeContextHelper';

function getDescription(debt: Debt, t: TaskProps['t']) {
  let description = t(debt.loanProduct) ?? '';

  if (!debt.isInternalLoan && !shouldDisplayId(debt)) {
    return description;
  }

  if (debt.sourceSystemCode === 'SYSCON') {
    return `${description} ${debt.accountId ?? ''} (${debt.sourceSystemCode})`;
  }

  return `${description} ${debt.accountId ?? ''}`;
}

function LoanDescription({ debt }: { debt: Debt }) {
  const { t } = useFlowContext();

  return <span>{getDescription(debt, t)}</span>;
}

export function LoanTypeCell({
  debt,
  className = '',
}: {
  debt: Debt;
  className: string;
}) {
  return (
    <div
      className={`flex w-full items-center gap-2 justify-between  ${className}`}
    >
      <div
        className={classNames(
          debt.toBeRedeemed ? 'text-neutral-500' : '',
          'whitespace-nowrap gap-2 items-center flex',
        )}
      >
        <DebtTypeSymbol debt={debt} />
        <LoanDescription debt={debt} />
      </div>
      <DebtLabel debt={debt} />
    </div>
  );
}
function DebtTypeSymbol({ debt }: { debt: Debt }) {
  if (
    debt.loanProduct === LoanType.CAR_BOAT_MC ||
    debt.loanProduct === LoanType.VEHICLES_TOTAL_LOAN
  ) {
    return <CarIcon color={debt.toBeRedeemed ? '#7A7C7A' : undefined} />;
  }

  if (debt.type === 'INSTALLMENT') {
    return debt.toBeRedeemed ? <HouseInvertedIcon /> : <HouseIcon />;
  }
  if (debt.type === 'CREDIT') {
    return <CreditCardIcon />;
  }

  if (debt.loanProduct === LoanType.STUDENT_LOAN) {
    return <BookIcon />;
  }

  return <HouseIcon />;
}
function DebtLabel({ debt }: { debt: Debt }) {
  if (isSelectedLoan(debt)) {
    return <Label value="new" className=" bg-secondary-300" />;
  } else if (debt.toBeRedeemed) {
    return <Label value="toBeRedeemed" className=" bg-negative-600" />;
  } else {
    return null;
  }
}
function Label({
  value,
  className = '',
}: {
  value: string;
  className?: string;
}) {
  const { t } = useFlowContext();
  return (
    <div
      className={`mr-4 flex h-6 items-center whitespace-nowrap rounded-3xl px-2.5 py-2 text-xs font-semibold text-white ${
        className ? className : 'bg-secondary-300'
      }`}
    >
      {t(value)}
    </div>
  );
}

function shouldDisplayId(debt: Debt) {
  return !isBridgingLoanProduct(debt.loanProduct) && !isSelectedLoan(debt);
}
