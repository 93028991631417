import { useFlowContext } from '../../hooks/useFlowContext';
import { useFormConnect } from '../../hooks/useFormConnect';
import { Text as FlowText } from '@flow/forms';

export function Text({
  label,
  id,
  placeholder = 'Skriv inn...',
}: {
  label: string;
  id: string;
  placeholder?: string;
}) {
  const { name, onChange } = useFormConnect(id);
  const { t } = useFlowContext();
  return (
    <FlowText
      id={name}
      name={name}
      placeholder={placeholder}
      onChange={onChange}
      label={t(label)}
    />
  );
}
