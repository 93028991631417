import { useCallback } from 'react';
import { useFlowContext } from '../../../hooks/useFlowContext';
import { PrimaryProgressButton } from '@flow/buttons';
import { Translation } from '../../../types/TaskProps';
import { DocumentHouseIcon } from '../../icons/DocumentHouseIcon';
import { DocumentationCard } from '../DocumentationCard/DocumentationCard';
import { FileUpload } from './FileUpload';

export function Documentation({
  documentationElements,
  saveDocument,
  deleteDocument,
  currentFile,
  setCurrentFile,
  note,
  setNote,
}: DocumentationProps) {
  const { t, task } = useFlowContext();
  const editable = task?.taskType === 'gjennomfor-utbetalings-kontroll';
  const hasDocuments = documentationElements.length > 0;

  const cancelUpload = useCallback(() => {
    setCurrentFile && setCurrentFile(undefined);
  }, [setCurrentFile]);

  return (
    <div className="w-full">
      {editable && (
        <div
          className={
            'mb-4 flex w-full flex-col justify-between gap-1 bg-white p-12 lg:flex-row'
          }
        >
          <div className={'w-full lg:w-6/12'}>
            <textarea
              id={'note'}
              name={'note'}
              placeholder={t('documentation-note-placeholder')}
              className="focus:outline-none h-full w-full bg-transparent p-2.5 text-left focus:border-transparent focus:bg-input focus:ring-2"
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
          </div>
          <div className={'m-auto flex w-full flex-col gap-y-4 lg:w-6/12 '}>
            <FileUpload
              onDelete={cancelUpload}
              currentFile={currentFile}
              setCurrentFile={setCurrentFile}
            />
            <div className={'grid place-items-end'}>
              <PrimaryProgressButton type="button" onClick={saveDocument}>
                {t('save')}
              </PrimaryProgressButton>
            </div>
          </div>
        </div>
      )}
      {hasDocuments ? (
        <div className={'flex flex-col gap-6'}>
          {documentationElements?.map((document: any, index: number) => (
            <DocumentationCard
              key={`document-id-${index}`}
              document={document}
              handleDelete={deleteDocument}
            />
          ))}
        </div>
      ) : (
        <EmptyState t={t} />
      )}
    </div>
  );
}

function EmptyState({ t }: { t: Translation }) {
  return (
    <div
      className={'flex h-full w-full flex-col items-center justify-center p-48'}
    >
      <DocumentHouseIcon />{' '}
      <h3 className={'mt-8 text-lg'}>{t('no-documentation-found')}</h3>
    </div>
  );
}

export type Attachment = {
  id: string;
  name: string;
  file: any;
};
export interface DocumentationElement {
  id: string;
  note?: string;
  attachment?: Attachment;
  sender?: string;
  timestamp: string;
  mimeType?: string;
  owner?: string;
}

interface DocumentationProps {
  documentationElements: DocumentationElement[];
  saveDocument?: (e: any) => void;
  deleteDocument?: (e: any) => void;
  currentFile?: any;
  setCurrentFile?: (data: any) => void;
  note: string;
  setNote: any;
}
