import { TaskProps } from '../../../types/TaskProps';
import { FormProvider, useForm } from 'react-hook-form';
import { TextArea } from '../../../components/_shared/TextArea';
import { useFlowContext } from '../../../hooks/useFlowContext';
import { DecisionButtonGroup } from '../../components/DecisionButtonGroup';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Stakeholder } from '../../../types/Stakeholder';
import { Select } from '../../../components/_shared/Select';

export function Decision({ save, complete }: TaskProps) {
  const { t, task, flow } = useFlowContext();

  const stakeholders: Stakeholder[] = flow?.data?.stakeholders || [];
  const isKYCCase = generateIsKYCValue(stakeholders);
  const isPEPCase = fetchIsPEPCaseValue(stakeholders);
  const method = useForm({
    defaultValues: getDefaultValues(task, flow),
    resolver: zodResolver(Decision.validationSchema),
  });

  const { handleSubmit } = method;

  const decisionOptions = [
    { value: '', label: '' },
    { value: 'approve', label: t('approveDeviations') },
    { value: 'sendToCompliance', label: t('sendToCompliance') },
  ];

  const decisionKYCOptions = [
    { value: '', label: '' },
    { value: 'approve-kyc', label: t('approve-kyc', 'Godkjenn KYC') },
    { value: 'reject-kyc', label: t('reject-kyc', 'Avslå KYC') },
  ];

  const onSubmit = (data: any) => {
    const structuredData = {
      assessment: data.assessment,
      ...(isPEPCase && {
        decisionPEP: data.decisionPEP,
      }),
      ...(isKYCCase && {
        decisionKYC: data.decisionKYC,
      }),
    };
    complete(structuredData);
  };

  return (
    <FormProvider {...method}>
      <form className="grid gap-6 py-8" onSubmit={handleSubmit(onSubmit)}>
        {isPEPCase && (
          <Select id="decisionPEP" label="PEP" options={decisionOptions} />
        )}
        {isKYCCase && (
          <Select id="decisionKYC" label="KYC" options={decisionKYCOptions} />
        )}
        <TextArea id="assessment" label={t('internalComment')} />
        <DecisionButtonGroup save={save} />
      </form>
    </FormProvider>
  );
}

function getDefaultValues(
  task: TaskProps['task'],
  flow: TaskProps['flow'],
): any {
  const stakeholders: Stakeholder[] = flow?.data?.stakeholders || [];
  const isKYCCase = generateIsKYCValue(stakeholders);
  const isPEPCase = fetchIsPEPCaseValue(stakeholders);

  return {
    decisionPEP: task.data?.decisionPEP,
    decisionKYC: task.data?.decisionKYC ?? '',
    assessment: task?.data?.assessment ?? '',
    isKYCCase,
    isPEPCase,
  };
}

Decision.validationSchema = z
  .object({
    decisionPEP: z.string().optional(),
    decisionKYC: z.string().optional(),
    assessment: z.string().refine((data) => data.replace(/ /g, '') !== '', {
      message: 'Kommentar er påkrevd',
    }),
    isKYCCase: z.boolean().optional(),
    isPEPCase: z.boolean().optional(),
  })
  .superRefine(
    ({ isPEPCase, decisionPEP, isKYCCase, decisionKYC }, context) => {
      if (isPEPCase && (!decisionPEP || decisionPEP === '')) {
        context.addIssue({
          code: 'custom',
          message: 'pep-is-required',
          path: ['decisionPEP'],
        });
      }
      if (isKYCCase && (!decisionKYC || decisionKYC === '')) {
        context.addIssue({
          code: 'custom',
          message: 'kyc-is-required',
          path: ['decisionKYC'],
        });
      }
    },
  );

function generateIsKYCValue(stakeholders: Stakeholder[]) {
  return stakeholders?.some((s) => {
    const declaration = s?.declaration;
    return (
      declaration?.incomeDeclaration?.hasOtherMainIncome ||
      declaration?.citizenshipDeclaration?.isForeignCitizen ||
      declaration?.citizenshipDeclaration?.foreignTax || // backwards compatible
      declaration?.citizenshipDeclaration?.foreignCitizenship || // backwards compatible
      declaration?.countryOfBirthDeclaration?.isBornOutsideOfNorway ||
      declaration?.paymentsDeclaration?.isExpectingPaymentsAbove100k ||
      declaration?.taxDeclaration?.isForeignCitizenTax
    );
  });
}

function fetchIsPEPCaseValue(stakeholders: Stakeholder[]) {
  return stakeholders?.some((stakeholder) => {
    return (
      stakeholder?.declaration?.pepDeclaration?.isPep ||
      stakeholder?.trapets?.hasHitInList
    );
  });
}
