import { PersonIcon } from '../../../components/icons/PersonIcon';
import { Card } from '../../../components/_shared/Card';
import { List } from '../../../components/_shared/List';
import { ListItemWithDecisionIconType } from '../../../components/_shared/ListItemWithDecisionIcon';
import { DecisionCode } from '../../../components/_shared/ScoringCard/DecisionsHelper';
import { useFlowContext } from '../../../hooks/useFlowContext';
import type { Stakeholder } from '../../../types/Stakeholder';
import TrapetsAccordion, { ItemsType } from './TrapetsAccordion';

export default function TrapetsCard({
  stakeholder,
}: {
  stakeholder: Stakeholder;
}) {
  const { t } = useFlowContext();
  const items: ListItemWithDecisionIconType[] = [
    {
      label: t('hasHitInList'),
      value: stakeholder?.trapets?.hasHitInList ? t('yes') : t('no'),
      decisionCode: stakeholder?.trapets?.hasHitInList
        ? DecisionCode.RED
        : DecisionCode.GREEN,
    },
  ];

  const trapetsItems: ItemsType = stakeholder?.trapets?.hits?.map((hit) => {
    const meta = stakeholder?.trapets?.meta?.find(
      (m) => m?.ExternalId === hit?.id,
    )!;
    return {
      label: hit?.name,
      value: hit?.id,
      items: [
        {
          label: t('id'),
          value: meta?.Id,
        },
        {
          label: t('name'),
          value: meta?.Name,
        },
        {
          label: t('title'),
          value: meta?.Title,
        },
        {
          label: t('protectedIdentity'),
          value: t(
            meta?.IsProtectedIdentity?.toString()?.toLowerCase() ?? 'false',
          ),
        },
        {
          label: t('hitRating'),
          value: meta?.HitRating,
        },
        {
          label: t('level'),
          value: meta?.Tier,
        },
        {
          label: t('gender'),
          value: meta?.IsMale ? t('male') : t('female'),
        },
      ],
    };
  });

  return (
    <Card>
      <Card.Header className="justify-between bg-grass-90 text-grass-20 ">
        <div className="flex gap-3">
          <PersonIcon height={24} width={24} />
          <Card.HeaderTitle>{stakeholder?.fullName}</Card.HeaderTitle>
        </div>
      </Card.Header>
      <Card.Content>
        <List items={items} />
        <TrapetsAccordion items={trapetsItems} />
      </Card.Content>
    </Card>
  );
}
