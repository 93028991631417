import React from 'react';
import { TaskProps } from '../../../../../types/TaskProps';
import { FlowContext } from '../../../../../hooks/useFlowContext';
import { ScoringCardApplicant } from '../../../../../components/_shared/ScoringCard/ScoringCardApplicant';
import { ScoringCardLoan } from '../../../../../components/_shared/ScoringCard/ScoringCardLoan';
import { ScoringCardPurpose } from '../../../../../components/_shared/ScoringCard/ScoringCard.Purpose';
import { ScoringCardOther } from '../../../../../components/_shared/ScoringCard/ScoringCardOther';
import { EmptyState } from './EmptyState';
import { Stakeholder } from '../../../../../types/Stakeholder';

export default function ScoringTab(props: TaskProps) {
  const { flow, t } = props;
  const { data } = flow;
  const hasSituation =
    !!data?.households?.[0]?.situations?.current?.betjeningsevne;

  if (!hasSituation) {
    return <EmptyState t={t} tab={t('scoring', 'Scoring')} />;
  }

  return (
    <FlowContext.Provider value={props}>
      <div className="grid grid-cols-2 gap-7 ">
        {data.stakeholders.map((stakeholder: Stakeholder, index: number) => (
          <ScoringCardApplicant key={index} applicant={stakeholder} />
        ))}
        <ScoringCardLoan />
        <ScoringCardPurpose />
        <ScoringCardOther />
      </div>
    </FlowContext.Provider>
  );
}
