export function TrendDownIcon() {
  return (
    <svg
      width="24"
      height="23"
      viewBox="0 0 24 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0344 5.54736L22.7395 16.3308L21.2529 17.6687L12.958 8.45218L6.99617 14.414L1.28906 8.70688L2.70328 7.29267L6.99617 11.5856L13.0344 5.54736Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 9V18H14V16H21V9H23Z"
        fill="currentColor"
      />
    </svg>
  );
}
