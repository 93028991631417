import { Debt } from '../../../../components/_shared/DebtCard/DebtCard.types';
import { useFlowContext } from '../../../../hooks/useFlowContext';

export function LoanTypeToggle({
  label,
  loan,
  onUpdate,
  initialValue,
  styling,
}: {
  label: string;
  loan: Debt;
  onUpdate: any;
  initialValue: string | number;
  styling?: string;
}) {
  const { t } = useFlowContext();

  const options = [
    { label: 'Annuitet', value: 'annuity' },
    { label: 'Serie', value: 'serial' },
  ];

  const handleChange = (e: any) => {
    const value = e.target.value;
    if (e.target.value !== initialValue) {
      onUpdate({
        ...loan,
        loanType: value,
      });
    }
  };

  return (
    <li
      className={`flex h-11 items-center justify-between px-4 text-xs ${styling}`}
    >
      <span>{t(label)}</span>
      <div>
        <div className="flex gap-3">
          {options.map((option) => (
            <div key={option.label} className="flex items-center">
              <input
                id={option.value}
                name="loanType"
                type="radio"
                value={option.value}
                checked={option.value === initialValue}
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                onChange={(e) => handleChange(e)}
              />
              <label
                htmlFor={option.value}
                className="ml-2 block text-xsfont-medium text-gray-700 "
              >
                {option.label}
              </label>
            </div>
          ))}
        </div>
      </div>
    </li>
  );
}
