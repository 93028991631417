import { useFlowContext } from '../../../hooks/useFlowContext';

interface Props {
  intentError?: string;
}

export function IntentionError({ intentError }: Props) {
  const { t } = useFlowContext();
  if (!intentError) return null;

  return (
    <div className="flex flex-col my-4 p-4 bg-negative-50">
      <span className="text-red-800 font-semibold">
        {t('settlementIntentionErrorMessage')}
      </span>
      <span className="text-sm pl-4 text-gray-600">{intentError}</span>
    </div>
  );
}
