import { HandMoneyIcon } from '../../icons/HandMoneyIcon';
import { Card } from '../Card';
import * as Collapsible from '@radix-ui/react-collapsible';
import { useFlowContext } from '../../../hooks/useFlowContext';
import { formatMoney } from '../../../utils/formatMoney';
import { useMemo } from 'react';
import { Chevron } from '../../icons/Chevron';
import { EmployerIncome } from '../../../types/SBL';
import { formatOrgNumber } from '../../../customer/tasks/ManuellBehandling/utils/formatOrgNumber';

type SBLCardProps = {
  employer: EmployerIncome;
};

type IncomeType = {
  id: string;
  isAccepted: boolean;
  elements: {
    monthKey: string;
    amount: number;
  }[];
};

type DeductionType = {
  id: string;
  elements: any;
};

type Totals = {
  monthKey: string;
  total: number;
};

type Acc = {
  incomes: IncomeType[];
  deductions: DeductionType[];
  advanceRateTotals: Totals[];
  deductionTotals: Totals[];
  incomeTotal: Totals[];
  months: { key: string; year: number }[];
};

function convertToKey(date: string) {
  return new Date(date)
    .toLocaleString('no-NO', {
      month: 'long',
    })
    .toUpperCase();
}

export default function SBLCard({ employer }: SBLCardProps) {
  const { t } = useFlowContext();

  const SBLData = useMemo(() => {
    return employer?.basis?.reduce(
      (acc, basis) => {
        // Basis = 1 unik måned
        const monthKey = convertToKey(basis?.date);
        const year = new Date(basis?.date).getFullYear();
        const monthData = acc.months?.find((e: any) => e.key === monthKey);
        if (!monthData) {
          acc.months?.push({
            key: monthKey,
            year,
          });
        }
        basis?.income?.forEach((incomeElement) => {
          let type = acc?.incomes?.find(
            (incomeType) => incomeType?.id === incomeElement?.inntektsInfo?.id,
          );

          if (!type) {
            type = {
              id: incomeElement?.inntektsInfo?.id,
              isAccepted: incomeElement?.isAccepted,
              elements: [],
            };
            acc.incomes.push(type);
          }

          const monthKey = convertToKey(basis?.date);
          const monthData = type.elements.find((e) => e.monthKey === monthKey);
          if (!monthData) {
            type.elements.push({
              monthKey,
              amount: incomeElement?.amount,
            });
          } else {
            monthData.amount += incomeElement?.amount;
          }
        });

        basis.fradrag?.forEach((deductionElement) => {
          let type = acc?.deductions?.find(
            (deductionType) =>
              deductionType?.id === deductionElement?.description,
          );

          if (!type) {
            type = {
              id: deductionElement?.description,
              elements: [],
            };
            acc?.deductions?.push(type);
          }
          type.elements.push({
            ...deductionElement,
            monthKey: convertToKey(basis?.date),
          });
        });
        acc.deductionTotals.push({
          total: basis?.totalFradrag,
          monthKey: convertToKey(basis?.date),
        });
        acc.advanceRateTotals.push({
          total: basis?.totalForskuddTrekk,
          monthKey: convertToKey(basis?.date),
        });
        acc.incomeTotal.push({
          total: basis?.totalIncome,
          monthKey: convertToKey(basis?.date),
        });
        return acc;
      },
      {
        incomes: [],
        deductions: [],
        advanceRateTotals: [],
        deductionTotals: [],
        incomeTotal: [],
        months: [],
      } as Acc,
    );
  }, [employer]);

  const orgId = employer?.virksomhetId;
  const organizationName = employer?.organizationName ?? '';

  return (
    <Collapsible.Root defaultOpen={true}>
      <Card>
        <Collapsible.Trigger className="group w-full bg-grass-90 py-2">
          <Card.Header className="flex justify-between bg-grass-90 text-grass-20">
            <div className="flex justify-between gap-3">
              <div className="my-auto flex">
                <HandMoneyIcon size={24} />
              </div>
              <div className="flex flex-col text-left">
                <span>Inntekter</span>
                <div>
                  {organizationName && (
                    <span className="text-sm">{`${organizationName}, `}</span>
                  )}
                  <span className="text-sm">
                    {t('org-nr', 'Orgnr.')} {formatOrgNumber(orgId)}
                  </span>
                </div>
              </div>
            </div>
            <div className="flex">
              <span>
                {t('last6Months')}:{' '}
                {formatMoney(employer?.totalAcceptedIncome, true)}
              </span>
              <div className="my-auto">
                <Chevron className="transform group-radix-state-open:rotate-180" />
              </div>
            </div>
          </Card.Header>
        </Collapsible.Trigger>
        <Collapsible.Content>
          <Card.Content horizontalPadding={false}>
            <table className="w-full text-basic-ink">
              <thead>
                <tr>
                  <th></th>
                  {SBLData.months?.map((month) => (
                    <th
                      key={month?.key}
                      className="text-right text-xs last:pr-5"
                    >
                      {`${month.key} ${month.year}`}
                    </th>
                  ))}
                </tr>
              </thead>

              <tbody>
                <tr className={`border-b border-misc-ui-separator`}>
                  <td className="py-3 pl-5 font-bold">{t('income')} </td>
                  {SBLData.months?.map((month, index) => {
                    const incomeTotal = SBLData?.incomeTotal?.find(
                      (e) => e.monthKey === month.key,
                    );
                    return (
                      <td
                        key={month?.key + index}
                        className="text-right last:pr-5"
                      >
                        {formatMoney(incomeTotal?.total!)}
                      </td>
                    );
                  })}
                </tr>
                {SBLData?.incomes?.map((incomeType) => (
                  <tr
                    key={incomeType?.id}
                    className={`border-b border-misc-ui-separator ${
                      incomeType?.isAccepted ? 'bg-grass-90' : ''
                    }`}
                  >
                    <td className="py-3 pl-5">
                      {t(incomeType?.id)}{' '}
                      {incomeType?.isAccepted ? (
                        <span className="text-grass-20">*</span>
                      ) : (
                        ''
                      )}
                    </td>
                    {SBLData?.months?.map((month, index) => {
                      const incomeElement = incomeType?.elements?.find(
                        (e: any) => e.monthKey === month.key,
                      );

                      return (
                        <td
                          key={month?.key + index}
                          className="text-right last:pr-5"
                        >
                          {formatMoney(incomeElement?.amount ?? '')}
                        </td>
                      );
                    })}
                  </tr>
                ))}
                <tr className={`border-b border-misc-ui-separator`}>
                  <td className="py-3 pl-5 font-bold">{t('deduction')} </td>
                  {SBLData.months?.map((month, index) => {
                    const deductionTotal = SBLData?.deductionTotals?.find(
                      (e) => e.monthKey === month.key,
                    );
                    return (
                      <td
                        key={month?.key + index}
                        className="text-right last:pr-5"
                      >
                        {formatMoney(deductionTotal?.total!)}
                      </td>
                    );
                  })}
                </tr>
                {SBLData?.deductions?.map((deductionType) => (
                  <tr
                    key={deductionType?.id}
                    className={`border-b border-misc-ui-separator`}
                  >
                    <td className="py-3 pl-5 font-bold">
                      {t(deductionType?.id)}{' '}
                    </td>
                    {SBLData?.months?.map((month, index) => {
                      const deductionElement = deductionType?.elements?.find(
                        (e: any) => e.monthKey === month.key,
                      );
                      return (
                        <td
                          key={month?.key + index}
                          className="text-right last:pr-5"
                        >
                          {formatMoney(deductionElement?.amount)}
                        </td>
                      );
                    })}
                  </tr>
                ))}
                <tr className={`border-b border-misc-ui-separator`}>
                  <td className="py-3 pl-5 font-bold">{t('advanceRate')} </td>
                  {SBLData.months?.map((month, index) => {
                    const advanceRateTotal = SBLData?.advanceRateTotals?.find(
                      (e) => e.monthKey === month.key,
                    );
                    return (
                      <td
                        key={month?.key + index}
                        className="text-right last:pr-5"
                      >
                        {formatMoney(advanceRateTotal?.total!)}
                      </td>
                    );
                  })}
                </tr>
              </tbody>

              <tfoot className="font-light text-grass-20">
                <tr>
                  <td className="py-5 pl-5">* {t('approvedIncomeElements')}</td>
                </tr>
              </tfoot>
            </table>
          </Card.Content>
        </Collapsible.Content>
      </Card>
    </Collapsible.Root>
  );
}
