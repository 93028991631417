import { useFlowContext } from '../../../hooks/useFlowContext';
import { useFormConnect } from '../../../hooks/useFormConnect';

export function RadioSelect({
  id,
  options,
  className = '',
  spanVertically = false,
}: {
  id: string;
  options: string[];
  className?: string;
  spanVertically?: boolean;
}) {
  const { t } = useFlowContext();
  const { onChange, value, error } = useFormConnect(id);

  return (
    <div
      className={`flex py-2 flex-${
        spanVertically ? 'col items-start' : 'row items-center'
      } justify-between ${className}`}
    >
      <label>{t(id)}</label>
      <div className="flex flex-col justify-center items-start">
        <div
          className={`flex flex-${
            spanVertically ? 'col items-start' : 'row items-start'
          }`}
        >
          {options.map((option) => (
            <div
              key={option}
              className="flex flex-row items-center justify-start h-7"
            >
              <input
                id={option}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500 cursor-pointer"
                onChange={(e) => onChange(e.target.value)}
                value={option}
                checked={value === option}
              />
              <label
                htmlFor={option}
                className="block px-2 cursor-pointer font-normal"
              >
                {t(option)}
              </label>
            </div>
          ))}
        </div>
        {error && <span className="text-negative-400 pt-1">{t(error)}</span>}
      </div>
    </div>
  );
}
