import { formatMoneyValue } from '../../utils/formatMoneyValue';
import { TaskProps } from '../../../../../types/TaskProps';
import { ListItemType } from '../../../../../components/_shared/ListItem';

type Props = {
  data: TaskProps['flow']['data'];
};
export function formatGracePeriodSummary({ data }: Props) {
  const loanDetails = data?.loanDetails;

  const summaryData = {
    priorityMortgageAmount: formatMoneyValue(loanDetails?.advanceDeposit),
    coopDebt: formatMoneyValue(loanDetails?.coopDebt),
    remainingLoanAmount: formatMoneyValue(loanDetails?.remainingLoanAmount),
    propertyValue: formatMoneyValue(loanDetails?.housingValue),
    loanToValue: `${
      !loanDetails?.ltv || isNaN(loanDetails?.ltv)
        ? '-'
        : `${(loanDetails?.ltv).toFixed(2)} %`
    }`,
    product: loanDetails?.product
      ? `${loanDetails?.product} - ${loanDetails?.nominalInterestRate}% (${loanDetails?.effectiveInterestRate}%)`
      : '-',
    loanType: loanDetails?.loanType ?? '-',
    hasActiveGracePeriod: loanDetails?.hasActiveGracePeriod ?? '-',
    remainingLoanDuration:
      !!loanDetails?.loanLengthYears || !!loanDetails?.loanLengthMonths
        ? `${(loanDetails?.loanLengthYears ?? 0).toFixed(
            0,
          )} år, ${loanDetails?.loanLengthMonths} måneder`
        : '-',
    gracePeriod: `${((loanDetails?.remainingGracePeriod ?? 0) / 12).toFixed(
      0,
    )} år`,

    requestedNumberOfTermsGracePeriod: data?.gracePeriod?.numberOfTermsRequested
      ?.terms
      ? `${(
          (data?.gracePeriod?.numberOfTermsRequested?.terms ?? 0) /
          (loanDetails?.termsPerYear || 12)
        ).toFixed(0)} år`
      : '-',
    amountOfLatePaymentsLast3years:
      loanDetails?.amountOfLatePaymentsLast3years ?? '-',
  };

  return Object.entries(summaryData).map(
    ([label, value]): ListItemType => ({
      label,
      value,
    }),
  );
}
