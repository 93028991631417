import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ThreeDotIcon } from '../icons/ThreeDotIcon';
import { classNames } from '../../utils/classNames';
import { useFlowContext } from '../../hooks/useFlowContext';

export interface MenuItem {
  label: string;
  handleClick?: React.MouseEventHandler<HTMLDivElement>;
  icon?: JSX.Element;
  nonClick?: boolean;
  selected?: boolean;
}

export type MenuProps = {
  items: MenuItem[];
};

export default function DropdownMenu({ items }: MenuProps) {
  const { t } = useFlowContext();
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="flex w-full justify-center rounded-md px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-200">
          <ThreeDotIcon aria-hidden="true" />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="focus:outline-none absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5">
          <div>
            {items.map((item, index) => {
              if (item?.nonClick) {
                return (
                  <div
                    key={index}
                    className="flex items-center rounded-t-md border-b border-gray-100 bg-gray-50 px-4 py-2 text-xs font-normal uppercase text-basic-wcag-aa"
                  >
                    {item.icon ?? null}
                    {t(item?.label)}
                  </div>
                );
              }
              return (
                <Menu.Item key={index}>
                  {({ active }) => {
                    return (
                      <div
                        className={
                          index + 1 !== items.length
                            ? 'border-b border-gray-100'
                            : ''
                        }
                      >
                        <div
                          className={classNames(
                            index + 1 === items.length && 'rounded-b-md',
                            index === 0 && 'rounded-t-md',
                            active || item?.selected
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-700',
                            'flex cursor-pointer items-center gap-2.5 px-4 py-2 text-xs font-normal uppercase text-basic-wcag-aa',
                          )}
                          onClick={item.handleClick}
                        >
                          {item.icon ?? null}

                          {t(item.label)}
                        </div>
                      </div>
                    );
                  }}
                </Menu.Item>
              );
            })}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
