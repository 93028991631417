import React from 'react';
import { useFlowContext } from '../../../hooks/useFlowContext';
import { ConditionalDecisionIcon } from '../ConditionalDecisionIcon';
import { Card } from '../Card';
import { BetjeningsevneIcon } from '../../icons/BetjeningsevneIcon';
import { DataTable } from '../DataTable';
import { ListItemWithDecisionIconType } from '../ListItemWithDecisionIcon';
import { Row } from 'react-table';
import WarningIcon from '../../icons/WarningIcon';

interface Props {
  decisions: ListItemWithDecisionIconType[];
  evaluationError?: any;
}

export function DecisionsCard({ decisions, evaluationError }: Props) {
  const { t } = useFlowContext();
  const columns = React.useMemo(
    () => [
      {
        accessor: 'label',
        columnType: 'left',
        Cell: ({ cell, row }: any) => (
          <div className="flex">{` ${t(cell.value, {
            context: t(row.original.context ?? ''),
          })}`}</div>
        ),
      },
      {
        accessor: 'value',
        Cell: ({ cell, row }: { cell: any; row: Row<any> }) => {
          return (
            <div className="float-right flex items-center gap-3">
              {cell.value?.[0] === '['
                ? JSON.parse(cell.value)
                    .map((item: any) => t(item))
                    .join(', ')
                : t(cell.value)}
              <div>
                <ConditionalDecisionIcon
                  decisionCode={row.original.decisionCode}
                />
              </div>
            </div>
          );
        },
      },
    ],
    [t],
  );
  return (
    <Card>
      <Card.Header className=" bg-negative-50 text-negative-900">
        <BetjeningsevneIcon />
        <Card.HeaderTitle>{t('decisions')}</Card.HeaderTitle>
      </Card.Header>
      <Card.Content>
        {evaluationError ? (
          <ErrorState errorMessage={evaluationError.message} />
        ) : decisions.length > 0 ? (
          <DataTable data={decisions} columns={columns} />
        ) : (
          <MissingDecisions />
        )}
      </Card.Content>
    </Card>
  );
}

function ErrorState({ errorMessage }: { errorMessage: string }) {
  const { t } = useFlowContext();
  return (
    <div className="border-red-500 p-4 border-2 ">
      <div className="flex items-center gap-2 text-negative-600">
        <WarningIcon />
        <div className="flex flex-col">
          <span>{t('evaluationError')}:</span>
          <span className="italic font-bold"> {t(errorMessage)}</span>
        </div>
      </div>
    </div>
  );
}

function MissingDecisions() {
  const { t } = useFlowContext();
  return (
    <div className="border-red-500 p-4 border-2 ">
      <div className="flex items-center gap-2 text-negative-600">
        <WarningIcon />
        <div className="flex flex-col">
          <span>{t('missingDecisionsMessage')}</span>
        </div>
      </div>
    </div>
  );
}
