export function formatMoneyValue(value: number | undefined): string | number {
  const numberValue = Number(value);
  if (isNaN(numberValue)) {
    return '-';
  }
  return new Intl.NumberFormat('no-NB', {
    style: 'decimal',
    currency: 'NOK',
  }).format(numberValue);
}
