import { Translation } from '../types/TaskProps';

export const getLoanDurationStringFromTermsAndTermLengthInMonths = (
  terms: number,
  termsPerYear: number,
  t: Translation,
) => {
  return buildLoanDurationString({
    durationMonths: (terms * termsPerYear) / 12,
    termsPerYear: termsPerYear,
    t,
  });
};

export function buildLoanDurationString({
  durationMonths,
  t,
  termsPerYear = 12,
}: {
  durationMonths: number;
  t: Translation;
  termsPerYear?: number;
}) {
  const months = durationMonths % termsPerYear;
  const years = Math.floor(durationMonths / termsPerYear);

  if (months === 0 && years === 0) {
    return t('noDuration');
  }

  const yearText = years > 0 ? `${years} ${t('year')}` : '';
  const monthText = months > 0 ? `${months} ${t('month')}` : '';
  return `${yearText} ${monthText}`;
}
