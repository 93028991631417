import React from 'react';
import { TaskProps } from '../../../../../types/TaskProps';

export function EmptyState({ t, tab }: { t: TaskProps['t']; tab: string }) {
  return (
    <div className="flex h-full min-h-40 flex-col items-center justify-center">
      <div className="text-lg font-semibold text-gray-700 max-w-lg text-center">
        <span>
          {t('not-enough-or-missing-data-found-in-tabs', {
            tab: tab,
          })}
        </span>
      </div>
    </div>
  );
}
