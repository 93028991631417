import { useState } from 'react';
import { Collateral } from './CollateralsCard.types';
import { isSelectedLoan } from '../../../utils/isSelectedLoan';

export function useCollateralsState(data: any) {
  const collateralValue =
    data.households[0]?.situations?.current?.situation?.debts?.find(
      isSelectedLoan,
    )?.value ?? 0;

  const initialState = data.loans?.flatMap((loan: any): Collateral[] =>
    (loan.securities || []).map(
      (security: any): Collateral => ({
        id: security.property.key,
        collateralType: security.property?.cadastralUnitNumber
          ? 'Pant i fast eiendom'
          : 'Pant i borettslag',

        value: collateralValue,
      }),
    ),
  );

  const [collaterals, setCollaterals] = useState<Collateral[]>(initialState);

  function onAdd() {
    throw new Error('Function not implemented.');
  }

  const onDelete = (collateralData: any) => {
    setCollaterals(
      collaterals.filter(
        (collateral: Collateral) => collateral.id !== collateralData.id,
      ),
    );
  };

  const onUpdate = (collateralData: any) => {
    const changedField = collateralData.columnId;
    const updated = collaterals.map((collateral: Collateral) => {
      return {
        ...collateral,
        [changedField]: collateralData.value,
      };
    });
    setCollaterals(updated);
  };

  return { collaterals, onAdd, onDelete, onUpdate };
}
