import React from 'react';
import { clsx } from 'clsx';
import { RadioGroup } from '@headlessui/react';
import { useFlowContext } from '../../hooks/useFlowContext';
import { useFormConnect } from '../../hooks/useFormConnect';

type Option<T> = {
  label: string;
  value: T;
};

type Props<T> = {
  options: Option<T>[];
  styling?: string;
  name: string;
  label: string;
};

export function RadioGroupBox<T>({ label, options = [], name }: Props<T>) {
  const { t } = useFlowContext();
  const props = useFormConnect(name);
  const { value, onChange, error } = props;

  return (
    <div className="grid gap-2">
      <label className="block font-medium text-gray-700">{label}</label>
      <RadioGroup value={value} onChange={onChange}>
        <RadioGroup.Label className="sr-only">{label}</RadioGroup.Label>
        <div className="divide-x-line divide-text grid grid-flow-col rounded border border-neutral-400 py-[4px] text-basic-ink">
          {options.map((option, index) => {
            return (
              <RadioGroup.Option
                key={option?.value?.toString()}
                value={option.value}
                className={({ checked }) =>
                  clsx(
                    ' focus:outline-none [&>*]:focus-visible:outline [&>*]:focus-visible:outline-2 [&>*]:focus-visible:outline-black h-radiogroup flex cursor-pointer items-center justify-center bg-white py-3 px-3 focus-visible:no-underline ',
                    checked
                      ? 'bg-primary-500 text-white underline'
                      : 'hover:bg-ecru focus:bg-ecru border-transparent text-basic-ink',
                    index === 0
                      ? 'border-r border-solid border-neutral-400'
                      : '',
                  )
                }
              >
                <RadioGroup.Label
                  as="span"
                  className="min-w-4 flex h-6 items-center"
                >
                  {option.label}
                </RadioGroup.Label>
              </RadioGroup.Option>
            );
          })}
        </div>
      </RadioGroup>
      {error && <span className="block pt-2 text-red-500">{t(error)}</span>}
    </div>
  );
}
