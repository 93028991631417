import { DecisionCode } from '../../../../components/_shared/ScoringCard/DecisionsHelper';
import { useState } from 'react';
import { DecisionId } from '../../../../components/_shared/ScoringCard/DecisionsHelper';
import { formatMoney } from '../../../../utils/formatMoney';

export type BetjeningsevneCommonType = {
  liquidityIndicator: number;
  disposableLiquidity: number;
  debtDegree: number;
  requestedAmount: number;
  acceptable: boolean;
  liquidityIndicatorAcceptable: boolean;
  disposableLiquidityAcceptable: boolean;
  details: any;
};
export interface RequestedLoanResponse extends BetjeningsevneCommonType {
  requestedAmount: number;
}

export interface MaxLoanResponse extends BetjeningsevneCommonType {
  maxLoanAmount: number;
}

export interface Betjeningsevne {
  maxLoan: MaxLoanResponse;
  requestedLoan: RequestedLoanResponse;
}

export function useDecisionsState(
  currentDecisions: any[],
  currentSituation: any,
  loans: any[],
) {
  const [decisions, setDecisions] = useState(() =>
    currentDecisions
      .filter(decisionFilter)
      .map((decision: any) => mapDecision(decision, currentSituation, loans)),
  );

  return { decisions, setDecisions };
}

export function mapDecision(
  decision: any,
  currentSituation: any,
  loans: any[],
) {
  return decision.scoring
    ? {
        label: decision.scoring?.label,
        value: mapDecisionValueFromState(
          decision.i18n,
          decision.scoring.value,
          currentSituation,
          loans,
        ),
        decisionCode: decision.scoring.decisionCode,
        context: decision.scoring.context,
      }
    : {
        // Currently in place for debug purposes.
        label: decision.i18n,
        value: 'value not mapped...',
        decisionCode: decision.code,
      };
}

const decisionsToExclude = [
  DecisionId.NOKKELTALL_INSOLVENCY,
  DecisionId.NOKKELTALL_HIGH_DEBT_DEGREE,
];

/**
 * Filter out decisions that are shown in the economy card, as well as potential duplicates.
 */
export const decisionFilter = (decision: any, index: number, self: any) =>
  !decisionsToExclude.includes(decision.i18n) &&
  index ===
    self.findIndex(
      (t: any) =>
        t.i18n === decision.i18n &&
        t.scoring?.context === decision.scoring?.context,
    ) &&
  decision.code !== DecisionCode.GREEN &&
  decision.type !== 'compliance';

function mapDecisionValueFromState(
  key: string,
  defaultValue: any,
  currentSituation: any,
  loans: any[],
) {
  switch (key) {
    case DecisionId.NOKKELTALL_ACCEPTABLE_WITHOUT_PAYOUT:
      const maxLoanAmount =
        currentSituation?.betjeningsevne?.maxLoan?.maxLoanAmount;
      return `Maks lånebeløp: ${formatMoney(maxLoanAmount)}`;
    case DecisionId.PAYOUT_REASON_OTHER:
      const loan = loans?.find((loan: any) => loan.isSelected);
      if (loan) {
        return JSON.stringify(
          loan.redemption.payouts.map((payout: any) => {
            if (payout.loanFor === 'other') {
              return payout.reason ?? payout.payoutReason;
            }
            return payout.loanFor;
          }),
        );
      }
      return (
        loans?.[0]?.redemption?.payout?.payoutReason ?? 'reason-not-stated'
      );
    default:
      return defaultValue;
  }
}
