import { create } from 'zustand';

interface EditingState {
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
}

export const useEditingStore = create<EditingState>((set) => ({
  isEditing: false,
  setIsEditing: (isEditing: boolean) => set({ isEditing }),
}));
