import { MaskedInput } from '../../../../../../components/_shared/Inputs/MaskedInput';
import { accountNumberMask } from '../../../../../../components/_shared/Inputs/masks';

type Props = {
  initialValue: string | number | undefined;
  id?: string;
  onUpdate: (e: any) => void;
  context?: string;
  name: string;
  styles?: string;
  hasError?: boolean;
};

export function AccountNumberInput({
  name,
  onUpdate,
  initialValue,
  id,
  context,
  styles = '',
  hasError = false,
}: Props) {
  const handleInputChange = (value: any) => {
    onUpdate({
      id,
      value: value,
      initialValue,
      context,
    });
  };

  return (
    <MaskedInput
      id={id}
      inputMode={'decimal'}
      name={name}
      value={initialValue}
      onChange={handleInputChange}
      mask={accountNumberMask}
      context={context}
      className={`focus:outline-none float-right justify-self-end border border-misc-ui-separator bg-input p-3 text-right focus:border-transparent focus:ring-2 ${styles} ${
        hasError && `border-l-0`
      }`}
    />
  );
}
