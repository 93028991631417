import React, { Fragment } from 'react';
import DropdownMenu from '../../../../../components/_shared/DropdownMenu';
import {
  Disbursement,
  LoanInput,
  Fee,
} from '../../../../../types/DisbursementTypes';
import { TrashIcon } from '../../../../../components/icons/TrashIcon';
import { formatMoney } from '../../../../../utils/formatMoney';
import { BookOpenIcon } from '../../../../../components/icons/BookOpenIcon';
import { useFlowContext } from '../../../../../hooks/useFlowContext';
import { HeartHouseIcon } from '../../../../../components/icons/HeartHouseIcon';
import { formatAccountNumber } from '../../../../../utils/formatAccountNumber';
import { DisbursementControlCardFeeRow } from './DisbursementControlCard.FeeRow';
import { WarningTooltip } from './WarningTooltip';
import { AccountNumberInput } from './Fields/AccountNumberInput';
import { TextInput } from './Fields/TextInput';
import { LoanAmountInput } from './Fields/LoanAmountInput';
import WarningIcon from '../../../../../components/icons/WarningIcon';
import { validateAccountNumberMOD11 } from '../../../../../libs/accountNumber';

type Props = {
  disbursements: Disbursement[];
  handleInputChange: (loanData: LoanInput) => void;
  handleDeleteValue: (elem: any) => void;
  customerPayout: any;
  handlePayoutChange: any;
  calculatedTotal: number;
  fees: Fee[];
};
export function DisbursementControlCardTableBody({
  disbursements,
  handleInputChange,
  handleDeleteValue,
  customerPayout,
  handlePayoutChange,
  calculatedTotal,
  fees,
}: Props) {
  const { t } = useFlowContext();
  const showAccountNumberWarning =
    !!customerPayout?.correctedAccountNumber &&
    customerPayout?.correctedAccountNumber !== customerPayout?.accountNumber;

  return (
    <Fragment>
      {disbursements?.map((elem: Disbursement, index: number) => {
        return (
          <tr
            key={`disbursement-${elem?.loanFor}-${index}`}
            className={'min-h-2.5rem border-b border-gray-300'}
          >
            <td className={'flex min-h-2.5rem  items-center gap-x-2 pl-4'}>
              <span className="text-basic-warning">
                <HeartHouseIcon />
              </span>
              {elem?.isInternalLoan ? (
                elem?.nameOfBank
              ) : (
                <TextInput
                  initialValue={elem?.nameOfBank}
                  id="nameOfBank"
                  name="nameOfBank"
                  context={elem?.id}
                  onUpdate={handleInputChange}
                  className="w-full min-w-10rem overflow-ellipsis border-b-0 border-t-0 text-left"
                />
              )}
            </td>
            <td className="pr-2 text-right">{elem?.loanNumber}</td>
            <td className="pr-2 text-right">
              {formatMoney(Number(elem?.loanAmount))}
            </td>
            <td>
              {elem.isInternalLoan ? (
                <TextInput
                  initialValue={formatAccountNumber(elem?.accountNumber)}
                  id="accountNumber"
                  context={elem?.id}
                  onUpdate={handleInputChange}
                  name="accountNumber"
                  className="w-full border-b-0 border-r-0 border-t-0 text-right"
                  readOnly={true}
                />
              ) : (
                <AccountInputWithError
                  elem={elem}
                  handleInputChange={handleInputChange}
                />
              )}
            </td>
            <td>
              <TextInput
                initialValue={elem?.ocr}
                id="ocr"
                context={elem?.id}
                onUpdate={handleInputChange}
                name="ocr"
                maxLength={30}
                className="w-full border-b-0 border-r-0 border-t-0 text-right"
                readOnly={elem.isInternalLoan}
              />
            </td>
            <td>
              <TextInput
                initialValue={elem?.message}
                id="message"
                context={elem?.id}
                onUpdate={handleInputChange}
                name="message"
                className="w-full border-b-0 border-t-0 text-right"
                readOnly={elem.isInternalLoan}
                maxLength={120}
              />
            </td>
            <td>
              {elem.isInternalLoan ? (
                <div className="text-right">
                  {formatMoney(elem.correctedAmount ?? 0)}
                </div>
              ) : (
                <LoanAmountInput
                  initialValue={elem?.correctedAmount}
                  id="correctedAmount"
                  context={elem?.id}
                  onUpdate={handleInputChange}
                  name="correctedAmount"
                  styles="w-full h-full border-t-0 border-b-0 text-right"
                />
              )}
            </td>
            {elem?.id?.startsWith('added-') ? (
              <td>
                <DropdownMenu
                  items={[
                    {
                      label: 'Slett',
                      icon: <TrashIcon />,
                      handleClick: () => handleDeleteValue(elem),
                    },
                  ]}
                />
              </td>
            ) : (
              <td>
                <div className="w-full px-5 py-2" />
              </td>
            )}
          </tr>
        );
      })}
      <DisbursementControlCardFeeRow fees={fees} />
      <tr className="min-h-2.5rem border-b border-gray-300">
        <td className="flex min-h-2.5rem items-center gap-x-2 pl-4">
          <BookOpenIcon /> Kundeutbetaling
        </td>
        <td className="pr-2 text-right">
          <div className="flex h-full w-full items-center justify-end gap-2">
            {customerPayout?.accountNumber &&
              formatAccountNumber(customerPayout?.accountNumber)}
            {showAccountNumberWarning && (
              <WarningTooltip text={t('account-number-mismatch')} />
            )}
          </div>
        </td>
        <td className="pr-2 text-right">
          {formatMoney(customerPayout?.amount)}
        </td>
        <td>
          <AccountNumberInput
            name="correctedAccountNumber"
            onUpdate={handlePayoutChange}
            initialValue={customerPayout?.correctedAccountNumber}
            styles="w-full h-full border-t-0 border-b-0 text-right"
            id="correctedAccountNumber"
          />
        </td>
        <td>
          <div />
        </td>
        <td>
          <TextInput
            className="w-full border-t-0 border-b-0 text-right"
            initialValue={customerPayout?.message}
            id="message"
            name="message"
            onUpdate={handlePayoutChange}
          />
        </td>
        <td
          className={`p-2 text-right ${
            calculatedTotal < 0 && `text-negative-600`
          }`}
        >
          <div
            className={`flex h-full w-full place-content-end ${
              calculatedTotal < 0 && `gap-2`
            }`}
          >
            {calculatedTotal < 0 && <WarningIcon size={18} />}
            {formatMoney(calculatedTotal)}
          </div>
        </td>
      </tr>
    </Fragment>
  );
}

const AccountInputWithError = ({
  elem,
  handleInputChange,
}: {
  elem: Disbursement;
  handleInputChange: (loanData: LoanInput) => void;
}) => {
  let isValidAccountNumber = false;
  if (!!elem.accountNumber && elem.accountNumber?.length > 8) {
    isValidAccountNumber = validateAccountNumberMOD11(
      elem.accountNumber ? elem.accountNumber : null,
    );
  }

  return (
    <div
      className={`flex w-full items-center ${
        !isValidAccountNumber && 'outline-error pl-2'
      }`}
    >
      {!isValidAccountNumber && <WarningIcon size={18} />}
      <AccountNumberInput
        initialValue={elem?.accountNumber}
        id="accountNumber"
        context={elem?.id}
        onUpdate={handleInputChange}
        name="accountNumber"
        styles="w-full h-full border-t-0 border-b-0 border-r-0 text-right"
        hasError={!isValidAccountNumber}
      />
    </div>
  );
};
