import axios from 'axios';

export async function uploadFile(files: any) {
  const form = new FormData();
  form.append('files', files);
  const { data } = await axios.post(`/api/flow/files`, form);
  return {
    id: data?.uploadedFiles[0]?.fileId as string,
    name: data?.uploadedFiles[0]?.filename as string,
  };
}

export async function getFiles(fileId: string) {
  return axios
    .get(`/api/flow/files/${fileId}`)
    .then((response) => response.data);
}

export async function deleteFile(fileId: string) {
  return await axios
    .delete(`/api/flow/files/${fileId}`)
    .then((response) => response.data);
}
