import { Listbox, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import { useFlowContext } from '../../hooks/useFlowContext';
import { classNames } from '../../utils/classNames';
import { Caret } from './Caret';

interface ListboxProps {
  initialValue: string | number;
  options: (string | number)[];
  rowId: number | string;
  onUpdate: any;
  columnId?: number | string;
  styles?: string;
}

export function ListboxCell({
  onUpdate,
  initialValue,
  options,
  rowId,
  columnId,
  styles,
}: ListboxProps) {
  const { t } = useFlowContext();
  const [value, setValue] = useState(initialValue);

  const onChange = (value: any) => {
    setValue(value);
    if (value !== initialValue) {
      onUpdate({ rowId, columnId, initialValue, value });
    }
  };

  return (
    <Listbox value={value} onChange={onChange}>
      <Listbox.Button className="flex bg-input border border-misc-ui-separator p-2.5 min-w-full justify-between whitespace-nowrap">
        {t(value?.toString())}
        <Caret />
      </Listbox.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Listbox.Options
          className={`absolute mt-2 origin-top-right z-10 w-full rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ${
            styles ?? ''
          }`}
        >
          <div className="py-1">
            {options.map((option, index) => (
              <Listbox.Option key={index} value={option} className="py-1">
                {({ active }) => (
                  <div
                    className={classNames(
                      active
                        ? 'bg-gray-100 text-gray-900'
                        : 'bg-white text-gray-700',
                      'px-4 py-2 text-sm flex gap-2 items-center cursor-pointer w-auto whitespace-nowrap',
                    )}
                  >
                    {t(option?.toString())}
                  </div>
                )}
              </Listbox.Option>
            ))}
          </div>
        </Listbox.Options>
      </Transition>
    </Listbox>
  );
}
