import { StateCreator } from 'zustand';
import { components } from '../../types/schemas/betjeningsevne';

export interface BetjeningsevneSlice {
  betjeningsevne: components['schemas']['RequestedAndMaxLoanResponse'];
  setBetjeningsevne: (
    betjeningsevne: components['schemas']['RequestedAndMaxLoanResponse'],
  ) => void;
  betjeningsevneShortTerm?: components['schemas']['RequestedAndMaxLoanResponse'];
  setBetjeningsevneShortTerm: (
    betjeningsevne: components['schemas']['RequestedAndMaxLoanResponse'],
  ) => void;
}

export const createBetjeningsevneSlice: StateCreator<
  BetjeningsevneSlice,
  [],
  [],
  BetjeningsevneSlice
> = (set) => ({
  betjeningsevne: {
    requestedLoan: {},
    maxLoan: {},
  },
  setBetjeningsevne: (
    betjeningsevne: components['schemas']['RequestedAndMaxLoanResponse'],
  ) => set({ betjeningsevne }),
  setBetjeningsevneShortTerm: (
    betjeningsevne: components['schemas']['RequestedAndMaxLoanResponse'],
  ) => set({ betjeningsevneShortTerm: betjeningsevne }),
});
