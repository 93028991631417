import { TaskProps } from '../../../types/TaskProps';
import { FormProvider, useForm } from 'react-hook-form';
import { Checkbox } from '../../../components/_shared/Checkbox';
import { TextArea } from '../../../components/_shared/TextArea';
import { useFormConnect } from '../../../hooks/useFormConnect';
import { DecisionButtonGroup } from '../../components/DecisionButtonGroup';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { DecisionError } from '../../components/DecisionError';
import { useFlowContext } from '../../../hooks/useFlowContext';
import { useState } from 'react';

const options = [
  { value: 'manual', label: 'Manuell behandling' },
  { value: 'toDecision', label: 'Til beslutning' },
];

export function ManualDecisionAssessment({ save, complete }: TaskProps) {
  const { task, t } = useFlowContext();
  const method = useForm({
    defaultValues: getDefaultValues(task),
    resolver: zodResolver(ManualDecisionAssessment.validationSchema),
  });
  const { handleSubmit, formState } = method;
  const { errors } = formState;
  const [submitErrors, setSubmitErrors] = useState<string[]>([]);

  const onSubmit = (data: any) => {
    complete(data, undefined, (error) => setSubmitErrors(error.errors));
  };

  return (
    <FormProvider {...method}>
      <form className="grid gap-2 py-8" onSubmit={handleSubmit(onSubmit)}>
        <h2 className=" text-sm font-semibold text-black">
          {t('vurder-behandlings-behov')}
        </h2>
        <div className="grid gap-6">
          <RadioButtonListWithCheckbox id="assessment" options={options} />
          <TextArea id="internalComment" label="internalComment" />
          <DecisionButtonGroup save={save} />
          {Object.keys(errors).map((index) => {
            return (
              <DecisionError
                key={index}
                text={errors[index]?.message?.toString() ?? ''}
              />
            );
          })}
          <div className="flex flex-col gap-2">
            {submitErrors.map((error, index) => (
              <div key={index} className="flex flex-col text-red-500">
                {error}
              </div>
            ))}
          </div>
        </div>
      </form>
    </FormProvider>
  );
}

export function RadioButtonListWithCheckbox({
  id,
  options,
}: RadioButtonListProps) {
  const { value, onChange, name } = useFormConnect(id);

  return (
    <div>
      <fieldset>
        <legend className="sr-only">Decision radio buttons</legend>
        <div className="space-y-3">
          {options.map((option) => (
            <div key={option.value}>
              <div className="flex items-center">
                <input
                  value={option.value}
                  id={option.value}
                  name={name}
                  type="radio"
                  checked={option.value === value}
                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  onChange={onChange}
                />

                <label
                  htmlFor={option.value}
                  className="ml-3 block text-sm font-medium text-gray-700"
                >
                  {option.label}
                </label>
              </div>
              {option.value === 'manual' && option.value === value && (
                <div className="mt-2 bg-primary-50 py-4">
                  <div className="ml-4 flex flex-col gap-2">
                    <Checkbox label="Dokumentasjon" id="documentation" />
                    <Checkbox label="Analyse" id="analysis" />
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </fieldset>
    </div>
  );
}

type RadioButtonListProps = {
  id: string;
  options: {
    label: string;
    value: any;
  }[];
};

ManualDecisionAssessment.validationSchema = z
  .object({
    assessment: z.string({
      required_error: 'assessmentRequired',
      invalid_type_error: 'assessmentRequired',
    }),
    internalComment: z.string().optional(),
    analysis: z.boolean().optional().default(false),
    documentation: z.boolean().optional().default(false),
  })
  .superRefine((val, context) => {
    if (val.assessment === 'manual' && !val?.analysis && !val?.documentation) {
      context.addIssue({
        code: 'custom',
        message: 'analysisOrDocumentationRequired',
        path: ['manual'],
      });
    }
  });

function getDefaultValues(task: TaskProps['task']): any {
  return {
    assessment: task.data?.assessment,
    internalComment: task.data?.internalComment ?? '',
    documentation: task.data?.documentation ?? false,
    analysis: task.data?.analysis ?? false,
  };
}
