import { TaskProps } from '../../../../types/TaskProps';

export function fetchDefaultValues(task: TaskProps['task']): any {
  const defaultInternalComment = task.data.internalComment ?? '';
  const defaultRedemptionDate =
    task.data.redemptionDate || task.context.redemptionDate || '';

  const defaultFinancingBridging = {
    isTransportReceived:
      task.data.checklistFinancingBridging?.isTransportReceived || undefined,
    isBrokerConsentReceivedForLandRegistry:
      task.data.checklistFinancingBridging
        ?.isBrokerConsentReceivedForLandRegistry || undefined,
    isMortgageDocumentRegistrationComplete:
      task.data.checklistFinancingBridging
        ?.isMortgageDocumentRegistrationComplete || undefined,
    isMortgageDocumentReceivedByPaperForSale:
      task.data.checklistFinancingBridging
        ?.isMortgageDocumentReceivedByPaperForSale || undefined,
  };

  const defaultFinancingMain = {
    isMortgageDocumentReceivedByPaperForPurchase:
      task.data.checklistFinancingMain
        ?.isMortgageDocumentReceivedByPaperForPurchase || undefined,
    isMortgageDocumentSentByPostToBroker:
      task.data.checklistFinancingMain?.isMortgageDocumentSentByPostToBroker ||
      undefined,
  };

  const defaultChecklistRefinancing = {
    priorityStatus:
      task.data.checklistRefinancing?.priorityStatus ??
      task.context.checklistRefinancing?.priorityStatus ??
      '',
    isMortgageRegistered:
      task.data.checklistRefinancing?.isMortgageRegistered ??
      task.context.checklistRefinancing?.isMortgageRegistered ??
      false,
  };

  return {
    internalComment: defaultInternalComment,
    redemptionDate: defaultRedemptionDate,
    checklistFinancingBridging: defaultFinancingBridging,
    checklistFinancingMain: defaultFinancingMain,
    checklistRefinancing: defaultChecklistRefinancing,
  };
}
