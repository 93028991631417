export function PadlockIcon({ width = 24, height = 24 }) {
  return (
    <svg
      fill="none"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        clipPath="url(#a)"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
      >
        <path d="M2.5 0h20v24h-20V0Zm2 2v20h16V2h-16Z" />
        <path d="M7.5 10h10v8h-10v-8Zm2 2v4h6v-4h-6Z" />
        <path d="M12.5 7a1 1 0 0 0-1 1v4h-2V8a3 3 0 1 1 6 0v4h-2V8a1 1 0 0 0-1-1Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" transform="translate(.5)" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
