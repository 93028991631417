import * as Accordion from '@radix-ui/react-accordion';
import { Chevron } from '../../../components/icons/Chevron';
import { List } from '../../../components/_shared/List';
import { ListItemType } from '../../../components/_shared/ListItem';

export type ItemsType = {
  label: string;
  value: string;
  items: ListItemType[];
}[];

export default function TrapetsAccordion({ items }: { items: ItemsType }) {
  return (
    <Accordion.Root type="multiple">
      {items.map((item) => (
        <Accordion.Item value={item.value} key={item.value}>
          <Accordion.Header>
            <Accordion.Trigger className="flex justify-between w-full">
              <span>{item.label}</span>
              <Chevron
                size={24}
                className="transform group-radix-state-open:rotate-180"
              />
            </Accordion.Trigger>
          </Accordion.Header>
          <Accordion.Content>
            <List items={item.items} />
          </Accordion.Content>
        </Accordion.Item>
      ))}
    </Accordion.Root>
  );
}
