import { DecisionPanel } from '../../../components/Layout/DecisionPanel';
import { Layout } from '../../../components/Layout/Layout';
import { Stakeholders } from '../../../components/Stakeholders';
import { FlowContext } from '../../../hooks/useFlowContext';
import { TaskProps } from '../../../types/TaskProps';
import { Decision } from './Decision';
import EndreNedbetalingsTid from './EndreNedbetalingsTid';

export default function EndreNedbetalingsTidMain(props: TaskProps) {
  const { flow } = props;
  const data = flow.data;

  return (
    <FlowContext.Provider value={props}>
      <Layout>
        <div className="container mx-auto my-10 max-w-md">
          <EndreNedbetalingsTid data={data} />
        </div>
        <div>
          <DecisionPanel>
            <Stakeholders stakeholders={data.stakeholders} />
            <Decision {...props} />
          </DecisionPanel>
        </div>
      </Layout>
    </FlowContext.Provider>
  );
}
