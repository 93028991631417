import { FileUpload } from '../Documentation/FileUpload';
import { PrimaryProgressButton } from '@flow/buttons';
import { useState } from 'react';
import {
  Attachment,
  DocumentationElement,
} from '../Documentation/Documentation';
import { useMutation } from '@tanstack/react-query';
import { triggerPendingMessageTask } from '../../../utils/messageTasks';
import { useFlowContext } from '../../../hooks/useFlowContext';

type Props = {
  handleCloseModal: () => void;
};
export const FileUploadView = ({ handleCloseModal }: Props) => {
  const {
    t,
    user,
    flow: { flowId, data },
  } = useFlowContext();
  const sender = user?.profile?.name;
  const [currentFile, setCurrentFile] = useState<Attachment>();
  const [documentationData, documentationDataSet] = useState<
    DocumentationElement[]
  >(data.documentation?.caseworker ?? []);

  const { mutate, isLoading } = useMutation({
    mutationKey: ['trigger-upload-document', currentFile],
    mutationFn: (document: DocumentationElement) =>
      triggerPendingMessageTask(flowId, 'trigger-upload-document', {
        documentationElement: document,
      }).then(() => {
        documentationDataSet([...documentationData, document]);
        setCurrentFile(undefined);
      }),
    onSuccess: () => {
      handleCloseModal();
    },
    onError: (err: any, newData, context) => {
      if (err.response) {
        console.error(err.response?.data);
      }
    },
  });

  async function saveDocument(e: any) {
    if (!currentFile) return;
    e.preventDefault();
    const newDocumentation: DocumentationElement = {
      id: currentFile?.id ?? `${documentationData.length + 1}`,
      attachment: currentFile,
      sender,
      timestamp: new Date().toISOString(),
      mimeType: currentFile?.file?.type ?? '',
      owner: 'caseWorker',
    };
    mutate(newDocumentation);
  }

  return (
    <div className="p-10 min-h-32 flex w-full flex-col justify-between gap-1">
      <FileUpload
        onDelete={() => {
          setCurrentFile(undefined);
        }}
        currentFile={currentFile}
        setCurrentFile={setCurrentFile}
      />
      <div className="grid place-items-start pt-4">
        <PrimaryProgressButton
          type="button"
          onClick={saveDocument}
          isLoading={isLoading}
        >
          {t('save')}
        </PrimaryProgressButton>
      </div>
    </div>
  );
};
