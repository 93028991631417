import { FormProvider, useForm } from 'react-hook-form';
import { TaskProps } from '../../../types/TaskProps';
import { Checkbox } from '../../../components/_shared/Checkbox';
import { TextArea } from '../../../components/_shared/TextArea';
import { DecisionButtonGroup } from '../../components/DecisionButtonGroup';
import { Stakeholder } from '../../../types/Stakeholder';
import { Text } from '../../../components/_shared/Text';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { DecisionError } from '../../components/DecisionError';
import { useFlowContext } from '../../../hooks/useFlowContext';
import { toPlainText } from '@portabletext/react';
import { findStakeholderByRole } from '../../../utils/findStakeholderByRole';
import { AdditionalValuesView } from '../../components/common/AdditionalValuesView';

const documentTypes = [
  'BusinessStatement',
  'EmploymentContract',
  'Lease',
  'Other',
  'ETariff',
  'CadastreInsurance',
  'PurchaseContractSoldProperty',
  'Equity',
];

const checkboxes = [
  {
    label: 'purchaseContractSoldProperty',
    value: 'PurchaseContractSoldProperty',
  },
  { label: 'equity', value: 'Equity' },
  {
    label: 'employmentContract',
    value: 'EmploymentContract',
  },
  {
    label: 'businessStatement',
    value: 'BusinessStatement',
  },
  {
    label: 'lease',
    value: 'Lease',
  },
  {
    label: 'eTariff',
    value: 'ETariff',
  },
  {
    label: 'cadastreInsurance',
    value: 'CadastreInsurance',
  },
  {
    label: 'other-documentation',
    value: 'Other',
  },
];

export function Decision({ save, complete }: TaskProps) {
  const { t, flow, task } = useFlowContext();
  const stakeholders: Stakeholder[] = flow?.data?.stakeholders;
  const defaultValues = createDefaultValues(stakeholders, task);
  const validationSchema = createValidationSchema(stakeholders);

  const method = useForm({
    defaultValues,
    resolver: zodResolver(validationSchema),
  });
  const { handleSubmit, watch, formState } = method;
  const documentsError = formState?.errors?.documents;

  const onSubmit = (data: any) => {
    complete(mapData(data));
  };

  return (
    <FormProvider {...method}>
      <form className="grid gap-8 py-8" onSubmit={handleSubmit(onSubmit)}>
        <AdditionalValuesView />
        <div className="flex flex-col gap-5">
          {stakeholders?.map((stakeholder) => {
            const showDescription = watch(
              `documents.${stakeholder?.type}Other`,
            ) as boolean;
            const showError =
              !!formState.errors?.[`${stakeholder?.type}Description`];
            return (
              <div key={stakeholder?.type} className="flex flex-col gap-3">
                <h2 className="text-base text-black ">
                  {t(stakeholder?.type)}
                </h2>
                <div className="flex flex-col gap-2">
                  {checkboxes.map((checkbox) => {
                    return (
                      <Checkbox
                        id={`documents.${stakeholder?.type}${checkbox.value}`}
                        label={t(checkbox.label)}
                        key={`${stakeholder?.type}${checkbox.value}`}
                      />
                    );
                  })}
                </div>
                {showDescription && (
                  <div>
                    <Text
                      id={`${stakeholder?.type}Description`}
                      label="descriptionOfDocumentation"
                      placeholder="Skriv inn... (vises på min side)"
                    />
                    {showError && <DecisionError text="descriptionRequired" />}
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <TextArea id="email" label="editable-email-text" />
        <TextArea id="internalComment" label="Intern kommentar (valgfritt)" />
        {!!documentsError && (
          <DecisionError text={t(`${documentsError?.message}`)} />
        )}
        <DecisionButtonGroup save={save} />
      </form>
    </FormProvider>
  );
}

function createValidationSchema(stakeholders: Stakeholder[]) {
  const documents = stakeholders.reduce((acc, stakeholder) => {
    const documents = documentTypes.reduce((acc, documentType) => {
      return {
        ...acc,
        [`${stakeholder?.type}${documentType}`]: z.boolean(),
      };
    }, {});
    return {
      ...acc,
      ...documents,
    };
  }, {} as any);
  const hasCoApplicant = stakeholders?.length === 2;
  if (hasCoApplicant) {
    return z
      .object({
        documents: z
          .object(documents)
          .refine(
            (input) => Object.values(input).some((value) => value === true),
            {
              message: 'documents-required',
            },
          ),
        email: z.string().optional(),
        internalComment: z.string().optional(),
        mainApplicantDescription: z.string().optional(),
        coApplicantDescription: z.string().optional(),
      })
      .superRefine((val, ctx) => {
        if (val.documents.mainApplicantOther && !val.mainApplicantDescription) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: `description-required`,
            path: ['mainApplicantDescription'],
          });
        }
      })
      .superRefine((val, ctx) => {
        if (val.documents.coApplicantOther && !val.coApplicantDescription) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: `description-required`,
            path: ['coApplicantDescription'],
          });
        }
      })
      .superRefine((val, ctx) => {
        if (!val.email || val.email === '') {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: `email-required`,
            path: ['email'],
          });
        }
      });
  }
  return z
    .object({
      documents: z
        .object(documents)
        .refine(
          (input) => Object.values(input).some((value) => value === true),
          {
            message: 'documents-required',
          },
        ),
      email: z.string().optional(),
      internalComment: z.string().optional(),
      mainApplicantDescription: z.string().optional(),
    })
    .superRefine((val, ctx) => {
      if (val.documents.mainApplicantOther && !val.mainApplicantDescription) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `description-required`,
          path: ['mainApplicantDescription'],
        });
      }
      if (!val.email || val.email === '') {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `email-required`,
          path: ['email'],
        });
      }
    });
}

function createDefaultValues(
  stakeholders: Stakeholder[],
  task: TaskProps['task'],
) {
  const defaultValues: any = {};
  defaultValues.documents = {};
  defaultValues.internalComment = task?.data?.internalComment ?? '';
  const body = task?.context?.email?.[0]?.body;
  const stakeholder = findStakeholderByRole(
    task?.variables?.role,
    stakeholders,
  );
  const email = toPlainText(body ?? '')?.replace(/#\{([^}]+)\}/g, () => {
    return stakeholder?.fullName!;
  });
  defaultValues.email = email ?? '';
  stakeholders.forEach((stakeholder) => {
    const purchaseContractSoldProperty = `documents.${stakeholder.type}PurchaseContractSoldProperty`;
    const equity = `documents.${stakeholder.type}Equity`;
    const businessStatement = `documents.${stakeholder.type}BusinessStatement`;
    const employmentContract = `documents.${stakeholder.type}EmploymentContract`;
    const lease = `documents.${stakeholder.type}Lease`;
    const other = `documents.${stakeholder.type}Other`;
    const eTariff = `documents.${stakeholder.type}ETariff`;
    const cadastreInsurance = `documents.${stakeholder.type}CadastreInsurance`;
    const description = `${stakeholder.type}Description`;
    defaultValues[businessStatement] =
      !!task?.data?.[businessStatement] ?? false;
    defaultValues[purchaseContractSoldProperty] =
      !!task?.data?.[purchaseContractSoldProperty] ?? false;
    defaultValues[equity] = !!task?.data?.[equity] ?? false;
    defaultValues[employmentContract] =
      !!task?.data?.[employmentContract] ?? false;
    defaultValues[lease] = !!task?.data?.[lease] ?? false;
    defaultValues[other] = !!task?.data?.[other] ?? false;
    defaultValues[eTariff] = !!task?.data?.[eTariff] ?? false;
    defaultValues[cadastreInsurance] =
      !!task?.data?.[cadastreInsurance] ?? false;
    defaultValues[description] = task?.data?.[description] ?? '';
  });
  return defaultValues;
}

function mapData(data: any) {
  const result: any = {};
  result.internalComment = data.internalComment;
  result.emailContent = data.email;
  result.documents = [
    ...documentTypes
      .filter((documentType) => data.documents[`mainApplicant${documentType}`])
      .map((documentType) => {
        const documentTypeTransformed = lowerCaseFirstLetter(documentType);
        if (documentType === 'Other') {
          return {
            owner: 'mainApplicant',
            documentType: documentTypeTransformed,
            description: data.mainApplicantDescription,
          };
        } else {
          return {
            owner: 'mainApplicant',
            documentType: documentTypeTransformed,
          };
        }
      }),
    ...documentTypes
      .filter((documentType) => data.documents[`coApplicant${documentType}`])
      .map((documentType) => {
        const documentTypeTransformed = lowerCaseFirstLetter(documentType);
        if (documentType === 'Other') {
          return {
            owner: 'coApplicant',
            documentType: documentTypeTransformed,
            description: data.coApplicantDescription,
          };
        } else {
          return {
            owner: 'coApplicant',
            documentType: documentTypeTransformed,
          };
        }
      }),
  ];
  return result;
}
function lowerCaseFirstLetter(str: string) {
  return str.charAt(0).toLowerCase() + str.slice(1);
}
