import { useTable } from 'react-table';
import { classNames } from '../../utils/classNames';

type DataTableProps = {
  data: any;
  columns: any;
  textLeft?: boolean;
  isEditable?: boolean;
};

export function DataTable({
  data,
  columns,
  textLeft = true,
  isEditable = false,
}: DataTableProps) {
  const {
    getTableProps,
    getTableBodyProps,
    headers,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });
  const hasHeadings =
    headers.filter((elem) => typeof elem.Header === 'string').length > 0;

  return (
    <div>
      <table {...getTableProps()} className="data-table w-full text-sm">
        {hasHeadings && (
          <thead
            className={classNames(
              textLeft ? 'text-left' : 'text-right',
              'text-xs uppercase text-basic-wcag-aa',
            )}
          >
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => {
                  if (
                    column.Header === '' &&
                    column.columnType === 'action' &&
                    isEditable === false
                  ) {
                    return null;
                  }
                  return (
                    <th
                      {...column.getHeaderProps()}
                      className={classNames(
                        'text-xs font-normal uppercase text-basic-wcag-aa',
                        column?.columnType === 'left'
                          ? 'text-left'
                          : 'text-right',
                      )}
                    >
                      {column.render('Header')}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
        )}
        <tbody {...getTableBodyProps()} className="divide-y divide-gray-200">
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell: any) => {
                  if (
                    cell?.column?.Header === '' &&
                    cell?.column?.columnType === 'action' &&
                    isEditable === false
                  ) {
                    return null;
                  }
                  return (
                    <td
                      {...cell.getCellProps()}
                      className={classNames(
                        `h-11 ${cell?.column?.cellStyles ?? ''}`,
                        cell?.column?.columnType === 'left'
                          ? 'text-left'
                          : 'text-right',
                      )}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
