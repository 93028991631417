import { SvgIconProps } from './SvgIcon.types';

export function HandMoneyIcon({ size = 18 }: SvgIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <g
        fill={'currentColor'}
        fillRule="evenodd"
        clipPath="url(#a)"
        clipRule="evenodd"
      >
        <path d="M15.5 2a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm-6 4a6 6 0 1 1 12 0 6 6 0 0 1-12 0ZM.5 11h2c.65 0 1.728.124 2.802.46.898.28 1.93.75 2.67 1.54H10.5a5 5 0 0 1 5 5v1h-8v-2h5.828a2.999 2.999 0 0 0-2.828-2H6.98l-.3-.426c-.356-.51-1.073-.924-1.974-1.205C3.83 13.095 2.954 13 2.5 13v5.382l6.014 3.006a3 3 0 0 0 2.224.185l10.49-3.228A2 2 0 0 0 19.338 17H16.5v-2h2.838a4 4 0 0 1 3.795 2.735l.639 1.92-12.446 3.83a5 5 0 0 1-3.706-.308L.5 19.618V11Z" />
        <path d="M16.5 4v4h-2V4h2Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M.5 0h24v24H.5z" />
        </clipPath>
      </defs>
    </svg>
  );
}
