import axios from 'axios';

export async function getGrunnboksutskriftPdf(
  flowId: string,
  propertyKey: string,
) {
  const { data } = await axios.get(
    `/api/flow/flows/${flowId}/attachments/grunnboksutskrift-${propertyKey}/download`,
    {
      responseType: 'blob',
    },
  );
  const file = new Blob([data], { type: 'application/pdf' });
  const url = URL.createObjectURL(file);
  window.open(url, '_blank');
}
