export function CalendarIcon({ color = '#AD362B' }) {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      height={16}
      width={16}
    >
      <path
        d="M7 12H5v1h2v-1ZM13 12h-2v1h2v-1Z"
        stroke={color}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M7 17H5v1h2v-1ZM13 17h-2v1h2v-1ZM19 12h-2v1h2v-1Z"
        stroke={color}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M7 12H5v1h2v-1ZM13 12h-2v1h2v-1ZM7 17H5v1h2v-1ZM13 17h-2v1h2v-1ZM19 12h-2v1h2v-1Z"
        fill={color}
      />
      <path
        d="M21 3H3a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h18a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2ZM6 1v3M18 1v3M1 8h22"
        stroke={color}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
}
