import { Fragment, ReactNode } from 'react';
import { useFlowContext } from '../../../../hooks/useFlowContext';
import { columnIds } from './DebtCard';

export function DebtHeader() {
  const { t } = useFlowContext();
  return (
    <Fragment>
      <ContentHeader>{t(columnIds.LOAN_TYPE)}</ContentHeader>
      <ContentHeader>{t(columnIds.BANK_NAME)}</ContentHeader>
      <ContentHeader>{t(columnIds.LOAN_OWNERS)}</ContentHeader>
      <ContentHeader className="mr-14 text-right">
        {t(columnIds.USED_CREDIT)}
      </ContentHeader>
      <ContentHeader className="text-right">
        {t(columnIds.GRACE_PERIOD)}
      </ContentHeader>
      <ContentHeader className="text-right">
        {t(columnIds.LOAN_DURATION)}
      </ContentHeader>
      <ContentHeader className="text-right pr-2 ">
        {t(columnIds.INTEREST_RATE)}
      </ContentHeader>
      <ContentHeader className="text-right">
        {t(columnIds.BALANCE)}
      </ContentHeader>
      <div />
    </Fragment>
  );
}

interface HeaderProps {
  children: ReactNode;
  className?: string;
}
function ContentHeader({ children, className }: HeaderProps) {
  return (
    <div className={`pb-4 text-xs uppercase ${className}`}>{children}</div>
  );
}
