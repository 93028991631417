import {
  Asset,
  AssetType,
  FuelType,
} from '../../../../components/_shared/AssetsCard/AssetsCard.types';
import { ListboxCell } from '../../../../components/_shared/ListboxCell';
import { NumericInput } from '../../../../components/_shared/NumericInput';
import { useFlowContext } from '../../../../hooks/useFlowContext';
import { CustomTextInput } from '../../GjennomforUtbetalingsKontroll/DisbursementControlCard/Components/Fields/CustomTextInput';
import { useSituationStore } from '../../../stores/useSituationStore';

export function Details({
  asset,
  onUpdate,
  editable,
  className = '',
}: {
  asset: Asset;
  onUpdate: any;
  editable?: boolean;
  className?: string;
}) {
  const { updateAssetNew } = useSituationStore();
  const { t } = useFlowContext();
  if (asset.type === AssetType.VEHICLE && editable) {
    return <VehicleDetails asset={asset} onUpdate={onUpdate} />;
  }

  if (asset.id?.toString().startsWith('new') && editable) {
    return (
      <CustomTextInput
        initialValue={asset.details ?? ''}
        id={asset.id}
        name={'details'}
        onUpdate={(val: string) => updateAssetNew({ ...asset, details: val })}
        styles={`w-full  border-t-0 text-left ${className}}`}
        placeholder={t('asset-details-placeholder')}
      />
    );
  }
  if (asset.hasUnknownAddress) {
    return (
      <CustomTextInput
        initialValue={asset.details ?? ''}
        id={asset.id}
        name={'details'}
        onUpdate={(val: string) =>
          handleUpdateAddress(asset, updateAssetNew, val)
        }
        styles={`w-full  border-t-0 text-left ${className}}`}
        placeholder={'Legg til adresse'}
      />
    );
  }
  return <span className={className}>{t(asset.details)}</span>;
}

function VehicleDetails({ asset, onUpdate }: { asset: Asset; onUpdate: any }) {
  return (
    <div className="flex items-center">
      <div className="relative w-auto">
        <ListboxCell
          initialValue={asset.fuelType ?? FuelType.GAS}
          options={Object.values(FuelType)}
          rowId={asset.id}
          onUpdate={onUpdate}
          columnId="fuelType"
        />
      </div>
      <NumericInput
        className="w-24"
        initialValue={asset.annualDrivingDistance}
        rowId={asset.id}
        columnId="annualDrivingDistance"
        onUpdate={onUpdate}
        suffix=" km"
      />
    </div>
  );
}

function handleUpdateAddress(
  asset: Asset,
  onUpdate: any,
  address: string,
): void {
  const updatedAsset = {
    ...asset,
    details: address,
    addressInformation: {
      ...asset.addressInformation,
      streetAddressName: address,
    },
  };

  onUpdate(updatedAsset);
}
