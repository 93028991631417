import React from 'react';
import { Card } from '../Card';
import { useFlowContext } from '../../../hooks/useFlowContext';
import { EmailIcon } from '../../icons/EmailIcon';

export function MessageFromCustomerCard() {
  const {
    t,
    task: { context },
  } = useFlowContext();
  const messageFromApplicant = context?.messageFromApplicant;

  if (!messageFromApplicant) {
    return null;
  }

  return (
    <Card>
      <Card.Header className="justify-between bg-neutral-400 text-neutral-900 ">
        <div className="flex items-center gap-3">
          <EmailIcon />
          <Card.HeaderTitle>
            {t('message-from-customer', 'Beskjed fra søker')}
          </Card.HeaderTitle>
        </div>
      </Card.Header>
      <Card.Content>{messageFromApplicant}</Card.Content>
    </Card>
  );
}
