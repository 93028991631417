import { ListItem, ListItemType } from './ListItem';
import {
  ListItemWithDecisionIcon,
  ListItemWithDecisionIconType,
} from './ListItemWithDecisionIcon';

export function List({ items }: { items: ListItemType[] }) {
  return (
    <ul className="divide-y divide-gray-200 text-sm">
      {items.map((item, index) =>
        isDecisionIconType(item) ? (
          <ListItemWithDecisionIcon key={index} item={item} />
        ) : (
          <ListItem key={index} item={item} />
        ),
      )}
    </ul>
  );
}

function isDecisionIconType(
  item: ListItemType,
): item is ListItemWithDecisionIconType {
  return (item as ListItemWithDecisionIconType).decisionCode !== undefined;
}
