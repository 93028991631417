import {
  MutationFunction,
  MutationKey,
  QueryFunction,
  QueryKey,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useState } from 'react';

interface Props {
  queryProps: {
    queryFn: QueryFunction;
    queryKey: QueryKey;
    initialData?: any;
    onSuccessFn?: (data: any) => void;
  };
  mutationProps: {
    mutationKey: MutationKey;
    mutationFn: MutationFunction;
  };
  refetchInterval?: number;
}

export function useMutationQueryFetcher({
  queryProps,
  mutationProps,
  refetchInterval = 500, // ms
}: Props) {
  const [shouldPoll, setShouldPoll] = useState(false);
  const {
    data,
    error: queryError,
    isFetching,
  } = useQuery({
    queryKey: queryProps.queryKey,
    initialData: queryProps.initialData,
    queryFn: queryProps.queryFn,
    refetchInterval,
    enabled: shouldPoll,
    onSuccess: (data) => {
      if (!data.isReevaluating) {
        queryProps.onSuccessFn && queryProps.onSuccessFn(data);
        setShouldPoll(false);
      }
    },
    onError: (error) =>
      console.error('[useQuery] error::: ', queryProps.queryKey, error),
  });

  const queryClient = useQueryClient();

  const {
    mutate,
    mutateAsync,
    error: mutationError,
    isLoading: isLoadingMutation,
  } = useMutation({
    mutationKey: mutationProps.mutationKey,
    mutationFn: mutationProps.mutationFn,
    onSuccess: () => setShouldPoll(true),
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: queryProps.queryKey });
      // Snapshot the previous value
      const previousevaluate = queryClient.getQueryData(queryProps.queryKey);
      // Optimistically update to the new value
      queryClient.setQueryData(queryProps.queryKey, (old: any) => ({
        ...(old && { ...old }),
        isReevaluating: true,
      }));
      // Return a context object with the snapshotted value
      return { previousevaluate };
    },
    onError: (err: any, newData, context) => {
      queryClient.setQueryData(queryProps.queryKey, {
        ...(context?.previousevaluate ?? {}),
        errors: err.response?.data?.errors ?? [],
      });
      if (err.response) {
        console.error(err.response?.data);
      }
    },
  });

  return {
    data,
    mutate,
    mutateAsync,
    mutationError,
    isFetching,
    queryError,
    isLoadingMutation,
    isReevaluating: data?.isReevaluating ?? false,
  };
}
